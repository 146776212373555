<div class="reporting-elements-list-view-container">
    <!--header-->
    <div class="reporting-element-header-container">
        <!--reporting element info-->
        <div class="header-element-info" style="display:flex">
            <div style="max-width:350px;">
                <div style="margin-top:10px;">
                    <span class="head-label">Template Code:</span>
                    <span class="head-value">{{selectedTemplateCode}}</span>
                </div>
                <!-- <div>
                    <span class="head-label">Template Name:</span>
                    <span class="head-value">{{selectedTemplateName}}</span>
                </div> -->
            </div>
            <div>
                <div style="display: flex;margin-left:15px;">
                    <div style="margin-top:-10px;">
                        <div style="font-size:10px;font-family:Montserrat Medium;color:grey;">Document</div>
                            <div style="width:150px;font-family:Montserrat Medium;" *ngIf="tempDocList.length">
                                <ng-select id="docid"
                                    style="width:150px;font-size:10px;"
                                    [items]="tempDocList" 
                                    bindLabel="value"
                                    bindValue="value" 
                                    [clearable]="false"
                                    (change)="documentSelectionChange(selectedDocumentCategory)"
                                    [(ngModel)]="selectedDocumentCategory">
                                    <ng-template ng-option-tmp let-item="item">
                                        <div [title]="item.value">{{item.value}}</div>
                                    </ng-template>
                                </ng-select>
                            </div>
                        </div>
                        <div style="margin-left:10px;margin-top:-10px;">
                            <div style="font-size:10px;font-family:Montserrat Medium;color:grey;">Template</div>
                            <div style="width:450px;font-family:Montserrat Medium;">
                                <ng-select id="docName"
                                    style="width:450px;font-size:10px;" 
                                    [items]="tempList" 
                                    bindLabel="labelTitle"
                                    bindValue="title" 
                                    [clearable]="false"
                                    [(ngModel)]="selectedDocument">
                                    <ng-template ng-option-tmp let-item="item">
                                        <div [title]="item.labelTitle"> {{item.labelTitle}}  </div>
                                    </ng-template>
                                </ng-select>
                            </div>
                        </div>
                        <div style="margin-top:7px;">
                            <button type="button" class="save-changes-btn" (click)="applyDocumentTypesFilter()"
                                style="width:40px;margin-left: 5px;">
                                Go
                            </button>
                        </div>

                        <!-- <div style="margin-top:7px;margin-left:20px;">
                            <button type="button" class="save-changes-btn">Save Changes</button>
                        </div> -->
                        <!-- <div style="margin-top:-10px;margin-left:20px;">
                                <div style="font-size:10px;font-family:Montserrat Medium;color:grey;">Set Relevance</div>
                                <div style="width:150px;font-family:Montserrat Medium;">
                                    <ng-select id="docid"
                                        style="width:150px;font-size:10px;"
                                        [items]="relevanceToCells" 
                                        bindLabel="label"
                                        bindValue="value" 
                                        [clearable]="false"
                                        (change)="setRelevanceForSelectedCells()"
                                        [(ngModel)]="relevanceOptionToset">
                                        <ng-template ng-option-tmp let-item="item">
                                            <div [title]="item.label">{{item.label}}</div>
                                        </ng-template>
                                    </ng-select>
                                </div>
                        </div> -->
                    </div>

            </div>
            
            <!-- <span>
                <span class="head-label">Legal Reference:</span>
                <span class="head-value">2014/680</span>
            </span> -->
        </div>
        <!--reporting element info-->
        <!--header-right-section-->
        <div class="header-right-section">
            
            <!-- <div class=rag-status style="padding-top:8px;">
                <app-r-a-g-status></app-r-a-g-status>
            </div> -->
            <!-- <div class="column-customization-box">
                <span class="dropdown">
                    <span data-toggle="dropdown" style="cursor: pointer;">Customize Column</span>
                    <img data-toggle="dropdown" src="../../../assets/images/icon-dropdown.svg" />
                    <ul class="dropdown-menu dropdown-menu-right">
                        <li *ngFor="let column of columns; let i=index" class="dropdown-item" style="padding:0px 8px;">
                            <label (change)="onCheckboxChange($event, i)" style="font-size:12px;margin-bottom:2px;">
                                <input type="checkbox" [value]="column.title" [checked] = "!column.isHidden" style="vertical-align: middle;"/>
                                {{column.title}}
                            </label>
                        </li>
                    </ul>
                </span>
            </div> -->
        </div>
        <!--header-right-section-->
    </div>
    <!--header-->
    <div style="margin-top:6px;font-size:12px;">* To set relevance for the selected cell, do right click to open menu after cell selection.</div>
    
    <!--reporting elements content list-->
    <div class="reporting-element-list-container" style="margin-top:12px;">
        <!-- <div class="data-table">
            <table class="width100">
                <thead>
                    <tr>
                        <th>Row ID</th>
                        <th>Column ID</th>
                        <th>Row Name</th>
                        <th>Column Name</th>
                        <th style="min-width:125px;">
                            Relevance
                            <span>
                                <img src="../../../../assets/images/icon-dropdown.svg" />
                            </span>
                        </th>
                        <th style="min-width:125px;">
                            Compliance
                            <span>
                                <img src="../../../../assets/images/icon-dropdown.svg" />
                            </span>
                        </th>
                        <th>Instruction</th>
                        <th>Assessment</th>
                        <th>Assessment Owner</th>
                        <th>Action</th>
                        <th>Action Owner/Department</th>
                        <th>Action Status</th>
                        <th style="min-width:95px;">Action Due Date</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let data of reportingElementsListData; let i = index;">
                        <td>
                            <span class="number-value">{{data.rowId}}</span>
                        </td>
                        <td>
                            <span class="number-value">{{data.colId}}</span>
                        </td>
                        <td>
                            <span class="text-value">{{data.rowName}}</span>
                        </td>
                        <td>
                            <span class="text-value">{{data.colName}}</span>
                        </td>
                        <td>
                            <span class="text-value">
                                <ng-select id="relevance{{i}}" 
                                    [items]="relevanceOptions" 
                                    bindLabel="label"
                                    bindValue="value" 
                                    [(ngModel)]="data.relevance"
                                    (change)="relevanceToReportingElementChanged(data)">
                                    <ng-template ng-label-tmp let-item="item">
                                        <div style="display:flex;">
                                            <div style="width:4px;border-radius:4px;margin-right:4px;" [style.background]="item.barColor"></div>
                                            <div>{{item.label}}</div>
                                        </div>
                                    </ng-template>
                                </ng-select>
                            </span>
                        </td>
                        <td>
                            <span class="text-value">
                                <ng-select id="compliance{{i}}" 
                                    [items]="complianceOptions" 
                                    bindLabel="label"
                                    bindValue="value" 
                                    [(ngModel)]="data.compliance"
                                    (change)="relevanceToReportingElementChanged(data)">
                                    <ng-template ng-label-tmp let-item="item">
                                        <div style="display:flex;">
                                            <div style="width:4px;border-radius:4px;margin-right:4px;" [style.background]="item.barColor"></div>
                                            <div>{{item.label}}</div>
                                        </div>
                                    </ng-template>
                                </ng-select>
                            </span>
                        </td>
                        <td>
                            <span class="text-value">{{data.instruction}}</span>
                        </td>
                        <td>
                            <span class="text-value">{{data.assessment}}</span>
                        </td>
                        <td>
                            <span class="text-value">
                                <ng-select id="compliance{{i}}" 
                                    [items]="assessmentOwnersList" 
                                    bindLabel="label"
                                    bindValue="value" 
                                    [(ngModel)]="data.assessment_owner"
                                    (change)="relevanceToReportingElementChanged(data)">
                                </ng-select>
                            </span>
                        </td>
                        <td>
                            <span class="text-value">{{data.action}}</span>
                        </td>
                        <td>
                            <span class="text-value">{{data.actionOwner}}</span>
                        </td>
                        <td>
                            <span class="text-value">{{data.actionStatus}}</span>
                        </td>
                        <td>
                            <span class="text-value">{{data.actionDueDate}}</span>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div> -->
        <div class="reporting-element-list-tab-container" id="reporting-element-list-tab-container" >
            <div class="hot" style="font-family: 'Montserrat Medium';font-size: 12px;" id="reporting-element-list-tab-table">
            </div>
        </div>
    </div>
    <!--reporting elements content list-->
</div>
<!--loader-->
<div style="position: absolute;top:40%;left:40%;" *ngIf="showLoader">
    <img src="../../../assets/images/loader1.gif"/>
</div>


