import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie';
import { ArticlesDataService } from '../../shared/services/articles-data-service/articles-data.service';
import { environment } from '../../../environments/environment';
import { DataServiceService } from '../../shared/services/data-service/data-service.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {
  userName: string = null;
  articlesIds: Array<any> = [];
  
  constructor(
    private router: Router,
    private _cookie: CookieService,
    private _articlesDataService: ArticlesDataService,
    private _dataService: DataServiceService
  ) {}

  ngOnInit(): void {
    this.userName = this._cookie.get("firstName") + ' ' +this._cookie.get("lastName");
    // this.getAssessment();
  }

  logoutUser(): void{
    this._cookie.remove('_id');
    this._cookie.remove('email');
    this._cookie.remove('firstName');
    this._cookie.remove('lastName');
    this._cookie.remove('token');
    this.router.navigate(['/login']);
  }

  isActive(url): boolean {
    return this.router.url.includes(url);
}

  getAssessment(): void {
    var url = environment.apiUrls.getAssessmentUrl;

    this._dataService.getData(url, [], true).toPromise()
        .then((response: any) => {
            if (response.status == 1) {
                response.data.forEach((item) => {
                    this.articlesIds.push(item._id);
                });
                this._articlesDataService.articlesIdsList = this.articlesIds;
            }
        }, (error) => {
            if (error.error) {
                // this._toastr.error(error.error.message);
            }
        });
}

}
