import { Injectable } from '@angular/core';
import { HttpRequest, HttpInterceptor, HttpHandler, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError, empty } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { CookieService } from 'ngx-cookie';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
@Injectable({
  providedIn: 'root'
})
export class HttpInterceptService implements HttpInterceptor{

  constructor(
    private router: Router,
    private _cookie: CookieService,
    private _toastr: ToastrService,
  ) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    return next.handle(request)
      .pipe(
        catchError((error, caught) => {
          if (error.error instanceof ErrorEvent) {
            // A client-side or network error occurred. Handle it accordingly.
          } else {
            if(error.status == 401){
              this._toastr.error(error.error.message);
              this._cookie.remove('_id');
              this._cookie.remove('email');
              this._cookie.remove('firstName');
              this._cookie.remove('lastName');
              this._cookie.remove('token');
              this.router.navigate(['/login']);
            } else if(error.status == 400 && error.error){
              return throwError(error.error);
            } else {
              return throwError(error);
            }
          }
          return empty();
        })
      );
  }
}



