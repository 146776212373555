import { Component, OnInit } from '@angular/core';
import { DataServiceService } from '../../shared/services/data-service/data-service.service';
import { environment } from '../../../environments/environment';
import Handsontable from 'handsontable';
import { ToastrService } from 'ngx-toastr';
import { Router, ActivatedRoute } from '@angular/router';

declare var $: any;


@Component({
  selector: 'app-reporting-elements-list',
  templateUrl: './reporting-elements-list.component.html',
  styleUrls: ['./reporting-elements-list.component.scss']
})
export class ReportingElementsListComponent implements OnInit {
  reportingElementsListData: Array<any> = [];
  relevanceOptions: Array<any> = [{label:'Yes', value:'yes', barColor: '#008000'},{label:'No', value:'no', barColor: '#b32d00'}];
  complianceOptions: Array<any> = [{label:'Partial', value: 'partial', barColor: '#ffcc00'},{label:'None', value: 'none', barColor: '#b32d00'},{label:'Full', value: 'full', barColor: '#008000'}];
  usersListForDropdown: Array<any> = [];
  usersList: Array<any> = [];
  getTableData: [];
  showLoader: Boolean = false;
  showContext =false;

  hotSettings: Handsontable.GridSettings;
  id: any = 'reporting-element-list-tab-table';
  tableInstance: Handsontable;
  relevanceToCells: Array<any> = [{label:'Yes', value: 'yes', barColor: 'green'},{label: 'No', value: 'no', barColor: 'lightblue'}];
  relevanceOptionToset: any;
  tempDocList: Array<{id:string,value:string, templates: any }> = [];
  selectedDocumentCategory: any ;
  tempList: Array<any> = [];
  tempFilterOptions: any = [{
    key: 'C73 - REPORTING ON LIQUIDITY (PART 2 OUTFLOWS)',
    templateCode: 'C73',
    templateName: 'REPORTING ON LIQUIDITY (PART 2 OUTFLOWS)',
    table: {
      rows: ['010', '020', '030', '035', '040', '050', '060', '070', '080', '090', '100', '110', '120', '130', '140', '150', '160', '170', '180', '190', '200', '203', '204', '205', '206', '207', '210', '220', '230', '240', '250', '260', '270', '280', '290', '300', '310', '340', '350', '360', '370', '380', '390', '400', '410', '420', '430', '450', '460', '470', '480', '490', '500', '510', '520', '530', '540', '550', '560', '570', '580', '590', '600', '610', '620', '630', '640', '650', '660', '670', '680', '690', '700', '710', '720', '731', '740', '750', '760', '770', '780', '850', '860', '870', '885', '890', '900', '912', '913', '914', '915', '916', '917', '918', '920', '930', '940', '945', '950', '955', '960', '965', '970', '975', '980', '985', '990', '995', '1000', '1005', '1010', '1020', '1030', '1035', '1040', '1045', '1050', '1055', '1060', '1065', '1070', '1075', '1080', '1085', '1090', '1095', '1100', '1130', '1170', '1170 RegArt', '1180', '1190', '1200', '1210', '1210 RegArt', '1290', '1300', '1310', '1320', '1330', '1340', '1345', '1350', '1360', '1370', '1370 RegArt', '1400', '1410', '1420', '1430', '1440'],
      columns: ['010', '020', '030', '040', '050', '060']
    }
  },{
    key: 'C72 - REPORTING ON LIQUIDITY (PART 1: LIQUID ASSETS)',
    templateCode: 'C72',
    templateName: 'REPORTING ON LIQUIDITY (PART 1: LIQUID ASSETS)',
    table: {
      rows: ['010', '020', '030', '040', '050', '060', '070', '080', '090', '100', '110', '120', '130', '140', '150', '160', '170', '180', '190', '200', '210', '220', '230', '240', '250', '260', '270', '280', '290', '300', '310', '320', '330', '340', '350', '360', '370', '380', '390', '400', '410', '420', '430', '440', '450', '460', '470', '485', '580', '590'],
      columns: ['010', '020', '030', '040']
    }
  },{
    key: 'C73 - REPORTING ON LIQUIDITY (PART 3: INFLOWS)',
    templateCode: 'C73',
    templateName: 'REPORTING ON LIQUIDITY (PART 3: INFLOWS)',
    table: {
      rows: ['010', '020', '030', '040', '050', '060', '070', '080', '090', '100', '110', '120', '130', '140', '150', '160', '170', '180', '190', '201', '210', '230', '240', '250', '260', '263', '265', '267', '269', '271', '273', '275', '277', '279', '281', '283', '285', '287', '289', '291', '293', '295', '297', '299', '301', '303', '305', '307', '309', '311', '313', '315', '317', '319', '321', '323', '325', '327', '329', '331', '333', '335', '337', '339', '341', '343', '345', '410', '420', '430', '450', '460', '470', '480', '490', '500', '510', '510 RegArt', '530', '540', '550', '560', '570'],
      columns: ['10', '20', '30', '40', '50', '60', '70', '80', '90', '100', '110', '120', '130', '140', '150', '160']
    }
  }];
  selectedDocument: any;
  selectedTemplateName: any = this.tempFilterOptions[0].templateName;
  selectedTemplateCode: any;
  disableFilter: any = true;
  getParamData: any;

  constructor(
    private _dataService: DataServiceService,
    private router: Router,
    private route: ActivatedRoute
  ) { }

  ngOnInit(): void {
    console.log('height', screen.height)
    let box = document.getElementById('reporting-element-list-tab-container');
    let heightForBox = screen.height - 300;
    box.style.cssText = `height:${heightForBox}px`;
    // this.getUsersList();
    // this.getReportingElementsList();
    this.getParamData = this.route.snapshot.params;
    this.getReportingList();
    
    // this.prepareTempTable(this.tempFilterOptions[0].table.rows, this.tempFilterOptions[0].table.columns);
    
    
  }

  getReportingList(){
    var url = environment.apiUrls.getDocumentReportingList;

    this._dataService.getData(url, [], true).toPromise()
    .then((response: any) => {
      if (response.status == 1) {
        this.tempDocList = [];
        response.data.forEach((item) => {
          this.tempDocList.push({
            value: item.title,
            id: item._id,
            templates: item.templates
          })
        });

        this.tempDocList.forEach((item) => {
          item.templates.forEach((temp) => {
            temp.labelTitle = temp.code + ' | ' + temp.title
          })
         });

        if(this.getParamData && this.getParamData.docId){
          this.tempDocList.forEach((item) =>{
            if(item.id == this.getParamData.docId){
              this.selectedDocumentCategory = item.value;
              this.tempList = item.templates;
            }
          });
          if(this.getParamData.templateId){
            this.tempList.forEach((item) => {
              if(item._id == this.getParamData.templateId){
                this.selectedDocument = item.title;
              }
            });
          }else{
            this.selectedDocument = this.tempList[0].title;
          }
            
        }else{
          this.selectedDocumentCategory = this.tempDocList[0].value;
          
           
           this.tempList = this.tempDocList[0].templates;
          this.selectedDocument = this.tempList[0].title;
        }
        this.applyDocumentTypesFilter();
      }
    })
  }

  documentSelectionChange(selectedDocument): any{
    this.tempDocList.forEach((item) => {
      if(item.value === selectedDocument){
        this.tempList = [];
        this.tempList = item.templates;
        this.selectedDocument = this.tempList[0].title;
      }
    })
  }

  onCheckboxChange(e, index) {
    var plugin = this.tableInstance.getPlugin('hiddenColumns');
    const isChecked = e.target.checked;
    if (isChecked) { // remove from hidden columns
        plugin.showColumn(index);
    } else {
        plugin.hideColumn(index);
    }
    this.tableInstance.render();
}

generateDataObj(rows, columns): any {
  var data = [];
  var number = 0;

  // if (!rows) {
  //   rows = 3;
  // }
  // if (!columns) {
  //   columns = 7;
  // }

  for (var i = 0; i < rows; i++) {
    data[i] = [];
    for (var j = 0; j < columns; j++) {
      data[i][j] = '';
    }
  }

  // for (i = 0; i < 2; i++) {
  //   data.push([]);
  // }

  return data;
};

applyDocumentTypesFilter(): any {
  let newTableConfig;
  // this.tempFilterOptions.forEach((item) => {
  //   if(item.key == this.selectedDocument){
  //     newTableConfig = item.table;
  //     this.selectedTemplateName = item.templateName;
  //     this.selectedTemplateCode = item.templateCode;
  //   }
  // });
     this.selectedTemplateName = this.selectedDocumentCategory;

     let selectedDocId, selectedTempId;
     this.tempDocList.forEach((item) => {
       if(item.value == this.selectedDocumentCategory){
         selectedDocId = item.id;
       }
     });

     this.tempList.forEach((item) => {
      if(item.title == this.selectedDocument){
        selectedTempId = item._id;
        this.selectedTemplateCode = item.code;
      }
    });
   
  const urlParams = [{
    key: 'documentId',
    val: selectedDocId
  }, {
    key: 'templateId',
    val: selectedTempId
  }]
  
  var url = environment.apiUrls.getReportingData ;
  url = url.replace(':documentId', selectedDocId )
  url = url.replace(':templateId', selectedTempId)
   this.showLoader = true;
   $('.reporting-element-list-container').addClass('displayNone');
    this._dataService.getData(url, urlParams, true).toPromise()
        .then((response: any) => {
          if(response.status === 1) {
            this.getTableData = response.data;
            if(this.tableInstance){
              this.tableInstance.destroy();
             
            }
            this.prepareSelectedTable(this.getTableData);
          }
        })
  
}

prepareTempTable(inputRows, inputColumns){
  var self = this;
  this.hotSettings = {
    data: self.generateDataObj(inputRows.length, inputColumns.length),
    colWidths: 100,
    colHeaders: inputColumns,
    rowHeaders: inputRows,
    contextMenu: true,
    manualRowMove: true,
    bindRowsWithHeaders: 'strict'
  };
  const container = document.getElementById(this.id);
  this.tableInstance = new Handsontable(container, this.hotSettings);
}

prepareSelectedTable(inputData){
  var self = this;
  let columnWidths = [];
  for(let i = 0; i < inputData.cells[0].length; i++){
    if(i == 0 || i == 1){
      columnWidths.push(60);
    }else{
      if(i == 2){
        columnWidths.push(250);
      }else{
        columnWidths.push(120);
      }
    }
  }
  this.hotSettings = {
    data: inputData.data,
    colWidths: columnWidths,
    rowHeights: 60,
    colHeaders: false,
    rowHeaders: false,
    rowHeaderWidth: 70,
    fixedRowsTop: 2,
    fixedColumnsLeft: 3,
    // copyPaste: true,
    beforeContextMenuShow: function(context) {
      console.log('wait...',context)
      return false;
    },
    beforeOnCellContextMenu: function(event, cord, td) {
      self.showContext = td.classList.contains('header') ? true : false;
    },
    afterContextMenuShow: function(context) {
      if(self.showContext) {
      $('.htMenu .htCore').first().addClass("noShow")
      }
    },
    contextMenu: {
      callback: function (key, selection, clickEvent) {
        // Common callback for all options
        console.log( 'ggggggg',selection);
      
      },
      items: {
        // 'copy': {},
        // 'paste': {},
        // 'cut': {},
        "Relevance": { // Own custom option
          name: 'Relevance',
          hidden: function() {
            let tableSelectedData = self.tableInstance.getSelectedRange();
            if(tableSelectedData && tableSelectedData[0]) {
              var rowInitial = tableSelectedData[0].from.row < tableSelectedData[0].to.row ? tableSelectedData[0].from.row: tableSelectedData[0].to.row;
              var rowFinal =  tableSelectedData[0].to.row > tableSelectedData[0].from.row ? tableSelectedData[0].to.row : tableSelectedData[0].from.row;
              var columnInitial = tableSelectedData[0].from.col < tableSelectedData[0].to.col ? tableSelectedData[0].from.col: tableSelectedData[0].to.col;
              var columnFinal = tableSelectedData[0].to.col > tableSelectedData[0].from.col ? tableSelectedData[0].to.col : tableSelectedData[0].from.col;
              if(rowInitial >=2 && rowFinal >=2 && columnInitial>=3 && columnFinal>=3)  {
                return false
              } 
              return true;
            }
            
          },
          disabled: function() {
            let tableSelectedData = self.tableInstance.getSelectedRange();
            if(tableSelectedData && tableSelectedData[0]) {
              var rowInitial = tableSelectedData[0].from.row < tableSelectedData[0].to.row ? tableSelectedData[0].from.row: tableSelectedData[0].to.row;
              var rowFinal =  tableSelectedData[0].to.row > tableSelectedData[0].from.row ? tableSelectedData[0].to.row : tableSelectedData[0].from.row;
              var columnInitial = tableSelectedData[0].from.col < tableSelectedData[0].to.col ? tableSelectedData[0].from.col: tableSelectedData[0].to.col;
              var columnFinal = tableSelectedData[0].to.col > tableSelectedData[0].from.col ? tableSelectedData[0].to.col : tableSelectedData[0].from.col;
              if(rowInitial >=2 && rowFinal >=2 && columnInitial>=3 && columnFinal>=3)  {
                return false
              }  
            }
            return true;
          },
          submenu: {
            // Custom option with submenu of items
            items: [
              {
                // Key must be in the form "parent_key:child_key"
                key: 'Relevance:yes',
                name: 'Yes',
                callback: function(key, selection, clickEvent) {
                  //  const url = 'api/reporting/'+id
                  //  self._dataService.patchData(url,data,true).subscribe(() =>{

                  //  })
                   let array = []
                let tableSelectedData = self.tableInstance.getSelectedRange();
                //let inputData = self.tableInstance.getData();
                if(tableSelectedData && tableSelectedData[0]) {
                var rowInitial = tableSelectedData[0].from.row < tableSelectedData[0].to.row ? tableSelectedData[0].from.row: tableSelectedData[0].to.row;
                var rowFinal =  tableSelectedData[0].to.row > tableSelectedData[0].from.row ? tableSelectedData[0].to.row : tableSelectedData[0].from.row;
                var columnInitial = tableSelectedData[0].from.col < tableSelectedData[0].to.col ? tableSelectedData[0].from.col: tableSelectedData[0].to.col;
                var columnFinal = tableSelectedData[0].to.col > tableSelectedData[0].from.col ? tableSelectedData[0].to.col : tableSelectedData[0].from.col;
                
                for(var i =rowInitial; i<= rowFinal ; i++) {
                  for( var j=columnInitial;j<= columnFinal;j++) {
                   if(!self.tableInstance.getCell(i,j).classList.contains('grey-cell')) {
                    let check =self.tableInstance.getCell(i,j);
                  // array.push(inputData.ids[i][j]._id);
                    const url = environment.apiUrls.getReportingRelevance+
                    "/"+inputData.ids[i][j]._id +"/"
                    const data = {isRelevant : true};
                   self._dataService.patchData(url,data,true).toPromise()
                   .then((response: any) => {
                      console.log(response);
                      check.style.backgroundColor = "#5aac44"
                   })
                   
                   }
                  }
                }
                
              }
            }
              },
              {
                // Key must be in the form "parent_key:child_key"
                key: 'Relevance:no',
                name: 'No',
                callback: function(key, selection, clickEvent) {
                  let tableSelectedData = self.tableInstance.getSelectedRange();
                  //let inputData = self.tableInstance.getData();
                  if(tableSelectedData && tableSelectedData[0]) {
                  var rowInitial = tableSelectedData[0].from.row < tableSelectedData[0].to.row ? tableSelectedData[0].from.row: tableSelectedData[0].to.row;
                  var rowFinal =  tableSelectedData[0].to.row > tableSelectedData[0].from.row ? tableSelectedData[0].to.row : tableSelectedData[0].from.row;
                  var columnInitial = tableSelectedData[0].from.col < tableSelectedData[0].to.col ? tableSelectedData[0].from.col: tableSelectedData[0].to.col;
                  var columnFinal = tableSelectedData[0].to.col > tableSelectedData[0].from.col ? tableSelectedData[0].to.col : tableSelectedData[0].from.col;
                  
                  for(var i =rowInitial; i<= rowFinal ; i++){
                    for( var j=columnInitial;j<= columnFinal;j++) {
                      if(!self.tableInstance.getCell(i,j).classList.contains('no-relevance-cell')) {
                        const url = environment.apiUrls.getReportingRelevance+
                        "/"+inputData.ids[i][j]._id +"/"
                        const data = {isRelevant : false};
                        let check =self.tableInstance.getCell(i,j)
                       self._dataService.patchData(url,data,true).toPromise()
                       .then((response: any) => {
                          console.log(response);
                          check.style.backgroundColor = '#e6e6e6'; 

                       })
                        }
                    }
                  }
                }
                }
              },
              {
                // Key must be in the form "parent_key:child_key"
                key: 'Relevance:tbd',
                name: 'TBD',
                callback: function(key, selection, clickEvent) {
                  let tableSelectedData = self.tableInstance.getSelectedRange();
                 // let inputData = self.tableInstance.getData();
                  if(tableSelectedData && tableSelectedData[0]) {
                  var rowInitial = tableSelectedData[0].from.row < tableSelectedData[0].to.row ? tableSelectedData[0].from.row: tableSelectedData[0].to.row;
                  var rowFinal =  tableSelectedData[0].to.row > tableSelectedData[0].from.row ? tableSelectedData[0].to.row : tableSelectedData[0].from.row;
                  var columnInitial = tableSelectedData[0].from.col < tableSelectedData[0].to.col ? tableSelectedData[0].from.col: tableSelectedData[0].to.col;
                  var columnFinal = tableSelectedData[0].to.col > tableSelectedData[0].from.col ? tableSelectedData[0].to.col : tableSelectedData[0].from.col;
                  
                  for(var i =rowInitial; i<=rowFinal ; i++){
                    for( var j=columnInitial;j<=columnFinal;j++) {
                      if(!self.tableInstance.getCell(i,j).classList.contains('grey-cell')) {
                        const url = environment.apiUrls.getReportingRelevance+
                        "/"+inputData.ids[i][j]._id +"/"
                        const data = {isRelevant : null};
                         let check = self.tableInstance.getCell(i,j);
                       self._dataService.patchData(url,data,true).toPromise()
                       .then((response: any) => {
                          console.log(response);
                        check.style.backgroundColor = "white";

                       })
                        } 
                    }
                  }
                }
                }
              }
            ]
          }
        }
      }
    },
    selectionMode: 'multiple',
    manualRowMove: true,
    licenseKey: 'non-commercial-and-evaluation',
    renderer: function(instance, td, row, col, prop, value, cellProperties) {
      Handsontable.renderers.HtmlRenderer.apply(this, arguments);
      td.className = inputData.cells[row][col];
      if(value && value.length > 50){
       
        td.innerHTML = value.substring(0, 50) + '...';
      }else{
        
        td.innerHTML = value;
      }
      if(row >=2 && col>=3) {
      td.style.cssText = td.className === 'grey-cell' ? 'position: relative;text-align:center;pointer-events:none;': 'position: relative;';

      if(td.className !== 'grey-cell') {
      const wrap = document.createElement('div')
      wrap.style.cssText = 'top: 2px;right: 6px;display: inline-block;position: absolute;';
      const span = document.createElement('i')
      span.id = "checkBtn-icon"
      span.className ="fa fa-bars check-btn";
      span.style.cssText = "cursor:pointer";
      wrap.append(span)
      td.append(wrap)
      }
      }
      else {
        td.title = value;
        
        // td.style.cssText = td.className === "header" ? 'pointer-events:none;': '';
      //  td.onmouseover = function() {
      //    td.style.cssText = "pointer-events:all; "
      //  }
      //  td.onmouseout = function() {
      //   td.style.cssText = "pointer-events:none; "
      // }
      }
      
      return td;
    },
    bindRowsWithHeaders: 'strict',
    readOnly:true,
    cells: function(row, col, prop) {
      // console.log('================================>');
       const cellProperties = this;
       const data = this.instance.getData();

       this.renderer = function(instance, td, row, col, prop, value, cellProperties) {
        Handsontable.renderers.HtmlRenderer.apply(this, arguments);
        td.className = inputData.cells[row][col];
        if(value && value.length > 50){
         
          td.innerHTML = value.substring(0, 50) + '...';
        }else{
          
          td.innerHTML = value;
        }
        if(row >=2 && col>=3) {
        td.style.cssText = td.className === 'grey-cell' ? 'position: relative;text-align:center;pointer-events:none;': 'position: relative;';
  
        if(td.className !== 'grey-cell') {
        const wrap = document.createElement('div')
        wrap.style.cssText = 'top: 2px;right: 6px;display: inline-block;position: absolute;';
        const span = document.createElement('i')
        span.id = "checkBtn-icon"
        span.className ="fa fa-bars check-btn";
        span.style.cssText = "cursor:pointer";
        wrap.append(span)
        td.append(wrap)
        }
        }
        else {
          td.title = value;
          
          // td.style.cssText = td.className === "header" ? 'pointer-events:none;': '';
        //  td.onmouseover = function() {
        //    td.style.cssText = "pointer-events:all; "
        //  }
        //  td.onmouseout = function() {
        //   td.style.cssText = "pointer-events:none; "
        // }
        }
        
        return td;
      }

       return cellProperties;
    },
    afterOnCellMouseDown: function(event:any, cords, td){
      var currentRowData = self.tableInstance.getDataAtRow(cords.row);
      // console.log(currentRowData);
      var data = inputData.ids[cords.row][cords.col]
      
      if(event['target'].id === "checkBtn-icon") {
        self.tableInstance.deselectCell();
         self.goToReportingElementDetails(data);
        }
        else {
        }
    },
    // afterSelectionEnd: function(row, col, row2, col2, selectionLayer){
    //   // console.log(row, col, row2, col2, selectionLayer);

    // }
  };
  const container = document.getElementById(this.id);
  this.tableInstance = new Handsontable(container, this.hotSettings);
  this.showLoader = false;
  $('.reporting-element-list-container').removeClass('displayNone');
}

goToReportingElementDetails(data) {
  if(data._id) {
  const url = this.router.createUrlTree(['dashboard/reporting-element-detail', data._id]).toString();
  window.open(url, '_blank');
  }
}

setRelevanceForSelectedCells(): void {

}

  prepareTable(): any{
    var self = this;
    this.hotSettings = {
      width: '100%',
      rowHeights: 50,
      columnHeaderHeight: 50,
      stretchH: 'all',
      data: this.reportingElementsListData,
      licenseKey: 'non-commercial-and-evaluation',
      // columns: this.columns,
      // hiddenColumns: {
      //   columns: this.hiddenColumns,
      // },
      cells: function(row, col, prop) {
        var cellProperties = this;
      
        if (col == 8 && prop == 'assessment_owner') {
          cellProperties.type = 'dropdown';
          var val = self.usersListForDropdown;
          
          if (typeof val != 'undefined') {
            cellProperties.source = val;
          }
        }
        return cellProperties;
      },
    };
    const container = document.getElementById(this.id);
    this.tableInstance = new Handsontable(container, this.hotSettings);
  }

  getUsersList(): void {
    var url = environment.apiUrls.getUsersUrl;

    this._dataService.getData(url, [], true).toPromise()
        .then((response: any) => {
            if (response.status == 1) {
                this.usersList = [];
                this.usersListForDropdown = [];
                response.data.users.forEach((obj) => {
                    var userObj = {
                        name: obj.firstName + ' ' + obj.lastName,
                        id: obj._id
                    };
                    this.usersList.push(userObj);

                });

                this.usersList.forEach((item) => {
                    this.usersListForDropdown.push(item.name);
                });

               // this.prepareTable();

            }
        }, (error) => {
            if (error.error) {
                // this._toastr.error(error.error.message);
            }
        });
}

  getReportingElementsList(): void {
    var obj = {
      rowId: 'Z10C2',
      colId: '45567',
      rowName: 'industry standard',
      colName: 'Duis eget',
      relevance: 'Yes',
      compliance: 'Full',
      instruction: 'TBD',
      assessment: 'nsjdfjdsnkfndsnjf',
      assessment_owner: 'Cole',
      action: 'make sure bank is compliant',
      actionOwner: 'Cole',
      actionStatus: 'New',
      actionDueDate: '20 May 2020'
    }

    for(let i = 0; i < 5; i++){
      obj['id'] = i;
      var newObj = Object.assign({}, obj);
      this.reportingElementsListData.push(newObj);
      
    }
    this.prepareTable();
  }

  

}
