<div class="reporting-element-details-view-container">
    <!--header-->
    <div class="reporting-element-details-header">
        <!--reporting element info-->
        <div class="header-element-info">
            <span>
                <span class="head-label">Template Code:</span>
                <span class="head-value">{{reportingElementDetailsObj.templateCode}}</span>,
            </span>
            <span>
                <span class="head-label">Template Name:</span>
                <span class="head-value">{{reportingElementDetailsObj.templateName}}</span>,
            </span>
            <span>
                <span class="head-label">Legal Reference:</span>
                <span class="head-value">{{reportingElementDetailsObj.templateRef}}</span>
            </span>
        </div>
        <!--reporting element info-->
        <!--right section prev-next-->
        <div class="reporting-element-detail-nav">
            <!-- <span class="prev-btn">
                <i class="fa fa-chevron-left"></i>
                Previous
            </span>
            <span class="next-btn">
                Next
                <i class="fa fa-chevron-right"></i>
            </span> -->
        </div>
        <!--right section prev-next-->  
    </div>
    <!--header-->
    <!--reporting-element-details-container-->
    <div class="reporting-element-details-box">
        <div class="row">
            <!--element info-->
            <div class="col-md-12">
                <div class="row">
                    <div class="col-md-3">
                        <div class="element-info-label">Row {{'(' + getReportingDetailDataRow.identifier + ')'}}</div>
                        <div class="element-info-value">
                        {{getReportingDetailDataRow.item}}{{' ' + getReportingDetailDataRow.header}} 
                            
                        </div>
                    </div>
                    <div class="col-md-3">
                        <div class="element-info-label">Column {{'(' + getReportingDetailDataColumn.identifier + ')'}}</div>
                        <div class="element-info-value">
                                {{getReportingDetailDataColumn.item}}{{' ' + getReportingDetailDataColumn.header}} 
                            
                        </div>
                    </div>
                    <div class="col-md-2">
                        <div class="element-info-label" *ngIf="reportingElementDetailsObj.relatedImages.length && 
                        (reportingElementDetailsObj.template && reportingElementDetailsObj.template.code && !reportingElementDetailsObj.template.code.includes('PRA110'))">
                            Decision Tree
                        </div>
                        <div class="element-info-value">
                            <button type="button" class="add-new-action-btn" data-toggle="modal" style="margin-top:0px;"
                                *ngIf="reportingElementDetailsObj.relatedImages.length" data-target="#ImagesModal">
                                IMAGES
                            </button>
                        </div>
                        
                    </div>
                    <div class="col-md-2">
                        <div class="element-info-label">Relevance</div>
                        <div class="element-info-value">
                            <ng-select id="elementRelevance" 
                                [items]="relevanceOptions" 
                                bindLabel="label"
                                bindValue="value" 
                                [clearable]="false"
                                (change)="updateRelevanceForElement(reportingElementDetailsObj.relevance)"
                                [(ngModel)]="reportingElementDetailsObj.relevance">
                                <ng-template ng-label-tmp let-item="item">
                                    <div style="display:flex;">
                                        <div style="width:4px;border-radius:4px;margin-right:4px;" [style.background]="item.barColor"></div>
                                        <div>{{item.label}}</div>
                                    </div>
                                </ng-template>
                            </ng-select>
                        </div>
                    </div>
                    <div class="col-md-2 ">
                            <div class="element-info-label">Assessment Owner</div>
                            <div class="element-info-value">
                                <ng-select id="elementAssessmentOwner" 
                                    [items]="usersList" 
                                    bindLabel="name"
                                    bindValue="id" 
                                    [clearable]="false"
                                    (change)="saveAssessment()"
                                    [(ngModel)]="reportingElementDetailsObj.assessment.assessmentOwnerId">
                                    <ng-template ng-label-tmp let-item="item">
                                        <div>{{item.name}}</div>
                                    </ng-template>
                                </ng-select>
                            </div>
                    </div>
                    <!-- <div class="col-md-2">
                        <div class="element-info-label">Compliance</div>
                            <div class="element-info-value">
                                <ng-select id="elementCompliance" 
                                    [items]="complianceOptions" 
                                    bindLabel="label"
                                    bindValue="value" 
                                    [(ngModel)]="reportingElementDetailsObj.compliance">
                                    <ng-template ng-label-tmp let-item="item">
                                        <div style="display:flex;">
                                            <div style="width:4px;border-radius:4px;margin-right:4px;" [style.background]="item.barColor"></div>
                                            <div>{{item.label}}</div>
                                        </div>
                                    </ng-template>
                                </ng-select>
                        </div>
                    </div> -->
                </div>
                
            </div>
            <div class="col-md-12 margin-tp25">
                <div class="row">
                    <div class="col-md-6 formula-div">
                        <div class="row" *ngIf="reportingElementDetailsObj.additionalDetailsSubHeading && reportingElementDetailsObj.additionalDetailsSubHeading != ''">
                            <div class="col-md-12">
                                <div class="element-info-label">Sub-Heading</div>
                                <div class="element-info-value" style="white-space: pre-line;overflow-wrap:break-word;" [innerHTML]="reportingElementDetailsObj.additionalDetailsSubHeading"></div>
                            </div>
                        </div>
                        <div class="row margin-tp25">
                            <div class="col-md-12">
                                <div class="element-info-label">Row Instruction (Regulatory)</div>
                                <div class="element-info-value" style="white-space: pre-line;overflow-wrap:break-word;" [innerHTML]="getReportingDetailDataRow.instruction"></div>
                            </div>
                        </div>
                        <div class="row margin-tp25">
                            <div class="col-md-12">
                                <div class="element-info-label">Column Instruction (Regulatory)</div>
                                <div class="element-info-value" style="white-space: pre-line;overflow-wrap:break-word;" [innerHTML]="getReportingDetailDataColumn.instruction"></div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="row ">
                            <div class="col-md-12" >
                            <div class="element-info-label">
                                Assessment/Instruction
                                <span style="width:16px;cursor:pointer;" (click)="editReportingElementFlag = !editReportingElementFlag">
                                    <img src="../../../assets/images/icon-edit.svg" />
                                </span>
                                <span *ngIf="editReportingElementFlag" (click)="saveAssessment()"
                                    style="margin-left:8px;cursor:pointer;">
                                    <i style="font-size:18px;" class="fa fa-save"></i>
                                </span>
                            </div>
                            <div *ngIf="!editReportingElementFlag" class="element-info-value" style="white-space: pre-line;overflow-wrap:break-word;" [innerHTML]="reportingElementDetailsObj.assessment.comment"></div>
                            <div *ngIf="editReportingElementFlag" class="element-info-value">
                                <textarea rows="20" [(ngModel)]="reportingElementDetailsObj.assessment.comment" style="resize: none;
                                    border: 1px solid lightgray;
                                    border-radius: 5px;
                                    width: 100%;"
                                    >
                                </textarea>
                            </div>
                            </div>
                            <!--regart Comment-->
                            <div class="col-md-12" style="margin-top:20px;" *ngIf="reportingElementDetailsObj.regartComments && reportingElementDetailsObj.regartComments.length">
                                <div class="element-info-label">Regart Comment</div>
                                <div class="element-info-value" *ngFor="let comment of reportingElementDetailsObj.regartComments">
                                    <div [innerHTML]="comment"></div>
                                </div>
                            </div> 
                            <!--regart Comment-->
                        </div>
                    </div>
                </div>
            </div>
            
            <!--element info-->
            <div class="col-md-12 margin-tp25">
                    
                <div class="row mt-2s">
                    <div class="col-md-6">
                        <div class="element-info-label" *ngIf="getReportingDetailDataRow.itemReferences?.length">Item References</div>
                        <div class="reporting-element-actions-list">
                        <table class="width100" *ngIf="getReportingDetailDataRow.itemReferences?.length">
                            <thead>
                                <tr>
                                    <th>Item</th>
                                    <th>Text</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let action of getReportingDetailDataRow.itemReferences">
                                    <td style="text-align: left;">
                                        <a style="cursor:pointer;" href="javascript: void(0);" (click)="navigateToDetailFromitemRef(action.reporting_element)">{{action.key}}</a>
                                    </td>
                                    <td style="text-align: left;">{{action.value}}</td> 
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div style="margin-top:15px;">
                        <a *ngIf="reportingElementDetailsObj.documentName == '2020/429/EU' && versionRefReportingElement" style="cursor:pointer;font-size:14px;" href="javascript: void(0);" (click)="navigateToCorrespondingVersion()">
                            Updated Version
                        </a>
                        <a *ngIf="reportingElementDetailsObj.documentName == '2021/451/EU' && versionRefReportingElement" style="cursor:pointer;font-size:14px;" href="javascript: void(0);" (click)="navigateToCorrespondingVersion()">
                            Previous Version
                        </a>
                    </div>
                    </div>
                    <div class="col-md-6 ">
                        <!-- -->
                        <div class="row">
                            <div class="col-md-12" *ngIf="actionsListData && actionsListData.length">
                                    <div class="element-info-label">Actions</div>
                                    <div class="reporting-element-actions-list">
                                            <table class="width100">
                                                <thead>
                                                    <tr>
                                                        <th>Action</th>
                                                        <th>Action Owner/Department</th>
                                                        <th>Action Status</th>
                                                        <th>Action Due Date</th>
                                                        <th></th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr *ngFor="let item of actionsListData">
                                                       <td style="width:300px;white-space: pre-line;text-align:left;">{{item.action}}</td>
                                                       <td>{{item.actionOwnerName}}</td>
                                                       <td>{{item.status == 'in_progress' ? 'In Progress' : item.status == 'completed' ? 'Completed' : item.status == 'new' ? 'New' : 'New'}}</td>
                                                       <td>{{item.displayDueDate}}</td> 
                                                       <td style="display: inline-flex;">
                                                            <span (click)="updateSelectedActionModal(item)" style="cursor:pointer;outline:none;">
                                                                <img src="../../../assets/images/icon-edit.svg" style="height:14px;"/>
                                                            </span>
                                                            <span  style="cursor:pointer;outline:none;margin-left:10px;">
                                                                <i (click)="deleteAction(item._id)" class="fa fa-trash" style="font-size:14px;"></i>
                                                            </span>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                            </div>
                            <div class="col-md-12">
                                <button type="button" class="add-new-action-btn" (click)="editAction = false;" data-toggle="modal" data-target="#addNewActionModal">
                                    <img src="../../../assets/images/icon-new-add.svg" />
                                    <span>New Action</span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                
            </div>
            <!--element assessment info-->
            
            <!--element assessment info-->
        </div>
    </div>
    <!--reporting-element-details-container-->
    <div class="reporting-element-details-box" *ngIf="(reportingElementDetailsObj['templateReferences'] && reportingElementDetailsObj['templateReferences'].length) || (reportingElementDetailsObj['documentReferences'] && reportingElementDetailsObj['documentReferences'].length)">
        <div class="row" *ngIf="reportingElementDetailsObj['templateReferences'] && reportingElementDetailsObj['templateReferences'].length">
            <div class="col-md-12">
            <div class="element-info-label" style="font-size:15px;">Template References</div>
            <div>
                <ul>
                    <li *ngFor="let item of reportingElementDetailsObj['templateReferences']" style="font-size:12px;">
                        <a style="cursor:pointer;" href="javascript: void(0);" (click)="navigateToReportingList(item)">
                            {{item.code + ' - ' + item.title}}
                        </a>
                    </li>
                </ul>
            </div>
            </div>
        </div>
        <div class="row" *ngIf="reportingElementDetailsObj['documentReferences'].length" style="margin-top:25px;">
            <div class="col-md-12">
            <div class="element-info-label" style="font-size:15px;">Document References</div>
            <div>
                <ul>
                    <li *ngFor="let item of reportingElementDetailsObj['documentReferences']" style="font-size:12px;">
                        <a style="cursor:pointer;" href="javascript: void(0);" (click)="navigateToDocument(item)">
                            {{item.title}}
                        </a>
                    </li>
                </ul>
            </div>
            </div>
        </div>
    </div>

    <!--reporting qna-->
    <div class="articles-reference-list" style="z-index:100;position:relative;" *ngIf="reportingQnAData && reportingQnAData.length">
        <div class="head-value" style="margin-bottom:15px;">QNA
            <span style="margin-left:10px; margin-top:-2.5px;" *ngIf="reportingQnAData.length">
                <button type="button" class="save-changes-btn" (click)="updateQNArelevance()">Save Changes</button>
            </span>
        </div>
        <div class="indirect-reference-articles-list-container" id="indirect-reference-articles-container">
            <div class="hot" style="font-family: 'Montserrat Medium';font-size: 12px;" id="reporting-qna-table">
            </div>
        </div>
        <div class="head-value" *ngIf="reportingQnAData.length == 0" style="font-size:14px;margin-top:10px;">No Data</div>
    </div>
    <!--reporting qna-->
    <!--reference reporting elements table-->
    <div class="reporting-elements-reference-list-container" *ngIf="row_col_ref && row_col_ref.length">
        <div class="head-value">References Reporting Elements</div>
        <!-- <div class="reporting-elements-reference-list-table-container" id="reporting-elements-reference-list-table-container">
            <div class="hot" style="font-family: 'Montserrat Medium';font-size: 12px;" id="reporting-elements-reference-list-table">
            </div>
        </div> -->
        <div class="reporting-element-actions-list">
                <table class="width100" style="table-layout: fixed;">
                    <thead>
                        <tr style="background: #f0f0f0;">
                            <th style="width:105px;">Template Code</th>
                            <th style="width:300px;">Template Name</th>
                            <th style="width:100px;">Row ID</th>
                            <th style="width:100px;">Column ID</th>
                            <th >Row Name</th>
                            <th>Column Name</th>
                            
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let item of row_col_ref">
                            <td style="text-align:left;">{{item.template ? item.template.code : ''}}</td>
                            <td style="text-align:left;">
                                {{item.template ? item.template.title : ''}}
                            </td>
                            <td style="text-align:left;" [ngStyle]="!item.row && {'background-color': 'lightgrey'}">
                                <a href="javascript: void(0);" (click)="navigateToReportingReferences(item)">
                                    {{item.row ? item.row.identifier : ''}}
                                </a>
                            </td>
                            <td style="text-align:left;" [ngStyle]="!item.column && {'background-color': 'lightgrey'}">
                                <a href="javascript: void(0);" (click)="navigateToReportingReferences(item)">
                                    {{item.column ? item.column.identifier : ''}}
                                </a>
                            </td>
                            <td style="text-align:left;" [ngStyle]="!item.row && {'background-color': 'lightgrey'}">
                                <!-- <span [title]="item.row ? item.row.instruction : ''">{{item.row ? item.row.instructionDisplay : ''}}</span> -->
                                <span *ngIf="item.row && !item.row.expandText" [title]="item.row ?item.row.instruction : ''">
                                    {{item.row ? item.row.instructionDisplay : ''}}
                                </span>
                                <span *ngIf="item.row && item.row.expandText" [title]="item.row ?item.row.instruction : ''">
                                    {{item.row ? item.row.instruction : ''}}
                                </span>
                                <span *ngIf="item.row && !item.row.expandText">
                                    <button style="cursor:pointer;border:none;outline:none;color:#a5473e;background:none;"
                                        (click)="item.row.expandText = !item.row.expandText">
                                        Show More
                                    </button>
                                </span>
                                <span *ngIf="item.row && item.row.expandText">
                                    <button style="cursor:pointer;border:none;outline:none;color:#a5473e;background:none;"
                                        (click)="item.row.expandText = !item.row.expandText">
                                        Show Less
                                    </button>
                                </span>
                            </td>
                            <td style="text-align:left;" [ngStyle]="!item.column && {'background-color': 'lightgrey'}">
                                <span *ngIf="item.column && !item.column.expandText" [title]="item.column ?item.column.instruction : ''">
                                    {{item.column ? item.column.instructionDisplay : ''}}
                                </span>
                                <span *ngIf="item.column && item.column.expandText" [title]="item.column ?item.column.instruction : ''">
                                    {{item.column ? item.column.instruction : ''}}
                                </span>
                                <span *ngIf="item.column && !item.column.expandText">
                                    <button style="cursor:pointer;border:none;outline:none;color:#a5473e;background:none;"
                                        (click)="item.column.expandText = !item.column.expandText">
                                        Show More
                                    </button>
                                </span>
                                <span *ngIf="item.column && item.column.expandText">
                                    <button style="cursor:pointer;border:none;outline:none;color:#a5473e;background:none;"
                                        (click)="item.column.expandText = !item.column.expandText">
                                        Show Less
                                    </button>
                                </span>
                            </td>
                            
                        </tr>
                    </tbody>
                </table>
            </div>
    </div>
    <!--reference reporting elements table-->
    
    <!--reference table container-->
    <div class="reporting-element-reference-articles-reference-container">
        <div class="head-value" *ngIf="articleReferencesData.length">Reference Articles</div>
        <!-- <div class="reporting-element-reference-articles-reference-table-container" id="reporting-element-reference-articles-reference-table-container">
            <div class="hot" style="font-family: 'Montserrat Medium';font-size: 12px;" id="reporting-element-reference-articles-reference-table">
            </div>
        </div> -->
        <div class="reporting-element-actions-list">
                <table class="width100" *ngIf="articleReferencesData.length">
                    <thead>
                        <tr style="background: #f0f0f0;">
                            <th>Article No.</th>
                            <th style="width:150px;">Document/Section</th>
                            <th>Article</th>
                            <th>Relevance</th>
                            <th>Compliance</th>
                            <th>Assessment</th>
                            <th style="width:100px;">Assessment Owner</th>
                            <!-- <th>Action</th>
                            <th>Action Owner/Department</th>
                            <th>Action Status</th>
                            <th>Action Due Date</th> -->
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let item of articleReferencesData">
                            <td style="text-align:left;width:6%;">
                                <a style="cursor:pointer;" href="javascript: void(0);" (click)="navigateArticleDetail(item)">{{item.articleNo}}</a></td>
                            <td style="text-align:left;">{{item.fullReference}}</td>
                            <td style="text-align:left;">
                                <!-- {{item.text}} -->
                                <tree-root [nodes]="item.nodeData" [options]="nodeOptions">
                                <ng-template #treeNodeTemplate let-node let-index="index">
                                    <span 
                                        style="color: #111111;
                                        font-family: 'Montserrat';
                                        font-size: 10px;
                                        font-weight: 400;"
                                        [innerHTML]="node.data.name">
                                        
                                    </span>
                                </ng-template>
                            </tree-root>
                            </td>
                            <td style="text-align:left;">{{item.isRelevant}}</td>
                            <td style="text-align:left;">{{item.compliantStatus}}</td>
                            <td style="text-align:left;">{{item.assessmentComment}}</td>
                            <td style="text-align:left;">{{item.assessmentOwner}}</td>
                            <!-- <td style="text-align:left;">{{item.action}}</td>
                            <td style="text-align:left;">{{item.actionOwner}}</td>
                            <td style="text-align:left;">{{item.actionStatus}}</td>
                            <td style="text-align:left;">{{item.dueDate}}</td> -->
                        </tr>
                    </tbody>
                </table>
            </div>
    </div>
    <!--reference table container-->
</div>


<!--add new action modal-->
<div class="modal fade" id="addNewActionModal" tabindex="-1" role="dialog" aria-labelledby="actionsLabel" aria-hidden="true">
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-header" style="padding:5px 10px;">
              <h5 class="modal-title actionlist-modal-header" id="actionsLabel" *ngIf="!editAction">Add New Action</h5>
              <h5 class="modal-title actionlist-modal-header" id="actionsLabel" *ngIf="editAction">Update Action</h5>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <!-- <form> -->
                <div class="row">
                    <div class="col-md-6 form-group">
                                <label class="actionlist-modal-labels" style="margin-bottom:0px;">Due Date</label>
                                <div class="input-box-container">
                                        <input class="input-box form-control" placeholder="Click to select a date" 
                                          angular-mydatepicker name="mydate" (click)="dp.toggleCalendar()" 
                                          [(ngModel)]="actionDueDate" [options]="myDpOptions" 
                                          #dp="angular-mydatepicker"/>
                                </div>
                    </div>
                    <div class="col-md-6 form-group">
                        <label class="actionlist-modal-labels" style="margin-bottom:0px;">Action Owner</label>
                        <ng-select id="elementActionOwner" class="form-control" style="padding:0px;border:none;"
                            [items]="usersList" 
                            bindLabel="name"
                            bindValue="id" 
                            [(ngModel)]="newActionObj.actionOwnerId">
                                <ng-template ng-label-tmp let-item="item">
                                    <div>{{item.name}}</div>
                                </ng-template>
                        </ng-select>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6 form-group">
                        <label class="actionlist-modal-labels" style="margin-bottom:0px;">Action</label>
                        <textarea class="form-control" type="text" [(ngModel)]="newActionObj.action"></textarea>
                    </div>
                    <div class="col-md-6 form-group">
                        <label class="actionlist-modal-labels" style="margin-bottom:0px;">Action Status</label>
                        <ng-select id="elementActionStatus" class="form-control" style="padding:0px;border:none;"
                            [items]="actionStatusList"
                            bindLabel="label"
    
                            bindValue="val"
                            [clearable]="false"
                            [(ngModel)]="newActionObj.status">
                                <ng-template ng-label-tmp let-item="item">
                                    <div>{{item.label}}</div>
                                </ng-template>
                        </ng-select>
                    </div>
                </div>
              <!-- </form> -->
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-secondary" data-dismiss="modal">Cancel</button>
              <button type="button" *ngIf="!editAction" class="btn btn-primary" (click)="addNewAction()" data-dismiss="modal">Add Action</button>
              <button type="button" *ngIf="editAction" class="btn btn-primary" (click)="updateAction()" data-dismiss="modal">Update Action</button>
            </div>
          </div>
        </div>
    </div>
    <!--add new action modal-->

    <div class="modal fade modal-fullscreen" id="ImagesModal" tabindex="-1" role="dialog" aria-labelledby="actionsLabel" aria-hidden="true">
        <div class="modal-dialog "  style="max-width:90vw;height: 90vh;" role="document">
            <div class="modal-content" style="height: 90vh;">
                <div class="modal-header" style="padding:5px 10px;">
                  <h5 class="modal-title actionlist-modal-header" id="actionsLabel">Linked Images</h5>
                  <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div class="modal-body" style="overflow-y:scroll;">
                    <div class="row" id="image{{i}}" *ngFor="let image of reportingElementDetailsObj.relatedImages; let i = index;" style="position:relative;text-align:center;margin-top:10px;padding-left:15px;padding-right:15px;">
                        <!-- <img src={{image}}  style="width:115%;" /> -->
                        <!-- <lib-ngx-image-zoom
                            [thumbImage]=image
                            [enableLens]="false"
                            [magnification]="1"
                            [enableScrollZoom]="true"
                            [scrollStepSize]="0.1"
                            >
                        </lib-ngx-image-zoom> -->
                        <div style="width:80vw;height:70vh;margin:auto;">
                        <pan-zoom [config]="panZoomConfig">

                            <div style="position: relative;">
                    
                              <img src={{image}} style="width:80vw;height:70vh;">
                    
                            </div>
                    
                        </pan-zoom>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                  <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                </div>
              </div>
            </div>
        </div>
    <!--images modal-->