// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

// const baseUrl = 'https://staging.regart.eu/';
// const baseUrl = 'http://13.229.215.172:3000/'; 
// sudo ssh -i "regart_uat.pem" ubuntu@ec2-54-179-167-206.ap-southeast-1.compute.amazonaws.com //staging
const baseUrl = 'https://app.regart.eu/';
// const baseUrl = 'https://rabobank.regart.eu/';
// const baseUrl = 'https://adc.regart.eu/';
// const baseUrl = 'https://zeb.regart.eu/';
// sudo ssh -i "regart_uat.pem" ubuntu@ec2-54-179-226-46.ap-southeast-1.compute.amazonaws.com //adc
// sudo ssh -i "regart_uat.pem" ubuntu@ec2-54-255-154-202.ap-southeast-1.compute.amazonaws.com //zeb
// sudo ssh -i "regart_uat.pem" ubuntu@ec2-54-255-204-246.ap-southeast-1.compute.amazonaws.com // rabobank
// sudo ssh -i "regart_uat.pem" ubuntu@ec2-13-229-215-172.ap-southeast-1.compute.amazonaws.com //app

export const environment = {
  production: false,
  apiUrls: {
    signupUrl: baseUrl + 'api/auth/register',
    verifyOTPUrl: baseUrl + 'api/auth/verify-otp',
    resendOTPUrl: baseUrl + 'api/auth/resend-verify-otp',
    loginUrl: baseUrl + 'api/auth/login',
    forgotPasswordUrl: baseUrl + 'api/auth/forgot-password',
    resetPasswordUrl: baseUrl + 'api/auth/reset-password',
    getAssessmentUrl: baseUrl + 'api/regulation/liquidity',
    getUsersUrl: baseUrl + 'api/user/',
    ragStatusUrl: baseUrl + 'api/regulation/liquidity/rag-status',
    updateAssessmentUrl: baseUrl + 'api/regulation/liquidity/update',
    getAssessmentDetailUrl: baseUrl + 'api/regulation/:regulationId',
    getAssessmentActionsListUrl: baseUrl + 'api/action/:assessmentId',
    newActionForAssessmentUrl: baseUrl + 'api/action/',
    getDocumentsListUrl: baseUrl + 'api/document/liquidity',
    regulationsReferencesSelectedArticleUrl: baseUrl + 'api/regulation/:regulationId/references',
    reportingElementDetails: baseUrl + 'api/reporting/detail',
    updateSubTreeForRelevanceUrl: baseUrl + 'api/regulation/:regulationId/updateSubTree',
    getDocumentReportingList: baseUrl + 'api/document/reporting',
    getReportingData: baseUrl + 'api/reporting/:documentId/:templateId',
    getReportingRelevance: baseUrl + 'api/reporting',
    getReportingDetailData: baseUrl + 'api/reporting/reporting-element-detail/:_id',
    getArticleDocumentReferences: baseUrl + 'api/regulation/:regulationId/documentReferences',
    getRegulationDocumentReferences: baseUrl + 'api/reporting/:regulationId/documentReferences',
    getRegulationTemplateReferences: baseUrl + 'api/reporting/:regulationId/templateReferences',
    getRegulationRegulationReferences: baseUrl + 'api/reporting/:regulationId/regulationReferences',
    getRegulationsDocumentReferences: baseUrl + 'api/reporting/:reportingElementId/documentReferences',
    getReportingElementReferences: baseUrl + 'api/reporting/:reportingElementId/reportingElementReferences',
    postReportingElementAssessment: baseUrl + 'api/assessment/',
    updateReportingElementAssessment: baseUrl + 'api/assessment/:assessmentId/',
    getElementsAssessment: baseUrl + 'api/assessment/reporting/:reportingElementId',
    getReportingEleActions: baseUrl + 'api/action/reporting/:reportingElementId',
    postReportingEleActions: baseUrl + 'api/action/',
    getRegulationActions: baseUrl + 'api/action/regulation/:regulationId',
    updateCompliantStatusUrl: baseUrl + 'api/regulation/:regulationId/updateCompliantStatus',
    reportingElementVersionRefUrl: baseUrl + 'api/reporting/:reportingElementId/versionReferences',
    deleteAction: baseUrl + 'api/action/:actionId',
    articleReferencesLinks: baseUrl + 'api/regulation/:regulationId/hyperlinks',
    indirectArticleRefs: baseUrl + 'api/regulation/:regulationId/indirectArticleReferences',
    documentDetailsUrl: baseUrl + 'api/document/:documentId',
    navigationList: baseUrl + 'api/regulation/navigationList',
    getRegulationTopicsUrl: baseUrl + 'api/regulatoryTopic',
    getRegulatoryTopicsDocumentUrl: baseUrl + 'api/regulatoryTopic/:topicId/documentTypes',
    getRegulatoryDocStats: baseUrl + 'api/document/documentStats',
    getQnAUrl: baseUrl + 'api/regulation/:regId/qa',
    updateQnAUrl: baseUrl + 'api/qa/:qaId/',
    articlesTableReportUrl: baseUrl + 'api/regulation/generateArticleReport/',
    regartCommentApi: baseUrl + 'api/regulation/:regulationId/regartComments',
    indirectReportingReferences: baseUrl  + 'api/regulation/:regulationId/indirectReportingReferences',
    qnaReportingUrl: baseUrl + 'api/reporting/:reportingElementId/itsQna',
    reportingQnAUpdateUrl: baseUrl + 'api/itsQna/:itsQnaId'
  }
};


/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
