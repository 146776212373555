<div class="login-page-container">
    <div class="login-form-container">
        <div class="form-header-container">
            <div class="logo-container">
                <img src="../../../assets/images/regart-logo.png"/>
            </div>
            <h4 *ngIf="!showForgotPasswordForm && !forgotPassEmailSentSuccess" class="form-title margin-bt0">User login</h4>
            <h4 *ngIf="showForgotPasswordForm && !forgotPassEmailSentSuccess" class="form-title margin-bt0">Forgot your password?</h4>
            <h4 *ngIf="forgotPassEmailSentSuccess" class="form-title margin-bt0">Email Sent</h4>
        </div>
        <div class="form-content-container" *ngIf="!showForgotPasswordForm && !forgotPassEmailSentSuccess">
            <form name="login-form" #loginForm="ngForm" (ngSubmit)="loginForm.valid && onLoginSubmit()">
                <div class="form-group margin-bt0">
                    <label for="email">User Email ID</label>
                    <input class="form-control margin-tp8" [(ngModel)]="userLoginCreds.email" type="email" id="email" 
                        name="email" placeholder="xyz@gmail.com" required #email="ngModel"
                        [ngClass]="{ 'is-invalid': loginForm.submitted && email.invalid, 'form-field-invalid': loginForm.submitted && email.invalid}"/>
                </div>
                <div class="form-group margin-bt0 margin-tp24">
                    <label for="password">Password</label>
                    <div class="password-container">
                        <input class="form-control margin-tp8" [(ngModel)]="userLoginCreds.password" 
                            [type]="showPassword ? 'text' : 'password'" 
                            name="password" id="password" required #password="ngModel"
                            [ngClass]="{ 'is-invalid': loginForm.submitted && password.invalid, 'form-field-invalid': loginForm.submitted && password.invalid}"/>
                        <img (click)="showPassword = !showPassword" *ngIf="showPassword" src="../../../assets/images/icon-password-hide.svg" />
                        <img (click)="showPassword = !showPassword" *ngIf="!showPassword" src="../../../assets/images/icon-password-show.svg" />
                    </div>
                </div>
                <div class="submit-button-container">
                    <button type="submit" class="app-form-submit-btn width100">Login</button>
                </div>
                <div class="margin-tp24">
                    <h6 class="text-center margin-bt0">
                        <a (click)="showForgotPasswordForm = true;" class="frgt-pass-text">Forgot Password?</a>
                    </h6>
                </div>
                <!-- <div class="margin-tp60">
                    <h6 class="text-center margin-bt0">
                        <span class="frgt-pass-text">Don't have any account?</span>
                        <span class="margin-lt5">
                            <a routerLink="/sign-up" class="sign-up-text">Signup here</a>
                        </span>
                    </h6>
                </div> -->
            </form>
        </div>
        <!--forgot password-->
        <div class="form-content-container" *ngIf="showForgotPasswordForm && !forgotPassEmailSentSuccess" style="margin-top:20px;">
            <div style="margin-bottom:20px;">
                <label style="font-size:13px;text-align:center;">Please enter your email ID. You will receive a link to create a new password via email.</label>
            </div>
            <form name="forgot-password-form" #forgotPassForm="ngForm" (ngSubmit)="forgotPassForm.valid && getResetPasswordLink()">
                <div class="form-group margin-bt0">
                    <label for="email">User Email ID</label>
                    <input class="form-control margin-tp8" [(ngModel)]="userLoginCreds.email" type="email" id="email" 
                        name="email" placeholder="xyz@gmail.com" required #email="ngModel"
                        [ngClass]="{ 'is-invalid': forgotPassForm.submitted && email.invalid, 'form-field-invalid': forgotPassForm.submitted && email.invalid}"/>
                </div>
                <div class="submit-button-container">
                    <button type="submit" class="app-form-submit-btn width100">Reset Password</button>
                </div>
                <div class="margin-tp60">
                    <h6 class="text-center margin-bt0">
                        <span class="margin-lt5">
                            <a (click)="showForgotPasswordForm = false;forgotPassEmailSentSuccess = false;" style="cursor:pointer;" class="sign-up-text">
                                <i class="fa fa-chevron-left"></i>
                                Back to Login
                            </a>
                        </span>
                    </h6>
                </div>
            </form>
        </div>
        <!--forgot password-->
        <!--reset password email sent-->
        <div class="form-content-container" *ngIf="forgotPassEmailSentSuccess" style="margin-top:20px;">
                <div style="margin-bottom:15px;text-align: center;">
                    <label style="font-size:13px;text-align:center;">Please follow the directions in the email to set up a new password for your account.</label>
                </div>
                <div style="text-align: center;">
                    <label style="font-size:14px;color:#a5473e;">{{userLoginCreds.email}}</label>
                </div>
                <div style="text-align: center;margin-top:10px;">
                    <label>Please check the above email for spelling mistakes</label>
                </div>
                <div style="text-align: center;margin-top:10px;">
                    <label>It should not take too long to receive the email. please check your spam or junk folder.</label>
                </div>
                <div class="submit-button-container">
                    <button type="button" (click)="showForgotPasswordForm = false;forgotPassEmailSentSuccess = false;" class="app-form-submit-btn width100">Okay, thanks!</button>
                </div>
                <div style="text-align: center;margin-top:30px;">
                    <label>If you're still having trouble please contact</label>
                    <label style="color:#a5473e;">support@regart.com</label>
                </div>
            </div>
        <!--reset password email sent-->
    </div>
</div>
