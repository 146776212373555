import { Injectable } from '@angular/core';
import Handsontable from 'handsontable';

@Injectable({
  providedIn: 'root'
})
export class ArticleDetailService {
  referenceArticlesTableColumns: Array<any> = [
    {
      title:"Article No.",
      data: 'articleNo',
      readOnly: true,
      width: '50px',
      cellClass: 'article-no-text',
      // isHidden: false,
    },
    {
      title:"Document/Section",
      data: 'fullReference',
      width: '100px',
      readOnly: true,
      // isHidden: false,
      renderer: function(instance, td, row, col, prop, value, cellProperties) {
        Handsontable.renderers.TextRenderer.apply(this, arguments);
        td.title = value;
        return td;
      }
    },
    {
      title: "Article",
      data: 'text',
      width: '180px',
      readOnly: true,
      // isHidden: false,
      renderer(instance, td, row, col, prop, value, cellProperties) {
        Handsontable.renderers.TextRenderer.apply(this, arguments);
        td.title = value;
        if (value) {
            const currentRowData = instance.getDataAtRow(row);
            if (value.length > 150) {
            // td.innerHTML = value.substring(0,150) + '...';
            const wrapper = document.createElement('div');
            const div1 = document.createElement('div');
            const div2 = document.createElement('div');
            if (currentRowData[11] == 'insert') {
              const childEle = document.createElement('div');
              childEle.innerHTML = 'new';
              childEle.style.cssText = 'background-color:lightgreen;padding:2px;color:#ffffff;margin-top:5px;';
              div2.appendChild(childEle);
            }
            if (currentRowData[11] == 'delete') {
              const childEle = document.createElement('div');
              childEle.innerHTML = 'Deleted';
              childEle.style.cssText = 'background-color:brown;padding:2px;color:#ffffff;margin-top:5px;';
              div2.appendChild(childEle);
            }
            if (currentRowData[11] == 'replace' || currentRowData[11] == 'text_change') {
              const img = document.createElement('img');
              img.src = '../../../assets/images/icon-star.svg';
              div2.appendChild(img);
            }

            div1.innerHTML = value.substring(0, 150) + '...';
            div2.style.cssText = 'margin-left:4px;';
            wrapper.style.cssText = 'display:flex';

            div1.setAttribute('id', `text${value}`)
            
            const btnWrapper = document.createElement('div');
            const btnShowMore = document.createElement('button')
            btnShowMore.innerText = 'Show More';
            btnShowMore.setAttribute('id', `showMore${value}`) 
            btnShowMore.style.cssText = 'cursor:pointer;border:none;outline:none;color:#a5473e;background:none;';

            const btnShowLess = document.createElement('button')
            btnShowLess.innerText = 'Show Less';
            btnShowLess.setAttribute('id', `showLess${value}`) 
            btnShowLess.style.cssText = 'cursor:pointer;border:none;outline:none;color:#a5473e;background:none;display:none;';

            
            btnShowMore.addEventListener('click', function(event){
              document.getElementById(`showLess${value}`).style.display = 'block';
              document.getElementById(`showMore${value}`).style.display = 'none';
              div1.innerHTML = value;
              
            })

            btnShowLess.addEventListener('click', function(event){
              document.getElementById(`showMore${value}`).style.display = 'block';
              document.getElementById(`showLess${value}`).style.display = 'none';
              div1.innerHTML = value.substring(0, 150) + '...';
            })

            btnWrapper.appendChild(btnShowMore);
            btnWrapper.appendChild(btnShowLess);
            
            const parentWrapper = document.createElement('div');

            wrapper.appendChild(div1);
            wrapper.appendChild(div2);

            parentWrapper.appendChild(wrapper);
            parentWrapper.appendChild(btnWrapper);

            Handsontable.dom.empty(td);
            td.appendChild(parentWrapper);
          } else {
            // td.innerHTML = value;
            const wrapper = document.createElement('div');
            const div1 = document.createElement('div');
            const div2 = document.createElement('div');
            if (currentRowData[11] == 'insert') {
              const childEle = document.createElement('div');
              childEle.innerHTML = 'new';
              childEle.style.cssText = 'background-color:lightgreen;padding:2px;color:#ffffff;';
              div2.appendChild(childEle);
            }
            if (currentRowData[11] == 'delete') {
              const childEle = document.createElement('div');
              childEle.innerHTML = 'Deleted';
              childEle.style.cssText = 'background-color:brown;padding:2px;color:#ffffff;margin-top:5px;';
              div2.appendChild(childEle);
            }
            if (currentRowData[11] == 'replace' || currentRowData[11] == 'text_change') {
              const img = document.createElement('img');
              img.src = '../../../assets/images/icon-star.svg';
              div2.appendChild(img);
            }

            div1.innerHTML = value;
            div2.style.cssText = 'margin-left:4px;';
            wrapper.style.cssText = 'display:flex';
            wrapper.appendChild(div1);
            wrapper.appendChild(div2);
            Handsontable.dom.empty(td);
            td.appendChild(wrapper);
          }
        }
        return td;
      }
      
    },
    {
      title: "Relevance",
      data: 'isRelevant',
      width: '60px',
      type: 'dropdown',
      // readOnly: true,
      // isHidden: false,
      source: ['Yes', 'No'],
      renderer: function(instance, td, row, col, prop, value, cellProperties) {
        if(value){
          if(value == 'Yes'){
            let wrapper = document.createElement('div');
            let div1 = document.createElement('div');
            let div2 = document.createElement('div');
            div2.innerHTML = value;
            div1.style.cssText = 'width:4px;border-radius:4px;margin-right:4px;background:#008000';
            wrapper.style.cssText = 'display:flex';
            wrapper.appendChild(div1);
            wrapper.appendChild(div2); 
            Handsontable.dom.empty(td);
            td.appendChild(wrapper);

          }

          if(value == 'No'){
            let wrapper = document.createElement('div');
            let div1 = document.createElement('div');
            let div2 = document.createElement('div');
            div2.innerHTML = value;
            div1.style.cssText = 'width:4px;border-radius:4px;margin-right:4px;background:#b32d00';
            wrapper.style.cssText = 'display:flex';
            wrapper.appendChild(div1);
            wrapper.appendChild(div2); 
            Handsontable.dom.empty(td);
            td.appendChild(wrapper);
          }
        }else{
            Handsontable.renderers.TextRenderer.apply(this, arguments);
        }

        return td;
      }
    },
    {
      title: "Compliance",
      data: 'compliantStatus',
      width: '70px',
      // isHidden: false,
      // readOnly: true,
      type: 'dropdown',
      source: ['None', 'Partial', 'Full', 'Not Assessed', 'Not Applicable'],
      renderer: function(instance, td, row, col, prop, value, cellProperties) {
        if(value){
          if(value == 'Partial'){
            let wrapper = document.createElement('div');
            let div1 = document.createElement('div');
            let div2 = document.createElement('div');
            div2.innerHTML = value;
            div1.style.cssText = 'width:4px;border-radius:4px;margin-right:4px;background:#ffcc00';
            wrapper.style.cssText = 'display:flex';
            wrapper.appendChild(div1);
            wrapper.appendChild(div2); 
            Handsontable.dom.empty(td);
            td.appendChild(wrapper);

          }

          if(value == 'None'){
            let wrapper = document.createElement('div');
            let div1 = document.createElement('div');
            let div2 = document.createElement('div');
            div2.innerHTML = value;
            div1.style.cssText = 'width:4px;border-radius:4px;margin-right:4px;background:#b32d00';
            wrapper.style.cssText = 'display:flex';
            wrapper.appendChild(div1);
            wrapper.appendChild(div2); 
            Handsontable.dom.empty(td);
            td.appendChild(wrapper);
          }

          if(value == 'Full'){
            let wrapper = document.createElement('div');
            let div1 = document.createElement('div');
            let div2 = document.createElement('div');
            div2.innerHTML = value;
            div1.style.cssText = 'width:4px;border-radius:4px;margin-right:4px;background:#008000';
            wrapper.style.cssText = 'display:flex';
            wrapper.appendChild(div1);
            wrapper.appendChild(div2); 
            Handsontable.dom.empty(td);
            td.appendChild(wrapper);
          }

          if(value == 'Not Assessed'){
            let wrapper = document.createElement('div');
            let div1 = document.createElement('div');
            let div2 = document.createElement('div');
            div2.innerHTML = value;
            div1.style.cssText = 'width:4px;border-radius:4px;margin-right:4px;background:grey';
            wrapper.style.cssText = 'display:flex';
            wrapper.appendChild(div1);
            wrapper.appendChild(div2); 
            Handsontable.dom.empty(td);
            td.appendChild(wrapper);
          }
        }else{
            Handsontable.renderers.TextRenderer.apply(this, arguments);
        }

        return td;
      }
    },
    {
      title: "Assessment",
      data: 'assessmentComment',
      width: '150px',
      // isHidden: false,
      // readOnly: true
    },
    {
      title: "Assessment Owner",
      data: 'assessmentOwner',
      width: '100px',
      type: 'dropdown',
      // isHidden: false,
      // readOnly: true
    },
    {
      title: "Action",
      data: 'action',
      width: '60px',
      // readOnly: true,
      // isHidden: false,
    },
    {
      title: "Action Owner/ Department",
      data: 'actionOwner',
      width: '70px',
      type: 'dropdown'
      // readOnly: true,
      // isHidden: false,
    },
    {
      title: "Action Status",
      data: 'actionStatus',
      width: '60px',
      type: 'dropdown',
      source: ['New', 'In Progress', 'Completed'],
      // readOnly: true,
      // isHidden: false,
    },
    {
      title: "Action Due Date",
      data: 'dueDate',
      width: '70px',
      // readOnly: true,
      // isHidden: false,
      type: 'date',
      dateFormat: 'DD-MM-YYYY',
      correctFormat: true,
      // datePicker additional options (see https://github.com/dbushell/Pikaday#configuration)
      datePickerConfig: {
        onOpen(){
          const container: any = document.querySelector('.htDatepickerHolder');
          const left = parseInt(this.el.offsetParent.style.left, 10);
          container.style.left = (left - 114) + 'px';
          },
        // First day of the week (0: Sunday, 1: Monday, etc)
        firstDay: 0,
        showWeekNumber: true,
        numberOfMonths: 1
      }
    },
    {
      title: 'AmendmentType',
      data: 'amendmentType',
      width: '1px',
      isHidden: true,
    },
  ];

  referenceReportingElementsTableColumns: Array<any> = [
    {
      title:"Template Code",
      data: 'templateCode',
      readOnly: true,
      width: '50px',
      cellClass: 'article-no-text',
      // isHidden: false,
    },
    {
      title:"Template Name",
      data: 'templateName',
      readOnly: true,
      width: '50px',
      cellClass: 'article-no-text',
      // isHidden: false,
    },
    {
      title:"Row ID",
      data: 'rowId',
      readOnly: true,
      width: '50px',
      cellClass: 'article-no-text',
      // isHidden: false,
    },
    {
      title:"Column ID",
      data: 'columnId',
      readOnly: true,
      width: '50px',
      cellClass: 'article-no-text',
      // isHidden: false,
    },
    {
      title:"Row Name",
      data: 'rowName',
      readOnly: true,
      width: '50px',
      cellClass: 'article-no-text',
      // isHidden: false,
    },
    {
      title:"Column Name",
      data: 'columnName',
      readOnly: true,
      width: '50px',
      cellClass: 'article-no-text',
      // isHidden: false,
    },
    {
      title:"Relevance",
      data: 'isRelevant',
      readOnly: true,
      width: '50px',
      cellClass: 'article-no-text',
      // isHidden: false,
    },
    {
      title:"Compliance",
      data: 'compliantStatus',
      readOnly: true,
      width: '50px',
      cellClass: 'article-no-text',
      // isHidden: false,
    },
    {
      title:"Instruction",
      data: 'instruction',
      readOnly: true,
      width: '50px',
      cellClass: 'article-no-text',
      // isHidden: false,
    },
    {
      title:"Assessment",
      data: 'assessment',
      readOnly: true,
      width: '50px',
      cellClass: 'article-no-text',
      // isHidden: false,
    },
    {
      title:"Assessment Owner",
      data: 'assessmentOwner',
      readOnly: true,
      width: '50px',
      cellClass: 'article-no-text',
      // isHidden: false,
    },
    {
      title:"Action",
      data: 'action',
      readOnly: true,
      width: '50px',
      cellClass: 'article-no-text',
      // isHidden: false,
    },
    {
      title:"Action Owner/ Department",
      data: 'actionOwner',
      readOnly: true,
      width: '50px',
      cellClass: 'article-no-text',
      // isHidden: false,
    },
    {
      title:"Action Status",
      data: 'actionStatus',
      readOnly: true,
      width: '50px',
      cellClass: 'article-no-text',
      // isHidden: false,
    },
    {
      title:"Action Due Date",
      data: 'actionDueDate',
      readOnly: true,
      width: '50px',
      cellClass: 'article-no-text',
      // isHidden: false,
    },
  ];

  amendmentsList: Array<any> = [];

  reportingQNATableColumns: Array<any> = [
    {
      title:"Header",
      data: 'question_header',
      readOnly: true,
      width: '100px',
      cellClass: 'article-normal-text',
      // isHidden: false,
    },
    {
      title:"ID",
      data: 'questionIdShow',
      width: '100px',
      readOnly: true,
      // isHidden: false,
      renderer: function(instance, td, row, col, prop, value, cellProperties) {
        Handsontable.renderers.TextRenderer.apply(this, arguments);
        td.title = value;
        return td;
      }
    },
    {
      title: "Question",
      data: 'question',
      width: '180px',
      readOnly: true,
      // isHidden: false,
      // renderer(instance, td, row, col, prop, value, cellProperties) {
      //   Handsontable.renderers.TextRenderer.apply(this, arguments);
      //   td.title = value;
      //   // if (value) {
      //   //     const currentRowData = instance.getDataAtRow(row);
      //   //     if (value.length > 150) {
      //   //     // td.innerHTML = value.substring(0,150) + '...';
      //   //     const wrapper = document.createElement('div');
      //   //     const div1 = document.createElement('div');
      //   //     const div2 = document.createElement('div');
            
      //   //     div1.innerHTML = value.substring(0, 150) + '...';
      //   //     div2.style.cssText = 'margin-left:4px;';
      //   //     wrapper.style.cssText = 'display:flex';

      //   //     div1.setAttribute('id', `text${value}`)
            
      //   //     const btnWrapper = document.createElement('div');
      //   //     const btnShowMore = document.createElement('button')
      //   //     btnShowMore.innerText = 'Show More';
      //   //     btnShowMore.setAttribute('id', `showMore${value}`) 
      //   //     btnShowMore.style.cssText = 'cursor:pointer;border:none;outline:none;color:#a5473e;background:none;';

      //   //     const btnShowLess = document.createElement('button')
      //   //     btnShowLess.innerText = 'Show Less';
      //   //     btnShowLess.setAttribute('id', `showLess${value}`) 
      //   //     btnShowLess.style.cssText = 'cursor:pointer;border:none;outline:none;color:#a5473e;background:none;display:none;';

            
      //   //     btnShowMore.addEventListener('click', function(event){
      //   //       document.getElementById(`showLess${value}`).style.display = 'block';
      //   //       document.getElementById(`showMore${value}`).style.display = 'none';
      //   //       div1.innerHTML = value;
              
      //   //     })

      //   //     btnShowLess.addEventListener('click', function(event){
      //   //       document.getElementById(`showMore${value}`).style.display = 'block';
      //   //       document.getElementById(`showLess${value}`).style.display = 'none';
      //   //       div1.innerHTML = value.substring(0, 150) + '...';
      //   //     })

      //   //     btnWrapper.appendChild(btnShowMore);
      //   //     btnWrapper.appendChild(btnShowLess);
            
      //   //     const parentWrapper = document.createElement('div');

      //   //     wrapper.appendChild(div1);
      //   //     wrapper.appendChild(div2);

      //   //     parentWrapper.appendChild(wrapper);
      //   //     parentWrapper.appendChild(btnWrapper);

      //   //     Handsontable.dom.empty(td);
      //   //     td.appendChild(parentWrapper);
      //   //   } else {
      //   //     // td.innerHTML = value;
      //   //     const wrapper = document.createElement('div');
      //   //     const div1 = document.createElement('div');
      //   //     const div2 = document.createElement('div');
      //   //     div1.innerHTML = value;
      //   //     div2.style.cssText = 'margin-left:4px;';
      //   //     wrapper.style.cssText = 'display:flex';
      //   //     wrapper.appendChild(div1);
      //   //     wrapper.appendChild(div2);
      //   //     Handsontable.dom.empty(td);
      //   //     td.appendChild(wrapper);
      //   //   }
      //   // }
      //   return td;
      // }
      
    },
    {
      title: "Response",
      data: 'response',
      width: '350px',
      // isHidden: false,
      readOnly: true
    },
    {
      title: "Relevance",
      data: 'isRelevant',
      width: '60px',
      type: 'dropdown',
      // readOnly: true,
      // isHidden: false,
      source: ['Yes', 'No'],
      renderer: function(instance, td, row, col, prop, value, cellProperties) {
        if(value || value == null){
          if(value == 'Yes'){
            let wrapper = document.createElement('div');
            let div1 = document.createElement('div');
            let div2 = document.createElement('div');
            div2.innerHTML = value;
            div1.style.cssText = 'width:4px;border-radius:4px;margin-right:4px;background:#008000';
            wrapper.style.cssText = 'display:flex';
            wrapper.appendChild(div1);
            wrapper.appendChild(div2); 
            Handsontable.dom.empty(td);
            td.appendChild(wrapper);

          }

          if(value == 'No'){
            let wrapper = document.createElement('div');
            let div1 = document.createElement('div');
            let div2 = document.createElement('div');
            div2.innerHTML = value;
            div1.style.cssText = 'width:4px;border-radius:4px;margin-right:4px;background:#b32d00';
            wrapper.style.cssText = 'display:flex';
            wrapper.appendChild(div1);
            wrapper.appendChild(div2); 
            Handsontable.dom.empty(td);
            td.appendChild(wrapper);
          }

          if(value == null){
            let wrapper = document.createElement('div');
            let div1 = document.createElement('div');
            let div2 = document.createElement('div');
            div2.innerHTML = 'No';
            div1.style.cssText = 'width:4px;border-radius:4px;margin-right:4px;background:#b32d00';
            wrapper.style.cssText = 'display:flex';
            wrapper.appendChild(div1);
            wrapper.appendChild(div2); 
            Handsontable.dom.empty(td);
            td.appendChild(wrapper);
          }
        }else{
            Handsontable.renderers.TextRenderer.apply(this, arguments);
        }

        return td;
      }
    },
    {
      title: "Comment",
      data: 'comment',
      width: '150px',
      // isHidden: false,
      // readOnly: false,
      // type: 'dropdown',
      // source: ['None', 'Partial', 'Full', 'Not Assessed', 'Not Applicable'],
      renderer: function(instance, td, row, col, prop, value, cellProperties) {
        if(value){
          td.appendChild(value)
        }else{
            Handsontable.renderers.TextRenderer.apply(this, arguments);
        }

        return td;
      }
    },
    {
      title: "Attachments",
      data: 'attachments',
      width: '150px',
      cellClass: 'article-no-text',

      // isHidden: false,
      readOnly: true,
      // type: 'dropdown',
      // source: ['None', 'Partial', 'Full', 'Not Assessed', 'Not Applicable'],
      renderer: function(instance, td, row, col, prop, value, cellProperties) {
        if(value && value.length){
          let wrapper = document.createElement('div')
          for(let i=0;i<value.length;i++){
            let a = document.createElement('a');
            let link = document.createTextNode(value[i]);
            a.appendChild(link);
            a.href = value[i];
            a.title = value[i];
            a.target = '_blank';
            wrapper.style.cssText = 'color:lightblue;cursor:pointer;'
            wrapper.appendChild(a);
          }
          Handsontable.dom.empty(td);
            
          td.appendChild(wrapper);
        }else{
            value = 'NA'
            Handsontable.renderers.TextRenderer.apply(this, arguments);
        }

        return td;
      }
    },
    {
      title: "Version",
      data: 'version',
      width: '100px',
      // isHidden: false,
      readOnly: true
    }
  ];


  constructor() { }


  monthName(monthNumber): any {
    let monthName = '';
    let List = [
      {month: '01', name: 'Jan'},
      {month: '02', name: 'Feb'},
      {month: '03', name: 'Mar'},
      {month: '04', name: 'Apr'},
      {month: '05', name: 'May'},
      {month: '06', name: 'Jun'},
      {month: '07', name: 'Jul'},
      {month: '08', name: 'Aug'},
      {month: '09', name: 'Sep'},
      {month: '10', name: 'Oct'},
      {month: '11', name: 'Nov'},
      {month: '12', name: 'Dec'}
    ];

    List.forEach((item) => {
      if(item.month == monthNumber){
        monthName = item.name;
      }
    });

    return monthName;
  }

  checkDatePast(dateString1, dateString2): any {
    if(dateString1.getFullYear() <= dateString2.getFullYear() )//check the year
  { 
    // console.log("date1+date2"+this.dateString1.getFullYear()+this.dateString2.getFullYear())
    if(dateString1.getMonth() <= dateString2.getMonth())//check the month
      {
        // console.log("date1+date2"+this.dateString1.getMonth()+this.dateString2.getMonth())
        if(dateString1.getDate() < dateString2.getDate())//check the date
        {
          return true;
        }
      }
  }else{
    return false;
  }
  }
}
