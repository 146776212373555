import { Component, OnInit, ViewChild, AfterContentChecked } from '@angular/core';
import { environment } from '../../../environments/environment';
import { DataServiceService } from '../../shared/services/data-service/data-service.service';
import { ArticlesDataService } from '../../shared/services/articles-data-service/articles-data.service'; 
import { RAGStatusComponent } from '../../shared/components/r-a-g-status/r-a-g-status.component';
import { MathJaxService } from '../../shared/services/math-jax.service';

declare var $: any;

@Component({
  selector: 'app-document-details',
  templateUrl: './document-details.component.html',
  styleUrls: ['./document-details.component.scss']
})
export class DocumentDetailsComponent implements OnInit, AfterContentChecked {
  @ViewChild(RAGStatusComponent) ragComponent: RAGStatusComponent;
  relevanceOptions: Array<any> = [{label:'Yes', value:true, barColor: '#5aac44'},{label:'No', value:false, barColor: 'grey'}];
  selectedDocumentFetchdDetails: any = {};
  usersList: Array<any> = [];
  documentsList: Array<any> = [];
  fetchDocument: any;
  selectedDocumentName: any;
  editNotesFlag: boolean = false;
  showDescription: Array<any> = [];
  regulatoryTopicsList: Array<any> = [];
  selectedTopic: any;
  documentCategoriesList: Array<any> = [];
  documentsAndCategoriesList: Array<any> = [];
  selectedDocumentCategory: any;
  documentNamesList: Array<any> = [];
  showModifiedDescription: Array<any> = [];
  selectedDocument: any;
  constructor(
    private _dataService: DataServiceService,
    private _articlesDataService: ArticlesDataService,
    private mathJaxService: MathJaxService,
    
  ) { }

  ngOnInit(): void {
    this.getUsersList();
    // this.getDocumentList();
    // this.getRegulatoryTopics();
    this.mathJaxService.renderEquation('formula-div');
    
  }

  ngAfterContentChecked(): any{
    this.mathJaxService.renderEquation('formula-div');
    
  }

  getRegulatoryTopics(): void{
    let url = environment.apiUrls.getRegulationTopicsUrl;
    this._dataService.getData(url, [], true).toPromise()
    .then((response: any) => {
      if (response.status == 1) {
        this.regulatoryTopicsList = [];
        response.data.forEach((item) => {
          if(item.isActive){
            this.regulatoryTopicsList.push(item);
          }
        })
        // this.regulatoryTopicsList = response.data;
        if(localStorage.getItem('dashboardArticleNavDocId')){
          this.selectedTopic = localStorage.getItem('dashboardArticleNavTopicId');
          this.getDocumentsForTopics(this.selectedTopic);
        }else{
          this.selectedTopic = this.regulatoryTopicsList[0]._id;
          this.getDocumentsForTopics(this.regulatoryTopicsList[0]._id);
        }
        
        
        
      }
    }, (error) => {});

  }

  getDocumentsForTopics(id): void{
    let url = environment.apiUrls.getRegulatoryTopicsDocumentUrl;
    url = url.replace(':topicId', id);
    
    this._dataService.getData(url, [{key: 'isDocumentScreen', val: true}], true).toPromise()
    .then((response: any) => {
      if (response.status == 1) {
        this.documentCategoriesList = [];
        response.data.forEach((item) => {
          item._id.docType = item._id.documentType;
          item._id.docCategory = item._id.documentCategory;
          this.documentCategoriesList.push(item._id);
        })

        this.documentsAndCategoriesList = response.data;
        if(localStorage.getItem('dashboardArticleNavDocId')){
          this.selectedDocumentCategory = localStorage.getItem('dashboardArticleNavCategoryId');
          this.onDocumentCategorySelection(this.selectedDocumentCategory);
        }else{
          this.selectedDocumentCategory = this.documentCategoriesList[0].docCategory;
          // this.selectedDocumentName = this.documentCategoriesList[0].docType;
          this.onDocumentCategorySelection(this.documentCategoriesList[0].docCategory);
        }
        
        
  
        // this.getUsersList();
      }
    }, (error) => {});
  }

  onDocumentCategorySelection(selectedCategory): any {
    this.documentsAndCategoriesList.forEach((item) => {
      if(item._id.docCategory == selectedCategory){
        this.documentsList = item.documents;
        
      }
    })
    if(localStorage.getItem('dashboardArticleNavDocId')){
      this.selectedDocument = localStorage.getItem('dashboardArticleNavDocId')
    }else{
      this.selectedDocument = this.documentsList[0]._id;
    
    }
      
    this.fetchSelectedDocument();
  }

  getUsersList(): void {
    var url = environment.apiUrls.getUsersUrl;

    this._dataService.getData(url, [], true).toPromise()
        .then((response: any) => {
            if (response.status == 1) {
                this.usersList = [];
                response.data.users.forEach((obj) => {
                    var userObj = {
                        name: obj.firstName + ' ' + obj.lastName,
                        id: obj._id
                    };
                    this.usersList.push(userObj);
                    
                });
            }
            this.getRegulatoryTopics();
        }, (error) => {
        });
  }

  getDocumentList(): void {
    const url = environment.apiUrls.getDocumentsListUrl;
    
    this._dataService.getData(url, [], true).toPromise()
      .then((response: any) => {
        if (response.status == 1) {
          this.documentsList = [];
          response.data.documents.forEach((doc) => {
            doc.documents.forEach((item) => {
              let documentObj = {
                name: item.type + ' ' + item.title,
                id: item._id,
                documentCategory: doc._id.documentCategory
              }

              this.documentsList.push(documentObj);
            });
          });

          this.selectedDocumentName = this.documentsList[0].name;
          this.fetchDocument = this.documentsList[0].id;
          let selectedDocumentObj = this.documentsList[0];
          this._articlesDataService.selectedDocCategoryFilter = selectedDocumentObj.documentCategory;
          this._articlesDataService.selectedDocFilter = selectedDocumentObj.id;
          // this.ragComponent.ngOnInit();
          this.fetchSelectedDocument();
        }
      }, (error) => {

      });
  }

  changeRelevance(){
    let url = environment.apiUrls.documentDetailsUrl;
    url = url.replace(':documentId', this.selectedDocument);

    let data = {};
    data['isRelevant'] = this.selectedDocumentFetchdDetails.isRelevant;
    this._dataService.patchData(url, data, true).toPromise()
    .then((response:any) => {
      if(response.status == 1){
        this.fetchSelectedDocument();
      }
    });
  }

  DontChangeRelevance(){
    this.fetchSelectedDocument();
  }

  fetchSelectedDocument(): any{
    let selectedDocumentObj:any;

    this.documentsList.forEach((item) => {
      if(item._id == this.selectedDocument){
        selectedDocumentObj = item;
      }
    });
    localStorage.removeItem('dashboardArticleNavDocId');
    localStorage.removeItem('dashboardArticleNavKey');
    localStorage.removeItem('dashboardArticleNavVal');
    localStorage.removeItem('dashboardArticleNavCategoryId');
    localStorage.removeItem('dashboardArticleNavTopicId');
    
    let url = environment.apiUrls.documentDetailsUrl;
    url = url.replace(':documentId', this.selectedDocument);

    this._dataService.getData(url, [], true).toPromise()
    .then((response:any) => {
      if(response.status == 1){
        this.selectedDocumentFetchdDetails = response.data;
        this.showDescription = [];
        if(response.data.description && response.data.description.metadata){
          for(let i=0; i< response.data.description.metadata.length; i++){
            this.showDescription.push(response.data.description.metadata[i]);
          }
        }

        if(response.data.description) {
          for(let key in response.data.description){
            if(key != 'metadata' && key != 'reference'){
              
              this.showDescription.push(response.data.description[key].text)
            }
            
          }
        }
        

        this.showModifiedDescription = [];

        if(this.showDescription.length){
          this.showDescription.forEach((item) => {
            let tempArr = [];
            tempArr = item.split(' ');
            let newTempArr = [];
            tempArr.forEach((subItem) => {
              if(subItem.length > 4 && subItem.substring(0,4) == 'http'){
                subItem = `<a href="${subItem}" target="_blank">${subItem}</a>` + ' ';
              }
              newTempArr.push(subItem);
              
            });
            let newStr = newTempArr.join(' ');
            this.showModifiedDescription.push(newStr);
          })
        }
        
        this.mathJaxService.renderEquation('formula-div');
    
  
        
        
      }
    })

    this.selectedDocumentName = selectedDocumentObj.title; 
    this._articlesDataService.selectedDocCategoryFilter = selectedDocumentObj.type;
    this._articlesDataService.selectedDocFilter = selectedDocumentObj._id;
    this.ragComponent.ngOnInit();

  }

  loadThisVersionDoc(docId):any{
    this.selectedDocument = docId;
    this.fetchSelectedDocument();
  }

  updateDocumentDetails(info): any{
    let url = environment.apiUrls.documentDetailsUrl;
    url = url.replace(':documentId', this.fetchDocument);

    let data = {};

    if(info == 'relevance'){
      $("#relevanceConfirmationModal").modal('show');
      return
      // data['isRelevant'] = this.selectedDocumentFetchdDetails.isRelevant;
    }

    if(info == 'docOwner'){
      data['documentOwner'] = this.selectedDocumentFetchdDetails.documentOwner;
    }

    if(info == 'notes'){
      data['notes'] = this.selectedDocumentFetchdDetails.notes;
    }

    this._dataService.patchData(url, data, true).toPromise()
    .then((response:any) => {
      if(response.status == 1){
        if(info == 'notes'){
          this.editNotesFlag = false;
        }
      }
    });
  }

}
