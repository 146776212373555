import { Component, OnInit, ViewChild, AfterContentInit, AfterContentChecked, ViewEncapsulation } from '@angular/core';
import { DataServiceService } from '../../shared/services/data-service/data-service.service';
import { environment } from '../../../environments/environment';
import { MathJaxService } from '../../shared/services/math-jax.service';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { CookieService } from 'ngx-cookie';
import { ToastrService } from 'ngx-toastr';
import { ArticleDetailService } from './article-detail.service';
import { Location } from '@angular/common';
import Handsontable from 'handsontable';
import {IAngularMyDpOptions, IMyDateModel} from 'angular-mydatepicker';
import { ArticlesDataService } from '../../shared/services/articles-data-service/articles-data.service';
import { env } from 'process';
import { DomSanitizer, SafeResourceUrl, SafeUrl} from '@angular/platform-browser';

//beware of XSS

declare var $: any;

@Component({
  selector: 'app-article-detail',
  templateUrl: './article-detail.component.html',
  styleUrls: ['./article-detail.component.scss'],
  // encapsulation: ViewEncapsulation.None,
})

export class ArticleDetailComponent implements OnInit, AfterContentChecked {
  currentHeightDocument: any;
  usersList: Array<any> = [];
  usersListForDropdown: Array<any> = [];
  selectedArticleId: any;
  documentReferences: any;
  editTagsFlag: Boolean = false;
  selectedArticleDetailsObj: any = {};
  istable: any;
  imagesListArticle: Array<any> = [];
  nodesData: Array<any> = [];
  relevanceOptions: Array<any> = [{label:'Yes', value:true, barColor: '#5aac44'},{label:'No', value:false, barColor: 'grey'}];
  complianceOptions: Array<any> = [{label:'None', value: 'none', barColor: '#b32d00'},{label:'Partial', value: 'partial', barColor: '#ffcc00'},{label:'Full', value: 'full', barColor: '#008000'}, {label:'Not Assessed', value: 'not_assessed', barColor: 'grey'},{label:'Not Applicable', value: 'not_applicable', barColor: 'white'}];
  editAssessmentFlag: Boolean = false;
  actionsListData: Array<any> = [];
  showAction: Boolean = false;
  clonedDataonRightClick: any = {};
  tabletest: any;
  nodeOptions: any = {
    actionMapping: {
      mouse: {
        click: (tree, node, $event) => {
          if(tree.nodes[0]._id != node.data._id){
            let url = this.router.createUrlTree(['dashboard/article-detail', node.data._id ]).toString();
            window.open(url, "_self");
          }else{
            // this._toastr.error('Selection is not allowed for parent node. Please Select child nodes for their details!');
          }
        },
        contextMenu: (tree, node, $event) => {
          this.clonedDataonRightClick = {};
          this.clonedDataonRightClick.tree = tree;
          this.clonedDataonRightClick.node = node;
          this.clonedDataonRightClick.event = $event.currentTarget;
          this.clonedDataonRightClick.checkOpen =this.clonedDataonRightClick.tree.nodes[0]._id != this.clonedDataonRightClick.node.data._id
          $event.preventDefault();
          console.log($("." + $event.currentTarget.className).offset().top);
          $(".custom-menu").finish().toggle(100).
          // In the right position (the mouse)
          css({
              top: $event.pageY - $("." + $event.currentTarget.className).offset().top + 16 +"px",
              left: $event.pageX - $("." + $event.currentTarget.className).offset().left + "px"
          });
          $(document).bind("mousedown", function (e) {
            // If the clicked element is not the menu
            if (!$(e.target).parents(".custom-menu").length) {
                // Hide it
                $(".custom-menu").hide(100);
            }
          });
        }
      },
    }
  };
  selectedArticlePreviousVersionObj: any = {};
  editPreviousVersionAssessmentFlag: Boolean = false;
  previousVersionActionsListData: Array<any> = [];
  previousVersionNodesData: Array<any> = [];
  showPreviousVersionAction: Boolean = false;
  qnaData: Array<any> = [];
  previousVersionNodeOptions: any = {
    actionMapping: {
      mouse: {
        click: (tree, node, $event) => {
          if(tree.nodes[0]._id != node.data._id){
            let url = this.router.createUrlTree(['dashboard/article-detail', node.data._id ]).toString();
            window.open(url, "_self");
          }else{
            // this._toastr.error('Selection is not allowed for parent node. Please Select child nodes for their details!');
          }
        },
        contextMenu: (tree, node, $event) => {
          // this.clonedDataonRightClick = {};
          // this.clonedDataonRightClick.tree = tree;
          // this.clonedDataonRightClick.node = node;
          // this.clonedDataonRightClick.event = $event.currentTarget;
          // this.clonedDataonRightClick.checkOpen =this.clonedDataonRightClick.tree.nodes[0]._id != this.clonedDataonRightClick.node.data._id
          // $event.preventDefault();
          console.log($("." + $event.currentTarget.className).offset().top);
          $(".custom-menu").finish().toggle(100).
          // In the right position (the mouse)
          css({
              top: $event.pageY - $("." + $event.currentTarget.className).offset().top + 16 +"px",
              left: $event.pageX - $("." + $event.currentTarget.className).offset().left + "px"
          });
          $(document).bind("mousedown", function (e) {
            // If the clicked element is not the menu
            if (!$(e.target).parents(".custom-menu").length) {
                // Hide it
                $(".custom-menu").hide(100);
            }
          });
        }
      },
    }
  };

  selectedArticleFutureVersionObj: any = {};
  futureVersionNodesData: Array<any> = [];
  futureVersionNodeOptions: any = {
    actionMapping: {
      mouse: {
        click: (tree, node, $event) => {
          if(tree.nodes[0]._id != node.data._id){
            let url = this.router.createUrlTree(['dashboard/article-detail', node.data._id ]).toString();
            window.open(url, "_self");
          }else{
            // this._toastr.error('Selection is not allowed for parent node. Please Select child nodes for their details!');
          }
        },
        contextMenu: (tree, node, $event) => {
          // this.clonedDataonRightClick = {};
          // this.clonedDataonRightClick.tree = tree;
          // this.clonedDataonRightClick.node = node;
          // this.clonedDataonRightClick.event = $event.currentTarget;
          // this.clonedDataonRightClick.checkOpen =this.clonedDataonRightClick.tree.nodes[0]._id != this.clonedDataonRightClick.node.data._id
          // $event.preventDefault();
          console.log($("." + $event.currentTarget.className).offset().top);
          $(".custom-menu").finish().toggle(100).
          // In the right position (the mouse)
          css({
              top: $event.pageY - $("." + $event.currentTarget.className).offset().top + 16 +"px",
              left: $event.pageX - $("." + $event.currentTarget.className).offset().left + "px"
          });
          $(document).bind("mousedown", function (e) {
            // If the clicked element is not the menu
            if (!$(e.target).parents(".custom-menu").length) {
                // Hide it
                $(".custom-menu").hide(100);
            }
          });
        }
      },
    }
  };
  editFutureVersionAssessmentFlag: Boolean = false;
  futureVersionActionsListData: Array<any> = [];
  showFutureVersionAction: Boolean = false;
  editEffectiveFlag: Boolean = false;
  editControlsFlag: Boolean = false;
  referencesList: Array<any> = [];
  indirectReferencesList: Array<any> = [];

  referenceArticlesTableInstance: Handsontable;
  hotSettings_referenceArticles: Handsontable.GridSettings;
  updatedDataListReferencesArticlesAssessment: Array<any> = [];
  updatedDataListReferencesArticlesActions: Array<any> = [];
  tableId_referenceArticlesForArticleDetail: any = 'reference-articles-table';
  indirectReferencesTableInstance: Handsontable;
  hotSettings_indirectReferenceArticles: Handsontable.GridSettings;
  updatedDataListIndirectReferencesArticlesAssessment: Array<any> = [];
  updatedDataListIndirectReferencesArticlesActions: Array<any> = [];
  tableId_indirectReferenceArticlesForArticleDetail: any = 'indirect-reference-articles-table';
  demoTablesLabel: any = ['Volatility adjustments', 'Volatility adjustments', 'Other collateral or exposure types', 'Volatility adjustment for currency mismatch'];

  newCurrentVersionActionObj: any = {
    action: null,
    actionOwnerId: null,
    dueDate: null,
    assignedBy: null,
    assessmentId: null,
    regulationId: null,
    status: null
  };
  actionDueDate: any = null;
  actionStatusList: Array<any> = [{label: 'New', val: 'new'},{label: 'In Progress', val: 'in_progress'},{label: 'Completed', val: 'completed'}];
  myDpOptions: IAngularMyDpOptions = {
    dateRange: false,
    dateFormat: 'dd.mm.yyyy'
  };
  editAction: Boolean = false;
  editPreviousVersionAction: Boolean = false;
  actionPreviousVersionDueDate: any = null;
  indirect_row_col_ref: any = [];
  
  newPreviousVersionActionObj: any = {
    action: null,
    actionOwnerId: null,
    dueDate: null,
    assignedBy: null,
    assessmentId: null,
    regulationId: null,
    status: null
  };
  editFutureVersionAction: Boolean = false;
  actionFutureVersionDueDate: any = null;
  newFutureVersionActionObj: any = {
    action: null,
    actionOwnerId: null,
    dueDate: null,
    assignedBy: null,
    assessmentId: null,
    regulationId: null,
    status: null
  };

  constructor(
    private _dataService: DataServiceService,
    private _articleDetailService: ArticleDetailService,
    private _articlesDataService: ArticlesDataService,
    private route: ActivatedRoute,
    private _cookie: CookieService,
    private _toastr: ToastrService,
    private router: Router,
    private location: Location,
    private mathJaxService: MathJaxService,
    public sanitizer: DomSanitizer
  ){
    // this.mathJaxService.renderEquation('#formula-div');
    // this.mathJaxService.renderEquation('#formula-div1');
  }

  ngOnInit(): void {
    var routeUrlParams: any = this.route.snapshot.paramMap;
    this.selectedArticleId = routeUrlParams.params.id;
    this.getUsersList();
    this.getAssessmentDetails();
    this.getRegulationDocumentReferences();
    this.mathJaxService.renderEquation('formula-div');
    this.currentHeightDocument = $(document).height();
  }

  ngAfterContentChecked(): any{
    this.mathJaxService.renderEquation('formula-div');
    
  }

  onNodeExpander(event): any{
    // this.mathJaxService.renderEquation('formula-div');
  }

  copyData() {
    let selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = this.clonedDataonRightClick.event.innerText;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
    $(".custom-menu").hide(100);
  }

  openNodeData() {
    if(!this.clonedDataonRightClick.previousVersion) {
      if(this.clonedDataonRightClick.tree.nodes[0]._id != this.clonedDataonRightClick.node.data._id){
        let url = this.router.createUrlTree(['dashboard/article-detail', this.clonedDataonRightClick.node.data._id ]).toString();
        window.open(url, "_self");
        $(".custom-menu").hide(100);
      }
    }
    else {
      let url = this.router.createUrlTree(['dashboard/article-detail', this.clonedDataonRightClick.node.data._id ]).toString();
      window.open(url, "_self");
      $(".custom-menu").hide(100);
    }
  }

  openNewTab() {
    if(!this.clonedDataonRightClick.previousVersion) {
      if(this.clonedDataonRightClick.tree.nodes[0]._id != this.clonedDataonRightClick.node.data._id){
        let url = this.router.createUrlTree(['dashboard/article-detail', this.clonedDataonRightClick.node.data._id ]).toString();
        window.open(url, "_blank");
        $(".custom-menu").hide(100);
      }
    }
    else {
      let url = this.router.createUrlTree(['dashboard/article-detail', this.clonedDataonRightClick.node.data._id ]).toString();
      window.open(url, "_self");
      $(".custom-menu").hide(100);
    }
  }

  openQnA(item): any {
    if(item.link) {
      window.open(item.link,'_blank');
    }
  }

  getQnAData(): any{
    var url = environment.apiUrls.getQnAUrl;
    url = url.replace(':regId', this.selectedArticleDetailsObj['regulations']._id);
    this._dataService.getData(url, [], true).toPromise()
    .then((response:any) => {
      if(response && response.status == 1 && response.data && response.data.result && response.data.result.length){
        response.data.result.forEach((item) => {
          item.editQnA = false;
        })
        this.qnaData = response.data.result;
      }
    }, (error) => {

    }) 
  }

  editQnANotes(item): any {
    var url = environment.apiUrls.updateQnAUrl;
    url = url.replace(':qaId', item._id);

    this._dataService.patchData(url, {notes: item.notes}, true).toPromise()
    .then((response: any) => {
      if(response && response.status == 1){
        item.editQnA = false;
      }
    }, (error) => {

    })
  }

  getUsersList(): void {
    var url = environment.apiUrls.getUsersUrl;
    this._dataService.getData(url, [], true).toPromise()
    .then((response: any) => {
        if (response.status == 1) {
          this.usersList = [];
          this.usersListForDropdown = [];
          response.data.users.forEach((obj) => {
              var userObj = {
                  name: obj.firstName + ' ' + obj.lastName,
                  id: obj._id
                };
              this.usersList.push(userObj);
          }); 
          this.usersList.forEach((item) => {
            this.usersListForDropdown.push(item.name);
          });
        }
      }, (error) => {});
  }

  getRegulationDocumentReferences() {
    var url = environment.apiUrls.getArticleDocumentReferences;
    url = this.selectedArticleId ? url.replace(':regulationId', this.selectedArticleId) : url;
    this._dataService.getData(url, [], true).toPromise()
    .then((response: any) => {
      if (response.status == 1) {
        this.documentReferences = response.data.documents;
      }
    }, (error) => {});
  }

  navigateToReferenceLink(ref): any{
    if(ref.refLink){
      const url = this.router.createUrlTree(['dashboard/article-detail', ref.refLink]).toString();
      window.open(url, '_blank');
    }else{
      this._toastr.error('Link is not available!');
    }
  }

  goToPreviousArticle(): any {
    var currentIndex;
    var articleIdList = [];
    articleIdList = JSON.parse(localStorage.getItem('articleIdsList'));
    articleIdList.forEach((item, index) => {
      if(item == this.selectedArticleId){
        currentIndex = index;
      }
    });

    if(currentIndex > 0){
      this.selectedArticleId = articleIdList[currentIndex - 1];
      let url = this.router.createUrlTree(['dashboard/article-detail', this.selectedArticleId ]).toString();
      this.location.go(url);
      this.getAssessmentDetails();
    }
  }

  goToNextArticle(): any {
    var currentIndex;
    var articleIdList = [];
    articleIdList = JSON.parse(localStorage.getItem('articleIdsList'));
    articleIdList.forEach((item, index) => {
      if(item == this.selectedArticleId){
        currentIndex = index;
      }
    });

    if(currentIndex < articleIdList.length - 1){
      this.selectedArticleId = articleIdList[currentIndex + 1];
      let url = this.router.createUrlTree(['dashboard/article-detail', this.selectedArticleId ]).toString();
      this.location.go(url);
      this.getAssessmentDetails();
    }
  }
  
  getFullReferenceHyperlinks(): any{
    var url = environment.apiUrls.articleReferencesLinks;
    url = url.replace(':regulationId', this.selectedArticleDetailsObj['regulations']._id);

    this._dataService.getData(url, [], true).toPromise()
      .then((response: any) => {
        if(response.status == 1){
          if(response.data){
            this.selectedArticleDetailsObj['fullReference'].forEach((item) => {
              if(response.data[item.refText]){
                item.refLink = response.data[item.refText];
              }else{
                item.refLink = null;
              }
            })
          }
        }
      }, (error: any) => {})
  }

  goToParentArticle(): any {
    let url = this.router.createUrlTree(['dashboard/article-detail', this.selectedArticleDetailsObj.regulations.parentId ]).toString();
    window.open(url, "_self");
  }

  navigateToPdf(item) {
    if(item.link) {
      window.open(item.link,'_blank');
    }
    else {
      if(item.type === "ITS") {
        this.router.navigate(['dashboard/reporting-elements-list', {docId: item.id}]);
        const url = this.router.createUrlTree(['dashboard/reporting-elements-list', {docId: item.id}]).toString();
        window.open(url, '_blank');
        // this.router.navigate([]).then(result => {  window.open(link, '_blank'); });
      } else {
        // this._articlesDataService.navigateDocCategory = item.category;
        // this._articlesDataService.navigateDocId = item.id;
        localStorage.setItem('topicForArticleList', item.regulatoryTopics[0]);
        localStorage.setItem('navigateDocCategory', item.category);
        localStorage.setItem('navigateDocId', item.id);
        // this._articlesDataService.topicForArticleList = item.regulatoryTopics[0];
        // this._articlesDataService.navigateDocCategory = item.category;
        // this._articlesDataService.navigateDocId = item.id;
        // this.router.navigate(['dashboard/article-list']);
        const url = this.router.createUrlTree(['dashboard/article-list']).toString();
        window.open(url, '_blank');
      }
    }
  }

  escapeHtml(text) {
    var map = {
      '<': '&lt;',
      '>': '&gt;',
      // "'": ''
      
    };
    // '"': '&quot;',
    //   "'": '&#039;'
    //   '&': '&amp;',
      
    return text.replace(/[&<>]/g, function(m) { return map[m]; });
  }

  recreateTables(tblHtml): any{
    
  }

  uniqueReportingRefs(array) {
    var flags = [], output = [], l = array.length, i;
    for( i=0; i<l; i++) {
      if(array[i].column){
        if(  flags[array[i].column.identifier]) continue;
        flags[array[i].column.identifier] = true;
        output.push(array[i]);
      }
      if(array[i].row){
        if(  flags[array[i].row.identifier]) continue;
        flags[array[i].row.identifier] = true;
        output.push(array[i]);
      }
    }
    return [...output];
   }

  getIndirectReportingElementReferences(): any{
    var url = environment.apiUrls.indirectReportingReferences;
    url = url.replace(':regulationId', this.selectedArticleId);

    this._dataService.getData(url, [], true).toPromise()
      .then((response: any) => {
        if(response.status == 1){
          this.indirect_row_col_ref = [];
          this.indirect_row_col_ref = response.data.indirectReportingReferences
          // this.indirect_row_col_ref = this.uniqueReportingRefs(response.data.indirectReportingReferences);
          
          this.indirect_row_col_ref.forEach((item) => {
            if(item.column){
              item.column.expandText = false;
            }
            if(item.row){
              item.row.expandText = false;
            }
            if(item.column && item.column.instruction){
              if(item.column.instruction.length > 200){
                item.column.instructionDisplay = item.column.instruction.substring(0, 200) + '...';
              }
            }

            if(item.row && item.row.instruction){
              if(item.row.instruction.length > 200){
                item.row.instructionDisplay = item.row.instruction.substring(0, 200) + '...';
              }
            }
          })
        }
      }, (error) => {

      });
  }

  navigateToReportingReferences(ele): any {
    if(ele.column && ele.row){
      const url = this.router.createUrlTree(['dashboard/reporting-element-detail', ele.reportingElement._id]).toString();
      window.open(url, '_self');
    }else{
      if(ele.row){
        const url =  this.router.createUrlTree(['dashboard/reporting-elements-list', {docId:ele.template.documentId, templateId: ele.template._id}]).toString();
        window.open(url, '_self');
      }

      if(ele.column){
        const url = this.router.createUrlTree(['dashboard/reporting-elements-list', {docId:ele.template.documentId, templateId: ele.template._id}]).toString();
        window.open(url, '_self');
      }
    }
    
  }

  updateTree(tree, version): any {
    if(tree.tableHtml){
      let htmlStr = tree.tableHtml.replace(/(\r\n|\n|\r)/gm, "");
      tree['name'] =   tree.text + '\n\n\n' + htmlStr;
    }else{
      if(tree.text == null && tree.meta && tree.meta.length == 0){
        if(version == 'current'){
          tree['name'] = this.selectedArticleDetailsObj.regulations.document.title;
        }
        
      }else{
        if (tree.text == null) {
          tree['name'] = tree.meta[0] + '\n' ;
          if(tree.meta.length > 1){
            tree['name'] = tree['name'] + '\n' + ' ';
            for(let i = 1; i < tree.meta.length; i++){
              tree['name'] = tree['name'] + tree.meta[i] + (i != tree.meta.length - 1 ? '\n' : '');
            }
            tree['name'] = tree['name'].replace(/\n|\r\n|\r/g, '<br/>')
          }
      }else{
        // let text = tree.text.replace(/\n|\r\n|\r/g, '<br/>')
        // let text = tree.text.replace(/\n/g, "<br />");
        let text = this.escapeHtml(tree.text);
        // text = text.replace(/\n|\r\n|\r/g, '<br/>')
        // text = text.replace(/\n/g, "");
        text = text.split("/n").join("<br/>");
        // text = text.replace(/\n/g, "");
        text = text.replace(/\n|\r\n|\r/g, '<br/>')
        tree['name'] = text;
      }
      }
     
    }
    

    if(this._articleDetailService.amendmentsList.length){
      this._articleDetailService.amendmentsList.forEach((item) => {
        if(item.id === tree._id){
          tree.amendmentFlag = item.amendmentFlag;
        }
      })
    }else{
      // let list = JSON.parse(localStorage.getItem('articlesAmendmentData'));
      // list.forEach((item) => {
      //   if(item.id === tree._id){
      //     tree.amendmentFlag = item.amendmentFlag;
      //   }
      // })
    }

    if(tree.nodes){
      tree['children'] = tree.nodes;
      tree['children'] = tree.children.map((item) => {
        return this.updateTree(item, version);
      })
    }
    return tree;
  }

  onChangeRelevance(version): any {
    if(version =='current'){
      if(this.selectedArticleDetailsObj.regulations.isRelevant == false){
        $("#relevanceConfirmationModal").modal('show');
      }else{
        if(this.selectedArticleDetailsObj.regulations.compliantStatus == 'not_applicable'){
          this.selectedArticleDetailsObj.regulations.compliantStatus = 'not_assessed';
        }
        this.updateAssessment('current');
      }
    }
    if(version == 'previousVersion'){
      if(this.selectedArticlePreviousVersionObj.regulations.isRelevant == false){
        $("#relevancePreviousVersionConfirmationModal").modal('show');
      }else{
        if(this.selectedArticlePreviousVersionObj.regulations.compliantStatus == 'not_applicable'){
          this.selectedArticlePreviousVersionObj.regulations.compliantStatus = 'not_assessed';
        }
        this.updateAssessment('previousVersion');
      }
    }
    if(version == 'futureVersion'){
      if(this.selectedArticleFutureVersionObj.regulations.isRelevant == false){
        $("#relevanceFutureVersionConfirmationModal").modal('show');
      }else{
        if(this.selectedArticleFutureVersionObj.regulations.compliantStatus == 'not_applicable'){
          this.selectedArticleFutureVersionObj.regulations.compliantStatus = 'not_assessed';
        }
        this.updateAssessment('futureVersion');
      }
    }
  }

  setRelevancetoyes(version): any {
    if(version == 'current'){
      this.selectedArticleDetailsObj.regulations.isRelevant = true;
    }
    if(version == 'previousVersion'){
      this.selectedArticlePreviousVersionObj.regulations.isRelevant = true;
    }
    if(version == 'futureVersion'){
      this.selectedArticleFutureVersionObj.regulations.isRelevant = true;
    }
  }

  setRelevancetoNo(version):any {
    if(version == 'current'){
      this.selectedArticleDetailsObj.regulations.compliantStatus = 'not_applicable';
      let url = environment.apiUrls.updateSubTreeForRelevanceUrl;
      url = url.replace(':regulationId', this.selectedArticleDetailsObj.regulations._id)
      let data = {
        isRelevant: false,
        updatedBy: this._cookie.get("_id")
      }
      this._dataService.patchData(url, data, true).toPromise()
      .then((response: any) => {
        if(response.status == 1){
          this._toastr.success(response.message);
          this.updateCompliantStatus('currentVersion');
        }else{
          this._toastr.error(response.message.message);
        }
      },(error) => {
        if(error.error){
          this._toastr.error(error.error.message);
        }
      });
    }

    if(version == 'previousVersion'){
      this.selectedArticlePreviousVersionObj.regulations.compliantStatus = 'not_applicable';
      let url = environment.apiUrls.updateSubTreeForRelevanceUrl;
      url = url.replace(':regulationId', this.selectedArticlePreviousVersionObj.regulations._id)
      let data = {
        isRelevant: false,
        updatedBy: this._cookie.get("_id")
      }
      this._dataService.patchData(url, data, true).toPromise()
      .then((response: any) => {
        if(response.status == 1){
          this._toastr.success(response.message);
          this.updateCompliantStatus('previousVersion');
        }else{
          this._toastr.error(response.message.message);
        }
      },(error) => {
        if(error.error){
          this._toastr.error(error.error.message);
        }
      });
    }

    if(version == 'futureVersion'){
      this.selectedArticleFutureVersionObj.regulations.compliantStatus = 'not_applicable';
      let url = environment.apiUrls.updateSubTreeForRelevanceUrl;
      url = url.replace(':regulationId', this.selectedArticleFutureVersionObj.regulations._id)
      let data = {
        isRelevant: false,
        updatedBy: this._cookie.get("_id")
      }
      this._dataService.patchData(url, data, true).toPromise()
      .then((response: any) => {
        if(response.status == 1){
          this._toastr.success(response.message);
          this.updateCompliantStatus('futureVersion');
        }else{
          this._toastr.error(response.message.message);
        }
      },(error) => {
        if(error.error){
          this._toastr.error(error.error.message);
        }
      });
    }

  }


  updateCompliantStatus(type):any {
    let url = environment.apiUrls.updateCompliantStatusUrl;
    
    let dataObj = {
      updatedBy: this._cookie.get("_id"),
      compliantStatus: null
    }

    if(type == 'currentVersion'){
      url = url.replace(':regulationId', this.selectedArticleDetailsObj.regulations._id);
      dataObj.compliantStatus = this.selectedArticleDetailsObj.regulations.compliantStatus;
    }
    if(type == 'previousVersion'){
      url = url.replace(':regulationId', this.selectedArticlePreviousVersionObj.regulations._id);
      dataObj.compliantStatus = this.selectedArticlePreviousVersionObj.regulations.compliantStatus;
    }
    if(type == 'futureVersion'){
      url = url.replace(':regulationId', this.selectedArticleFutureVersionObj.regulations._id);
      dataObj.compliantStatus = this.selectedArticleFutureVersionObj.regulations.compliantStatus;
    }
    
    if(dataObj['compliantStatus'] == 'none'){
      dataObj['compliantStatus'] = null;
    }
    
    this._dataService.patchData(url, dataObj, true).toPromise()
    .then((response: any) => {
      if(response.status == 1){
        this._toastr.success(response.message);
        if(type == 'currentVersion'){
          this.selectedArticleDetailsObj.regulations.compliantStatus === 'not_assessed' ? this.showAction = false : this.showAction = true ;
        }
        if(type == 'previousVersion'){
          this.selectedArticlePreviousVersionObj.regulations.compliantStatus === 'not_assessed' ? this.showPreviousVersionAction = false : this.showPreviousVersionAction = true ;
        }
        if(type == 'futureVersion'){
          this.selectedArticleFutureVersionObj.regulations.compliantStatus === 'not_assessed' ? this.showFutureVersionAction = false : this.showFutureVersionAction = true ;
        }
      }else{
        this._toastr.error(response.message.message);
      }
    },(error) => {
      if(error.error){
        this._toastr.error(error.error.message);
      }
    });
  }

  updateTags(): any{
    var dataObj = {
      type: 'liquidity'
    }
    
    dataObj['regulationId'] = this.selectedArticleDetailsObj.regulations._id;
    dataObj['tags'] = this.selectedArticleDetailsObj.regulations.tags;  
    dataObj['effective'] = this.selectedArticleDetailsObj.regulations.effective;
    dataObj['controls'] =  this.selectedArticleDetailsObj.regulations.controls;
    
    var dataToUpdate = {
      updatedBy: this._cookie.get("_id"),
      updatedRegulations: []
    }

    dataToUpdate.updatedRegulations.push(dataObj);

    var url = environment.apiUrls.updateAssessmentUrl

    this._dataService.patchData(url, dataToUpdate, true).toPromise()
    .then((response: any) => {
      if(response.status == 1){
          this.editTagsFlag = false;
          this.editEffectiveFlag = false;
          this.editControlsFlag = false;
          this._toastr.success(response.message);
      }else{
        this._toastr.error(response.message);
      }
    },(error) => {
      if(error.error){
        this._toastr.error(error.error.message);
      }
    });
  }

  updateAssessment(version): any{
    var dataObj = {
      type: 'liquidity'
    }
    if(version == 'current'){
      dataObj['regulationId'] = this.selectedArticleDetailsObj.regulations._id;
      dataObj['assessmentOwnerId'] = this.selectedArticleDetailsObj.assessments.assessmentOwnerId; 
      dataObj['assessmentComment'] = this.selectedArticleDetailsObj.assessments.comment;
      dataObj['isRelevant'] = this.selectedArticleDetailsObj.regulations.isRelevant;
      dataObj['compliantStatus'] = this.selectedArticleDetailsObj.regulations.compliantStatus;
      

    }
    if(version == 'previousVersion'){
      dataObj['regulationId'] = this.selectedArticlePreviousVersionObj.regulations._id;
      dataObj['assessmentOwnerId'] = this.selectedArticlePreviousVersionObj.assessments.assessmentOwnerId; 
      dataObj['assessmentComment'] = this.selectedArticlePreviousVersionObj.assessments.comment;
      dataObj['isRelevant'] = this.selectedArticlePreviousVersionObj.regulations.isRelevant;
      dataObj['compliantStatus'] = this.selectedArticlePreviousVersionObj.regulations.compliantStatus;
      
    }
    if(version == 'futureVersion'){
      dataObj['regulationId'] = this.selectedArticleFutureVersionObj.regulations._id;
      dataObj['assessmentOwnerId'] = this.selectedArticleFutureVersionObj.assessments.assessmentOwnerId; 
      dataObj['assessmentComment'] = this.selectedArticleFutureVersionObj.assessments.comment;
      dataObj['isRelevant'] = this.selectedArticleFutureVersionObj.regulations.isRelevant;
      dataObj['compliantStatus'] = this.selectedArticleFutureVersionObj.regulations.compliantStatus;
      
    }
    if(dataObj['compliantStatus'] == 'none'){
      dataObj['compliantStatus'] = null;
    }

    var dataToUpdate = {
      updatedBy: this._cookie.get("_id"),
      updatedRegulations: []
    }

    dataToUpdate.updatedRegulations.push(dataObj);

    var url = environment.apiUrls.updateAssessmentUrl

    this._dataService.patchData(url, dataToUpdate, true).toPromise()
    .then((response: any) => {
      if(response.status == 1){
        if(version == 'current'){
          this.editAssessmentFlag = false;
        }
        if(version == 'previousVersion'){
          this.editPreviousVersionAssessmentFlag = false;
        }
        if(version == 'futureVersion'){
          this.editFutureVersionAssessmentFlag = false;
        }
        // this.showActionBtn = true;
        // this.clonedSelectedAssessment = Object.assign({}, this.selectedArticleDetailsObj);
        this._toastr.success(response.message);
      }else{
        this._toastr.error(response.message);
      }
    },(error) => {
      if(error.error){
        this._toastr.error(error.error.message);
      }
    });
  }

  getAssessmentActions(id, version): any {
    var url = environment.apiUrls.getRegulationActions;
    url = url.replace(':regulationId', id);

    this._dataService.getData(url, [], true).toPromise()
    .then((response: any) => {
        if(response.status == 1){
          if(version == 'currentVersion'){
            this.actionsListData = response.data;
            this.actionsListData.forEach((item) => {
            if(item.dueDate){
              var tempSplitDate = item.dueDate.split('T');
              var tempDisplayDate = tempSplitDate[0].split('-');
              item.displayDueDate = tempDisplayDate[2] + '-' + tempDisplayDate[1]  + '-' + tempDisplayDate[0];
            
            }
          });
          }
          if(version == 'previousVersion'){
            this.previousVersionActionsListData = response.data;
            this.previousVersionActionsListData.forEach((item) => {
            if(item.dueDate){
              var tempSplitDate = item.dueDate.split('T');
              var tempDisplayDate = tempSplitDate[0].split('-');
              item.displayDueDate = tempDisplayDate[2] + '-' + tempDisplayDate[1]  + '-' + tempDisplayDate[0];
            
            }
          });
          }
          if(version == 'futureVersion'){
            this.futureVersionActionsListData = response.data;
            this.futureVersionActionsListData.forEach((item) => {
            if(item.dueDate){
              var tempSplitDate = item.dueDate.split('T');
              var tempDisplayDate = tempSplitDate[0].split('-');
              item.displayDueDate = tempDisplayDate[2] + '-' + tempDisplayDate[1]  + '-' + tempDisplayDate[0];
            
            }
          });
          }
        }
      }, (error) => {});
  }

  updateSelectedActionModal(selectedAction, version): any {
    if(version == "current"){
      this.editAction = true;
      this.newCurrentVersionActionObj['action'] = selectedAction.action;
      if(selectedAction.dueDate){
        this.actionDueDate = {};
        this.actionDueDate['singleDate'] = {};
        this.actionDueDate.singleDate['jsDate'] = new Date(selectedAction.dueDate);
        let tempDate = selectedAction.dueDate.split('T');
        let tempFormatDate = tempDate[0];
        let tempFormatDateArray = tempFormatDate.split('-');
        let formattedDate = tempFormatDateArray[2]+'.'+tempFormatDateArray[1]+'.'+tempFormatDateArray[0];
        this.actionDueDate.singleDate['formatted'] = formattedDate;
      }else{
        this.actionDueDate = null;
      }
      this.newCurrentVersionActionObj.status = selectedAction.status;
      this.newCurrentVersionActionObj['actionOwnerId'] = selectedAction.actionOwnerId;
      this.newCurrentVersionActionObj['id'] = selectedAction._id;
      $("#addNewActionModal").modal('show');
    }
    if(version == "previousVersion"){
      this.editPreviousVersionAction = true;
      this.newPreviousVersionActionObj['action'] = selectedAction.action;
      if(selectedAction.dueDate){
        this.actionPreviousVersionDueDate = {};
        this.actionPreviousVersionDueDate['singleDate'] = {};
        this.actionPreviousVersionDueDate.singleDate['jsDate'] = new Date(selectedAction.dueDate);
        let tempDate = selectedAction.dueDate.split('T');
        let tempFormatDate = tempDate[0];
        let tempFormatDateArray = tempFormatDate.split('-');
        let formattedDate = tempFormatDateArray[2]+'.'+tempFormatDateArray[1]+'.'+tempFormatDateArray[0];
        this.actionPreviousVersionDueDate.singleDate['formatted'] = formattedDate;
      }else{
        this.actionPreviousVersionDueDate = null;
      }
      this.newPreviousVersionActionObj.status = selectedAction.status;
      this.newPreviousVersionActionObj['actionOwnerId'] = selectedAction.actionOwnerId;
      this.newPreviousVersionActionObj['id'] = selectedAction._id;
      $("#addNewActionPreviousVersionModal").modal('show');
    }
    if(version == "futureVersion"){
      this.editFutureVersionAction = true;
      this.newFutureVersionActionObj['action'] = selectedAction.action;
      if(selectedAction.dueDate){
        this.actionFutureVersionDueDate = {};
        this.actionFutureVersionDueDate['singleDate'] = {};
        this.actionFutureVersionDueDate.singleDate['jsDate'] = new Date(selectedAction.dueDate);
        let tempDate = selectedAction.dueDate.split('T');
        let tempFormatDate = tempDate[0];
        let tempFormatDateArray = tempFormatDate.split('-');
        let formattedDate = tempFormatDateArray[2]+'.'+tempFormatDateArray[1]+'.'+tempFormatDateArray[0];
        this.actionFutureVersionDueDate.singleDate['formatted'] = formattedDate;
      }else{
        this.actionFutureVersionDueDate = null;
      }
      this.newFutureVersionActionObj.status = selectedAction.status;
      this.newFutureVersionActionObj['actionOwnerId'] = selectedAction.actionOwnerId;
      this.newFutureVersionActionObj['id'] = selectedAction._id;
      $("#addNewActionFutureVersionModal").modal('show');
    }
    
  }

  deleteAction(id, version): any{
    let url = environment.apiUrls.deleteAction;
    url = url.replace(':actionId', id);

    this._dataService.deleteData(url, true).toPromise()
    .then((response: any) => {
      if(response.status == 1){
        if(version == 'currentVersion'){
          this.getAssessmentActions(this.selectedArticleDetailsObj.regulations._id, 'currentVersion');
        }
        if(version == 'previousVersion'){
          this.getAssessmentActions(this.selectedArticlePreviousVersionObj.regulations._id, 'previousVersion');
        }
        if(version == 'futureVersion'){
          this.getAssessmentActions(this.selectedArticleFutureVersionObj.regulations._id, 'futureVersion');
        }
        this._toastr.success('Action deleted');
      }
    }, (error: any) =>{
      this._toastr.error(error.error.message);
    })
  }

  clearActionModalData(version): any {
    if(version == 'current'){
      this.editAction = false;
      this.newCurrentVersionActionObj.action = null;
      this.newCurrentVersionActionObj.actionOwnerId = null;
      this.newCurrentVersionActionObj.dueDate = null;
      this.newCurrentVersionActionObj.assignedBy = null;
      this.newCurrentVersionActionObj.assessmentId = null;
      this.newCurrentVersionActionObj.regulationId = null;
      this.actionDueDate = null;
      this.newCurrentVersionActionObj.status = 'new';
    }
    if(version == 'previousVersion'){
      this.editPreviousVersionAction = false;
      this.newPreviousVersionActionObj.action = null;
      this.newPreviousVersionActionObj.actionOwnerId = null;
      this.newPreviousVersionActionObj.dueDate = null;
      this.newPreviousVersionActionObj.assignedBy = null;
      this.newPreviousVersionActionObj.assessmentId = null;
      this.newPreviousVersionActionObj.regulationId = null;
      this.actionPreviousVersionDueDate = null;
      this.newPreviousVersionActionObj.status = 'new';
    }
    if(version == 'futureVersion'){
      this.editFutureVersionAction = false;
      this.newCurrentVersionActionObj.action = null;
      this.newCurrentVersionActionObj.actionOwnerId = null;
      this.newCurrentVersionActionObj.dueDate = null;
      this.newCurrentVersionActionObj.assignedBy = null;
      this.newCurrentVersionActionObj.assessmentId = null;
      this.newCurrentVersionActionObj.regulationId = null;
      this.actionDueDate = null;
      this.newCurrentVersionActionObj.status = 'new';
    }
    
  }

  addNewAction(version): any {
    if(version == 'current'){
      if(this.newCurrentVersionActionObj.action){
        this.newCurrentVersionActionObj.assignedBy = this._cookie.get("_id");
        if(this.actionDueDate && this.actionDueDate.singleDate){
          var actionDate = this.actionDueDate.singleDate.formatted.split('.');
          this.newCurrentVersionActionObj.dueDate = actionDate[0]+'-'+actionDate[1]+'-'+actionDate[2];
        }
        this.newCurrentVersionActionObj.regulationId = this.selectedArticleDetailsObj.regulations._id;
        var url = environment.apiUrls.newActionForAssessmentUrl;
        this._dataService.postData(url, this.newCurrentVersionActionObj, true).toPromise()
        .then((response:any) => {
          if(response.status == 1){
            this.newCurrentVersionActionObj = {
              action: null,
              actionOwnerId: null,
              dueDate: null,
              assignedBy: null,
              assessmentId: null,
              regulationId: null,
              status: null,
              documentId: this.selectedArticleDetailsObj.regulations.document._id
            }
            this.actionDueDate = null;
            this.getAssessmentActions(this.selectedArticleDetailsObj.regulations._id, 'currentVersion');
            this._toastr.success(response.message);
          }else{
            this._toastr.error(response.message.message);
          }
        }, (error) => {});
      }else{
        this._toastr.error('Action field is required to create action.')
      }
    }

    if(version == 'previousVersion'){
      if(this.newPreviousVersionActionObj.action){
        this.newPreviousVersionActionObj.assignedBy = this._cookie.get("_id");
        if(this.actionPreviousVersionDueDate && this.actionPreviousVersionDueDate.singleDate){
          var actionDate = this.actionPreviousVersionDueDate.singleDate.formatted.split('.');
          this.newPreviousVersionActionObj.dueDate = actionDate[0]+'-'+actionDate[1]+'-'+actionDate[2];
        }
        this.newPreviousVersionActionObj.regulationId = this.selectedArticlePreviousVersionObj.regulations._id;
        var url = environment.apiUrls.newActionForAssessmentUrl;
        this._dataService.postData(url, this.newPreviousVersionActionObj, true).toPromise()
        .then((response:any) => {
          if(response.status == 1){
            this.newPreviousVersionActionObj = {
              action: null,
              actionOwnerId: null,
              dueDate: null,
              assignedBy: null,
              assessmentId: null,
              regulationId: null,
              status: null,
              documentId: this.selectedArticlePreviousVersionObj.regulations.document._id
            }
            this.actionPreviousVersionDueDate = null;
            this.getAssessmentActions(this.selectedArticlePreviousVersionObj.regulations._id, 'previousVersion');
            this._toastr.success(response.message);
          }else{
            this._toastr.error(response.message.message);
          }
        }, (error) => {});
      }else{
        this._toastr.error('Action field is required to create action.')
      }
    }

    if(version == 'futureVersion'){
      if(this.newFutureVersionActionObj.action){
        this.newFutureVersionActionObj.assignedBy = this._cookie.get("_id");
        if(this.actionFutureVersionDueDate && this.actionFutureVersionDueDate.singleDate){
          var actionDate = this.actionFutureVersionDueDate.singleDate.formatted.split('.');
          this.newFutureVersionActionObj.dueDate = actionDate[0]+'-'+actionDate[1]+'-'+actionDate[2];
        }
        this.newFutureVersionActionObj.regulationId = this.selectedArticleFutureVersionObj.regulations._id;
        var url = environment.apiUrls.newActionForAssessmentUrl;
        this._dataService.postData(url, this.newFutureVersionActionObj, true).toPromise()
        .then((response:any) => {
          if(response.status == 1){
            this.newFutureVersionActionObj = {
              action: null,
              actionOwnerId: null,
              dueDate: null,
              assignedBy: null,
              assessmentId: null,
              regulationId: null,
              status: null,
              documentId: null
            }
            this.actionFutureVersionDueDate = null;
            this.getAssessmentActions(this.selectedArticleFutureVersionObj.regulations._id, 'futureVersion');
            this._toastr.success(response.message);
          }else{
            this._toastr.error(response.message.message);
          }
        }, (error) => {});
      }else{
        this._toastr.error('Action field is required to create action.')
      }
    }
    
  }

  updateAction(version): any {
    if(version == 'current'){
      var url = environment.apiUrls.newActionForAssessmentUrl + this.newCurrentVersionActionObj.id;
    this.newCurrentVersionActionObj.assignedBy = this._cookie.get("_id");
    if(this.actionDueDate && this.actionDueDate.singleDate){
      var actionDate = this.actionDueDate.singleDate.formatted.split('.');
      this.newCurrentVersionActionObj.dueDate = actionDate[0]+'-'+actionDate[1]+'-'+actionDate[2];
    }
    this.newCurrentVersionActionObj.regulationId = this.selectedArticleDetailsObj.regulations._id;
    this._dataService.patchData(url, this.newCurrentVersionActionObj, true).toPromise()
    .then((response: any) => {
      if(response.status == 1){
        this.newCurrentVersionActionObj = {
          action: null,
          actionOwnerId: null,
          dueDate: null,
          assignedBy: null,
          assessmentId: null,
          regulationId: null,
          status: null,
          documentId: this.selectedArticleDetailsObj.document._id
        }
        this.actionDueDate = null;
        this.getAssessmentActions(this.selectedArticleDetailsObj.regulations._id, 'currentVersion');
        this._toastr.success(response.message);
      }else{
        this._toastr.error(response.message.message);
      }
    },(error) => {
      if(error.error){
        this._toastr.error(error.error.message);
      }
    });
    }

    if(version == 'previousVersion'){
      var url = environment.apiUrls.newActionForAssessmentUrl + this.newPreviousVersionActionObj.id;
    this.newPreviousVersionActionObj.assignedBy = this._cookie.get("_id");
    if(this.actionPreviousVersionDueDate && this.actionPreviousVersionDueDate.singleDate){
      var actionDate = this.actionPreviousVersionDueDate.singleDate.formatted.split('.');
      this.newPreviousVersionActionObj.dueDate = actionDate[0]+'-'+actionDate[1]+'-'+actionDate[2];
    }
    this.newPreviousVersionActionObj.regulationId = this.selectedArticlePreviousVersionObj.regulations._id;
    this._dataService.patchData(url, this.newPreviousVersionActionObj, true).toPromise()
    .then((response: any) => {
      if(response.status == 1){
        this.newPreviousVersionActionObj = {
          action: null,
          actionOwnerId: null,
          dueDate: null,
          assignedBy: null,
          assessmentId: null,
          regulationId: null,
          status: null,
          documentId: this.selectedArticlePreviousVersionObj.document._id
        }
        this.actionPreviousVersionDueDate = null;
        this.getAssessmentActions(this.selectedArticlePreviousVersionObj.regulations._id, 'previousVersion');
        this._toastr.success(response.message);
      }else{
        this._toastr.error(response.message.message);
      }
    },(error) => {
      if(error.error){
        this._toastr.error(error.error.message);
      }
    });
    }
    
    if(version == 'futureVersion'){
      var url = environment.apiUrls.newActionForAssessmentUrl + this.newFutureVersionActionObj.id;
    this.newFutureVersionActionObj.assignedBy = this._cookie.get("_id");
    if(this.actionFutureVersionDueDate && this.actionFutureVersionDueDate.singleDate){
      var actionDate = this.actionFutureVersionDueDate.singleDate.formatted.split('.');
      this.newFutureVersionActionObj.dueDate = actionDate[0]+'-'+actionDate[1]+'-'+actionDate[2];
    }
    this.newFutureVersionActionObj.regulationId = this.selectedArticlePreviousVersionObj.regulations._id;
    this._dataService.patchData(url, this.newFutureVersionActionObj, true).toPromise()
    .then((response: any) => {
      if(response.status == 1){
        this.newFutureVersionActionObj = {
          action: null,
          actionOwnerId: null,
          dueDate: null,
          assignedBy: null,
          assessmentId: null,
          regulationId: null,
          status: null,
          documentId: this.selectedArticleFutureVersionObj.document._id
        }
        this.actionFutureVersionDueDate = null;
        this.getAssessmentActions(this.selectedArticleFutureVersionObj.regulations._id, 'futureVersion');
        this._toastr.success(response.message);
      }else{
        this._toastr.error(response.message.message);
      }
    },(error) => {
      if(error.error){
        this._toastr.error(error.error.message);
      }
    });
    }
  }

  getRegartComments(): any{
    let url = environment.apiUrls.regartCommentApi;
    url = url.replace(':regulationId', this.selectedArticleId);
    
    this._dataService.getData(url, [], true).toPromise()
    .then((response: any) => {
      if(response && response.data){
        this.selectedArticleDetailsObj.regulations.regartComment = response.data
      }
    }, (error) => {

    }) 
  }

  getPreviousRegartComments(): any{
    let url = environment.apiUrls.regartCommentApi;
    url = url.replace(':regulationId', this.selectedArticlePreviousVersionObj.regulations._id);
    
    this._dataService.getData(url, [], true).toPromise()
    .then((response: any) => {
      if(response && response.data){
        this.selectedArticlePreviousVersionObj.regulations.regartComment = response.data
      }
    }, (error) => {

    }) 
  }

  getFutureRegartComments(): any{
    let url = environment.apiUrls.regartCommentApi;
    url = url.replace(':regulationId', this.selectedArticleFutureVersionObj.regulations._id);
    
    this._dataService.getData(url, [], true).toPromise()
    .then((response: any) => {
      if(response && response.data){
        this.selectedArticleFutureVersionObj.regulations.regartComment = response.data
      }
    }, (error) => {

    }) 
  }

  
  getAssessmentDetails(): any {
    var url = environment.apiUrls.getAssessmentDetailUrl;
    url = url.replace(':regulationId', this.selectedArticleId);
    
    this._dataService.getData(url, [], true).toPromise()
    .then((response: any) => {
        if (response.status == 1) {
          this.selectedArticleDetailsObj['assessments'] = response.data.currentRegulation.assessments;
          if(this.selectedArticleDetailsObj['assessments'] == null){
            this.selectedArticleDetailsObj['assessments'] = {
              assessmentOwnerId: null
            }
          }
          this.selectedArticleDetailsObj['regulations'] = response.data.currentRegulation.regulations;
          let regulationFullRef = response.data.currentRegulation.regulations.fullReference.split('|');
          this.selectedArticleDetailsObj['fullReference'] = [];
          for(let i = 0; i < regulationFullRef.length; i++){
            this.selectedArticleDetailsObj['fullReference'].push({refText:regulationFullRef[i], refLink: null});
          }
          let tables = [];
          if(response.data.currentRegulation.regulations.tables && response.data.currentRegulation.regulations.tables.length){
            response.data.currentRegulation.regulations.tables.forEach((obj) => {
              for(let key in obj){
                tables.push(obj[key]);
              }
            });
            // if(this.selectedArticleId == '601846ae5ca33e6e9bbfa49e' || this.selectedArticleId == '6018469a5ca33e6e9bbebe52'){
            //   let newTables = [];
            //   newTables.push(tables[0]);
            //   newTables.push(tables[1]);
            //   newTables.push(tables[3]);
            //   newTables.push(tables[2]);
            //   this.selectedArticleDetailsObj.regulations['tablesDisplay'] = newTables; 
            // }else{
            //   this.selectedArticleDetailsObj.regulations['tablesDisplay'] = tables; 
            // }
          }else{
            this.selectedArticleDetailsObj.regulations['tablesDisplay'] = []; 
          }
          this.getFullReferenceHyperlinks();
          this.getRegartComments();
          this.selectedArticleDetailsObj['refDocumentType'] = response.data.currentRegulation.regulations.document.type;
          this.imagesListArticle = response.data.currentRegulation.regulations.images ? response.data.currentRegulation.regulations.images : [];
          this.selectedArticleDetailsObj.regulations.compliantStatus === 'not_assessed' ? this.showAction = false : this.showAction = true;
          this.nodesData = [];
          let data = this.updateTree(response.data.currentRegulation.regulations, 'current');
          this.istable = response.data.currentRegulation.regulations.tableHtml;
          this.nodesData.push(data);
          this.getAssessmentActions(response.data.currentRegulation.regulations._id, 'currentVersion');
          // if(response.data.regulations.backwardAmended || response.data.regulations.forwardAmended){
            this.getSiblingVersions(response.data.previousRegulation, response.data.nextRegulation);
          // }
          if(this.referenceArticlesTableInstance){
            this.referenceArticlesTableInstance.destroy();
          }
          this.getReferencesTableData(this.selectedArticleId);
          if(this.indirectReferencesTableInstance){
            this.indirectReferencesTableInstance.destroy();
          }
          this.getIndirectReferencesTableData();
          this.getIndirectReportingElementReferences()
          this.mathJaxService.renderEquation('formula-div');
          this.qnaData = [];
          this.getQnAData();                   

          // this.selectedArticleDetailsObj['articleNumber'] = response.data.regulations ? response.data.regulations.articleNumber : '';
                // this.selectedArticleDetailsObj['documentName'] = response.data.regulations ? response.data.regulations.document : '';
                // let enfDate = response.data.regulations.enforcementDate ? response.data.regulations.enforcementDate : null;
                // if(enfDate){
                //   var tempSplitDate = enfDate.split('T');
                //   var tempDisplayDate = tempSplitDate[0].split('-');
                //   this.selectedArticleDetailsObj['enforcementDate'] = tempDisplayDate[2] + ' ' + this._articleDetailService.monthName(tempDisplayDate[1])  + ' ' + tempDisplayDate[0];
                
                // }
                // if(response.data.regulations.isAmended){
                //   if(response.data.regulations.document.title.includes('Consolidated')){
                //     this.selectedArticleDetailsObj['amendmentSourceDocument'] = response.data.regulations.amendmentSourceDocument;
                //   }else{
                //     this.selectedArticleDetailsObj['amendmentSourceDocument'] = response.data.regulations.document.title;
                //   }
                  
                // }else{
                //   this.selectedArticleDetailsObj['amendmentSourceDocument'] = response.data.regulations && response.data.regulations.amendmentSourceDocument ? response.data.regulations.amendmentSourceDocument : null;
                
                // }
                
                // if(this.selectedArticleDetailsObj['compliantStatus'] == null){
                //   this.selectedArticleDetailsObj['compliantStatus'] = 'none';
                // }
                

                // this.selectedArticleDetailsObj['assessmentComment'] = response.data.assessments ? response.data.assessments.comment : ' ';
                // this.selectedArticleDetailsObj['assessmentOwner'] = response.data.assessments ? response.data.assessments.assessmentOwnerId : null;
                // this.selectedArticleDetailsObj['assessmentId'] = response.data.assessments ? response.data.assessments._id : null;
                // this.selectedArticleDetailsObj['amendmentType'] = response.data.regulations ? response.data.regulations.amendmentType : null;
                // this.clonedSelectedAssessment = Object.assign({}, this.selectedArticleDetailsObj);
                
                


                // if(this._articleDetailService.amendmentsList.length){
                //   this._articleDetailService.amendmentsList.forEach((item) => {
                //     if(item.id === this.selectedArticleId){
                //       this.selectedArticleDetailsObj.amendmentFlag = item.amendmentFlag;
                //     }
                //   })
                // }else{
                //   let list = JSON.parse(localStorage.getItem('articlesAmendmentData'));
                //   list.forEach((item) => {
                //     if(item.id === this.selectedArticleId){
                //       this.selectedArticleDetailsObj.amendmentFlag = item.amendmentFlag;
                //     }
                //   })
                // }
                // // this.testData();
                
              }
        }, (error) => {
            if (error.error) {
                this._toastr.error(error.error.message);
            }
        });
  }

    getSiblingVersions(previousRegulation, nextRegulation): any {
      // var url = environment.apiUrls.getAssessmentDetailUrl + '/siblings';
      // url = url.replace(':regulationId', this.selectedArticleId);
      // this._dataService.getData(url, [], true).toPromise()
      // .then((response: any) => {
      //       if (response.status == 1) {
                // this.previousVersionNodesData = [];
                // this.futureVersionNodesData = [];
                this.selectedArticlePreviousVersionObj = {}
                this.selectedArticleFutureVersionObj = {}
                if(previousRegulation && Object.keys(previousRegulation).length != 0){
                  this.selectedArticlePreviousVersionObj = previousRegulation;
                  this.selectedArticlePreviousVersionObj['refDocumentType'] = previousRegulation.regulations.document.type;
                  this.previousVersionNodesData.push(this.updateTree(previousRegulation.regulations, 'prev'));
                  this.selectedArticlePreviousVersionObj.regulations.compliantStatus === 'not_assessed' ? this.showPreviousVersionAction = false : this.showPreviousVersionAction = true;
                  if(this.selectedArticlePreviousVersionObj['assessments'] == null){
                    this.selectedArticlePreviousVersionObj['assessments'] = {
                      assessmentOwnerId: null
                    }
                  }
                  this.getPreviousRegartComments()
                  this.getAssessmentActions(this.selectedArticlePreviousVersionObj.regulations._id, 'previousVersion');
                }
                if(nextRegulation && Object.keys(nextRegulation).length != 0){
                  this.selectedArticleFutureVersionObj = nextRegulation;
                  this.futureVersionNodesData.push(this.updateTree(nextRegulation.regulations, 'next'));
                  this.selectedArticleFutureVersionObj['refDocumentType'] = nextRegulation.regulations.document.type;
                  this.selectedArticleFutureVersionObj.regulations.compliantStatus === 'not_assessed' ? this.showFutureVersionAction = false : this.showFutureVersionAction = true;
                  if(this.selectedArticleFutureVersionObj['assessments'] == null){
                    this.selectedArticleFutureVersionObj['assessments'] = {
                      assessmentOwnerId: null
                    }
                  }
                  this.getFutureRegartComments()
                  this.getAssessmentActions(this.selectedArticleFutureVersionObj.regulations._id, 'futureVersion')
                }
              // let todayDate = new Date();
              // this.previousVersionArticleObj['id'] = response.data.regulations._id;
              // this.previousVersionArticleObj['isRelevant'] = response.data.regulations ? response.data.regulations.isRelevant : null;
              // this.previousVersionArticleObj['compliantStatus'] = response.data.regulations ? response.data.regulations.compliantStatus : null;
              // if(this.previousVersionArticleObj['compliantStatus'] == null){
              //   this.previousVersionArticleObj['compliantStatus'] = 'none';
              // }
              // this.previousVersionArticleObj['refDocumentType'] = response.data.regulations.document.type;
              // this.previousVersionArticleObj['amendmentType'] = response.data.regulations ? response.data.regulations.amendmentType : null;
              // this.previousVersionArticleObj.compliantStatus === 'not_assessed' ? this.showOtherVersionAction = false : this.showOtherVersionAction = true ;
              // let futureEnforcementDate = response.data.regulations.enforcementDate ? response.data.regulations.enforcementDate : null;
              // if(futureEnforcementDate){
              //   var tempSplitDate = futureEnforcementDate.split('T');
              //   var tempDisplayDate = tempSplitDate[0].split('-');
              //   this.previousVersionArticleObj['enforcementDate'] = tempDisplayDate[2] + ' ' + this._articleDetailService.monthName(tempDisplayDate[1])  + ' ' + tempDisplayDate[0];
              //   let eDate = new Date(futureEnforcementDate);
              //   this.previousVersionArticleObj['isEnforcementDateFuture'] = eDate > todayDate ? true : false;
              // }else{
              //   this.previousVersionArticleObj['enforcementDate'] = null;
              //   this.previousVersionArticleObj['isEnforcementDateFuture'] = false;
              // }

              // this.getOtherVersionActionsList(this.previousVersionArticleObj.id);
              // this.clonedVersionAssessment = Object.assign({}, this.previousVersionArticleObj);
              
        //       }
        // }, (error) => {
        //     if (error.error) {
        //         // this._toastr.error(error.error.message);
        //     }
        // });
  }

  updateReferencesArticles(): any {
    const assessmentDataList = this.prepareAssessmentDataToUpdate();
    const updatedActionsList = this.prepareDataUpdateForActions();
    const actionsPromises = this.actionsDataAPICalls(updatedActionsList);
    let rowUpdatePromise;
    if (assessmentDataList.length) {
      const url = environment.apiUrls.updateAssessmentUrl;
      const data = {
        updatedBy: this._cookie.get('_id'),
        updatedRegulations: assessmentDataList
      };
      rowUpdatePromise = this._dataService.patchData(url, data, true).toPromise();
      actionsPromises.push(rowUpdatePromise);
    }
    Promise.all(actionsPromises).then((res) => {
      this.updatedDataListReferencesArticlesActions = [];
      this.updatedDataListReferencesArticlesAssessment = [];
      res.forEach((obj: any) => {
        if (obj.status == 1) {
          this._toastr.success(obj.message);
        } else {
          this._toastr.error(obj.message);
        }
      });
    });
    if (updatedActionsList.length == 0 && assessmentDataList.length == 0) {
      this._toastr.error('No data to update!');
    }
  }

  actionsDataAPICalls(updatedActionsList): any {
  if (updatedActionsList.length) {
    const patchActionDataList = [];
    const postActionDataList = [];
    updatedActionsList.forEach((item) => {
      if (item.actionId) {
          const url = environment.apiUrls.newActionForAssessmentUrl + item.actionId;
          delete item.actionId;
          const patchPromise = this._dataService.patchData(url, item, true).toPromise();
          patchActionDataList.push(patchPromise);
        } else {
          const url = environment.apiUrls.newActionForAssessmentUrl;
          delete item.actionId;
          const postPromise = this._dataService.postData(url, item, true).toPromise();
          postActionDataList.push(postPromise);
        }
    });
    return [...patchActionDataList, ...postActionDataList];
  } else {
    return [];
  }
}

  prepareDataUpdateForActions(): any {
  const patchActionsData = [];
  const loginUser = this._cookie.get('_id');
  const copyOfData = this.updatedDataListReferencesArticlesActions.map(obj => Object.assign({}, obj));
  if (copyOfData && copyOfData.length) {
    copyOfData.forEach((item) => {
      let obj: any;
      obj = {
        action: item.action,
        assignedBy: loginUser,
        regulationId: item.regulationId,
        actionId: item.actionId,
        dueDate: item.dueDate,
        documentId: this.selectedArticleDetailsObj.document._id
      };

      if (item.actionStatus == 'New') {
          obj.status = 'new';
      } else {
        if (item.actionStatus == 'In Progress') {
          obj.status = 'in_progress';
        } else {
          if (item.actionStatus == 'Completed') {
            obj.status = 'completed';
          }
        }
      }

      this.usersList.forEach((user) => {
        if (user.name == item.actionOwner) {
          obj.actionOwnerId = user.id;
        }
      });

      patchActionsData.push(obj);

      });
      return patchActionsData;
    } else {
      return [];
    }
}

  prepareAssessmentDataToUpdate(): any {
  let dataList = [];
  this.updatedDataListReferencesArticlesAssessment.forEach((item) => {
    let obj = {
      isRelevant: item.isRelevant == 'Yes' ? true : false,
      compliantStatus: item.compliantStatus == 'Partial' ? 'partial' : item.compliantStatus == 'Full' ? 'full' : item.compliantStatus == 'Not Assessed' ? 'not_assessed' : null,
      regulationId: item.regulationId,
      type: 'liquidity'
    }

    this.usersList.forEach((user) => {
      if (user.name == item.assessmentOwner) {
        obj['assessmentOwnerId'] = user.id;
      }
    });

    if (item.assessmentComment || item.assessmentComment == null || item.assessmentComment == '') {
      if(item.assessmentComment == null){
        obj['assessmentComment'] = '';
      }else{
        obj['assessmentComment'] = item.assessmentComment;
      }
    }

    dataList.push(obj);

  });

  return dataList;
}


  updateIndirectReferencesArticles(): any {
    const assessmentDataList = this.prepareIndirectRefAssessmentDataToUpdate();
    const updatedActionsList = this.prepareDataUpdateIndirectRefForActions();
    const actionsPromises = this.actionsDataAPICalls(updatedActionsList);
    let rowUpdatePromise;
    if (assessmentDataList.length) {
      const url = environment.apiUrls.updateAssessmentUrl;
      const data = {
        updatedBy: this._cookie.get('_id'),
        updatedRegulations: assessmentDataList
      };
      rowUpdatePromise = this._dataService.patchData(url, data, true).toPromise();
      actionsPromises.push(rowUpdatePromise);
    }
    Promise.all(actionsPromises).then((res) => {
      this.updatedDataListIndirectReferencesArticlesActions = [];
      this.updatedDataListIndirectReferencesArticlesAssessment = [];
      res.forEach((obj: any) => {
        if (obj.status == 1) {
          this._toastr.success(obj.message);
        } else {
          this._toastr.error(obj.message);
        }
      });
    });
    if (updatedActionsList.length == 0 && assessmentDataList.length == 0) {
      this._toastr.error('No data to update!');
    }
  }

  prepareDataUpdateIndirectRefForActions(): any {
  const patchActionsData = [];
  const loginUser = this._cookie.get('_id');
  const copyOfData = this.updatedDataListIndirectReferencesArticlesActions.map(obj => Object.assign({}, obj));
  if (copyOfData && copyOfData.length) {
    console.log(copyOfData)
    console.log(this.selectedArticleDetailsObj)
    copyOfData.forEach((item) => {
      let obj: any;
      obj = {
        action: item.action,
        assignedBy: loginUser,
        regulationId: item.regulationId,
        actionId: item.actionId,
        dueDate: item.dueDate,
        // documentId: this.selectedArticleDetailsObj.document._id
      };

      if (item.actionStatus == 'New') {
          obj.status = 'new';
      } else {
        if (item.actionStatus == 'In Progress') {
          obj.status = 'in_progress';
        } else {
          if (item.actionStatus == 'Completed') {
            obj.status = 'completed';
          }
        }
      }

      this.usersList.forEach((user) => {
        if (user.name == item.actionOwner) {
          obj.actionOwnerId = user.id;
        }
      });

      patchActionsData.push(obj);

      });
      return patchActionsData;
    } else {
      return [];
    }
}

  prepareIndirectRefAssessmentDataToUpdate(): any {
  let dataList = [];
  this.updatedDataListIndirectReferencesArticlesAssessment.forEach((item) => {
    let obj = {
      isRelevant: item.isRelevant == 'Yes' ? true : false,
      compliantStatus: item.compliantStatus == 'Partial' ? 'partial' : item.compliantStatus == 'Full' ? 'full' : item.compliantStatus == 'Not Assessed' ? 'not_assessed' : null,
      regulationId: item.regulationId,
      type: 'liquidity'
    }

    this.usersList.forEach((user) => {
      if (user.name == item.assessmentOwner) {
        obj['assessmentOwnerId'] = user.id;
      }
    });

    if ( item.assessmentComment || item.assessmentComment == null || item.assessmentComment == '') {
      if(item.assessmentComment == null){
        obj['assessmentComment'] = ''  
      }else{
        obj['assessmentComment'] = item.assessmentComment;
      }
    }

    dataList.push(obj);

  });

  return dataList;
}

  getReferencesTableData(refId): any {
    let url = environment.apiUrls.regulationsReferencesSelectedArticleUrl;
    url = url.replace(':regulationId', refId);

    this._dataService.getData(url, [], true).toPromise()
    .then((response: any) => {
      if (response.status == 1) {
        if(response.data && response.data.length){
          response.data.forEach((item) => {
            let obj = {
              articleNo: item.regulationNumber ? item.regulationNumber : '',
              fullReference: item.path ? item.path : '',
              text: item.description ? item.description : '',
              isRelevant: item.isRelevant == true ? 'Yes' : 'No',
              compliantStatus: item.compliantStatus ? item.compliantStatus : null,
              assessmentComment: item.assessment ? item.assessment : '',
              action: item.action ? item.action : '',
              actionStatus: item.actionStatus == 'in_progress' ? 'In Progress' : item.actionStatus == 'completed' ? 'Completed' : item.actionStatus == 'new' ? 'New' : '',
              regulationId: item._id,
              amendmentType: item.amendmentType,
              actionId: item.actionId
            }

            if(item.actionDueDate){
              var tempSplitDate = item.actionDueDate.split('T');
              var tempDisplayDate = tempSplitDate[0].split('-');
              obj['dueDate'] = tempDisplayDate[2] + '-' + tempDisplayDate[1]  + '-' + tempDisplayDate[0];
            }

            if(item.assessmentOwnerId){
              this.usersList.forEach((userObj) => {
                if(userObj.id == item.assessmentOwnerId){
                  obj['assessmentOwner'] = userObj.name;
                }
              })
            }else{
              obj['assessmentOwner'] = '';
            }
            
            if(item.actionOwnerId){
              this.usersList.forEach((userObj) => {
                if(userObj.id == item.actionOwnerId){
                  obj['actionOwner'] = userObj.name;
                }
              })
            }else{
              obj['actionOwner'] = '';
            }

            if(obj.compliantStatus == 'partial'){
              obj.compliantStatus = 'Partial';
            }

            if(obj.compliantStatus == null){
              obj.compliantStatus = 'None';
            }

            if(obj.compliantStatus == 'full'){
              obj.compliantStatus = 'Full';
            }

            if(obj.compliantStatus == 'not_assessed'){
              obj.compliantStatus = 'Not Assessed';
            }

            if(obj.compliantStatus == 'not_applicable'){
              obj.compliantStatus = 'Not Applicable'
            }

            this.referencesList.push(obj);
          });
          this.prepareReferenceArticleTable();
        }else{
          this.referencesList = [];
        }
      }
    }, (error) => {
      if (error.error) {
        this._toastr.error(error.error.message);
      }
    });
  }

  prepareReferenceArticleTable(): any{
    if(this.referenceArticlesTableInstance){
      this.referenceArticlesTableInstance.destroy();
    }

    var self = this;
    this.hotSettings_referenceArticles = {
      width: '100%',
      rowHeights: 50,
      columnHeaderHeight: 50,
      stretchH: 'all',
      data: this.referencesList,
      licenseKey: 'non-commercial-and-evaluation',
      columns: this._articleDetailService.referenceArticlesTableColumns,
      afterOnCellMouseDown: function(event, cords, td){
        if(cords.col == 0 && cords.row != -1){
          self.referenceArticlesTableInstance.deselectCell();
          var currentRowData = self.referenceArticlesTableInstance.getDataAtRow(cords.row);
          var indexOfSelected: any;
          self.referencesList.forEach((item, index) =>{
            if(item.articleNo == currentRowData[0] && item.fullReference == currentRowData[1]){
                indexOfSelected = index;
              }
          });
          let url = self.router.createUrlTree(['dashboard/article-detail', self.referencesList[indexOfSelected].regulationId ]).toString();
          window.open(url, "_blank");
        }
      },
      afterChange: (changes, src) => {
        if (changes && changes.length) {
          for (let i = 0; i < changes.length; i++) {
            if ((changes[i][1] == 'isRelevant' || changes[i][1] == 'compliantStatus' || changes[i][1] == 'assessmentComment' || changes[i][1] == 'assessmentOwner')) {
              const oldValue = changes[i][2];
              const newValue = changes[i][3];
              if (newValue != oldValue) {
                const rowIndex = changes[i][0];
                const existIndex = self.updatedDataListReferencesArticlesAssessment.findIndex(item => item.regulationId == self.referencesList[rowIndex].regulationId);
                if (self.updatedDataListReferencesArticlesAssessment.length == 0) {
                  self.updatedDataListReferencesArticlesAssessment.push(self.referencesList[rowIndex]);
                } else {
                  if (existIndex == -1) {
                    self.updatedDataListReferencesArticlesAssessment.push(self.referencesList[rowIndex]);
                  }
                }
              }
            }
            if (changes[i][1] == 'action' || changes[i][1] == 'actionOwner' || changes[i][1] == 'actionStatus' || changes[i][1] == 'dueDate') {
              const oldValue = changes[i][2];
              const newValue = changes[i][3];
              if (newValue != oldValue) {
                const rowIndex = changes[i][0];
                const existIndex = self.updatedDataListReferencesArticlesActions.findIndex(item => item.regulationId == self.referencesList[rowIndex].regulationId);
                if (self.updatedDataListReferencesArticlesActions.length == 0) {
                  self.updatedDataListReferencesArticlesActions.push(self.referencesList[rowIndex]);
                } else {
                  if (existIndex == -1) {
                    self.updatedDataListReferencesArticlesActions.push(self.referencesList[rowIndex]);
                  }
                }
              }
            }
          }
        }
      },
      cells(row, col, prop) {
        const cellProperties = this;
        const data = this.instance.getData();

        if (((data && data[row] && data[row][4] )
          && (col === 7 || col === 8 || col === 9 || col === 10)) ||
          ((data && data[row] && !data[row][5].trim())
            && (col === 7 || col === 8 || col === 9 || col === 10))
        ) {

          if (data[row][4] !== 'Not Assessed' && data[row][4] !== 'Not Applicable')
          {
          if(col === 7 && !data[row][7]){
            this.instance.setCellMeta(row, col, 'className',  '' );
            cellProperties.readOnly = false;
          }
          if(col === 7 && data[row][7]){
            this.instance.setCellMeta(row, col, 'className',  '' );
            cellProperties.readOnly = false;
          }
          if(data[row][7] && (col == 8 || col == 9 || col == 10)){
            this.instance.setCellMeta(row, col, 'className',  '' );
            cellProperties.readOnly = false;
          }else{
            if(col == 8 || col == 9 || col == 10){
              this.instance.setCellMeta(row, col, 'className',  'grayed-out' );
              cellProperties.readOnly = true;
                 
            }
          }

         }
         else{
           this.instance.setCellMeta(row, col, 'className',  'grayed-out' );
           cellProperties.readOnly = true;
         }
       }
        
        if (col == 6 && prop == 'assessmentOwner') {
          cellProperties.type = 'dropdown';
          const val = self.usersListForDropdown;

          if (typeof val != 'undefined') {
            cellProperties.source = val;
          }
        }
        if (col == 8 && prop == 'actionOwner') {

          cellProperties.type = 'dropdown';
          const val = self.usersListForDropdown;

          if (typeof val != 'undefined') {
            cellProperties.source = val;
          }
        }

        if(col == 4){
          if(data[row] && data[row][3] && data[row][3] === 'No'){
            this.instance.setCellMeta(row, col, 'className', 'grayed-out' );
            cellProperties.readOnly = true;
          }else{
            cellProperties.readOnly = false;
            this.instance.setCellMeta(row, col, 'className', '');
          }
         }

        //Renderer
        if(col == 1 && row >= 0){
          this.renderer = function(instance, td, row, col, prop, value, cellProperties) {
            Handsontable.renderers.TextRenderer.apply(this, arguments);
            td.title = value;
            return td;
          }
        }

        if(col == 2 && row >= 0){
          this.renderer = function(instance, td, row, col, prop, value, cellProperties) {
            Handsontable.renderers.TextRenderer.apply(this, arguments);
            td.title = value;
            if (value) {
                const currentRowData = instance.getDataAtRow(row);
                if (value.length > 150) {
                // td.innerHTML = value.substring(0,150) + '...';
                const wrapper = document.createElement('div');
                const div1 = document.createElement('div');
                const div2 = document.createElement('div');
                if (currentRowData[11] == 'insert') {
                  const childEle = document.createElement('div');
                  childEle.innerHTML = 'new';
                  childEle.style.cssText = 'background-color:lightgreen;padding:2px;color:#ffffff;margin-top:5px;';
                  div2.appendChild(childEle);
                }
                if (currentRowData[11] == 'delete') {
                  const childEle = document.createElement('div');
                  childEle.innerHTML = 'Deleted';
                  childEle.style.cssText = 'background-color:brown;padding:2px;color:#ffffff;margin-top:5px;';
                  div2.appendChild(childEle);
                }
                if (currentRowData[11] == 'replace' || currentRowData[11] == 'text_change') {
                  const img = document.createElement('img');
                  img.src = '../../../assets/images/icon-star.svg';
                  div2.appendChild(img);
                }
    
                div1.innerHTML = value.substring(0, 150) + '...';
                div2.style.cssText = 'margin-left:4px;';
                wrapper.style.cssText = 'display:flex';
    
                div1.setAttribute('id', `text${value}`)
                
                const btnWrapper = document.createElement('div');
                const btnShowMore = document.createElement('button')
                btnShowMore.innerText = 'Show More';
                btnShowMore.setAttribute('id', `showMore${value}`) 
                btnShowMore.style.cssText = 'cursor:pointer;border:none;outline:none;color:#a5473e;background:none;';
    
                const btnShowLess = document.createElement('button')
                btnShowLess.innerText = 'Show Less';
                btnShowLess.setAttribute('id', `showLess${value}`) 
                btnShowLess.style.cssText = 'cursor:pointer;border:none;outline:none;color:#a5473e;background:none;display:none;';
    
                
                btnShowMore.addEventListener('click', function(event){
                  document.getElementById(`showLess${value}`).style.display = 'block';
                  document.getElementById(`showMore${value}`).style.display = 'none';
                  div1.innerHTML = value;
                  let parentEle:any = document.getElementById('reference-articles-table');
                  let tableContainerHeight = parentEle.querySelector('.htCore').offsetHeight
                  // setTimeout(() => {
                    // let heightDiff = self.currentHeightDocument - parentEle.querySelector(`.${value.replaceAll(' ', '')}`).offsetHeight
                    // self.currentHeightDocument = $(document).height()
                  parentEle.style.height = tableContainerHeight + 'px';
                  // parent2Ele.style.minHeight = tableContainerHeight  + 'px';
                  // self.referenceArticlesTableInstance.updateSettings({height:tableContainerHeight})
                  // }, 50)
                  
                  // let xyz = document.getElementById('reference-articles-container');
                  // xyz.style.marginBottom = '0px';
                  // xyz.style.marginBottom = '50px';
                  // xyz.style.height = tableContainerHeight + 'px';
                })
    
                btnShowLess.addEventListener('click', function(event){
                  document.getElementById(`showMore${value}`).style.display = 'block';
                  document.getElementById(`showLess${value}`).style.display = 'none';
                  div1.innerHTML = value.substring(0, 150) + '...';
                  let parentEle:any = document.getElementById('reference-articles-table');
                  let tableContainerHeight = parentEle.querySelector('.htCore').offsetHeight
                  // setTimeout(() => {
                    // let heightDiff = self.currentHeightDocument - $(document).height()
                    // self.currentHeightDocument = $(document).height()
                  // console.log(parentEle.querySelector('.htCore').scrollHeight)
                  // console.log(parentEle.querySelector('.htCore').clientHeight)
                  // console.log(parentEle.querySelector('.htCore').offsetHeight)
                  // console.log($(document).height())
                  // console.log(screen.height)
                  parentEle.style.height = tableContainerHeight  + 'px';
                  // parent2Ele.style.minHeight = tableContainerHeight  + 'px';
                  // self.referenceArticlesTableInstance.updateSettings({height:tableContainerHeight})
                  // }, 50)
                })
    
                btnWrapper.appendChild(btnShowMore);
                btnWrapper.appendChild(btnShowLess);
                
                const parentWrapper = document.createElement('div');
                parentWrapper.setAttribute('class', value.replaceAll(' ', '') )
                wrapper.appendChild(div1);
                wrapper.appendChild(div2);
    
                parentWrapper.appendChild(wrapper);
                parentWrapper.appendChild(btnWrapper);
    
                Handsontable.dom.empty(td);
                td.appendChild(parentWrapper);
              } else {
                // td.innerHTML = value;
                const wrapper = document.createElement('div');
                const div1 = document.createElement('div');
                const div2 = document.createElement('div');
                if (currentRowData[11] == 'insert') {
                  const childEle = document.createElement('div');
                  childEle.innerHTML = 'new';
                  childEle.style.cssText = 'background-color:lightgreen;padding:2px;color:#ffffff;';
                  div2.appendChild(childEle);
                }
                if (currentRowData[11] == 'delete') {
                  const childEle = document.createElement('div');
                  childEle.innerHTML = 'Deleted';
                  childEle.style.cssText = 'background-color:brown;padding:2px;color:#ffffff;margin-top:5px;';
                  div2.appendChild(childEle);
                }
                if (currentRowData[11] == 'replace' || currentRowData[11] == 'text_change') {
                  const img = document.createElement('img');
                  img.src = '../../../assets/images/icon-star.svg';
                  div2.appendChild(img);
                }
    
                div1.innerHTML = value;
                div2.style.cssText = 'margin-left:4px;';
                wrapper.style.cssText = 'display:flex';
                wrapper.appendChild(div1);
                wrapper.appendChild(div2);
                Handsontable.dom.empty(td);
                td.appendChild(wrapper);
              }
            }
            return td;
          }
        }

        if(col == 3 && row >= 0){
          this.renderer = function(instance, td, row, col, prop, value, cellProperties) {
            if(value){
              if(value == 'Yes'){
                let wrapper = document.createElement('div');
                let div1 = document.createElement('div');
                let div2 = document.createElement('div');
                const divP = document.createElement('div');
                const divIconDrop = document.createElement('div');
                divIconDrop.className = 'htAutocompleteArrow';
                divIconDrop.innerHTML = '▼';
                div2.innerHTML = value;
                div1.style.cssText = 'width:4px;border-radius:4px;margin-right:4px;background:#008000';
                wrapper.style.cssText = 'display:flex;justify-content:space-between;';
                divP.style.cssText = 'display:flex;';  
                divP.appendChild(div1);
                divP.appendChild(div2);
                wrapper.appendChild(divP);
                wrapper.appendChild(divIconDrop);
                Handsontable.dom.empty(td);
                td.appendChild(wrapper);
    
              }
    
              if(value == 'No'){
                let wrapper = document.createElement('div');
                let div1 = document.createElement('div');
                let div2 = document.createElement('div');
                const divP = document.createElement('div');
                const divIconDrop = document.createElement('div');
                divIconDrop.className = 'htAutocompleteArrow';
                divIconDrop.innerHTML = '▼';
                div2.innerHTML = value;
                div1.style.cssText = 'width:4px;border-radius:4px;margin-right:4px;background:#b32d00';
                wrapper.style.cssText = 'display:flex;justify-content:space-between;';
                divP.style.cssText = 'display:flex;';  
                divP.appendChild(div1);
                divP.appendChild(div2);
                wrapper.appendChild(divP);
                wrapper.appendChild(divIconDrop);
                Handsontable.dom.empty(td);
                td.appendChild(wrapper);
              }
            }else{
                Handsontable.renderers.TextRenderer.apply(this, arguments);
            }
    
            return td;
          }
        }

        if(col == 4 && row >= 0){
          this.renderer = function(instance, td, row, col, prop, value, cellProperties) {
            if(value){
              const currentRowData = instance.getDataAtRow(row);
              if(value == 'Partial'){
                let wrapper = document.createElement('div');
                let div1 = document.createElement('div');
                let div2 = document.createElement('div');
                const divP = document.createElement('div');
                const divIconDrop = document.createElement('div');
                divIconDrop.className = 'htAutocompleteArrow';
                divIconDrop.innerHTML = '▼';
                div2.innerHTML = value;
                div1.style.cssText = 'width:4px;border-radius:4px;margin-right:4px;background:#ffcc00';
                wrapper.style.cssText = 'display:flex;justify-content:space-between;';
                divP.style.cssText = 'display:flex;';  
                divP.appendChild(div1);
                divP.appendChild(div2);
                wrapper.appendChild(divP);
                wrapper.appendChild(divIconDrop);
                if (currentRowData[3] == 'No') {
                  td.style.background = '#e6e6e6';
                }
                Handsontable.dom.empty(td);
                td.appendChild(wrapper);
    
              }
    
              if(value == 'None'){
                let wrapper = document.createElement('div');
                let div1 = document.createElement('div');
                let div2 = document.createElement('div');
                const divP = document.createElement('div');
                const divIconDrop = document.createElement('div');
                divIconDrop.className = 'htAutocompleteArrow';
                divIconDrop.innerHTML = '▼';
                div2.innerHTML = value;
                div1.style.cssText = 'width:4px;border-radius:4px;margin-right:4px;background:#b32d00';
                wrapper.style.cssText = 'display:flex;justify-content:space-between;';
                divP.style.cssText = 'display:flex;';  
                divP.appendChild(div1);
                divP.appendChild(div2);
                wrapper.appendChild(divP);
                wrapper.appendChild(divIconDrop);
                if (currentRowData[3] == 'No') {
                  td.style.background = '#e6e6e6';
                }
                Handsontable.dom.empty(td);
                td.appendChild(wrapper);
              }
    
              if(value == 'Full'){
                let wrapper = document.createElement('div');
                let div1 = document.createElement('div');
                let div2 = document.createElement('div');
                const divP = document.createElement('div');
                const divIconDrop = document.createElement('div');
                divIconDrop.className = 'htAutocompleteArrow';
                divIconDrop.innerHTML = '▼';
                div2.innerHTML = value;
                div1.style.cssText = 'width:4px;border-radius:4px;margin-right:4px;background:#008000';
                wrapper.style.cssText = 'display:flex;justify-content:space-between;';
                divP.style.cssText = 'display:flex;';  
                divP.appendChild(div1);
                divP.appendChild(div2);
                wrapper.appendChild(divP);
                wrapper.appendChild(divIconDrop);
                if (currentRowData[3] == 'No') {
                  td.style.background = '#e6e6e6';
                }
                Handsontable.dom.empty(td);
                td.appendChild(wrapper);
              }
    
              if(value == 'Not Assessed'){
                let wrapper = document.createElement('div');
                let div1 = document.createElement('div');
                let div2 = document.createElement('div');
                const divP = document.createElement('div');
                const divIconDrop = document.createElement('div');
                divIconDrop.className = 'htAutocompleteArrow';
                divIconDrop.innerHTML = '▼';
                div2.innerHTML = value;
                div1.style.cssText = 'width:4px;border-radius:4px;margin-right:4px;background:grey';
                wrapper.style.cssText = 'display:flex;justify-content:space-between;';
                divP.style.cssText = 'display:flex;';  
                divP.appendChild(div1);
                divP.appendChild(div2);
                wrapper.appendChild(divP);
                wrapper.appendChild(divIconDrop);
                if (currentRowData[3] == 'No') {
                  td.style.background = '#e6e6e6';
                }
                Handsontable.dom.empty(td);
                td.appendChild(wrapper);
              }

              if (value == 'Not Applicable') {
                const wrapper = document.createElement('div');
                const div1 = document.createElement('div');
                const div2 = document.createElement('div');
                const divP = document.createElement('div');
                const divIconDrop = document.createElement('div');
                divIconDrop.className = 'htAutocompleteArrow';
                divIconDrop.innerHTML = '▼';
                div2.innerHTML = value;
                div1.style.cssText = 'width:4px;border-radius:4px;margin-right:4px;background:white';
                wrapper.style.cssText = 'display:flex;justify-content:space-between;';
                divP.style.cssText = 'display:flex;';  
                divP.appendChild(div1);
                divP.appendChild(div2);
                wrapper.appendChild(divP);
                wrapper.appendChild(divIconDrop);
                if (currentRowData[3] == 'No') {
                  td.style.background = '#e6e6e6';
                }
                Handsontable.dom.empty(td);
                
                td.appendChild(wrapper);
              }
            }else{
                Handsontable.renderers.TextRenderer.apply(this, arguments);
            }
    
            return td;
          }
        }
        
        return cellProperties;
      },
    };
    const container = document.getElementById(this.tableId_referenceArticlesForArticleDetail);
    this.referenceArticlesTableInstance = new Handsontable(container, this.hotSettings_referenceArticles);
  }

  getIndirectReferencesTableData(): any {
    let url = environment.apiUrls.indirectArticleRefs;
    url = url.replace(':regulationId', this.selectedArticleId);
    this._dataService.getData(url, [], true).toPromise()
    .then((response: any) => {
      if (response.status == 1) {
        if(response.data && response.data.length){
          let getNodesPromise = [];
          response.data.forEach((item) => {
            let obj = {
              articleNo: item.regulationNumber ? item.regulationNumber : '',
              fullReference: item.path ? item.path : '',
              text: item.description ? item.description : '',
              isRelevant: item.isRelevant == true ? 'Yes' : 'No',
              compliantStatus: item.compliantStatus ? item.compliantStatus : null,
              assessmentComment: item.assessment ? item.assessment : '',
              action: item.action ? item.action : '',
              actionStatus: item.actionStatus == 'in_progress' ? 'In Progress' : item.actionStatus == 'completed' ? 'Completed' : item.actionStatus == 'new' ? 'New' : '',
              regulationId: item._id,
              amendmentType: item.amendmentType,
              actionId: item.actionId
            }
            if(item.actionDueDate){
              var tempSplitDate = item.actionDueDate.split('T');
              var tempDisplayDate = tempSplitDate[0].split('-');
              obj['dueDate'] = tempDisplayDate[2] + '-' + tempDisplayDate[1]  + '-' + tempDisplayDate[0];
            }
            if(item.assessmentOwnerId){
              this.usersList.forEach((userObj) => {
                if(userObj.id == item.assessmentOwnerId){
                  obj['assessmentOwner'] = userObj.name;
                }
              })
            }else{
              obj['assessmentOwner'] = '';
            }
            if(item.actionOwnerId){
              this.usersList.forEach((userObj) => {
                if(userObj.id == item.actionOwnerId){
                  obj['actionOwner'] = userObj.name;
                }
              })
            }else{
              obj['actionOwner'] = '';
            }
            if(obj.compliantStatus == 'partial'){
              obj.compliantStatus = 'Partial';
            }
            if(obj.compliantStatus == null){
              obj.compliantStatus = 'None';
            }
            if(obj.compliantStatus == 'full'){
              obj.compliantStatus = 'Full';
            }
            if(obj.compliantStatus == 'not_assessed'){
              obj.compliantStatus = 'Not Assessed';
            }
            if(obj.compliantStatus == 'not_applicable'){
              obj.compliantStatus = 'Not Applicable'
            }
            this.indirectReferencesList.push(obj);
          });

          this.prepareIndirectReferenceArticleTable();
          
        }else{
          this.indirectReferencesList = [];
        }
      }
    }, (error) => {
      if (error.error) {
        this._toastr.error(error.error.message);
      }
    });
  }

  prepareIndirectReferenceArticleTable(): any{
    if(this.indirectReferencesTableInstance){
      this.indirectReferencesTableInstance.destroy();
    }
    var self = this;
    this.hotSettings_indirectReferenceArticles = {
      width: '100%',
      rowHeights: 50,
      columnHeaderHeight: 50,
      stretchH: 'all',
      data: this.indirectReferencesList,
      licenseKey: 'non-commercial-and-evaluation',
      columns: this._articleDetailService.referenceArticlesTableColumns,
      afterOnCellMouseDown: function(event, cords, td){
        if(cords.col == 0 && cords.row != -1){
          self.indirectReferencesTableInstance.deselectCell();
          var currentRowData = self.indirectReferencesTableInstance.getDataAtRow(cords.row);
          var indexOfSelected: any;
          self.indirectReferencesList.forEach((item, index) =>{
            if(item.articleNo == currentRowData[0] && item.fullReference == currentRowData[1]){
                indexOfSelected = index;
              }
          });
          let url = self.router.createUrlTree(['dashboard/article-detail', self.indirectReferencesList[indexOfSelected].regulationId ]).toString();
          window.open(url, "_blank");
        }
      },
      afterChange: (changes, src) => {
        if (changes && changes.length) {
          for (let i = 0; i < changes.length; i++) {
            if ((changes[i][1] == 'isRelevant' || changes[i][1] == 'compliantStatus' || changes[i][1] == 'assessmentComment' || changes[i][1] == 'assessmentOwner')) {
              const oldValue = changes[i][2];
              const newValue = changes[i][3];
              if (newValue != oldValue) {
                const rowIndex = changes[i][0];
                const existIndex = self.updatedDataListIndirectReferencesArticlesAssessment.findIndex(item => item.regulationId == self.indirectReferencesList[rowIndex].regulationId);
                if (self.updatedDataListIndirectReferencesArticlesAssessment.length == 0) {
                  self.updatedDataListIndirectReferencesArticlesAssessment.push(self.indirectReferencesList[rowIndex]);
                } else {
                  if (existIndex == -1) {
                    self.updatedDataListIndirectReferencesArticlesAssessment.push(self.indirectReferencesList[rowIndex]);
                  }
                }
              }
            }
            if (changes[i][1] == 'action' || changes[i][1] == 'actionOwner' || changes[i][1] == 'actionStatus' || changes[i][1] == 'dueDate') {
              const oldValue = changes[i][2];
              const newValue = changes[i][3];
              if (newValue != oldValue) {
                const rowIndex = changes[i][0];
                const existIndex = self.updatedDataListIndirectReferencesArticlesActions.findIndex(item => item.regulationId == self.indirectReferencesList[rowIndex].regulationId);
                if (self.updatedDataListIndirectReferencesArticlesActions.length == 0) {
                  self.updatedDataListIndirectReferencesArticlesActions.push(self.indirectReferencesList[rowIndex]);
                } else {
                  if (existIndex == -1) {
                    self.updatedDataListIndirectReferencesArticlesActions.push(self.indirectReferencesList[rowIndex]);
                  }
                }
              }
            }
          }
        }
      },
      cells(row, col, prop) {
        const cellProperties = this;
        const data = this.instance.getData();

        if (((data && data[row] && data[row][4] )
          && (col === 7 || col === 8 || col === 9 || col === 10)) ||
          ((data && data[row] && !data[row][5].trim())
            && (col === 7 || col === 8 || col === 9 || col === 10))
        ) {

          if (data[row][4] !== 'Not Assessed' && data[row][4] != 'Not Applicable'){
          if(col === 7 && !data[row][7]){
            this.instance.setCellMeta(row, col, 'className',  '' );
            cellProperties.readOnly = false;
          }
          if(col === 7 && data[row][7]){
            this.instance.setCellMeta(row, col, 'className',  '' );
            cellProperties.readOnly = false;
          }
          if(data[row][7] && (col == 8 || col == 9 || col == 10)){
            this.instance.setCellMeta(row, col, 'className',  '' );
            cellProperties.readOnly = false;
          }else{
            if(col == 8 || col == 9 || col == 10){
              this.instance.setCellMeta(row, col, 'className',  'grayed-out' );
              cellProperties.readOnly = true;
                 
            }
          }

         }
         else{
          this.instance.setCellMeta(row, col, 'className',  'grayed-out' );
           cellProperties.readOnly = true;
         }
       }
        
        if (col == 6 && prop == 'assessmentOwner') {
          cellProperties.type = 'dropdown';
          const val = self.usersListForDropdown;

          if (typeof val != 'undefined') {
            cellProperties.source = val;
          }
        }
        if (col == 8 && prop == 'actionOwner') {

          cellProperties.type = 'dropdown';
          const val = self.usersListForDropdown;

          if (typeof val != 'undefined') {
            cellProperties.source = val;
          }
        }

        if(col == 4){
          if(data[row] && data[row][3] && data[row][3] === 'No'){
            this.instance.setCellMeta(row, col, 'className', 'grayed-out' );
            cellProperties.readOnly = true;
          }else{
            cellProperties.readOnly = false;
            this.instance.setCellMeta(row, col, 'className', '');
          }
         }

        if(col == 1 && row >= 0){
          this.renderer = function(instance, td, row, col, prop, value, cellProperties) {
            Handsontable.renderers.TextRenderer.apply(this, arguments);
            td.title = value;
            return td;
          }
        }

        if(col == 2 && row >= 0){
          this.renderer = function(instance, td, row, col, prop, value, cellProperties) {
            Handsontable.renderers.TextRenderer.apply(this, arguments);
            // td.title = value;
            const currentRowData = instance.getDataAtRow(row);
            if (value) {
                const currentRowData = instance.getDataAtRow(row);
                if (value.length > 150) {
                const wrapper = document.createElement('div');
                const div1 = document.createElement('div');
                const div2 = document.createElement('div');
                if (currentRowData[11] == 'insert') {
                  const childEle = document.createElement('div');
                  childEle.innerHTML = 'new';
                  childEle.style.cssText = 'background-color:lightgreen;padding:2px;color:#ffffff;margin-top:5px;';
                  div2.appendChild(childEle);
                }
                if (currentRowData[11] == 'delete') {
                  const childEle = document.createElement('div');
                  childEle.innerHTML = 'Deleted';
                  childEle.style.cssText = 'background-color:brown;padding:2px;color:#ffffff;margin-top:5px;';
                  div2.appendChild(childEle);
                }
                if (currentRowData[11] == 'replace' || currentRowData[11] == 'text_change') {
                  const img = document.createElement('img');
                  img.src = '../../../assets/images/icon-star.svg';
                  div2.appendChild(img);
                }
    
                div1.innerHTML = value.substring(0, 150) + '...';
                div2.style.cssText = 'margin-left:4px;';
                wrapper.style.cssText = 'display:flex';
    
                div1.setAttribute('id', `indirecttext${value}`)
                
                const btnWrapper = document.createElement('div');
                const btnShowMore = document.createElement('button')
                btnShowMore.innerText = 'Show More';
                btnShowMore.setAttribute('id', `indirectshowMore${value}`) 
                btnShowMore.style.cssText = 'cursor:pointer;border:none;outline:none;color:#a5473e;background:none;';
    
                const btnShowLess = document.createElement('button')
                btnShowLess.innerText = 'Show Less';
                btnShowLess.setAttribute('id', `indirectshowLess${value}`) 
                btnShowLess.style.cssText = 'cursor:pointer;border:none;outline:none;color:#a5473e;background:none;display:none;';
    
                
                btnShowMore.addEventListener('click', function(event){
                  document.getElementById(`indirectshowLess${value}`).style.display = 'block';
                  document.getElementById(`indirectshowMore${value}`).style.display = 'none';
                  div1.innerHTML = value;
                  let parentEle:any = document.getElementById('indirect-reference-articles-table');
                  let tableContainerHeight = parentEle.querySelector('.htCore').scrollHeight
                  parentEle.style.height = tableContainerHeight + 'px';
                  
                })
    
                btnShowLess.addEventListener('click', function(event){
                  document.getElementById(`indirectshowMore${value}`).style.display = 'block';
                  document.getElementById(`indirectshowLess${value}`).style.display = 'none';
                  div1.innerHTML = value.substring(0, 150) + '...';
                  let parentEle:any = document.getElementById('indirect-reference-articles-table');
                  let tableContainerHeight = parentEle.querySelector('.htCore').scrollHeight
                  parentEle.style.height = tableContainerHeight + 'px';
                 
                })
    
                btnWrapper.appendChild(btnShowMore);
                btnWrapper.appendChild(btnShowLess);
                
                const parentWrapper = document.createElement('div');
    
                wrapper.appendChild(div1);
                wrapper.appendChild(div2);
    
                parentWrapper.appendChild(wrapper);
                parentWrapper.appendChild(btnWrapper);
    
                Handsontable.dom.empty(td);
                td.appendChild(parentWrapper);
              } else {
                const wrapper = document.createElement('div');
                const div1 = document.createElement('div');
                const div2 = document.createElement('div');
                if (currentRowData[11] == 'insert') {
                  const childEle = document.createElement('div');
                  childEle.innerHTML = 'new';
                  childEle.style.cssText = 'background-color:lightgreen;padding:2px;color:#ffffff;';
                  div2.appendChild(childEle);
                }
                if (currentRowData[11] == 'delete') {
                  const childEle = document.createElement('div');
                  childEle.innerHTML = 'Deleted';
                  childEle.style.cssText = 'background-color:brown;padding:2px;color:#ffffff;margin-top:5px;';
                  div2.appendChild(childEle);
                }
                if (currentRowData[11] == 'replace' || currentRowData[11] == 'text_change') {
                  const img = document.createElement('img');
                  img.src = '../../../assets/images/icon-star.svg';
                  div2.appendChild(img);
                }
    
                div1.innerHTML = value;
                div2.style.cssText = 'margin-left:4px;';
                wrapper.style.cssText = 'display:flex';
                wrapper.appendChild(div1);
                wrapper.appendChild(div2);
                Handsontable.dom.empty(td);
                td.appendChild(wrapper);
              }
            }
            return td;
          }
        }

        if(col == 3 && row >= 0){
          this.renderer = function(instance, td, row, col, prop, value, cellProperties) {
            if(value){
              if(value == 'Yes'){
                let wrapper = document.createElement('div');
                let div1 = document.createElement('div');
                let div2 = document.createElement('div');
                const divP = document.createElement('div');
                const divIconDrop = document.createElement('div');
                divIconDrop.className = 'htAutocompleteArrow';
                divIconDrop.innerHTML = '▼';
                div2.innerHTML = value;
                div1.style.cssText = 'width:4px;border-radius:4px;margin-right:4px;background:#008000';
                wrapper.style.cssText = 'display:flex;justify-content:space-between;';
                divP.style.cssText = 'display:flex;';  
                divP.appendChild(div1);
                divP.appendChild(div2);
                wrapper.appendChild(divP);
                wrapper.appendChild(divIconDrop);
                Handsontable.dom.empty(td);
                td.appendChild(wrapper);
    
              }
    
              if(value == 'No'){
                let wrapper = document.createElement('div');
                let div1 = document.createElement('div');
                let div2 = document.createElement('div');
                const divP = document.createElement('div');
                const divIconDrop = document.createElement('div');
                divIconDrop.className = 'htAutocompleteArrow';
                divIconDrop.innerHTML = '▼';
                div2.innerHTML = value;
                div1.style.cssText = 'width:4px;border-radius:4px;margin-right:4px;background:#b32d00';
                wrapper.style.cssText = 'display:flex;justify-content:space-between;';
                divP.style.cssText = 'display:flex;';  
                divP.appendChild(div1);
                divP.appendChild(div2);
                wrapper.appendChild(divP);
                wrapper.appendChild(divIconDrop);
                Handsontable.dom.empty(td);
                td.appendChild(wrapper);
              }
            }else{
                Handsontable.renderers.TextRenderer.apply(this, arguments);
            }
    
            return td;
          }
        }

        if(col == 4 && row >= 0){
          this.renderer = function(instance, td, row, col, prop, value, cellProperties) {
            if(value){
              const currentRowData = instance.getDataAtRow(row);
              if(value == 'Partial'){
                let wrapper = document.createElement('div');
                let div1 = document.createElement('div');
                let div2 = document.createElement('div');
                const divP = document.createElement('div');
                const divIconDrop = document.createElement('div');
                divIconDrop.className = 'htAutocompleteArrow';
                divIconDrop.innerHTML = '▼';
                div2.innerHTML = value;
                div1.style.cssText = 'width:4px;border-radius:4px;margin-right:4px;background:#ffcc00';
                wrapper.style.cssText = 'display:flex;justify-content:space-between;';
                divP.style.cssText = 'display:flex;';  
                divP.appendChild(div1);
                divP.appendChild(div2);
                wrapper.appendChild(divP);
                wrapper.appendChild(divIconDrop);
                if (currentRowData[3] == 'No') {
                  td.style.background = '#e6e6e6';
                }
                Handsontable.dom.empty(td);
                td.appendChild(wrapper);
    
              }
    
              if(value == 'None'){
                let wrapper = document.createElement('div');
                let div1 = document.createElement('div');
                let div2 = document.createElement('div');
                const divP = document.createElement('div');
                const divIconDrop = document.createElement('div');
                divIconDrop.className = 'htAutocompleteArrow';
                divIconDrop.innerHTML = '▼';
                div2.innerHTML = value;
                div1.style.cssText = 'width:4px;border-radius:4px;margin-right:4px;background:#b32d00';
                wrapper.style.cssText = 'display:flex;justify-content:space-between;';
                divP.style.cssText = 'display:flex;';  
                divP.appendChild(div1);
                divP.appendChild(div2);
                wrapper.appendChild(divP);
                wrapper.appendChild(divIconDrop);
                if (currentRowData[3] == 'No') {
                  td.style.background = '#e6e6e6';
                }
                Handsontable.dom.empty(td);
                td.appendChild(wrapper);
              }
    
              if(value == 'Full'){
                let wrapper = document.createElement('div');
                let div1 = document.createElement('div');
                let div2 = document.createElement('div');
                const divP = document.createElement('div');
                const divIconDrop = document.createElement('div');
                divIconDrop.className = 'htAutocompleteArrow';
                divIconDrop.innerHTML = '▼';
                div2.innerHTML = value;
                div1.style.cssText = 'width:4px;border-radius:4px;margin-right:4px;background:#008000';
                wrapper.style.cssText = 'display:flex;justify-content:space-between;';
                divP.style.cssText = 'display:flex;';  
                divP.appendChild(div1);
                divP.appendChild(div2);
                wrapper.appendChild(divP);
                wrapper.appendChild(divIconDrop);
                if (currentRowData[3] == 'No') {
                  td.style.background = '#e6e6e6';
                }
                Handsontable.dom.empty(td);
                td.appendChild(wrapper);
              }
    
              if(value == 'Not Assessed'){
                let wrapper = document.createElement('div');
                let div1 = document.createElement('div');
                let div2 = document.createElement('div');
                const divP = document.createElement('div');
                const divIconDrop = document.createElement('div');
                divIconDrop.className = 'htAutocompleteArrow';
                divIconDrop.innerHTML = '▼';
                div2.innerHTML = value;
                div1.style.cssText = 'width:4px;border-radius:4px;margin-right:4px;background:grey';
                wrapper.style.cssText = 'display:flex;justify-content:space-between;';
                divP.style.cssText = 'display:flex;';  
                divP.appendChild(div1);
                divP.appendChild(div2);
                wrapper.appendChild(divP);
                wrapper.appendChild(divIconDrop);
                if (currentRowData[3] == 'No') {
                  td.style.background = '#e6e6e6';
                }
                Handsontable.dom.empty(td);
                td.appendChild(wrapper);
              }

              if (value == 'Not Applicable') {
                const wrapper = document.createElement('div');
                const div1 = document.createElement('div');
                const div2 = document.createElement('div');
                const divP = document.createElement('div');
                const divIconDrop = document.createElement('div');
                divIconDrop.className = 'htAutocompleteArrow';
                divIconDrop.innerHTML = '▼';
                div2.innerHTML = value;
                div1.style.cssText = 'width:4px;border-radius:4px;margin-right:4px;background:white';
                wrapper.style.cssText = 'display:flex;justify-content:space-between;';
                divP.style.cssText = 'display:flex;';  
                divP.appendChild(div1);
                divP.appendChild(div2);
                wrapper.appendChild(divP);
                wrapper.appendChild(divIconDrop);
                if (currentRowData[3] == 'No') {
                  td.style.background = '#e6e6e6';
                }
                Handsontable.dom.empty(td);
                td.appendChild(wrapper);
              }
            }else{
                Handsontable.renderers.TextRenderer.apply(this, arguments);
            }
    
            return td;
          }
        }
        
        return cellProperties;
      },
    };
    const container = document.getElementById(this.tableId_indirectReferenceArticlesForArticleDetail);
    this.indirectReferencesTableInstance = new Handsontable(container, this.hotSettings_indirectReferenceArticles);
  }



}















//////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////



// import { updateLocale } from 'moment';
// import { TREE_ACTIONS, KEYS, IActionMapping, ITreeOptions } from 'angular-tree-component';
// import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';


//   clonedSelectedAssessment: any = {};
//   clonedVersionAssessment: any = {};
//   clonedFutureVersionAssessment: any = {};
//   clonedDataonRightClick:any = {}
//   contextMenuShow:any = {}



//   prevDisabled: Boolean = true;
//   getParamData = {}












  




