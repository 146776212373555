<div class="dashboard-container container-fluid pad0">
    <!--header-->
    <div class="header-container">
        <!--logo-->
        <div class="logo-container">
            <img src="../../../assets/images/regart-logo.png"  routerLink="regulatory-list" alt="logo" style="cursor:pointer;outline:none;" />
        </div>
        <!--logo-->
        <!--nav menu-->
        <div class="nav-menu-container">
            <ul class="dashboard-nav-menu">
                <!-- <li class="header-nav-item margin-lt0" style="background:darkgray;margin-bottom:14px;">Test Env</li> -->
                
                <li routerLink="regulatory-list" routerLinkActive="nav-item-selected" class="header-nav-item margin-lt0">
                    Regulatory Topics
                </li>

                <li routerLink="article-list" routerLinkActive="nav-item-selected" class="header-nav-item margin-lt0">
                    Articles
                </li>
                <!-- <li [routerLink]="['article-detail', articlesIds[0]]" [class.nav-item-selected]="isActive('article-detail')"
                    [routerLinkActive]="'nav-item-selected'" class="header-nav-item">
                    Article Detail
                </li> -->
                <li routerLink="reporting-elements-list" routerLinkActive="nav-item-selected" class="header-nav-item">
                    Reporting Elements
                </li>
                <!-- <li routerLink="reporting-element-detail" routerLinkActive="nav-item-selected" class="header-nav-item">
                    Reporting Element Detail
                </li> -->
                <li routerLink="actions-list" routerLinkActive="nav-item-selected" class="header-nav-item margin-rt0">
                    Actions
                </li>
                <li routerLink="document-overview" routerLinkActive="nav-item-selected" class="header-nav-item margin-rt0">
                    Documents
                </li>
            </ul>
        </div>
        <!--nav menu-->
        <!--search-->
        <!-- <div class="dashboard-header-search">
            <img src="../../../assets/images/icon-search.svg" />
            <input type="text" name="search" placeholder="search..." />
        </div> -->
        <!--search-->
        <!--right section-->
        <div class="header-user-actions-container display-f">
            <!-- <div class="notification-icon">
                <img src="../../../assets/images/icon-notification.svg" />
            </div> -->
            <div class="user-icon display-f">
                <div>
                    <img class="user-icon-image" src="../../../assets/images/icon-user.svg" />
                </div>
                <div class="margin-lt4 margin-tpneg5">
                    <div class="hello-text">Hello!</div>
                    <div class="login-user-text">
                        <span>{{userName}}</span> 
                        <span class="dropdown">
                            <img class="margin-lt4" data-toggle="dropdown" src="../../../assets/images/icon-dropdown.svg" />
                            <ul class="dropdown-menu dropdown-menu-right">
                                <!-- <li class="dropdown-item">Change Password</li> -->
                                <li (click)="logoutUser()" class="dropdown-item">Logout</li>
                            </ul>
                        </span>
                    </div>
                </div>
            </div>
        </div>
        <!--right section-->
    </div>
    <!--header-->
    <!--routes for nav menu-->
    <div class="dashboard-menu-details-container">
        <router-outlet></router-outlet>
    </div>
    <!--routes for nav menu-->
</div>
