<div class="article-detail-view-container">
    <div style="padding-top:20px;">
        <div class="article-detail-view-header">
            <div class="article-detail-info" style="padding-top:20px;">
                <span class="head-value">
                    <!-- {{selectedDocumentName}} margin-left:25px;-->
                </span>
            </div>
            <div style="display:flex;margin-bottom:20px;">
                <div>
                    <div style="font-size:10px;font-family:Montserrat Medium;color:grey;">Topic</div>
                    <div style="width:120px;font-family:Montserrat Medium;">
                        <ng-select id="regTopic" style="width:120px;font-size:10px;" [items]="regulatoryTopicsList"
                            bindLabel="title" bindValue="_id" (change)="getDocumentsForTopics(selectedTopic)"
                            [(ngModel)]="selectedTopic" [clearable]="false">
                            <ng-template ng-option-tmp let-item="item">
                                <div [title]="item.title">{{item.title}}</div>
                            </ng-template>
                        </ng-select>
                    </div>
                </div>
                <div style="margin-left:10px;">
                    <div style="font-size:10px;font-family:Montserrat Medium;color:grey;">Category</div>
                    <div style="width:70px;font-family:Montserrat Medium;">
                        <ng-select id="docCategory" style="width:70px;font-size:10px;" [items]="documentCategoriesList"
                            bindLabel="docType" bindValue="docCategory" (change)="onDocumentCategorySelection(selectedDocumentCategory)"
                            [(ngModel)]="selectedDocumentCategory" [clearable]="false">
                        </ng-select>
                    </div>
                </div>
                <div style="margin-left:10px;">
                    <div style="font-size:10px;font-family:Montserrat Medium;color:grey;">Document</div>
                    <div style="width:250px;font-family:Montserrat Medium;">
                        <ng-select id="docName" style="width:250px;font-size:10px;"
                            [items]="documentsList" bindLabel="title" bindValue="_id"
                            [(ngModel)]="selectedDocument" [clearable]="false">
                            <ng-template ng-option-tmp let-item="item">
                                <div [title]="item.title">{{item.title}}</div>
                            </ng-template>
                        </ng-select>
                    </div>
                </div>
                <div style="margin-top:17.5px;">
                    <button type="button" class="save-changes-btn" (click)="fetchSelectedDocument()"
                        style="width:40px;margin-left: 5px;">
                        Go
                    </button>
                </div>
            </div>
        </div>
        <!--------->
        <div class="article-detail-section">
            <div class="row">
                <div class="col-md-5">
                    <div class="element-info-label">
                        Description
                    </div>
                    <div class="element-info-value">
                        <!-- {{selectedDocumentFetchdDetails.description || 'NA'}} -->
                        <div class="formula-div" style="overflow:auto;">
                            <div *ngFor="let item of showModifiedDescription">
                                <p [innerHTML]="item" style="white-space: break-spaces;"></p>
                            </div>
                            <!-- <tree-root *ngIf="showDescription" [nodes]="showDescription" >
                                <ng-template #treeNodeTemplate let-node let-index="index">
                                    <span [innerHTML]="node.data.title">
                                    </span>
                                </ng-template>
                            </tree-root> -->
                        </div>
                        <div *ngIf="showDescription && !showDescription.length">
                            Description is not present for selected document.
                        </div>
                    </div>
                </div>
                <div class="col-md-2">
                    <div *ngIf="selectedDocumentFetchdDetails.originalDocumentId">
                        <a style="cursor:pointer;" class="element-info-link" href="javascript: void(0);" (click)="loadThisVersionDoc(selectedDocumentFetchdDetails.originalDocumentId)">
                            Original Document
                        </a>
                    </div>
                    <div *ngIf="selectedDocumentFetchdDetails.previousDocumentId">
                        <a style="cursor:pointer;" class="element-info-link" href="javascript: void(0);" (click)="loadThisVersionDoc(selectedDocumentFetchdDetails.previousDocumentId)">
                            Previous Document
                        </a>
                    </div>
                    <div *ngIf="selectedDocumentFetchdDetails.nextDocumentId">
                        <a style="cursor:pointer;" class="element-info-link" href="javascript: void(0);" (click)="loadThisVersionDoc(selectedDocumentFetchdDetails.nextDocumentId)">
                            Next Document
                        </a>
                    </div>
                </div>
                <div class="col-md-5">
                    <div style="display: flex;justify-content: space-between;">
                        <div>
                            <div class="element-info-label">Relevance</div>
                            <div class="element-info-value">
                                <ng-select id="elementRelevance"
                                    style="max-width:250px;min-width:200px;"
                                    [items]="relevanceOptions"
                                    bindLabel="label"
                                    bindValue="value"
                                    [clearable]="false"
                                    (change)="updateDocumentDetails('relevance')"
                                    [(ngModel)]="selectedDocumentFetchdDetails.isRelevant">
                                    <ng-template ng-label-tmp let-item="item">
                                        <div style="display:flex;">
                                            <div style="width:4px;border-radius:4px;margin-right:4px;" [style.background]="item.barColor"></div>
                                            <div>{{item.label}}</div>
                                        </div>
                                    </ng-template>
                                </ng-select>
                            </div>
                        </div>
                        <div>
                            <div class="element-info-label">Document Owner</div>
                            <div class="element-info-value">
                                <ng-select id="elementAssessmentOwner"
                                    style="max-width:250px;min-width:200px;"
                                    [items]="usersList"
                                    [clearable]="false"
                                    bindLabel="name"
                                    bindValue="id"
                                    (change)="updateDocumentDetails('docOwner')"
                                    [(ngModel)]="selectedDocumentFetchdDetails.documentOwner">
                                        <ng-template ng-label-tmp let-item="item">
                                            <div>{{item.name}}</div>
                                        </ng-template>
                                </ng-select>
                            </div>
                        </div>
                    </div>
                    <div style="margin-top:35px;">
                        <div>
                            <app-r-a-g-status></app-r-a-g-status>
                        </div>
                    </div>
                    <div style="margin-top:35px;">
                        <div class="element-info-label">
                            Notes
                            <span style="width:16px;cursor:pointer;" (click)="editNotesFlag = !editNotesFlag">
                                <img src="../../../assets/images/icon-edit.svg" />
                            </span>
                            <span *ngIf="editNotesFlag" (click)="updateDocumentDetails('notes')"
                                style="margin-left:8px;cursor:pointer;">
                                <i style="font-size:18px;" class="fa fa-save"></i>
                            </span>
                        </div>
                        <div *ngIf="!editNotesFlag" class="element-info-value" style="white-space: pre-line;overflow-wrap:break-word;" [innerHTML]="selectedDocumentFetchdDetails.notes">
                        </div>
                        <div *ngIf="editNotesFlag" class="element-info-value">
                            <textarea rows="15" [(ngModel)]="selectedDocumentFetchdDetails.notes" style="resize: none;
                                border: 1px solid lightgray;border-radius: 5px;width: 100%;">
                            </textarea>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!---------------->
    </div>
</div>


<!-- relevance confirmation modal-->
<div class="modal fade" id="relevanceConfirmationModal" tabindex="-1" role="dialog" aria-labelledby="actionsLabel" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header" style="padding:5px 10px;">
              <h5 class="modal-title actionlist-modal-header" id="actionsLabel">Confirmation</h5>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="DontChangeRelevance()">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body" style="max-height: 400px;overflow-y:scroll;">
                <div>
                    Please note by marking this item as 'Relevant/Not Relevant', all elements
                    under this item will also get the same value of Relevance.
                </div>
                <div>
                    Do you wish to proceed?
                </div>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-primary" (click)="changeRelevance()" data-dismiss="modal">Yes</button>
              <button type="button" class="btn btn-secondary" (click)="DontChangeRelevance()" data-dismiss="modal">No</button>
            </div>
        </div>
    </div>
</div>