import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ArticlesDataService {
  articlesIdsList: Array<any> = [];
  selectedDocFilter: any = '';
  selectedDocCategoryFilter: any = '';
  navigateDocCategory: any = '';
  navigateDocId: any = '';
  topicForArticleList: any = '';
  

  constructor() { }

  
}
