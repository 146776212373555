import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { CookieService } from 'ngx-cookie';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DataServiceService {

  constructor(
    private _http: HttpClient,
    private _cookie: CookieService
  ) {}

  getHttpOptionsWithoutAccess() {
    const httpOptionsWithoutAccess = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      })
    };
    return httpOptionsWithoutAccess;
  }

  getHttpOptions() {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': 'bearer' + ' ' +this._cookie.get("token")
      })  
    };
    return httpOptions;
  }

  getHttpOptionsReport() {
    const httpOptions = {
      headers: new HttpHeaders({
        // 'Accept': 'text/csv',
        'Content-Type': 'application/json',
        // 'responseType': 'text',
        'Authorization': 'bearer' + ' ' +this._cookie.get("token"),
        
        
        
      })  
    };
    return httpOptions;
  }

  postData(url, data, sendHeaders) {
    return (sendHeaders === false ? this._http.post(url, data, this.getHttpOptionsWithoutAccess()) : this._http.post(url, data, this.getHttpOptions()))
  };

  getData(url, urlParam, sendHeaders) {
    const urlWithParams = this.getUrlWithParams(url, urlParam);
    return (sendHeaders === false ? this._http.get(urlWithParams, this.getHttpOptionsWithoutAccess()) : this._http.get(urlWithParams, this.getHttpOptions()))
  }

  getReport(url, data, sendHeaders) {
    // const urlWithParams = this.getUrlWithParams(url, urlParam);
    return (sendHeaders === false ? this._http.post(url, data, this.getHttpOptionsWithoutAccess()) : this._http.post(url, data, this.getHttpOptionsReport()))
  }

  deleteData(url, sendHeaders) {
    return (sendHeaders === false ? this._http.delete(url, this.getHttpOptionsWithoutAccess()) : this._http.delete(url, this.getHttpOptions()))
  }

  patchData(url, data, sendHeaders) {
    return (sendHeaders === false ? this._http.patch(url, data, this.getHttpOptionsWithoutAccess()) : this._http.patch(url, data, this.getHttpOptions()))
  };

  getUrlWithParams= function (url, params){
    if(params === null){
        return url;
    }else {
        var urlWithParams = '?',
            variable;
        if (Array.isArray(params)) {
            for (variable in params) {
                urlWithParams += params[variable].key + '=' + params[variable].val + '&';
            }
            urlWithParams = urlWithParams.substring(0, urlWithParams.length - 1);
        }
        else {
            urlWithParams += params.key + '=' + params.val;
        }
        urlWithParams = url + (urlWithParams);
        return urlWithParams;
      }
  };


}
