import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './components/login/login.component';
import { SignupComponent } from './components/signup/signup.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { ArticlesListComponent } from './components/articles-list/articles-list.component';
import { ArticleDetailComponent } from './components/article-detail/article-detail.component';
import { ReportingElementsListComponent } from './components/reporting-elements-list/reporting-elements-list.component';
import { ReportingElementDetailComponent } from './components/reporting-element-detail/reporting-element-detail.component';
import { ActionsListComponent } from './components/actions-list/actions-list.component';
import { ResetPasswordComponent } from './components/reset-password/reset-password.component';
import { RegulatoryActivitiesComponent } from './components/regulatory-activities/regulatory-activities.component';
import { DocumentDetailsComponent } from './components/document-details/document-details.component';
import { AuthGuard } from './shared/guards/auth.guard';
import { CanDeactivateGuard } from './components/articles-list/article-list-deactivate-route-gaurd';

const routes: Routes = [
  { 
    path: '',
    redirectTo: '/login',
    pathMatch: 'full'
  },
  { 
    path: 'login', 
    component: LoginComponent 
  },
  // { 
  //   path: 'sign-up', 
  //   component: SignupComponent 
  // },
  { 
    path: 'reset-password/:userId/:token', 
    component: ResetPasswordComponent 
  },
  {
    path: 'dashboard',
    component: DashboardComponent,
    children: [
      {
        path: '',
        redirectTo: 'regulatory-list',
        pathMatch: 'full',
        canActivate: [AuthGuard],
      },
      {
        path: 'regulatory-list',
        component: RegulatoryActivitiesComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'article-list',
        component: ArticlesListComponent,
        canActivate: [AuthGuard],
        canDeactivate: [CanDeactivateGuard]
      },
      {
        path: 'article-list/:docId/:keyId/:val',
        component: ArticlesListComponent,
        canActivate: [AuthGuard],
        canDeactivate: [CanDeactivateGuard]
      },
      {
        path: 'article-detail/:id',
        component: ArticleDetailComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'reporting-elements-list',
        component: ReportingElementsListComponent
      },
      {
        path: 'reporting-element-detail/:_id',
        component: ReportingElementDetailComponent
      },
      {
        path: 'actions-list',
        component: ActionsListComponent
      },
      {
        path: 'document-overview',
        component: DocumentDetailsComponent
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {onSameUrlNavigation: 'reload'})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
