import { Component, OnInit, ViewChild, AfterContentInit, OnDestroy, AfterContentChecked } from '@angular/core';
import { environment } from '../../../environments/environment';
import Handsontable from 'handsontable';
import { RAGStatusComponent } from '../../shared/components/r-a-g-status/r-a-g-status.component';
import { DataServiceService } from '../../shared/services/data-service/data-service.service';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { HotTableRegisterer } from '@handsontable/angular';
import {CookieService} from 'ngx-cookie';
import { trigger, transition, animate, style } from '@angular/animations';
import { ArticlesDataService } from '../../shared/services/articles-data-service/articles-data.service';
import { MathJaxService } from '../../shared/services/math-jax.service';
import { env } from 'process';

//     

declare var $: any;

@Component({
  selector: 'app-articles-list',
  templateUrl: './articles-list.component.html',
  styleUrls: ['./articles-list.component.scss'],
  animations: [
    trigger('slideInOut', [
      transition(':enter', [
        style({transform: 'translateX(-100%)'}),
        animate('200ms ease-in', style({transform: 'translateX(0%)'}))
      ]),
      transition(':leave', [
        animate('200ms ease-in', style({transform: 'translateX(-100%)'}))
      ])
    ])
  ]
})

export class ArticlesListComponent implements OnInit {
  private hotRegisterer = new HotTableRegisterer();
  selectedDocumentName: any;
  filtersForDownloadDoc: Array<any> = [];
  selectedDocumentNameLong: any;
  getParamData: any;
  showLoader: boolean = false;
  documentsList: Array<any> = [];
  selectedDocumentCategory: any = 'ALL';
  documentNamesList: Array<any> = [];
  selectedDocument: any = 'ALL';
  usersList: Array<any> = [];
  usersListForDropdown: Array<any> = [];
  articleListTableData: Array<any> = [];
  @ViewChild(RAGStatusComponent) ragComponent: RAGStatusComponent;
  rowData: Array<any> = [];
  updatedRowsData: Array<any> = [];
  updatedActionRowsData: Array<any> = [];
  currentDocumentReference: String = '';
  articleListTableId: any = 'article-list-table-id';
  articleListHotSettings: Handsontable.GridSettings;
  tableInstance: any;
  showNavMenu: Boolean = false;
  regulatoryTopic: any = '';
  navNodesData: any = [];
  regulatoryTopicsList: Array<any> = [];
  selectedTopic: any;
  documentCategoriesList: Array<any> = [];
  documentsAndCategoriesList: Array<any> = [];
  nodeOptions: any = {
    actionMapping: {
      mouse: {
        click: (tree, node, $event) => {
          if(tree.nodes[0]._id != node.data._id){
            this.hotRegisterer.getInstance(this.articleListTableId).getPlugin('filters').clearConditions();
		        this.hotRegisterer.getInstance(this.articleListTableId).getPlugin('filters').filter();
            this.hotRegisterer.getInstance(this.articleListTableId).render();
            this.scrollToSelectedRow(node.data._id);
          } 
        },
        contextMenu: (tree, node, $event) => {}
      },
    }
  };

  constructor(
    private _dataService: DataServiceService,
    private router: Router,
    private route: ActivatedRoute,
    private _toastr: ToastrService,
    private _cookie: CookieService,
    private _articlesDataService: ArticlesDataService,
    private mathJaxService: MathJaxService
  
     
  ){}

  ngOnInit(): void {
    let box = document.getElementById('list-container-height-article');
    let heightForBox = screen.height - 300;
    box.style.cssText = `height:${heightForBox}px`;
    this.getParamData = this.route.snapshot.params;
    this.getRegulatoryTopics();
    $('body').click(function(event){
      // check if the clicked element is a descendent of navigation 
      if ($(event.target).closest('.custom-col-pane').length) {
        return; //do nothing if event target is within the navigation
      } else {
      // do something if the event target is outside the navigation
         // code for collapsing menu here...
         if(document.getElementById('custom-col-pane')){
          document.getElementById('custom-col-pane').classList.remove('show');
         }
      }
    });
    this.mathJaxService.renderEquation('list-formula-div');
  
  }

  downloadReportForList(): void{
    const url = environment.apiUrls.articlesTableReportUrl;
    let excludedColumns = [];
    
    for(let i = 0; i < this.articleListHotSettings.columns.length; i++){
      if(this.articleListHotSettings.columns[i].isHidden){
        excludedColumns.push(this.articleListHotSettings.columns[i].data);
      }
    }
    let urlParam = [{
      key: 'documentId',
      val: this.selectedDocument
    },{
      key: 'documentCategory',
      val: this.selectedDocumentCategory
    }];

    if(excludedColumns.length){
      urlParam.push({key: 'excludedColumns', val: excludedColumns.join()})
    }
    let articles = [];
    
    if(this.filtersForDownloadDoc.length){
      this.filtersForDownloadDoc.forEach((item) => {
        if(item.col != 0){
          urlParam.push({key: item.colName, val: item.values.join()})
        }
        if(item.col == 0){
          articles = item.values;
        } 
      })
    }

    let data = {}
    if(articles.length){
      data['articles'] = articles;
    }

    let queryUrl = this._dataService.getUrlWithParams(url, urlParam);

    this._dataService.getReport(queryUrl, data, true).toPromise()
    // .subscribe((res)=> {
    //   console.log('asfasdsdasd')
    // }, (err) => {
    //   console.log('sasda')
    // })
    .then((response: any) => {
      // let data = JSON.parse(response);
      var hiddenElement = document.createElement('a');
      hiddenElement.href = 'data:text/csv;charset=utf-8,' + encodeURI(response);
      hiddenElement.target = '_blank';
      hiddenElement.download = 'report.csv';
      hiddenElement.click();
      
    }, (erorr) => {
      console.log('erorroroororr')
    })
  }

  getRegulatoryTopics(): void{
    let url = environment.apiUrls.getRegulationTopicsUrl;
    this._dataService.getData(url, [], true).toPromise()
    .then((response: any) => {
      if (response.status == 1) {
        this.regulatoryTopicsList = [];
        response.data.forEach((item) => {
          if(item.isActive){
            this.regulatoryTopicsList.push(item);
          }
        })
        // this.regulatoryTopicsList = response.data;
        if(localStorage.getItem('dashboardArticleNavDocId')){
          this.selectedTopic = localStorage.getItem('dashboardArticleNavTopicId');
          this.getDocumentsForTopics(this.selectedTopic);
        }else{
          if(localStorage.getItem('topicForArticleList')){
            this.selectedTopic = localStorage.getItem('topicForArticleList');
            this.getDocumentsForTopics(this.selectedTopic);
          }else{
            this.selectedTopic = this.regulatoryTopicsList[0]._id;
            this.getDocumentsForTopics(this.regulatoryTopicsList[0]._id);
          }
        }
        
      }
    }, (error) => {});

  }

  getDocumentsForTopics(id): void{
    let url = environment.apiUrls.getRegulatoryTopicsDocumentUrl;
    url = url.replace(':topicId', id);

    this._dataService.getData(url, [], true).toPromise()
    .then((response: any) => {
      if (response.status == 1) {
        this.documentCategoriesList = [];
        response.data.forEach((item) => {
          item._id.docType = item._id.documentType;
          item._id.docCategory = item._id.documentCategory;
          this.documentCategoriesList.push(item._id);
        })

        this.documentsAndCategoriesList = response.data;

        this.selectedDocumentCategory = this.documentCategoriesList[0].docCategory;
        this.selectedDocumentName = this.documentCategoriesList[0].docType;
        // this.documentsList = response.data[0].documents;
        // this.documentsList.forEach((item) => {
        //   item.docType = response.data[0]._id.documentType;
        //   item.docCategory = response.data[0]._id.documentCategory;
        // });
        if(localStorage.getItem('dashboardArticleNavDocId')){
          this.selectedDocumentCategory = localStorage.getItem('dashboardArticleNavCategoryId');
          this.onDocumentCategorySelection(this.selectedDocumentCategory);
        }else{
          if(localStorage.getItem('navigateDocCategory')){
            this.selectedDocumentCategory = localStorage.getItem('navigateDocCategory');
            this.onDocumentCategorySelection(localStorage.getItem('navigateDocCategory'));
          }else{
            this.selectedDocumentCategory = this.documentCategoriesList[0].docCategory;
            this.onDocumentCategorySelection(this.documentCategoriesList[0].docCategory);
          }
        }
        
  
        this.getUsersList();
      }
    }, (error) => {});
  }
  
  ngAfterContentChecked(): any{
    this.mathJaxService.renderEquation('list-formula-div');
  }

  // ngAfterContentInit(){
  //   this._articlesDataService.navigateDocCategory = '';
  //   this._articlesDataService.navigateDocId = '';
        
  // }

  // hideOverlays(){
  //   document.getElementById('custom-col-pane').classList.remove('show');
  // }

  ngOnDestroy(): void {
    if(localStorage.getItem('dashboardArticleNavDocId')){
      localStorage.removeItem('dashboardArticleNavDocId');
      localStorage.removeItem('dashboardArticleNavKey');
      localStorage.removeItem('dashboardArticleNavVal');
      localStorage.removeItem('dashboardArticleNavTopicId');
      localStorage.removeItem('dashboardArticleNavCategoryId');
    }
  }

  scrollToSelectedRow(id): any{
    // this.hotRegisterer.getInstance(this.articleListTableId).getPlugin('filters').clearConditions();
    // // this.hotRegisterer.getInstance(this.articleListTableId).render();
    // this.hotRegisterer.getInstance(this.articleListTableId).updateSettings({
    //     data: this.rowData
    // });
    let indexToScroll;
    this.rowData.forEach((item, index) => {
      if(item.regulationId == id){
        indexToScroll = index;
      }
    })
    this.hotRegisterer.getInstance(this.articleListTableId).scrollViewportTo(indexToScroll, 0);
  }



  goToArticleDetails(regulationId): any {
    if ((this.updatedRowsData && this.updatedRowsData.length) || (this.updatedActionRowsData && this.updatedActionRowsData.length)) {
      this._toastr.success('Saving unsaved changes!');
      this.updateData('state_change', regulationId);
    } else {
      const url = this.router.createUrlTree(['dashboard/article-detail', regulationId]).toString();
      window.open(url, '_blank');
    }
  }

  goToOtherTabs(): any {
    if((this.updatedRowsData && this.updatedRowsData.length) || (this.updatedActionRowsData && this.updatedActionRowsData.length)) {
      this._toastr.success('Saving unsaved changes!');
      this.updateData('none', 'none');
      return true;
    }else{
      return true; 
    }
  }

  getDocumentList(): void {
    const url = environment.apiUrls.getDocumentsListUrl;
    this._dataService.getData(url, [], true).toPromise()
    .then((response: any) => {
      if (response.status == 1) {
        this.documentsList = response.data.documents;
        this.documentsList.forEach((item) => {
          item.docType = item._id.documentType;
          item.docCategory = item._id.documentCategory;
        });
        
        if(this.getParamData.docCategory){
          this.selectedDocumentCategory = this.getParamData.docCategory;
          this.onDocumentCategorySelection(this.getParamData.docCategory);
        }else{
          this.selectedDocumentCategory = this.documentsList[0].docCategory;
          this.onDocumentCategorySelection(this.documentsList[0].docCategory);
        }
  
        this.getUsersList();
      }
    }, (error) => {});
  }

  onDocumentCategorySelection(selectedCategory): any {
    this.documentsAndCategoriesList.forEach((item) => {
      if(item._id.docCategory == selectedCategory){
        this.documentNamesList = item.documents;
        
      }
    })

    // this.selectedDocumentName = this.documentNamesList[0]._id;
    // this.documentsList.forEach((item) => {
    //   if (selectedCategory == item._id.documentCategory) {
    //     this.selectedDocumentName = item.docType;
    //     this.documentNamesList = item.documents;
    //   }
    // });
    if(localStorage.getItem('dashboardArticleNavDocId')){
      this.selectedDocument = localStorage.getItem('dashboardArticleNavDocId')
    }else{
      if(localStorage.getItem('navigateDocId')){
        this.selectedDocument = localStorage.getItem('navigateDocId')
      }else{
        this.selectedDocument = this.documentNamesList[0]._id;
      }
    }
    
    this.onDocumentSelection(this.selectedDocument, this.documentNamesList);
  }

  onDocumentSelection(docId, documentNamesList){
    let topicId;
    documentNamesList.forEach((item) => {
      if(item._id == docId){
        topicId = item.regulatoryTopicId
        this.selectedDocumentNameLong = item.longTitle;
      }
    });
    // if(topicId == '5ffcbb94f7028d0487f28581'){
    //   this.regulatoryTopic = 'CRR';
    // }
    // if(topicId == '5ffcbb94f7028d0487f28584'){
    //   this.regulatoryTopic = 'Credit Risk';
    // }
    // if(topicId == '5ffcbb94f7028d0487f2858d'){
    //   this.regulatoryTopic = 'Model Validation';
    // }
    this._articlesDataService.selectedDocCategoryFilter = this.selectedDocumentCategory;
    this._articlesDataService.selectedDocFilter = this.selectedDocument;

  }

  getUsersList(): void {
    const url = environment.apiUrls.getUsersUrl;
    this._dataService.getData(url, [], true).toPromise()
    .then((response: any) => {
      if (response.status == 1) {
        this.usersList = [];
        this.usersListForDropdown = [];
        response.data.users.forEach((obj) => {
          const userObj = {
            name: obj.firstName + ' ' + obj.lastName,
            id: obj._id
          };
          this.usersList.push(userObj);
          this.usersListForDropdown.push(userObj.name);
        });
        this.getAssessment([], 'kuchNahi');
      }
    }, (error) => {});
  }

  getNavigationList(): void{
    this.navNodesData = [];
    const url = environment.apiUrls.navigationList;
    let urlParams = [];
    if (this.selectedDocumentCategory && this.selectedDocumentCategory != 'ALL') {
      const catObj = {
        key: 'type',
        val: this.selectedDocumentCategory
      };
      urlParams.push(catObj);
    }

    if (this.selectedDocument && this.selectedDocument != 'ALL') {
      let docName;
      this.documentNamesList.forEach((item) => {
        if(item._id == this.selectedDocument){
          docName = item.title;
        }
      })
      const docObj = {
        key: 'docTitle',
        val: docName
      };
      urlParams.push(docObj);
    }
    this._dataService.getData(url, urlParams, true).toPromise()
    .then((response: any) => {
      if (response.status == 1) {
        this.navNodesData = [];
        var data = this.updateTree(response.data);
        this.navNodesData.push(data);
      }
    }, (error) => {
      if (error.error) {
        this._toastr.error(error.error.message);
      }
    });
  }

  getAssessment(urlParam, reason): void {
    this.showLoader = true;
    // console.log(window.location.href)
    // let browseUrl = window.location.href;
    // let urlElements = browseUrl.split('/');
    this.filtersForDownloadDoc = [];
        
    if(reason == 'docChange'){
      if(localStorage.getItem('dashboardArticleNavTopicId')){
        localStorage.removeItem('dashboardArticleNavCategoryId');
        localStorage.removeItem('dashboardArticleNavTopicId');
        localStorage.removeItem('dashboardArticleNavDocId');
        localStorage.removeItem('dashboardArticleNavKey');
        localStorage.removeItem('dashboardArticleNavVal');
      }
    }

    if(localStorage.getItem('dashboardArticleNavDocId') && reason != 'docChange'){
      urlParam = [
        {
          key: 'documentCategory',
          val: localStorage.getItem('dashboardArticleNavCategoryId')
        },
        {
          key: 'documentId',
          val: localStorage.getItem('dashboardArticleNavDocId')
        },
        {
          key: localStorage.getItem('dashboardArticleNavKey'),
          val: localStorage.getItem('dashboardArticleNavVal')
        }
      ]
      localStorage.removeItem('dashboardArticleNavDocId');
        localStorage.removeItem('dashboardArticleNavKey');
        localStorage.removeItem('dashboardArticleNavVal');
        localStorage.removeItem('dashboardArticleNavCategoryId');
        localStorage.removeItem('dashboardArticleNavTopicId');
    }


    
    
    this.rowData = [];
    // if(this.hotRegisterer.getInstance(this.articleListTableId)){
    //   this.hotRegisterer.getInstance(this.articleListTableId).destroy();
    // }
    const url = environment.apiUrls.getAssessmentUrl;
    // const paramObj = {
    //   key: 'page',
    //   val: this.pageNo
    // };
    // const paramPageLimit = {
    //   key: 'limit',
    //   val: 50
    // };
    // urlParam.push(paramObj);
    // urlParam.push(paramPageLimit);
    if(urlParam && urlParam.length == 0){
      if (this.selectedDocumentCategory && this.selectedDocumentCategory != 'ALL') {
        // this._articlesDataService.selectedDocCategoryFilter = this.selectedDocumentCategory;
        const catObj = {
          key: 'documentCategory',
          val: this.selectedDocumentCategory
        };
        urlParam.push(catObj);
      }
  
      if (this.selectedDocument && this.selectedDocument != 'ALL') {
        // this._articlesDataService.selectedDocFilter = this.selectedDocument;
        const docObj = {
          key: 'documentId',
          val: this.selectedDocument
        };
        urlParam.push(docObj);
      }
    }

    if(localStorage.getItem('topicForArticleList')){
      localStorage.removeItem('topicForArticleList')
    }
    if(localStorage.getItem('navigateDocCategory')){
      localStorage.removeItem('navigateDocCategory')
    }
    if(localStorage.getItem('navigateDocId')){
      localStorage.removeItem('navigateDocId')
    }
    
    this.getNavigationList();

    this._dataService.getData(url, urlParam, true).toPromise()
    .then((response: any) => {
      console.log(Date.now(), '<<------------assessment response')
      if (response.status == 1) {
        this.articleListTableData = response.data;
          this.ragComponent.ngOnInit();
          this.prepareRowDataForGrid();
          // this.documentsList.forEach((item) => {
          //   if (this.selectedDocumentCategory == item._id.documentCategory) {
          //     this.selectedDocumentName = item._id.documentType;
          //   }
          // });
      }
    }, (error) => {
      this.showLoader = false;
    });
  }

  prepareRowDataForGrid(): any {
    this.updatedRowsData = [];
    this.updatedActionRowsData = [];
    this.rowData = [];
    // let amendmentsDataList =  [];
    // this._articleDetailService.amendmentsList = [];
    this.articleListTableData.forEach((item, index) => {
      let rowObj = {
        regulationNumber: item.regulationNumber,
        description: item.description,
        isRelevant: item.isRelevant,
        compliantStatus: item.compliantStatus,
        assessment: item.assessment,
        action: item.action ? item.action : '',
        actionStatus: item.actionStatus == 'in_progress' ? 'In Progress' : item.actionStatus == 'completed' ? 'Completed' : item.actionStatus == 'new' ? 'New' : '',
        actionDueDate: '',
        regulationId: item._id,
        path: item.path,
        isUpdated: false,
        amendmentType: item.amendmentType,
        assessmentId: item.assessmentId ? item.assessmentId : null,
        actionId: item.actionId,
        amendmentFlag: item.amendmentFlag,
        assessmentOwner: null,
        actionOwner: null,
        dataIndex: index,
        tags: item.tags,
        controls: item.controls,
        effective: item.effective,
      };

      if(item.amendmentFlag){
        if(item.amendmentFlag == 'new'){
          rowObj['amendments'] = 'New';
        }
        if(item.amendmentFlag == 'tbd'){
          rowObj['amendments'] = 'TBD';
        }
        if(item.amendmentFlag == 'star'){
          rowObj['amendments'] = 'Modified';
        }
        if(item.amendmentFlag == 'delete'){
          rowObj['amendments'] = 'Deleted';
        }

      }

      const fullRef = item.path.split('|');
      let tempStr = '';
      for (let i = 0; i < fullRef.length; i++) {
        if (i != fullRef.length - 1) {
          tempStr = tempStr + fullRef[i] + ' ' + '->' + ' ';
        } else {
          tempStr = tempStr + fullRef[i];
        }
      }
      rowObj.path = tempStr != '' ? tempStr : item.path;
      if (item.actionDueDate) {
        const tempSplitDate = item.actionDueDate.split('T');
        const tempDisplayDate = tempSplitDate[0].split('-');
        let displayDueDate = tempDisplayDate[2] + '-' + tempDisplayDate[1] + '-' + tempDisplayDate[0];
        rowObj.actionDueDate = displayDueDate;
      }
      rowObj.isRelevant = item.isRelevant == true ? 'Yes' : 'No';
      item.isRelevant = item.isRelevant == true ? 'Yes' : 'No';
      rowObj.compliantStatus = rowObj.compliantStatus == null ? 'None' : (rowObj.compliantStatus == 'partial' ? 'Partial' : (rowObj.compliantStatus == 'full' ? 'Full' : (rowObj.compliantStatus == 'not_assessed' ? 'Not Assessed' : (rowObj.compliantStatus == 'not_applicable' ? 'Not Applicable' : 'None'))));
      if(rowObj.isRelevant == 'Yes' && rowObj.compliantStatus == 'Not Applicable'){
        rowObj.compliantStatus = 'Not Assessed';
      }
      if (item.assessmentOwnerId || item.actionOwnerId) {
        this.usersList.forEach((userObj) => {
          if (userObj.id == item.assessmentOwnerId) {
            rowObj['assessmentOwner'] = userObj.name;
          }
          if (userObj.id == item.actionOwnerId) {
            rowObj['actionOwner'] = userObj.name;
          }
        });
      }    
      // this._articlesDataService.articlesIdsList.push(item._id);
      // localStorage.setItem('articleIdsList', JSON.stringify(this._articlesDataService.articlesIdsList));
      // this._articleDetailService.amendmentsList.push({id: item._id, amendmentFlag: item.amendmentFlag})
      // amendmentsDataList.push({id: item._id, amendmentFlag: item.amendmentFlag});
      this.rowData.push(rowObj);
    });
    // localStorage.setItem('articlesAmendmentData', JSON.stringify(amendmentsDataList));
    this.currentDocumentReference = this.rowData && this.rowData.length ? this.rowData[0].path : '';
    // this.getArticleTree(this.rowData[0].regulationId);
    // this.showLoader = true;
    // $('.articles-list-container').addClass('displayNone');
    // setTimeout(() => {
    //   this.prepareTable();
    // },0);
    // this.prepareTable();
    this.prepareTableConfig();
  }

  prepareDataToUpdate(): any {
    const copyOfData = this.updatedRowsData.map(obj => Object.assign({}, obj));
    if (copyOfData && copyOfData.length) {
      copyOfData.forEach((item) => {
        item.isRelevant = item.isRelevant == 'Yes' ? true : false;
        item.compliantStatus = item.compliantStatus == 'Partial' ? 'partial' : item.compliantStatus == 'Full' ? 'full' : item.compliantStatus == 'Not Assessed' ? 'not_assessed' : item.compliantStatus == 'Not Applicable' ? 'not_applicable' : null;
        item.regulationId = item.regulationId;
        this.usersList.forEach((user) => {
          if (user.name == item.assessmentOwner) {
            item.assessmentOwnerId = user.id;
          }
        });
        if (item.assessment || item.assessment == null || item.assessment == '') {
          item.assessmentComment = item.assessment == null ? '' : item.assessment
        }
        item.type = 'liquidity';
        delete item.action;
        delete item.actionOwner;
        delete item.actionStatus;
        delete item.regulationNumber;
        delete item.assessmentId;
        delete item.actionDueDate;
        delete item.isUpdated;
        delete item.description;
        delete item.path;
        delete item.assessment;
        delete item.assessmentOwner;
        delete item.amendmentType;
        delete item.actionId;
        delete item.amendmentFlag;
        delete item.amendments;
      });
      return copyOfData;
    } else {
      return [];
    }
  }

  prepareDataUpdateForActions(): any {
    const patchActionsData = [];
    const loginUser = this._cookie.get('_id');
    const copyOfData = this.updatedActionRowsData.map(obj => Object.assign({}, obj));
    if (copyOfData && copyOfData.length) {
      copyOfData.forEach((item) => {
        let obj: any;
        obj = {
          action: item.action,
          assignedBy: loginUser,
          regulationId: item.regulationId,
          actionId: item.actionId,
          dueDate: item.actionDueDate,
          documentId: this.selectedDocument
        };
        obj.status = item.actionStatus == 'New' ? 'new' : (item.actionStatus == 'In Progress' ? 'in_progress' : (item.actionStatus == 'Completed' ? 'completed' : null))  
        this.usersList.forEach((user) => {
          if (user.name == item.actionOwner) {
            obj.actionOwnerId = user.id;
          }
        });
        patchActionsData.push(obj);
      });
      return patchActionsData;
    } else {
      return [];
    }
  }

  actionsDataAPICalls(updatedActionsList): any {
    if (updatedActionsList.length) {
      const patchActionDataList = [];
      const postActionDataList = [];
      updatedActionsList.forEach((item) => {
        if (item.actionId) {
          const url = environment.apiUrls.newActionForAssessmentUrl + item.actionId;
          delete item.actionId;
          const patchPromise = this._dataService.patchData(url, item, true).toPromise();
          patchActionDataList.push(patchPromise);
        } else {
          const url = environment.apiUrls.newActionForAssessmentUrl;
          delete item.actionId;
          const postPromise = this._dataService.postData(url, item, true).toPromise();
          postActionDataList.push(postPromise);
        }
      });
      return [...patchActionDataList, ...postActionDataList];
    } else {
      return [];
    }
  }

  updateData(condition, conditionId): any {
    const dataList = this.prepareDataToUpdate();
    const updatedActionsList = this.prepareDataUpdateForActions();
    const actionsPromises = this.actionsDataAPICalls(updatedActionsList);
    let rowUpdatePromise;
    if (dataList.length) {
      const url = environment.apiUrls.updateAssessmentUrl;
      const data = {
        updatedBy: this._cookie.get('_id'),
        updatedRegulations: dataList
      };
      rowUpdatePromise = this._dataService.patchData(url, data, true).toPromise();
      actionsPromises.push(rowUpdatePromise);
    }
    Promise.all(actionsPromises).then((res) => {
      this.updatedActionRowsData = [];
      this.updatedRowsData = [];
      res.forEach((obj: any) => {
        if (obj.status == 1) {
          this._toastr.success(obj.message);
        } else {
          this._toastr.error(obj.message);
        }
        this.ragComponent.ngOnInit();
      });
      if (condition == 'state_change') {
        const url = this.router.createUrlTree(['dashboard/article-detail', conditionId]).toString();
        window.open(url, '_blank');
      }
    });
    if (updatedActionsList.length == 0 && dataList.length == 0) {
      this._toastr.error('No data to update!');
    }
  }

  prepareTableConfig(): any{
    const self = this;
    this.articleListHotSettings = {
      licenseKey: 'non-commercial-and-evaluation',
      // viewportRowRenderingOffset: 15,
      width: '100%',
      rowHeights: 50,
      columnHeaderHeight: 50,
      stretchH: 'all',
      hiddenColumns: {
        columns: [11,12,13,14],
      },
      filters: true,
      dropdownMenu: ['filter_action_bar', 'filter_by_value'],
      columns: [
        {
          title: 'Article No.',
          data: 'regulationNumber',
          readOnly: true,
          width: '50px',
          cellClass: 'article-no-text',
          isHidden: false,
          renderer: function(instance, td, row, col, prop, value, cellProperties) {
            Handsontable.renderers.TextRenderer.apply(this, arguments);
            td.title = 'Click to view details';
            if (value) {
              const wrapper = document.createElement('div');
              wrapper.id = "target1";
              wrapper.style.cssText = 'text-align:center;';
              const atag = document.createElement('a');
              atag.innerHTML = value
              atag.id="link-target"
              atag.className = "targetVal-article"
              atag.style.cssText = 'color:#a5473e;padding:2px;margin-top:5px;text-decoration:none;';
              wrapper.appendChild(atag)
              Handsontable.dom.empty(td);
              td.appendChild(wrapper);
            }
            return td;
          }
        },
        { 
          title: 'Article',
          data: 'description',
          width: '180px',
          readOnly: true,
          isHidden: false,
          renderer: function(instance, td, row, col, prop, value, cellProperties) {
            Handsontable.renderers.TextRenderer.apply(this, arguments);
            if(value){
              const currentRowData = instance.getDataAtRow(row);
              td.title = value;
              const wrapper = document.createElement('div');
              const div1 = document.createElement('div');
              const div2 = document.createElement('div');
              if (value.length > 150) {
                div1.innerHTML = value.substring(0, 150) + '...';
              }else{
                div1.innerHTML = value;  
              }
              // const index = self.rowData.findIndex(item => (item.regulationNumber == currentRowData[0] && item.description == currentRowData[1]));
              // // let index = self.rowData[row].dataIndex;
              // if(index && self.rowData[index].amendmentType == 'actual'){
              //   const childEle = document.createElement('div');
              //   childEle.style.cssText = 'width:0;height:0;border-left:15px solid transparent;border-right:15px solid transparent;border-bottom:15px solid #e6e6e6;'
              //   div2.appendChild(childEle);
              // }
              // if(index && self.rowData[index].amendmentType == 'rolled'){
              //   const childEle = document.createElement('div');
              //   const childEle2 = document.createElement('div');
              //   childEle.style.cssText = 'position:relative;width:0;border-bottom:solid 15px #e6e6e6;border-right:solid 15px transparent;border-left:solid 15px transparent;';
              //   childEle2.style.cssText = 'position:absolute;top:2px;left:-12px;width:0;border-bottom:solid 12px white;border-right:solid 12px transparent;border-left:solid 12px transparent;';
              //   childEle.appendChild(childEle2);
              //   div2.appendChild(childEle);
              
              // }
              // if (index && (self.rowData[index].amendmentFlag == 'new' || self.rowData[index].amendmentFlag == 'delete' || self.rowData[index].amendmentFlag == 'tbd')) {
              //   const childEle = document.createElement('div');
              //   childEle.innerHTML = self.rowData[index].amendmentFlag == 'new' ? 'New' : 'Deleted';
              //   if(self.rowData[index].amendmentFlag == 'tbd'){
              //     childEle.innerHTML = 'TBD';
              //   }
              //   childEle.style.cssText = self.rowData[index].amendmentFlag == 'new' ? 'background-color:lightgreen;padding:2px;color:#ffffff;margin-top:5px;' : 'background-color:brown;padding:2px;color:#ffffff;margin-top:5px;';
              //   if(self.rowData[index].amendmentFlag == 'tbd'){
              //     childEle.style.cssText = 'background-color:orange;padding:2px;color:#ffffff;margin-top:5px;';
              //   }
              //   div2.appendChild(childEle);
              // }
              // if (index && self.rowData[index].amendmentFlag == 'star') {
              //   const img = document.createElement('img');
              //   img.src = '../../../assets/images/icon-star.svg';
              //   img.alt = 'logo';
              //   img.style.cssText = 'cursor:pointer';
              //   div2.appendChild(img);
              // }
              wrapper.style.cssText = 'display:flex';
              wrapper.appendChild(div1);
              div2.style.cssText = 'margin-left:4px;';
              wrapper.appendChild(div2);
              wrapper.classList.add('list-formula-div');
              Handsontable.dom.empty(td);
              td.style.cssText = "cursor:pointer"
              td.appendChild(wrapper);
            }else{
              Handsontable.renderers.TextRenderer.apply(this, arguments);
            }
            return td;
          }
        },
        {
          title: 'Relevance',
          data: 'isRelevant',
          width: '60px',
          type: 'dropdown',
          isHidden: false,
          source: ['Yes', 'No'],
          renderer: function(instance, td, row, col, prop, value, cellProperties) {
            Handsontable.renderers.TextRenderer.apply(this, arguments);
            if(value){
              const wrapper = document.createElement('div');
              const div1 = document.createElement('div');
              const div2 = document.createElement('div');
              const divContent = document.createElement('div');
              divContent.style.cssText = 'display:flex';
              const divIconDrop = document.createElement('div');
              divIconDrop.className = 'htAutocompleteArrow';
              divIconDrop.innerHTML = '▼';
              div2.innerHTML = value;
              if (value == 'Yes') {
                div1.style.cssText = 'width:4px;border-radius:4px;margin-right:4px;background:#008000';
              }
              if (value == 'No') {
                div1.style.cssText = 'width:4px;border-radius:4px;margin-right:4px;background:grey';
              }
              wrapper.style.cssText = 'display:flex;justify-content:space-between;';
              divContent.appendChild(div1);
              divContent.appendChild(div2);
              wrapper.appendChild(divContent);
              wrapper.appendChild(divIconDrop);
              Handsontable.dom.empty(td);
              td.appendChild(wrapper);
            }else{
              Handsontable.renderers.TextRenderer.apply(this, arguments);
            }
            return td;
          }
        },
        {
          title: 'Compliance',
          data: 'compliantStatus',
          width: '70px',
          isHidden: false,
          type: 'dropdown',
          allowInvalid: false,
          preventOverflow: true,
          source: ['None', 'Partial', 'Full', 'Not Assessed', 'Not Applicable'],
          renderer: function(instance, td, row, col, prop, value, cellProperties) {
            Handsontable.renderers.TextRenderer.apply(this, arguments);
            if(value){
              const currentRowData = instance.getDataAtRow(row);
              const wrapper = document.createElement('div');
              const div1 = document.createElement('div');
              const div2 = document.createElement('div');
              const divContent = document.createElement('div');
              divContent.style.cssText = 'display:flex';
              const divIconDrop = document.createElement('div');
              divIconDrop.className = 'htAutocompleteArrow';
              divIconDrop.innerHTML = '▼';
              div2.innerHTML = value;
              if (value == 'Partial') {
                div1.style.cssText = 'width:4px;border-radius:4px;margin-right:4px;background:#ffcc00';
              }
              if (value == 'None') {
                div1.style.cssText = 'width:4px;border-radius:4px;margin-right:4px;background:#b32d00';
              }
              if (value == 'Full') {
                div1.style.cssText = 'width:4px;border-radius:4px;margin-right:4px;background:#008000';
              }
              if (value == 'Not Assessed') {
                div1.style.cssText = 'width:4px;border-radius:4px;margin-right:4px;background:grey';
              }
              if (value == 'Not Applicable') {
                div1.style.cssText = 'width:4px;border-radius:4px;margin-right:4px;background:white';
              }
              if (currentRowData[2] == 'No') {
                td.style.background = '#e6e6e6';
              }
              wrapper.style.cssText = 'display:flex;justify-content:space-between;';
              divContent.appendChild(div1);
              divContent.appendChild(div2);
              wrapper.appendChild(divContent);
              wrapper.appendChild(divIconDrop);
              Handsontable.dom.empty(td);
              td.appendChild(wrapper);
            }else{
              Handsontable.renderers.TextRenderer.apply(this, arguments);
            }
            return td;
          } 
        },
        {
          title: 'Assessment',
          data: 'assessment',
          width: '150px',
          isHidden: false,
          renderer: function(instance, td, row, col, prop, value, cellProperties) {
            Handsontable.renderers.TextRenderer.apply(this, arguments);
            if(value){
              td.title = value;
              if (value.length > 150) {
                td.innerHTML = value.substring(0, 150) + '...';
              } else {
                td.innerHTML = value;
              }
              td.className = 'assessmentCol'
              td.style.cssText = "word-break:break-word";
            }else{
              Handsontable.renderers.TextRenderer.apply(this, arguments);
            }
            return td;
          }
        },
        {
          title: 'Assessment Owner',
          data: 'assessmentOwner',
          width: '100px',
          type: 'dropdown',
          isHidden: false,
          source: this.usersListForDropdown
        },
        {
          title: 'Action',
          data: 'action',
          width: '60px',
          isHidden: false,
          renderer: function(instance, td, row, col, prop, value, cellProperties) {
            Handsontable.renderers.TextRenderer.apply(this, arguments);
            const data = instance.getDataAtRow(row);
            if(data[3] == 'Not Assessed' || data[3] == 'Not Applicable'){
                // instance.setCellMeta(row, col, 'className',  '' );
                td.style.background = '#e6e6e6';
                cellProperties.readOnly = true;
              }else{
                // instance.setCellMeta(row, col, 'className',  'grayed-out' );
                td.style.background = '#ffffff';
                cellProperties.readOnly = false;
              }
            if(value){
              td.title = value;
              if (value.length > 150) {
                td.innerHTML = value.substring(0, 150) + '...';
              } else {
                td.innerHTML = value;
              }

              

              td.style.cssText = "word-break:break-word";
            }else{
              Handsontable.renderers.TextRenderer.apply(this, arguments);
            }
            return td;
          }
        },
        {
          title: 'Action Owner',
          data: 'actionOwner',
          width: '70px',
          isHidden: false,
          type: 'dropdown',
          source: this.usersListForDropdown,
          renderer(instance, td, row, col, prop, value, cellProperties) {
            const currentRowData = instance.getDataAtRow(row);
            // if (currentRowData[2] == 'No') {
            //   td.style.background = '#e6e6e6';
            //   Handsontable.dom.empty(td);
            // } else {
            //   Handsontable.renderers.TextRenderer.apply(this, arguments);
            // }

            if((currentRowData[3] == 'Not Assessed' || currentRowData[3] == 'Not Applicable') || !currentRowData[6]){
              // instance.setCellMeta(row, col, 'className',  '' );
              td.style.background = '#e6e6e6';
              cellProperties.readOnly = true;
            }else{
              // this.instance.setCellMeta(row, col, 'className',  'grayed-out' );
              td.style.background = '#ffffff';
              cellProperties.readOnly = false;
            }

            if(value){
              const wrapper = document.createElement('div');
              const div1 = document.createElement('div');
              const divIconDrop = document.createElement('div');
              divIconDrop.className = 'htAutocompleteArrow';
              divIconDrop.innerHTML = '▼';
              wrapper.style.cssText = 'display:flex;justify-content:space-between;';
              div1.innerHTML = value;
              wrapper.appendChild(div1);
              wrapper.appendChild(divIconDrop);
              Handsontable.dom.empty(td);
              td.appendChild(wrapper);
            }else{
              const wrapper = document.createElement('div');
              const div1 = document.createElement('div');
              const divIconDrop = document.createElement('div');
              divIconDrop.className = 'htAutocompleteArrow';
              divIconDrop.innerHTML = '▼';
              wrapper.style.cssText = 'display:flex;justify-content:space-between;';
              div1.innerHTML = '';
              wrapper.appendChild(div1);
              wrapper.appendChild(divIconDrop);
              Handsontable.dom.empty(td);
              td.appendChild(wrapper);
              
            }
            
            // Handsontable.renderers.TextRenderer.apply(this, arguments);    
            return td;
          }
        },
        {
          title: 'Action Status',
          data: 'actionStatus',
          width: '65px',
          isHidden: false,
          type: 'dropdown',
          allowInvalid: false,
          source: ['New', 'In Progress', 'Completed'],
          renderer(instance, td, row, col, prop, value, cellProperties) {
            const currentRowData = instance.getDataAtRow(row);

            if((currentRowData[3] == 'Not Assessed' || currentRowData[3] == 'Not Applicable') || !currentRowData[6]){
              // instance.setCellMeta(row, col, 'className',  '' );
              td.style.background = '#e6e6e6';
                cellProperties.readOnly = true;
            }else{
              // this.instance.setCellMeta(row, col, 'className',  'grayed-out' );
              td.style.background = '#ffffff';
              cellProperties.readOnly = false;
              
            }
            // if (currentRowData[2] == 'No') {
            //   td.style.background = '#e6e6e6';
            // }
            // Handsontable.renderers.TextRenderer.apply(this, arguments);
            if(value){
              const wrapper = document.createElement('div');
              const div1 = document.createElement('div');
              const divIconDrop = document.createElement('div');
              divIconDrop.className = 'htAutocompleteArrow';
              divIconDrop.innerHTML = '▼';
              wrapper.style.cssText = 'display:flex;justify-content:space-between;';
              div1.innerHTML = value;
              wrapper.appendChild(div1);
              wrapper.appendChild(divIconDrop);
              Handsontable.dom.empty(td);
              td.appendChild(wrapper);
            }else{
              const wrapper = document.createElement('div');
              const div1 = document.createElement('div');
              const divIconDrop = document.createElement('div');
              divIconDrop.className = 'htAutocompleteArrow';
              divIconDrop.innerHTML = '▼';
              wrapper.style.cssText = 'display:flex;justify-content:space-between;';
              div1.innerHTML = '';
              wrapper.appendChild(div1);
              wrapper.appendChild(divIconDrop);
              Handsontable.dom.empty(td);
              td.appendChild(wrapper);
            }
            // Handsontable.renderers.TextRenderer.apply(this, arguments);
            return td;
          }
        },
        {
          title: 'Action Due Date',
          data: 'actionDueDate',
          width: '70px',
          isHidden: false,
          type: 'date',
          dateFormat: 'DD-MM-YYYY',
          correctFormat: true,
          // datePicker additional options (see https://github.com/dbushell/Pikaday#configuration)
          datePickerConfig: {
            onOpen(){
              const container: any = document.querySelector('.htDatepickerHolder');
              const left = parseInt(this.el.offsetParent.style.left, 10);
              container.style.left = (left - 114) + 'px';
            },
            // First day of the week (0: Sunday, 1: Monday, etc)
            firstDay: 0,
            showWeekNumber: true,
            numberOfMonths: 1
          },
          renderer(instance, td, row, col, prop, value, cellProperties) {
            const currentRowData = instance.getDataAtRow(row);
            if((currentRowData[3] == 'Not Assessed' || currentRowData[3] == 'Not Applicable') || !currentRowData[6]){
              // instance.setCellMeta(row, col, 'className',  '' );
              td.style.background = '#e6e6e6';
                cellProperties.readOnly = true;
            }else{
              // this.instance.setCellMeta(row, col, 'className',  'grayed-out' );
              td.style.background = '#ffffff';
              cellProperties.readOnly = false;
              
            }
            // if (currentRowData[2] == 'No') {
            //   td.style.background = '#e6e6e6';
            // }
            // Handsontable.renderers.TextRenderer.apply(this, arguments);
            
            Handsontable.renderers.TextRenderer.apply(this, arguments);
            return td;
          }
        },
        {
          title: 'Amendment',
          data: 'amendments',
          width: '60px',
          isHidden: false,
          readOnly: true,
          renderer: function(instance, td, row, col, prop, value, cellProperties) {
            Handsontable.renderers.TextRenderer.apply(this, arguments);
            const currentRowData = instance.getDataAtRow(row);
              const wrapper = document.createElement('div');
              const div1 = document.createElement('div');
              const div2 = document.createElement('div');
              
              const index = self.rowData.findIndex(item => (item.regulationNumber == currentRowData[0] && item.description == currentRowData[1]));
              // let index = self.rowData[row].dataIndex;
              if(index && self.rowData[index].amendmentType == 'actual'){
                const childEle = document.createElement('div');
                childEle.style.cssText = 'width:0;height:0;border-left:15px solid transparent;border-right:15px solid transparent;border-bottom:15px solid #e6e6e6;'
                div2.appendChild(childEle);
              }
              if(index && self.rowData[index].amendmentType == 'rolled'){
                const childEle = document.createElement('div');
                const childEle2 = document.createElement('div');
                childEle.style.cssText = 'position:relative;width:0;border-bottom:solid 15px #e6e6e6;border-right:solid 15px transparent;border-left:solid 15px transparent;';
                childEle2.style.cssText = 'position:absolute;top:2px;left:-12px;width:0;border-bottom:solid 12px white;border-right:solid 12px transparent;border-left:solid 12px transparent;';
                childEle.appendChild(childEle2);
                div2.appendChild(childEle);
              
              }
            // if(value){
              
              if (value && (value == 'New' || value == 'Deleted' || value == 'TBD')) {
                const childEle = document.createElement('div');
                childEle.innerHTML = value == 'New' ? 'New' : 'Deleted';
                if(value == 'TBD'){
                  childEle.innerHTML = 'TBD';
                }
                childEle.style.cssText = value == 'New' ? 'background-color:lightgreen;padding:2px;color:#ffffff;margin-top:5px;' : 'background-color:brown;padding:2px;color:#ffffff;margin-top:5px;';
                if(value == 'TBD'){
                  childEle.style.cssText = 'background-color:orange;padding:2px;color:#ffffff;margin-top:5px;';
                }
                div2.appendChild(childEle);
              }
              if (value && value == 'Modified') {
                const img = document.createElement('img');
                img.src = '../../../assets/images/icon-star.svg';
                img.alt = 'logo';
                img.style.cssText = 'cursor:pointer';
                div2.appendChild(img);
              }
              wrapper.style.cssText = 'display:flex';
              wrapper.appendChild(div1);
              div2.style.cssText = 'margin-left:4px;';
              wrapper.appendChild(div2);
              wrapper.classList.add('list-formula-div');
              if(value) {
                td.title = value;
              
              }
              
              Handsontable.dom.empty(td);
              td.style.cssText = "cursor:pointer"
              td.appendChild(wrapper);
            // }else{
            //   Handsontable.renderers.TextRenderer.apply(this, arguments);
            // }
            return td;
          }
        },
        {
          title: 'Tags',
          data: 'tags',
          width: '100px',
          isHidden: true,
          renderer: function(instance, td, row, col, prop, value, cellProperties) {
            Handsontable.renderers.TextRenderer.apply(this, arguments);
            if(value){
              td.title = value;
              td.innerHTML = value;
            }else{
              Handsontable.renderers.TextRenderer.apply(this, arguments);
            }
            return td;
          }
        },
        {
          title: 'Effective',
          data: 'effective',
          width: '100px',
          isHidden: true,
          renderer: function(instance, td, row, col, prop, value, cellProperties) {
            Handsontable.renderers.TextRenderer.apply(this, arguments);
            if(value){
              td.title = value;
              td.innerHTML = value;
            }else{
              Handsontable.renderers.TextRenderer.apply(this, arguments);
            }
            return td;
          }
        },
        {
          title: 'Controls',
          data: 'controls',
          width: '100px',
          isHidden: true,
          renderer: function(instance, td, row, col, prop, value, cellProperties) {
            Handsontable.renderers.TextRenderer.apply(this, arguments);
            if(value){
              td.title = value;
              td.innerHTML = value;
            }else{
              Handsontable.renderers.TextRenderer.apply(this, arguments);
            }
            return td;
          }
        },
        {
          title: 'id',
          data: 'regulationId',
          width: '10px',
          isHidden: true,
          
        }
      ],
      afterChange: (changes, src) => {
        const tableInstance = self.hotRegisterer.getInstance(self.articleListTableId);
        
        if(changes && changes.length){
          changes.forEach((item) => {
            const change = item;
            if(change && change[1] == 'isRelevant' && change[3] == 'Yes'){
              if(self.rowData[change[0]].compliantStatus == 'Not Applicable'){
                tableInstance.setDataAtCell(change[0], 3, 'Not Assessed');
              }
            }
            if(change && change[1] == 'isRelevant' && change[3] == 'No'){
              tableInstance.setDataAtCell(change[0], 3, 'Not Applicable');
            }
            if (change){
              if (change[1] === 'action'){
                if (change[3]){
                  tableInstance.setDataAtCell(change[0], 8, 'New');
                }else{
                  tableInstance.setDataAtCell(change[0], 8, '');
                }
              }
            }
          })
        }

        
        if (changes && changes.length) {
          for (let i = 0; i < changes.length; i++) {
            if ((changes[i][1] == 'isRelevant' || changes[i][1] == 'compliantStatus' || changes[i][1] == 'assessment' || changes[i][1] == 'assessmentOwner' || changes[i][1] == 'tags' || changes[i][1] == 'effective' || changes[i][1] == 'controls' )) {
              const oldValue = changes[i][2];
              const newValue = changes[i][3];
              if (newValue != oldValue) {
                const rowIndex = changes[i][0];
                const currentRowData = tableInstance.getDataAtRow(rowIndex);
                const dataIndex = self.rowData.findIndex(item => (item.regulationId == currentRowData[14]));
                self.rowData[dataIndex].isUpdated = true;
                // const dataIndex = self.rowData[rowIndex].dataIndex;
                const existIndex = self.updatedRowsData.findIndex(item => item.regulationId == self.rowData[dataIndex].regulationId);
                if (self.updatedRowsData.length == 0) {
                  self.updatedRowsData.push(self.rowData[dataIndex]);
                } else {
                  if (existIndex == -1) {
                    self.updatedRowsData.push(self.rowData[dataIndex]);
                  }
                }
              }
            }
            if (changes[i][1] == 'action' || changes[i][1] == 'actionOwner' || changes[i][1] == 'actionStatus' || changes[i][1] == 'actionDueDate') {
              const oldValue = changes[i][2];
              const newValue = changes[i][3];
              if (newValue != oldValue) {
                const rowIndex = changes[i][0];
                const currentRowData = tableInstance.getDataAtRow(rowIndex);
                const dataIndex = self.rowData.findIndex(item => (item.regulationId == currentRowData[14]));
                self.rowData[dataIndex].isUpdated = true;
                // const dataIndex = self.rowData[rowIndex].dataIndex;
                const existIndex = self.updatedActionRowsData.findIndex(item => item.regulationId == self.rowData[dataIndex].regulationId);
                if (self.updatedActionRowsData.length == 0) {
                  self.updatedActionRowsData.push(self.rowData[dataIndex]);
                } else {
                  if (existIndex == -1) {
                    self.updatedActionRowsData.push(self.rowData[dataIndex]);
                  }
                }
              }
            }
          }
        }
      },
      cells(row, col, prop): any {
        // const cellProperties = this;
        // const data = this.instance.getData();
        // return cellProperties;
      },
      afterSelection: (row, column, row2, column2, preventScrolling, selectionLayerLevel) => {
        // setting if prevent scrolling after selection
        var currentRowData = self.hotRegisterer.getInstance(self.articleListTableId).getDataAtRow(row);
        var indexOfSelected: any;
        self.rowData.forEach((item, index) =>{
          if(item.regulationId == currentRowData[14]){
            self.currentDocumentReference = item.path;
          }
        });
        // if(self.rowData[row]){
        //   self.currentDocumentReference = self.rowData[row].path;
        //   // this.getArticleTree(this.rowData[row].regulationId)
        // }
        preventScrolling.value = false;
      },
      // afterScrollVertically() {
      //   const AutoRowSizePlugin = this.tableInstance.getPlugin('autoRowSize');
      //   const firstRowIndex = AutoRowSizePlugin.getFirstVisibleRow();
      //   this.currentDocumentReference = this.rowData[firstRowIndex - 1].path;
      // },
      beforeFilter(item) {
        // console.log(item, 'before filter')
        if(item && item.length){
          let col = item[0].column;
          let colName = '';
          let values = [];
          let colExist = self.filtersForDownloadDoc.findIndex(obj => obj.col == col);
          if(col == 0){
            colName =  'articles';
            item[0].conditions[0].args.forEach((obj) => {
              self.rowData.forEach((rowObj) => {
                if(rowObj.regulationNumber == obj){
                  values.push((rowObj.path).replaceAll(' -> ', '|'));
                }
              })
            })
          }
          if(col == 2){
            colName = 'isRelevant';
            item[0].conditions[0].args.forEach((filterval) => {
              let newVal = filterval == 'Yes' ? true : filterval == 'No' ? false : '';
              if(newVal != ''){
                values.push(newVal);
              }
            })
          }
          if(col == 3){
            colName = 'compliantStatus';
            item[0].conditions[0].args.forEach((filterval) => {
              let newVal = filterval == 'None' ? null : (filterval == 'Partial' ? 'partial' : (filterval == 'Full' ? 'full' : (filterval == 'Not Assessed' ? 'not_assessed' : (filterval == 'Not Applicable' ? 'not_applicable' : null))));
              values.push(newVal);
            })
          }
          if(colExist == -1){
            self.filtersForDownloadDoc.push({colName: colName, col: col, values: values})
          }else{
            self.filtersForDownloadDoc[colExist].values = values;
          }
          
        }
        // if(sessionStorage.getItem('dashboardArticleNavDocId')){
        //   sessionStorage.removeItem('dashboardArticleNavDocId');
        //   sessionStorage.removeItem('dashboardArticleNavKey');
        //   sessionStorage.removeItem('dashboardArticleNavVal');
        // }
      },
      // afterFilter(item) {
      //   console.log(item, 'after filter')
      //   // setTimeout(function() {
      //   //   self.showLoader = false;
      //   //   $('.articles-list-container').removeClass('displayNone');
      //   //   $('.htMenu.htDropdownMenu.handsontable').removeClass('noShow');
      //   // },1000)
      // },
      // afterDropdownMenuHide: function(instance) {
      //   if(instance.menu['hotMenu']) {
      //     self.showLoader = true;
      //     $('.htMenu.htDropdownMenu.handsontable').addClass('noShow');
      //     $('.articles-list-container').addClass('displayNone');
      //   }
      // },
      afterDropdownMenuShow: function(instance) {
        var filters: any = self.hotRegisterer.getInstance(self.articleListTableId).getPlugin('filters');
        filters.components.get('filter_by_value').elements[0].onClearAllClick({preventDefault: function() {}});
      },
      afterOnCellMouseDown: function(event, cords, td){
        document.getElementById('custom-col-pane').classList.remove('show');
        self.showNavMenu = false;
        if((cords.col == 0 || cords.col == 1) && cords.row != -1){
          var currentRowData = self.hotRegisterer.getInstance(self.articleListTableId).getDataAtRow(cords.row);
          var indexOfSelected: any;
          // self.rowData.forEach((item, index) =>{
          //   if(item.regulationNumber == currentRowData[0] && item.description == currentRowData[1] && self.currentDocumentReference == item.path){
          //     indexOfSelected = index;
          //   }
          // });
          if(cords.col == 0 && cords.row >= 0 || cords.col == 1 && cords.row >= 0) {
            self.hotRegisterer.getInstance(self.articleListTableId).deselectCell();
            self.goToArticleDetails(currentRowData[14]);
          }
        }else{
          // self.hotRegisterer.getInstance(self.articleListTableId).deselectCell();
        }
      }

    }
    this.showLoader = false;
    this.mathJaxService.renderEquation('list-formula-div');
    
  }

  updateTree(tree): any {
    let splitDoc = tree.title.split('|');

    tree['name'] = splitDoc[splitDoc.length - 1];
    if(tree.nodes){
      tree['children'] = tree.nodes;
      tree['children'] = tree.children.map((item) => {
        return this.updateTree(item);
      })
    }
    return tree;
  }

  labelClickForChecbox(index){
    let columnsIndex = [];
    if(this.articleListHotSettings.columns[index].isHidden){
      this.articleListHotSettings.columns[index].isHidden = false;
      for(let i = 0; i < this.articleListHotSettings['columns'].length; i++){
        if(this.articleListHotSettings.columns[i].isHidden){
          columnsIndex.push(i);
        }
      }
      
      this.hotRegisterer.getInstance(this.articleListTableId).updateSettings({
        hiddenColumns: {
          columns: columnsIndex
        }
      });
    }else{
      this.articleListHotSettings.columns[index].isHidden = true;
      // plugin.hideColumn(index);

      for(let i = 0; i < this.articleListHotSettings['columns'].length; i++){
        if(this.articleListHotSettings.columns[i].isHidden){
          columnsIndex.push(i);
        }
      }

      this.hotRegisterer.getInstance(this.articleListTableId).updateSettings({
        hiddenColumns: {
          columns: columnsIndex
        }
      });
    }
  }

  onCheckboxChange(e, index) {
    // const plugin = this.hotRegisterer.getInstance(this.articleListTableId).getPlugin('hiddenColumns');
    e.stopImmediatePropagation();
    // 
    const isChecked = e.target.checked
    let columnsIndex = [];
    if (isChecked) { // remove from hidden columns
      // plugin.showColumn(index);
      this.articleListHotSettings.columns[index].isHidden = false;
      for(let i = 0; i < this.articleListHotSettings['columns'].length; i++){
        if(this.articleListHotSettings.columns[i].isHidden){
          columnsIndex.push(i);
        }
      }
      
      this.hotRegisterer.getInstance(this.articleListTableId).updateSettings({
        hiddenColumns: {
          columns: columnsIndex
        }
      });
    } else {
      this.articleListHotSettings.columns[index].isHidden = true;
      // plugin.hideColumn(index);

      for(let i = 0; i < this.articleListHotSettings['columns'].length; i++){
        if(this.articleListHotSettings.columns[i].isHidden){
          columnsIndex.push(i);
        }
      }

      this.hotRegisterer.getInstance(this.articleListTableId).updateSettings({
        hiddenColumns: {
          columns: columnsIndex
        }
      });
      
    }
    // this.tableInstance.render();
    // this.tableInstance.updateSettings({
    //   hiddenColumns: {
    //     columns: [0]
    //   }
    // });
  }

}





  


  










