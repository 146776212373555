import { Component, OnInit, AfterContentChecked } from '@angular/core';
import { DataServiceService } from '../../shared/services/data-service/data-service.service';
import { environment } from '../../../environments/environment';
import Handsontable from 'handsontable';
import { ArticleDetailService } from '../article-detail/article-detail.service';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import {IAngularMyDpOptions, IMyDateModel} from 'angular-mydatepicker';
import { CookieService } from 'ngx-cookie';
import { ToastrService } from 'ngx-toastr';
import { Location } from '@angular/common';
import { PanZoomConfig, PanZoomAPI, PanZoomModel, PanZoomConfigOptions } from 'ngx-panzoom';
import { MathJaxService } from '../../shared/services/math-jax.service';


declare var $: any;
@Component({
  selector: 'app-reporting-element-detail',
  templateUrl: './reporting-element-detail.component.html',
  styleUrls: ['./reporting-element-detail.component.scss']
})
export class ReportingElementDetailComponent implements OnInit, AfterContentChecked {
  reportingElementDetailsObj: any = {assessment: {},rowName: null, colName: null, relevance: null, compliance: null, rowInstruction: null, colInstruction: null, actionsList: [],relatedImages:[]};
  relevanceOptions: Array<any> = [{label:'Yes', value:true, barColor: '#5aac44'},{label:'No', value:false, barColor: '#e6e6e6'},{label:'TBD', value:null, barColor: 'white'}];
  complianceOptions: Array<any> = [{label:'Partial', value: 'partial', barColor: '#ffcc00'},{label:'None', value: 'none', barColor: '#b32d00'},{label:'Full', value: 'full', barColor: '#008000'}];
  usersList: Array<any> = [];
  tableId_referenceReportingElementsForElementDetail: any = 'reporting-elements-reference-list-table';
  tableId_referenceReportingElements: any = 'reporting-element-reference-articles-reference-table';
  hotSettings_referenceReportingElementsForElementDetail: Handsontable.GridSettings;
  hotSettings_referenceReportingElements: Handsontable.GridSettings;
  referenceReportingElementsForElementDetailInstance: Handsontable;
  referenceReportingElementsInstance: Handsontable;
  selectedReportingElementId: any;
  selectedReportingElementDetailsObj: any = {};
  getParamData = {}
  actionsListData: Array<any> = [];
  versionRefReportingElement: any = null;
  actionStatusList: Array<any> = [{label: 'New', val: 'new'},{label: 'In Progress', val: 'in_progress'},{label: 'Completed', val: 'completed'}];
  panZoomConfig: PanZoomConfig = new PanZoomConfig();
  myDpOptions: IAngularMyDpOptions = {
    dateRange: false,
    dateFormat: 'dd.mm.yyyy'
    // other options are here...
  };
  editReportingElementFlag: boolean = false;
  tableId_reportingQNA: any = 'reporting-qna-table';
  reportingQNATableInstance: Handsontable;
  updatedQNAData: Array<any> = [];
  hotSettings_reportingQNA: Handsontable.GridSettings;
  reportingQnAData: Array<any> = [];
  actionDueDate: any = null;
  newActionObj: any = {
    action: null,
    actionOwnerId: null,
    dueDate: null,
    assignedBy: null,
    reportingElementId: null,
  };
  currentIndex: any;
  totalReferenceElements: any;
  editAssessmentFlag: boolean = false;
  clonedSelectedReportingElement: any = {};
  mockReportingElementObj: any = {};
  articleReferencesData: any = [];
  templateReferences: any = [];
  row_col_ref: any = [];
  getReportingDetailDataRow: any = {};
  getReportingDetailDataColumn: any= {};
  editAction: boolean = false;
  nodeOptions: any = {
    actionMapping: {
      mouse: {
      },
    }
  };

  constructor(
    private _dataService: DataServiceService,
    private _articleDetailService: ArticleDetailService,
    private route: ActivatedRoute,
    private _cookie: CookieService,
    private _toastr: ToastrService,
    private router: Router,
    private location: Location,
    private mathJaxService: MathJaxService,
    
  ) { }

  ngOnInit(): void {
    this.getUsersList();
    this.getParamData = this.route.snapshot.params;
    // this.getReportingElementDetails();
    //this.getRegulationDocumentReferences();
    this.reportingElementDetails();
    this.panZoomConfig.keepInBounds = true;
    this.panZoomConfig.freeMouseWheel = false;
    this.mathJaxService.renderEquation('formula-div');
  }

  getReportingElementDocVersionRef(){
    
    var url = environment.apiUrls.reportingElementVersionRefUrl;
     url = this.getParamData['_id'] ? url.replace(':reportingElementId', this.getParamData['_id'] ) : url
    this._dataService.getData(url, [], true).toPromise()
      .then((response: any) => {
          if (response.status == 1) {
            this.versionRefReportingElement = response.data.reportingElementId;
          }
      })
  }
  
  getTemplateReferences() {
     var url = environment.apiUrls.getRegulationTemplateReferences;
     url = this.getParamData['_id'] ? url.replace(':regulationId', this.getParamData['_id'] ) : url
    // url = this.getParamData['columnId'] ? url.replace(':columnId', this.getParamData['columnId'] ): url
     this._dataService.getData(url, [], true).toPromise()
      .then((response: any) => {
          if (response.status == 1) {
            this.reportingElementDetailsObj['templateReferences'] = response.data.templates;
          }
      })
  }

  navigateToDetailFromitemRef(reporting_element){
    const url = this.router.createUrlTree(['dashboard/reporting-element-detail', reporting_element]).toString();
    window.open(url, '_blank');
  }

  navigateToCorrespondingVersion(){
      const url = this.router.createUrlTree(['dashboard/reporting-element-detail', this.versionRefReportingElement]).toString();
      window.open(url, '_blank');
  }
  
  reportingElementDetails() {
    var url = environment.apiUrls.getReportingDetailData;
    url = this.getParamData['_id'] ? url.replace(':_id', this.getParamData['_id'] ) : url
    
    this._dataService.getData(url, [], true).toPromise()
    .then((response: any) => {
        if (response.status == 1) {
          this.getReportingDetailDataColumn = response.data.column; 
          this.getReportingDetailDataRow = response.data.row;
          this.reportingElementDetailsObj.relevance = response.data.isRelevant;
          this.reportingElementDetailsObj.templateCode = response.data.template.code;
          this.reportingElementDetailsObj.templateName = response.data.template.title;
          this.reportingElementDetailsObj.templateRef = response.data.document.title;
          this.reportingElementDetailsObj['documentName'] = response.data.document.title;
          this.reportingElementDetailsObj['documentReferences'] = [];
          this.reportingElementDetailsObj['templateReferences'] = [];
          this.reportingElementDetailsObj['regulationReferences'] = [];
          this.reportingElementDetailsObj['assessment'] = {};
          this.reportingElementDetailsObj['additionalDetailsSubHeading'] = response.data.row.subheading ? response.data.row.subheading : response.data.column.subheading ? response.data.column.subheading : '';
          this.reportingElementDetailsObj.assessment = {
            comment: ' ',
            assessmentId: null,
            regulationId: null,
            reportingElementId: this.getParamData['_id'],
            assessmentOwnerId: null
          }
          this.reportingElementDetailsObj.relatedImages = [];
          this.reportingElementDetailsObj.relatedImages = [...response.data.row.images, ...response.data.column.images];
          this.reportingElementDetailsObj.regartComments = [...response.data.column.regartComments, ...response.data.row.regartComments];
          this.getTemplateReferences();
          this.getDocumentReferences();
          this.getArticleReferences();
          this.getReportingElementReferences();
          this.getAssessmentForElement();
          this.getReportingElementsActions();
          this.getReportingElementDocVersionRef();
          this.getReportingQNA()
          this.mathJaxService.renderEquation('formula-div');
        }
    }, (error) => {
        if (error.error) {
          // this._toastr.error(error.error.message);
        }
    });
  }

  getReportingQNA() {
    var url = environment.apiUrls.qnaReportingUrl;
    url = url.replace(':reportingElementId', this.getParamData['_id']);
    this.updatedQNAData = [];

    this._dataService.getData(url, [], true).toPromise()
      .then((response: any) => {
        if(response.status == 1){
          response.data.response.forEach((item) => {
            item.questionIdShow = item.question_id + item.question_item;
            

            item.isRelevant = item.isRelevant == true ? 'Yes' : 'No';
              
          })

          this.reportingQnAData = response.data.response;
          if(this.reportingQNATableInstance){
            this.reportingQNATableInstance.destroy();
          }
          setTimeout(() => {this.prepareReportingQNATable()},1000)
          
        }
      }, (error) => {

      });
  }

  updateQNArelevance(): any {
    let dataList = [];
    this.updatedQNAData.forEach((item) => {
    let obj = {
      isRelevant: item.isRelevant == 'Yes' ? true : false,
      comment: item.comment,
      _id: item._id
      }

      dataList.push(obj);
    })

    let patchPromise = [];
    dataList.forEach((item) => {
        let url = environment.apiUrls.reportingQnAUpdateUrl;
        url = url.replace(':itsQnaId', item._id);
        let getItemPromise = this._dataService.patchData(url, {isRelevant: item.isRelevant, comment: item.comment}, true).toPromise();
        patchPromise.push(getItemPromise);
    });

    Promise.all(patchPromise).then((res) => {
      res.forEach((obj: any) => {
        if (obj.status == 1) {
          this.updatedQNAData = [];
        }})
      });

    
  }

  getAssessmentForElement(): any {
    var url = environment.apiUrls.getElementsAssessment;
    url = url.replace(':reportingElementId', this.getParamData['_id']);
    
    this._dataService.getData(url, [], true).toPromise()
    .then((response: any) => {
        if (response.status == 1 && response.data.length) {
            this.reportingElementDetailsObj.assessment.comment = response.data[0].comment;
            this.reportingElementDetailsObj.assessment.assessmentId = response.data[0]._id;
            this.reportingElementDetailsObj.assessment.assessmentOwnerId = response.data[0].assessmentOwnerId;
        }
    }, (error) => {
        if (error.error) {
          // this._toastr.error(error.error.message);
        }
    });
  }

  ngAfterContentChecked(): any{
    this.mathJaxService.renderEquation('formula-div');
    
  }

  getDocumentReferences(): any {
    var url = environment.apiUrls.getRegulationsDocumentReferences;
    url = url.replace(':reportingElementId', this.getParamData['_id']);

    this._dataService.getData(url, [], true).toPromise()
    .then((response: any) => {
        if (response.status == 1) {
          this.reportingElementDetailsObj['documentReferences'] = response.data.documents;
        }
      }, (error) => {
          if (error.error) {
            // this._toastr.error(error.error.message);
          }
    });

  }

  getArticleReferences(): any {
    var url = environment.apiUrls.getRegulationRegulationReferences;
    url = url.replace(':regulationId', this.getParamData['_id']);

    this._dataService.getData(url, [], true).toPromise()
    .then((response: any) => {
        if (response.status == 1) {
          this.reportingElementDetailsObj['regulationReferences'] = response.data.regulations;
          this.reportingElementDetailsObj['regulationReferences'].forEach((item) => {
            if(item.actionDueDate){
              var tempSplitDate = item.actionDueDate.split('T');
              var tempDisplayDate = tempSplitDate[0].split('-');
              item.displayDueDate = tempDisplayDate[2] + '-' + tempDisplayDate[1]  + '-' + tempDisplayDate[0];
            }
            
            let obj = {
              id: item._id,
              articleNo: item.regulationNumber,
              fullReference: item.path,
              text: item.description,
              isRelevant: item.isRelevant == true ? 'Yes' : 'No',
              compliantStatus: item.compliantStatus == 'partial' ? 'Partial' : item.compliantStatus == 'full' ? 'Full' : item.compliantStatus == 'not_assessed' ? 'Not Assessed': 'None',
              assessmentComment: item.assessment,
              action: item.action,
              actionStatus: item.actionStatus == 'in_progress' ? 'In Progress' : item.actionStatus == 'completed' ? 'Completed' : item.actionStatus == 'new' ? 'New' : '',
              dueDate: item.actionDueDate ? item.displayDueDate : ''

            }

            if(item.assessmentOwnerId){
              this.usersList.forEach((userObj) => {
                if(userObj.id == item.assessmentOwnerId){
                  obj['assessmentOwner'] = userObj.name;
                }
              })
            }else{
              obj['assessmentOwner'] = '';
            }
            if(item.actionOwnerId){
              this.usersList.forEach((userObj) => {
                if(userObj.id == item.actionOwnerId){
                  obj['actionOwner'] = userObj.name;
                }
              })
            }else{
              obj['actionOwner'] = '';
            }

            

            this.articleReferencesData.push(obj);
            this.articleReferencesData.sort(this.compare);
            // this.articleReferencesData =this.unique(this.articleReferencesData)
            // this.prepareReferenceTable();.

            // let getPromise = [];
            // response.data.regulations.forEach((item) => {
            //   let url = environment.apiUrls.getAssessmentDetailUrl;
            //   url = url.replace(':regulationId', item._id);
            //   let getItemPromise = this._dataService.getData(url, [], true).toPromise();
            //   getPromise.push(getItemPromise);
            // });

            // Promise.all(getPromise).then((res) => {
            //   res.forEach((obj: any) => {
            //     if (obj.status == 1) {
            //       let nodesData = [];
            //       let data = this.updateTree(obj.data.currentRegulation.regulations);
            //       nodesData.push(data);
            //       let regulationIndex = this.articleReferencesData.findIndex((item)=>item.id == obj.data.currentRegulation.regulations._id);
            //       if(regulationIndex != -1){
            //         this.articleReferencesData[regulationIndex].nodeData = nodesData;
            //       }
            //     }
            //   });
            // });
            

          })

          let getPromise = [];
            response.data.regulations.forEach((item) => {
              let url = environment.apiUrls.getAssessmentDetailUrl;
              url = url.replace(':regulationId', item._id);
              let getItemPromise = this._dataService.getData(url, [], true).toPromise();
              getPromise.push(getItemPromise);
            });

            Promise.all(getPromise).then((res) => {
              res.forEach((obj: any) => {
                if (obj.status == 1) {
                  let nodesData = [];
                  let data = this.updateTree(obj.data.currentRegulation.regulations);
                  nodesData.push(data);
                  let regulationIndex = this.articleReferencesData.findIndex((item)=>item.id == obj.data.currentRegulation.regulations._id);
                  if(regulationIndex != -1){
                    this.articleReferencesData[regulationIndex].nodeData = nodesData;
                  }
                }
              });
            });
        }
      }, (error) => {
          if (error.error) {
            // this._toastr.error(error.error.message);
          }
    });

  }

  updateTree(tree): any {
    if (tree.text == null) {
        tree['name'] = tree.meta[0] + ' - ' + tree.meta[1];
        if(tree.meta.length > 2){
          tree['name'] = tree['name'] + '\n' + ' ';
          for(let i = 2; i < tree.meta.length; i++){
            tree['name'] = tree['name'] + tree.meta[i] + (i != tree.meta.length - 1 ? ' - ' : '');
          }
          tree['name'] = tree['name'].replace(/\n|\r\n|\r/g, '<br/>')
        }
    }else{
      let text = tree.text.replace(/\n|\r\n|\r/g, '<br/>')
      tree['name'] = text;
    }
    if(tree.nodes){
      tree['children'] = tree.nodes;
      tree['children'] = tree.children.map((item) => {
        return this.updateTree(item);
      })
    }
    return tree;
}

   unique(array) {
    var flags = [], output = [], l = array.length, i;
    for( i=0; i<l; i++) {
        if( flags[array[i].articleNo]) continue;
        flags[array[i].articleNo] = true;
        output.push(array[i]);
    }
    return [...output];
   }

   compare(a, b) {
    // Use toUpperCase() to ignore character casing
    const bandA = a.articleNo.toUpperCase();
    const bandB = b.articleNo.toUpperCase();
  
    let comparison = 0;
    if (bandA > bandB) {
      comparison = 1;
    } else if (bandA < bandB) {
      comparison = -1;
    }
    return comparison;
  }

  navigateToDocument(docInfo) {
    if(docInfo.link) {
      window.open(docInfo.link,'_blank');
    }
    else {
      if(docInfo.type === "ITS") {
        this.router.navigate(['dashboard/reporting-elements-list', {docId: docInfo.id}]);
      } else {
        localStorage.setItem('dashboardArticleNavTopicId', docInfo.regulatoryTopics[0]);
        localStorage.setItem('dashboardArticleNavCategoryId', docInfo.category);
        localStorage.setItem('dashboardArticleNavDocId', docInfo.id);
        this.router.navigate(['dashboard/article-list']);
      }
    }
  }
  
  saveAssessment(): any{
    this.reportingElementDetailsObj.assessment.assignedBy = this._cookie.get("_id");;
   
    if(this.reportingElementDetailsObj.assessment.assessmentId == null){
      let url = environment.apiUrls.postReportingElementAssessment;
      
      this._dataService.postData(url, this.reportingElementDetailsObj.assessment, true).toPromise()
      .then((response:any) => {
        if(response.status == 1){
          this._toastr.success(response.message);
          this.editReportingElementFlag = false;
        }else{
          this._toastr.error(response.message.message);
        }
      }, (error) => {

      });
    }else{

      let url = environment.apiUrls.updateReportingElementAssessment;
      url = url.replace(':assessmentId', this.reportingElementDetailsObj.assessment.assessmentId)
      this._dataService.patchData(url, this.reportingElementDetailsObj.assessment, true).toPromise()
      .then((response:any) => {
        if(response.status == 1){
          this._toastr.success(response.message);
          this.editReportingElementFlag = false;
        }else{
          this._toastr.error(response.message.message);
        }
      }, (error) => {

      });

    }

  }

  navigateArticleDetail(item) {
    if(item.id) {
      const url = this.router.createUrlTree(['dashboard/article-detail', item.id]).toString();
      window.open(url, '_blank');
    }
  }

  navigateToReportingList(templateInfo): any {
    this.router.navigate(['dashboard/reporting-elements-list', {docId:templateInfo.documentId, templateId: templateInfo._id}]);
    // window.open(url, "_blank");
  }

  navigateToReportingDetail(): any {
    let url = this.router.createUrlTree(['dashboard/reporting-element-detail']).toString();
    window.open(url, "_blank");
  }

  navigateToReportingReferences(ele): any {
    if(ele.column && ele.row){
      const url = this.router.createUrlTree(['dashboard/reporting-element-detail', ele.reportingElement._id]).toString();
      window.open(url, '_self');
    }else{
      if(ele.row){
        const url =  this.router.createUrlTree(['dashboard/reporting-elements-list', {docId:ele.template.documentId, templateId: ele.template._id}]).toString();
        window.open(url, '_self');
      }

      if(ele.column){
        const url = this.router.createUrlTree(['dashboard/reporting-elements-list', {docId:ele.template.documentId, templateId: ele.template._id}]).toString();
        window.open(url, '_self');
      }
    }
    
  }

  updateSelectedActionModal(item){
    this.editAction = true;
    this.newActionObj['action'] = item.action;
    if(item.dueDate){
      this.actionDueDate = {};
      this.actionDueDate['singleDate'] = {};
      this.actionDueDate.singleDate['jsDate'] = new Date(item.dueDate);
  
      let tempDate = item.dueDate.split('T');
      let tempFormatDate = tempDate[0];
      let tempFormatDateArray = tempFormatDate.split('-');
      let formattedDate = tempFormatDateArray[2]+'.'+tempFormatDateArray[1]+'.'+tempFormatDateArray[0];
      this.actionDueDate.singleDate['formatted'] = formattedDate;
    }else{
      this.actionDueDate = null;
    }
    this.newActionObj.status = item.status;

    this.newActionObj['actionOwnerId'] = item.actionOwnerId;
    this.newActionObj['id'] = item._id;
    $("#addNewActionModal").modal('show');
  }

  updateAction(){
    var url = environment.apiUrls.newActionForAssessmentUrl + this.newActionObj.id;

    this.newActionObj.assignedBy = this._cookie.get("_id");
    
    if(this.actionDueDate && this.actionDueDate.singleDate){
      var actionDate = this.actionDueDate.singleDate.formatted.split('.');
      this.newActionObj.dueDate = actionDate[0]+'-'+actionDate[1]+'-'+actionDate[2];
    }
    this.newActionObj.reportingElementId = this.getParamData['_id'];

    this._dataService.patchData(url, this.newActionObj, true).toPromise()
    .then((response: any) => {
      if(response.status == 1){
        this.newActionObj = {
          action: null,
          actionOwnerId: null,
          dueDate: null,
          assignedBy: null,
          status: null
        }
          this.actionDueDate = null;
          this.getReportingElementsActions();
          this._toastr.success(response.message);
        }else{
          this._toastr.error(response.message.message);
        }
    },(error) => {
      if(error.error){
        this._toastr.error(error.error.message);
    }
    });
  }

  addNewAction(): any {
    this.editAction = false;
    this.newActionObj.reportingElementId = this.getParamData['_id'];
    this.newActionObj.assignedBy = this._cookie.get("_id");
    var actionDate = this.actionDueDate.singleDate.formatted.split('.');
    this.newActionObj.dueDate = actionDate[0]+'-'+actionDate[1]+'-'+actionDate[2];

    var url = environment.apiUrls.postReportingEleActions;

    this._dataService.postData(url, this.newActionObj, true).toPromise()
      .then((response:any) => {
        if(response.status == 1){
          this.newActionObj = {
            action: null,
            actionOwnerId: null,
            dueDate: null,
            assignedBy: null,
            assreportingElementIdessmentId: null,
          }
          this.getReportingElementsActions();
          this._toastr.success(response.message);
        }else{
          this._toastr.error(response.message.message);
        }
      }, (error) => {

      });
  }

  deleteAction(id): any{
    let url = environment.apiUrls.deleteAction;
    url = url.replace(':actionId', id);
  
    this._dataService.deleteData(url, true).toPromise()
      .then((response: any) => {
        if(response.status == 1){
          this.getReportingElementsActions();
          this._toastr.success('Action deleted');
        }
      }, (error: any) =>{
        this._toastr.error(error.error.message);
      })
  }

  getReportingElementsActions(): any {
    var url = environment.apiUrls.getReportingEleActions;
    url = url.replace(':reportingElementId', this.getParamData['_id']);

    this._dataService.getData(url, [], true).toPromise()
      .then((response: any) => {
        if(response.status == 1){
          this.actionsListData = response.data;
          this.actionsListData.forEach((item) => {
            if(item.dueDate){
              var tempSplitDate = item.dueDate.split('T');
              var tempDisplayDate = tempSplitDate[0].split('-');
              item.displayDueDate = tempDisplayDate[2] + '-' + tempDisplayDate[1]  + '-' + tempDisplayDate[0];
            
            }
          });
        }
      }, (error) => {

      });
  }

  getUsersList(): void {
    var url = environment.apiUrls.getUsersUrl;

    this._dataService.getData(url, [], true).toPromise()
        .then((response: any) => {
            if (response.status == 1) {
                this.usersList = [];
                response.data.users.forEach((obj) => {
                    var userObj = {
                        name: obj.firstName + ' ' + obj.lastName,
                        id: obj._id
                    };
                    this.usersList.push(userObj);
                });
            }
        }, (error) => {
            if (error.error) {
                // this._toastr.error(error.error.message);
            }
        });
}

  updateRelevanceForElement(value): any {
    const url = environment.apiUrls.getReportingRelevance + "/" + this.getParamData['_id'] +"/"
    const data = {isRelevant : value};
    
    this._dataService.patchData(url,data,true).toPromise()
    .then((response: any) => {
      if(response.status == 1){
        this._toastr.success(response.message);
      }
    })
  }

  uniqueReportingRefs(array) {
    var flags = [], output = [], l = array.length, i;
    for( i=0; i<l; i++) {
      if(array[i].column){
        if(  flags[array[i].column.identifier]) continue;
        flags[array[i].column.identifier] = true;
        output.push(array[i]);
      }
      if(array[i].row){
        if(  flags[array[i].row.identifier]) continue;
        flags[array[i].row.identifier] = true;
        output.push(array[i]);
      }
    }
    return [...output];
   }

  getReportingElementReferences(): any{
    var url = environment.apiUrls.getReportingElementReferences;
    url = url.replace(':reportingElementId', this.getParamData['_id']);

    this._dataService.getData(url, [], true).toPromise()
      .then((response: any) => {
        if(response.status == 1){
          this.row_col_ref = [];
          this.row_col_ref = this.uniqueReportingRefs(response.data.reportingElements);
          this.row_col_ref.forEach((item) => {
            if(item.column){
              item.column.expandText = false;
            }
            if(item.row){
              item.row.expandText = false;
            }
            if(item.column && item.column.instruction){
              if(item.column.instruction.length > 200){
                item.column.instructionDisplay = item.column.instruction.substring(0, 200) + '...';
              }
            }

            if(item.row && item.row.instruction){
              if(item.row.instruction.length > 200){
                item.row.instructionDisplay = item.row.instruction.substring(0, 200) + '...';
              }
            }
          })
        }
      }, (error) => {

      });
  }

  prepareReportingQNATable(): any{
    if(this.reportingQNATableInstance){
      this.reportingQNATableInstance.destroy();
    }
    var self = this;
    this.hotSettings_reportingQNA = {
      width: '100%',
      rowHeights: 50,
      columnHeaderHeight: 50,
      stretchH: 'all',
      data: this.reportingQnAData,
      licenseKey: 'non-commercial-and-evaluation',
      columns: this._articleDetailService.reportingQNATableColumns,
      afterChange: (changes, src) => {
        if (changes && changes.length) {
          for (let i = 0; i < changes.length; i++) {
            if ((changes[i][1] == 'isRelevant' || changes[i][1] == 'comment')) {
              const oldValue = changes[i][2];
              const newValue = changes[i][3];
              if (newValue != oldValue) {
                const rowIndex = changes[i][0];
                const existIndex = self.updatedQNAData.findIndex(item => item.regulationId == self.reportingQnAData[rowIndex].regulationId);
                if (self.updatedQNAData.length == 0) {
                  self.updatedQNAData.push(self.reportingQnAData[rowIndex]);
                } else {
                  if (existIndex == -1) {
                    self.updatedQNAData.push(self.reportingQnAData[rowIndex]);
                  }
                }
              }
            }
            
          }
        }
      },
      cells(row, col, prop) {
        const cellProperties = this;
        const data = this.instance.getData();

        
        

        // if(col == 5){
        //   if(data[row] && data[row][4] && data[row][4] === 'No'){
        //     this.instance.setCellMeta(row, col, 'className', 'grayed-out' );
        //     cellProperties.readOnly = true;
        //   }else{
        //     cellProperties.readOnly = false;
        //     this.instance.setCellMeta(row, col, 'className', '');
        //   }
        //  }

        if(col == 1 && row >= 0){
          this.renderer = function(instance, td, row, col, prop, value, cellProperties) {
            Handsontable.renderers.TextRenderer.apply(this, arguments);
            td.title = value;
            return td;
          }
        }

        // if(col == 2 && row >= 0){
        //   this.renderer = function(instance, td, row, col, prop, value, cellProperties) {
        //     Handsontable.renderers.TextRenderer.apply(this, arguments);
        //     // td.title = value;
        //     const currentRowData = instance.getDataAtRow(row);
        //     // if (value) {
        //     //     const currentRowData = instance.getDataAtRow(row);
        //     //     if (value.length > 150) {
        //     //     const wrapper = document.createElement('div');
        //     //     const div1 = document.createElement('div');
        //     //     const div2 = document.createElement('div');
                
                
        //     //     div1.innerHTML = value.substring(0, 150) + '...';
        //     //     div2.style.cssText = 'margin-left:4px;';
        //     //     wrapper.style.cssText = 'display:flex';
    
        //     //     div1.setAttribute('id', `indirecttext${value}`)
                
        //     //     const btnWrapper = document.createElement('div');
        //     //     const btnShowMore = document.createElement('button')
        //     //     btnShowMore.innerText = 'Show More';
        //     //     btnShowMore.setAttribute('id', `indirectshowMore${value}`) 
        //     //     btnShowMore.style.cssText = 'cursor:pointer;border:none;outline:none;color:#a5473e;background:none;';
    
        //     //     const btnShowLess = document.createElement('button')
        //     //     btnShowLess.innerText = 'Show Less';
        //     //     btnShowLess.setAttribute('id', `indirectshowLess${value}`) 
        //     //     btnShowLess.style.cssText = 'cursor:pointer;border:none;outline:none;color:#a5473e;background:none;display:none;';
    
                
        //     //     btnShowMore.addEventListener('click', function(event){
        //     //       document.getElementById(`indirectshowLess${value}`).style.display = 'block';
        //     //       document.getElementById(`indirectshowMore${value}`).style.display = 'none';
        //     //       div1.innerHTML = value;
        //     //       let parentEle:any = document.getElementById('reporting-qna-table');
        //     //       let tableContainerHeight = parentEle.querySelector('.htCore').scrollHeight
        //     //       parentEle.style.height = tableContainerHeight + 'px';
                  
        //     //     })
    
        //     //     btnShowLess.addEventListener('click', function(event){
        //     //       document.getElementById(`indirectshowMore${value}`).style.display = 'block';
        //     //       document.getElementById(`indirectshowLess${value}`).style.display = 'none';
        //     //       div1.innerHTML = value.substring(0, 150) + '...';
        //     //       let parentEle:any = document.getElementById('reporting-qna-table');
        //     //       let tableContainerHeight = parentEle.querySelector('.htCore').scrollHeight
        //     //       parentEle.style.height = tableContainerHeight + 'px';
                 
        //     //     })
    
        //     //     btnWrapper.appendChild(btnShowMore);
        //     //     btnWrapper.appendChild(btnShowLess);
                
        //     //     const parentWrapper = document.createElement('div');
    
        //     //     wrapper.appendChild(div1);
        //     //     wrapper.appendChild(div2);
    
        //     //     parentWrapper.appendChild(wrapper);
        //     //     parentWrapper.appendChild(btnWrapper);
    
        //     //     Handsontable.dom.empty(td);
        //     //     td.appendChild(parentWrapper);
        //     //   } else {
        //     //     const wrapper = document.createElement('div');
        //     //     const div1 = document.createElement('div');
        //     //     const div2 = document.createElement('div');
        //     //     div1.innerHTML = value;
        //     //     div2.style.cssText = 'margin-left:4px;';
        //     //     wrapper.style.cssText = 'display:flex';
        //     //     wrapper.appendChild(div1);
        //     //     wrapper.appendChild(div2);
        //     //     Handsontable.dom.empty(td);
        //     //     td.appendChild(wrapper);
        //     //   }
        //     // }
        //     return td;
        //   }
        // }

        if(col == 4 && row >= 0){
          this.renderer = function(instance, td, row, col, prop, value, cellProperties) {
            if(value || value == null){
              if(value == 'Yes'){
                let wrapper = document.createElement('div');
                let div1 = document.createElement('div');
                let div2 = document.createElement('div');
                const divP = document.createElement('div');
                const divIconDrop = document.createElement('div');
                divIconDrop.className = 'htAutocompleteArrow';
                divIconDrop.innerHTML = '▼';
                div2.innerHTML = value;
                div1.style.cssText = 'width:4px;border-radius:4px;margin-right:4px;background:#008000';
                wrapper.style.cssText = 'display:flex;justify-content:space-between;';
                divP.style.cssText = 'display:flex;';  
                divP.appendChild(div1);
                divP.appendChild(div2);
                wrapper.appendChild(divP);
                wrapper.appendChild(divIconDrop);
                Handsontable.dom.empty(td);
                td.appendChild(wrapper);
    
              }
    
              if(value == 'No'){
                let wrapper = document.createElement('div');
                let div1 = document.createElement('div');
                let div2 = document.createElement('div');
                const divP = document.createElement('div');
                const divIconDrop = document.createElement('div');
                divIconDrop.className = 'htAutocompleteArrow';
                divIconDrop.innerHTML = '▼';
                div2.innerHTML = value;
                div1.style.cssText = 'width:4px;border-radius:4px;margin-right:4px;background:#b32d00';
                wrapper.style.cssText = 'display:flex;justify-content:space-between;';
                divP.style.cssText = 'display:flex;';  
                divP.appendChild(div1);
                divP.appendChild(div2);
                wrapper.appendChild(divP);
                wrapper.appendChild(divIconDrop);
                Handsontable.dom.empty(td);
                td.appendChild(wrapper);
              }

              if(value == null){
                let wrapper = document.createElement('div');
                let div1 = document.createElement('div');
                let div2 = document.createElement('div');
                const divP = document.createElement('div');
                const divIconDrop = document.createElement('div');
                divIconDrop.className = 'htAutocompleteArrow';
                divIconDrop.innerHTML = '▼';
                div2.innerHTML = 'No';
                div1.style.cssText = 'width:4px;border-radius:4px;margin-right:4px;background:#b32d00';
                wrapper.style.cssText = 'display:flex;justify-content:space-between;';
                divP.style.cssText = 'display:flex;';  
                divP.appendChild(div1);
                divP.appendChild(div2);
                wrapper.appendChild(divP);
                wrapper.appendChild(divIconDrop);
                Handsontable.dom.empty(td);
                td.appendChild(wrapper);
              }
            }else{
                Handsontable.renderers.TextRenderer.apply(this, arguments);
            }
    
            return td;
          }
        }

        if(col == 6 && row >= 0){
          this.renderer = function(instance, td, row, col, prop, value, cellProperties) {
            if(value && value.length){
              let wrapper = document.createElement('div')
              for(let i=0; i< value.length; i++) {
                let a = document.createElement('a');
                let link = document.createTextNode(value[i]);
                a.appendChild(link);
                a.href = value[i];
                a.title = value[i];
                a.target = '_blank';
                wrapper.style.cssText = 'color:lightblue;cursor:pointer;'
                wrapper.appendChild(a);
              
              }
              
              
              td.appendChild(wrapper);
                
                Handsontable.dom.empty(td);
                td.appendChild(wrapper);
    
              
    
              

              
            }else{
                Handsontable.renderers.TextRenderer.apply(this, arguments);
            }
    
            return td;
          }
        }



        // if(col == 4 && row >= 0){
        //   this.renderer = function(instance, td, row, col, prop, value, cellProperties) {
        //     if(value){
        //       const currentRowData = instance.getDataAtRow(row);
        //       if(value == 'Partial'){
        //         let wrapper = document.createElement('div');
        //         let div1 = document.createElement('div');
        //         let div2 = document.createElement('div');
        //         const divP = document.createElement('div');
        //         const divIconDrop = document.createElement('div');
        //         divIconDrop.className = 'htAutocompleteArrow';
        //         divIconDrop.innerHTML = '▼';
        //         div2.innerHTML = value;
        //         div1.style.cssText = 'width:4px;border-radius:4px;margin-right:4px;background:#ffcc00';
        //         wrapper.style.cssText = 'display:flex;justify-content:space-between;';
        //         divP.style.cssText = 'display:flex;';  
        //         divP.appendChild(div1);
        //         divP.appendChild(div2);
        //         wrapper.appendChild(divP);
        //         wrapper.appendChild(divIconDrop);
        //         if (currentRowData[3] == 'No') {
        //           td.style.background = '#e6e6e6';
        //         }
        //         Handsontable.dom.empty(td);
        //         td.appendChild(wrapper);
    
        //       }
    
        //       if(value == 'None'){
        //         let wrapper = document.createElement('div');
        //         let div1 = document.createElement('div');
        //         let div2 = document.createElement('div');
        //         const divP = document.createElement('div');
        //         const divIconDrop = document.createElement('div');
        //         divIconDrop.className = 'htAutocompleteArrow';
        //         divIconDrop.innerHTML = '▼';
        //         div2.innerHTML = value;
        //         div1.style.cssText = 'width:4px;border-radius:4px;margin-right:4px;background:#b32d00';
        //         wrapper.style.cssText = 'display:flex;justify-content:space-between;';
        //         divP.style.cssText = 'display:flex;';  
        //         divP.appendChild(div1);
        //         divP.appendChild(div2);
        //         wrapper.appendChild(divP);
        //         wrapper.appendChild(divIconDrop);
        //         if (currentRowData[3] == 'No') {
        //           td.style.background = '#e6e6e6';
        //         }
        //         Handsontable.dom.empty(td);
        //         td.appendChild(wrapper);
        //       }
    
        //       if(value == 'Full'){
        //         let wrapper = document.createElement('div');
        //         let div1 = document.createElement('div');
        //         let div2 = document.createElement('div');
        //         const divP = document.createElement('div');
        //         const divIconDrop = document.createElement('div');
        //         divIconDrop.className = 'htAutocompleteArrow';
        //         divIconDrop.innerHTML = '▼';
        //         div2.innerHTML = value;
        //         div1.style.cssText = 'width:4px;border-radius:4px;margin-right:4px;background:#008000';
        //         wrapper.style.cssText = 'display:flex;justify-content:space-between;';
        //         divP.style.cssText = 'display:flex;';  
        //         divP.appendChild(div1);
        //         divP.appendChild(div2);
        //         wrapper.appendChild(divP);
        //         wrapper.appendChild(divIconDrop);
        //         if (currentRowData[3] == 'No') {
        //           td.style.background = '#e6e6e6';
        //         }
        //         Handsontable.dom.empty(td);
        //         td.appendChild(wrapper);
        //       }
    
        //       if(value == 'Not Assessed'){
        //         let wrapper = document.createElement('div');
        //         let div1 = document.createElement('div');
        //         let div2 = document.createElement('div');
        //         const divP = document.createElement('div');
        //         const divIconDrop = document.createElement('div');
        //         divIconDrop.className = 'htAutocompleteArrow';
        //         divIconDrop.innerHTML = '▼';
        //         div2.innerHTML = value;
        //         div1.style.cssText = 'width:4px;border-radius:4px;margin-right:4px;background:grey';
        //         wrapper.style.cssText = 'display:flex;justify-content:space-between;';
        //         divP.style.cssText = 'display:flex;';  
        //         divP.appendChild(div1);
        //         divP.appendChild(div2);
        //         wrapper.appendChild(divP);
        //         wrapper.appendChild(divIconDrop);
        //         if (currentRowData[3] == 'No') {
        //           td.style.background = '#e6e6e6';
        //         }
        //         Handsontable.dom.empty(td);
        //         td.appendChild(wrapper);
        //       }

        //       if (value == 'Not Applicable') {
        //         const wrapper = document.createElement('div');
        //         const div1 = document.createElement('div');
        //         const div2 = document.createElement('div');
        //         const divP = document.createElement('div');
        //         const divIconDrop = document.createElement('div');
        //         divIconDrop.className = 'htAutocompleteArrow';
        //         divIconDrop.innerHTML = '▼';
        //         div2.innerHTML = value;
        //         div1.style.cssText = 'width:4px;border-radius:4px;margin-right:4px;background:white';
        //         wrapper.style.cssText = 'display:flex;justify-content:space-between;';
        //         divP.style.cssText = 'display:flex;';  
        //         divP.appendChild(div1);
        //         divP.appendChild(div2);
        //         wrapper.appendChild(divP);
        //         wrapper.appendChild(divIconDrop);
        //         if (currentRowData[3] == 'No') {
        //           td.style.background = '#e6e6e6';
        //         }
        //         Handsontable.dom.empty(td);
        //         td.appendChild(wrapper);
        //       }
        //     }else{
        //         Handsontable.renderers.TextRenderer.apply(this, arguments);
        //     }
    
        //     return td;
        //   }
        // }
        
        return cellProperties;
      },
    };
    const container = document.getElementById(this.tableId_reportingQNA);
    this.reportingQNATableInstance = new Handsontable(container, this.hotSettings_reportingQNA);
  }

   

  
}
