<div class="articles-list-view-container">
    <div>
        <!--header-->
        <div class="">
            <h4 class="requlatory-title">
                Regulatory Topics
            </h4>
        </div>
        <div>
            <div class="row">
                <ng-container *ngFor = "let item of regulatoryTopicsList">
                    <div class="col-lg-4" *ngIf="item.title != 'Checklist'">
                        <a [ngClass]="item.isActive ? '': 'isDisabled'" (click)="goToArticleListForTopic(item)" 
                            class="btn-requlatoryTab btn-block-requlatoryTab">
                            {{item.title}}
                        </a>
                    </div>
                </ng-container>
            </div>
        </div>
        <div style="margin-top:30px;" >
            <div style="border-radius: 8px;display: flex;
            border: 1px solid #ebedf8;
            background-color: #ffffff;
            padding: 10px;">
                <div class="regulatory-items-rag" *ngIf="nodesData && nodesData.length"  style="width: 100%;">
                
                    <tree-root *ngIf="nodesData" [nodes]="nodesData" >
                    <ng-template #treeNodeTemplate let-node let-index="index">
                        <div *ngIf="node.data.title == 'head1'"  style="display: flex;justify-content: space-between;">
                            <div>
                            <table>
                                <tr>
                                    <td>
                                    </td>
                                </tr>
                            </table>
                            </div>
                            <div style="display: inline-flex;">
                            <table style="margin-left:5px;">
                                <tr>
                                    <td>
                                    </td>
                                </tr>
                            </table>
                            <table style="margin-left:5px;width:180px;">
                                <tr>
                                    <td style="text-align:center;font-weight:bold;font-family:'Montserrat Medium';font-size:12px;">
                                        #Article Relevance
                                    </td>
                                </tr>
                            </table>
                            <table style="margin-left:5px;width:185px;">
                                <tr >
                                    <td style="text-align:center;font-weight:bold;font-family:'Montserrat Medium';font-size:12px;">
                                        #Article Compliance
                                    </td>
                                </tr>
                            </table>
                            <table style="margin-left:5px;width:185px;">
                                <tr >
                                    <td style="text-align:center;font-weight:bold;font-family:'Montserrat Medium';font-size:12px;">
                                        #Article Actions
                                    </td>
                                </tr>
                            </table>
                            </div>
                        </div>
                        <div *ngIf=" node.data.title == 'head2'"  style="display: flex;justify-content: space-between;">
                            <div>
                            <table>
                                <tr>
                                    <td>
                                    </td>
                                </tr>
                            </table>
                            </div>
                            <div style="display: inline-flex;">
                            <table style="margin-left:5px;">
                                <tr>
                                    <td>
                                    </td>
                                </tr>
                            </table>
                            <table style="margin-left:5px;width:180px;">
                                <tr >
                                    <td style="text-align:center;font-family:'Montserrat Medium';font-size:10px;">
                                        Yes
                                    </td>
                                </tr>
                            </table>
                            <table style="margin-left:5px;width:185px;">
                                <tr >
                                    <td style="text-align:center;width:85px;font-family:'Montserrat Medium';font-size:10px;">
                                        Not Assessed
                                    </td>
                                    <td style="text-align:center;width:50px;font-family:'Montserrat Medium';font-size:10px;">
                                        Partial
                                    </td>
                                    <td style="text-align:center;width:50px;font-family:'Montserrat Medium';font-size:10px;">
                                        None
                                    </td>
                                </tr>
                            </table>
                            <table style="margin-left:5px;width:185px;">
                                <tr >
                                    <td style="text-align:center;width:30px;font-family:'Montserrat Medium';font-size:10px;">
                                        New
                                    </td>
                                    <td style="text-align:center;width:70px;font-family:'Montserrat Medium';font-size:10px;">
                                        In Progress
                                    </td>
                                    <td style="text-align:center;width:85px;font-family:'Montserrat Medium';font-size:10px;">
                                        Past Due Date
                                    </td>
                                </tr>
                            </table>
                            </div>
                        </div>
                        
                        <div *ngIf="node.data.title != 'head1' && node.data.title != 'head2'" [title]="node.data.longTitle || ''"  style="display: flex;justify-content: space-between;">
                            <div>
                            <table>
                                <tr>
                                    <td>
                                        <span *ngIf="!node.data.detailsShow" [innerHTML]="node.data.title">

                                        </span>
                                        <span *ngIf="node.data.detailsShow">
                                            <a style="cursor:pointer;color:blue;" href="javascript: void(0);" (click)="gotoDOcumentDetails(node.data.docTopicId ,node.data.docCategoryId ,node.data.details._document._id,'isRelevant', true)">
                                                {{node.data.title}}
                                            </a>
                                        </span>
                                        <!-- <span *ngIf="node.data.detailsShow" style="font-weight: bold;">{{' (' + (node.data.details?.articleCount || 'NA') + ')'}}</span> -->
                                    </td>
                                </tr>
                            </table>
                            </div>
                            <div style="display: inline-flex;">
                            <table style="margin-left:5px;">
                                <tr>
                                    <td>
                                        <!-- <span style="float:right"> -->
                                        <span *ngIf="node.data.detailsShow && node.data.details && node.data.details.ragStatus">
                                            <app-r-a-g-status [ragData]="node.data.details.ragStatus"></app-r-a-g-status>
                                        </span>
                                    </td>
                                </tr>
                            </table>
                            <table style="margin-left:5px;width:180px;">
                                <tr>
                                    <td style="text-align:center;font-family:'Montserrat Medium';font-size:10px;" *ngIf="node.data.detailsShow">
                                        <a style="cursor:pointer;color:blue;" href="javascript: void(0);" (click)="loadFilteredArticleList(node.data.docTopicId ,node.data.docCategoryId ,node.data.details._document._id,'isRelevant', true)">{{node.data.details?.isRelevant?.isTrue || 0}}</a>
                                    </td>
                                </tr>
                            </table>
                            <table style="margin-left:5px;width:185px;">
                                <tr>
                                    <td style="text-align:center;width:85px;font-family:'Montserrat Medium';font-size:10px;" *ngIf="node.data.detailsShow">
                                        <a style="cursor:pointer;color:blue;" href="javascript: void(0);" (click)="loadFilteredArticleList(node.data.docTopicId ,node.data.docCategoryId ,node.data.details._document._id,'compliantStatus', 'not_assessed')">{{node.data.details?.compliantStatus?.not_assessed || 0}}
                                            </a>
                                        </td>
                                    <td style="text-align:center;width:50px;font-family:'Montserrat Medium';font-size:10px;" *ngIf="node.data.detailsShow">
                                        <a style="cursor:pointer;color:blue;" href="javascript: void(0);" (click)="loadFilteredArticleList(node.data.docTopicId ,node.data.docCategoryId ,node.data.details._document._id,'compliantStatus', 'partial')">{{node.data.details?.compliantStatus?.partial || 0}}
                                            </a>
                                        </td>
                                    <td style="text-align:center;width:50px;font-family:'Montserrat Medium';font-size:10px;" (click)="loadFilteredArticleList(node.data.docTopicId ,node.data.docCategoryId ,node.data.details._document._id,'compliantStatus', 'none')" *ngIf="node.data.detailsShow">
                                        <a style="cursor:pointer;color:blue;" href="javascript: void(0);">{{node.data.details?.compliantStatus?.none || 0}}
                                            </a>
                                        </td>
                                </tr>
                            </table>
                            <table style="margin-left:5px;width:185px;">
                                <tr >
                                    <td style="text-align:center;width:30px;font-family:'Montserrat Medium';font-size:10px;" *ngIf="node.data.detailsShow">
                                        {{node.data.details?.actionStats?.new || 0}}
                                    </td>
                                    <td style="text-align:center;width:70px;font-family:'Montserrat Medium';font-size:10px;" *ngIf="node.data.detailsShow">
                                        {{node.data.details?.actionStats?.inProgress || 0}}
                                    </td>
                                    <td style="text-align:center;width:85px;font-family:'Montserrat Medium';font-size:10px;" *ngIf="node.data.detailsShow">
                                        {{node.data.details?.actionStats?.past || 0}}
                                    </td>
                                </tr>
                            </table>
                            </div>
                        </div>
                        
                        
                        
                        
                    </ng-template>
                </tree-root>
                <!-- <ay-treeTable [value]="treeDataDocuments">
                    <ay-column field="name" header="Name"></ay-column>
                    <ay-column field="rag" header="rag"></ay-column>
                     <ay-column field="gender" header="Gender"></ay-column> 
                </ay-treeTable> -->
                </div>
                <div *ngIf="nodesData.length == 0" style="margin:auto;text-align:center;">
                    <div style="margin: auto;" >
                        <img style="height:100px;" src="../../../assets/images/loader1.gif"/>
                        <div style="font-size:12px;font-family:'Montserrat Medium'">Regulatory Topic details being fetched...</div>
                    </div>
                </div>
                <!-- <div>
                    <div>
                        <div>#Article Relevance</div>
                        <table>
                            <thead>
                                <tr>
                                    <td>Yes</td>
                                    <td>No</td>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let item of documentsList">
                                    <td>NA</td>
                                    <td>NA</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div> -->
            </div>
        </div>
    </div>
    <!--loader-->
        <div style="position: absolute;top:40%;left:40%;" *ngIf="false">
            <img src="../../../assets/images/loader1.gif"/>
        </div>
    <!---->

</div>
