import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { FormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './components/login/login.component';
import { SignupComponent } from './components/signup/signup.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { ArticlesListComponent } from './components/articles-list/articles-list.component';
import { ArticleDetailComponent } from './components/article-detail/article-detail.component';
import { ReportingElementsListComponent } from './components/reporting-elements-list/reporting-elements-list.component';
import { ReportingElementDetailComponent } from './components/reporting-element-detail/reporting-element-detail.component';
import { ActionsListComponent } from './components/actions-list/actions-list.component';
import { RAGStatusComponent } from './shared/components/r-a-g-status/r-a-g-status.component';
import { DataServiceService } from './shared/services/data-service/data-service.service';
import { DataTableComponent } from './shared/components/data-table/data-table.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';
import { NgSelectModule } from '@ng-select/ng-select';
import { ResetPasswordComponent } from './components/reset-password/reset-password.component';
import { CookieModule } from 'ngx-cookie';
import { HttpInterceptService } from './shared/services/http-intercept.service';
import { ForgotPasswordComponent } from './components/forgot-password/forgot-password.component';
import { VerifyOtpComponent } from './components/verify-otp/verify-otp.component';
import { ArticlesDataService } from './shared/services/articles-data-service/articles-data.service';
import { ArticleDetailService } from './components/article-detail/article-detail.service';
import { AngularMyDatePickerModule } from 'angular-mydatepicker';
import { TreeModule } from 'angular-tree-component';
import { CanDeactivateGuard } from './components/articles-list/article-list-deactivate-route-gaurd';
import { RegulatoryActivitiesComponent } from './components/regulatory-activities/regulatory-activities.component';
import { NgxImageZoomModule } from 'ngx-image-zoom';
import { NgxPanZoomModule } from 'ngx-panzoom';
import { MathJaxService } from './shared/services/math-jax.service';
import { DocumentDetailsComponent } from './components/document-details/document-details.component';
import { HotTableModule } from '@handsontable/angular';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    SignupComponent,
    DashboardComponent,
    ArticlesListComponent,
    ArticleDetailComponent,
    ReportingElementsListComponent,
    ReportingElementDetailComponent,
    ActionsListComponent,
    RAGStatusComponent,
    DataTableComponent,
    ResetPasswordComponent,
    ForgotPasswordComponent,
    VerifyOtpComponent,
    RegulatoryActivitiesComponent,
    DocumentDetailsComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot({enableHtml:true}),
    CookieModule.forRoot(),
    NgSelectModule,
    AngularMyDatePickerModule,
    NgxImageZoomModule,
    NgxPanZoomModule,
    TreeModule.forRoot(),
    HotTableModule,
  ],
  providers: [
    DataServiceService,
    ArticlesDataService,
    ArticleDetailService,
    CanDeactivateGuard,
    MathJaxService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpInterceptService,
      multi: true
    },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
