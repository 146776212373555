import { Component, OnDestroy, OnInit } from '@angular/core';
import { environment } from '../../../environments/environment';
import { DataServiceService } from '../../shared/services/data-service/data-service.service';
import { ArticlesDataService } from '../../shared/services/articles-data-service/articles-data.service'; 
import { RAGStatusComponent } from '../../shared/components/r-a-g-status/r-a-g-status.component';
import { TREE_ACTIONS, KEYS, IActionMapping, ITreeOptions } from 'angular-tree-component';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { takeUntil, catchError } from 'rxjs/operators';
import { BehaviorSubject, zip, forkJoin, of } from 'rxjs';


@Component({
  selector: 'app-regulatory-activities',
  templateUrl: './regulatory-activities.component.html',
  styleUrls: ['./regulatory-activities.component.scss']
})


export class RegulatoryActivitiesComponent implements OnInit, OnDestroy {
  documentsList: Array<any> = [];
  nodesData: any = [];
  regulatoryTopicsList: any = [];
  destroyed = new BehaviorSubject<Boolean>(false);
  documentCategoriesPromise:any = [];
  statsPromise:any = [];
  forkJoinSubscriptionA: any;
  forkJoinSubscriptionB: any;
  requlatoryData = [
    {
      name: "Liquidity Risk",
      url: "article-list"
      
    },
    {
      name: "Market Risks",
      url: ""
      
    },
  {
    name: "Accounting",
    url: ""
    
  }
  ,{
    name: "Credit Risk",
    url: ""
    
  },{
    name: "Internal governance",
    url: ""
    
  }, {
    name: "Investment firms",
    url: ""
    
  }
  ,{
    name: "Leverage ratio",
    url: ""
    
  }, {
    name: "Accounting and auditing",
    url: ""
    
  }
  ,{
    name: "Operational risk",
    url: ""
    
  },
  {
    name: "Own funds",
    url: ""
    
  }, {
    name: "Remuneration",
    url: ""
    
  }
  , {
    name: "Recovery, resolution and DGS",
    url: ""
    
  }, {
    name: "Model validation",
    url: ""
    
  },{
    name: "Supervisory benchmarking exercises",
    url: ""
    
  }
  ,  {
    name: "Transparency and Pillar 3",
    url: ""
    
  }, {
    name: "Large exposures",
    url: ""
    
  },{
    name: "Supervisory reporting",
    url: ""
    
  }, {
    name: "Other Topics",
    url: ""
    
  }]
  controller = new window.AbortController();
  signal = this.controller.signal;
  documentCatSubs: any = [];
  statsSubs: any = [];

  constructor(
    private _dataService: DataServiceService,
    private _articlesDataService: ArticlesDataService,
    private router: Router,
    
  ) { }

  ngOnInit(): void {
    // this.getDocumentStats();
    this.getRegulatoryTopics();
    // this.getDocumentList([]);
    // this.getRegulatoryTopics();
  }

  ngOnDestroy(): void {
    if(this.forkJoinSubscriptionA){
      this.forkJoinSubscriptionA.unsubscribe();
    }

    if(this.forkJoinSubscriptionB) {
      this.forkJoinSubscriptionB.unsubscribe();
    }
    // this.destroyed.next(true)
    // this.statsPromise.abort();
    // this.documentCategoriesPromise.abort();
    // if(this.statsSubs) {
    //   this.statsSubs.forEach((item) => {
    //     item.unsubscribe()
    //   })
    // }

    // if(this.documentCatSubs) {
    //   this.documentCatSubs.forEach((item) => {
    //     item.unsubscribe()
    //   })
    // }
  }

 

  logNode(event): void{

  }

  loadFilteredArticleList(topicId, categoryId, docId, key, val): any{
    if(docId){
      let filterQuery = '?' + key + '=' + val;
      localStorage.setItem('dashboardArticleNavTopicId', topicId);
      localStorage.setItem('dashboardArticleNavCategoryId', categoryId);
      localStorage.setItem('dashboardArticleNavDocId', docId);
      localStorage.setItem('dashboardArticleNavKey', key);
      localStorage.setItem('dashboardArticleNavVal', val);
      const url = this.router.createUrlTree([`dashboard/article-list`]).toString();
      window.open(url, '_blank'); 
    
    }
  }

  gotoDOcumentDetails(topicId, categoryId, docId, key, val): any{
    if(docId){
      let filterQuery = '?' + key + '=' + val;
      localStorage.setItem('dashboardArticleNavTopicId', topicId);
      localStorage.setItem('dashboardArticleNavCategoryId', categoryId);
      localStorage.setItem('dashboardArticleNavDocId', docId);
      localStorage.setItem('dashboardArticleNavKey', key);
      localStorage.setItem('dashboardArticleNavVal', val);
      console.log(topicId, categoryId, docId)
      const url = this.router.createUrlTree([`dashboard/document-overview`]).toString();
      window.open(url, '_blank'); 
    
    }
  }

  getRegulatoryTopics(): void{
    let url = environment.apiUrls.getRegulationTopicsUrl;
    this._dataService.getData(url, [], true).toPromise()
    .then((response: any) => {
      if (response.status == 1) {
        this.regulatoryTopicsList = [];
        if(response.data && response.data.length){
          // response.data.forEach((item) => {
          //   if(item.title != 'Checklist'){
          //     this.regulatoryTopicsList.push(item);
          //   }
          // });
          this.regulatoryTopicsList = response.data;
        }
      }
      this.nodesData = [];
      this.documentCategoriesPromise = []
      let tempDataArray = [];
      let modifiedTempDataArray = []; 
      this.documentCatSubs = []; 
      this.regulatoryTopicsList.forEach((item) => {
        let obj = {
          title: item.title,
          id: item._id,
          detailsShow: false,
          children: []
        }
        tempDataArray.push(obj); 
        
        let documentsListUrl = environment.apiUrls.getRegulatoryTopicsDocumentUrl;
        documentsListUrl = documentsListUrl.replace(':topicId', item._id);
        let subsA = this._dataService.getData(documentsListUrl, [], true)
        this.documentCategoriesPromise.push(subsA.toPromise())
        this.documentCatSubs.push(subsA);

      })


      this.forkJoinSubscriptionA = forkJoin(this.documentCatSubs).subscribe((response) => {
        response.forEach((res: any, index) => {
          if(res.status == 1 && res.data.length){
            res.data.forEach((item) => {
              let childObj = {
                documentCategory: item._id.documentCategory,
                title: item._id.documentType,
                detailsShow: false,
                children: []
              }

              tempDataArray[index].children.push(childObj);
            })
          }

        });
        tempDataArray.forEach((item) => {
          if(item.children.length){
            modifiedTempDataArray.push(item);
          }
        });

        this.statsPromise = [];
        this.statsSubs = [];
        let promiseCounter = 0;
        modifiedTempDataArray.forEach((item) => {
          item.children.forEach((child) => {
            let url = environment.apiUrls.getRegulatoryDocStats;
            let urlParam = [
              {
                key: 'type',
                val: child.title
              },
              {
                key: 'regulatoryTopicId',
                val: item.id
              }
            ];
            child.resIdx = promiseCounter;
            promiseCounter = promiseCounter + 1;
            let subB = this._dataService.getData(url, urlParam, true).pipe(
              catchError((err) => {
                // Handle specific error for this call
                return of(undefined)
              }
            ));
            this.statsPromise.push(subB.toPromise());
            this.statsSubs.push(subB)
          })
        });

        this.forkJoinSubscriptionB = forkJoin(this.statsSubs).subscribe((responsex) => {
          console.log(responsex)
          responsex.forEach((resx: any, ix) => {
            if(resx && resx.status == 1) {
              if(resx.data && resx.data.length) {
                modifiedTempDataArray.forEach((item) => {
                  item.children.forEach((baby) => {
                    if(baby.resIdx == ix){
                      resx.data.forEach((docRec) => {
                        let documentObj = {
                          name: docRec._document.type + ' ' + docRec._document.title,
                          id: docRec._document._id,
                          documentCategory: docRec._document.type,
                          title: docRec._document.title,
                          longTitle: docRec._document.longTitle,
                          detailsShow: true,
                          details: docRec,
                          docCategoryId: baby.documentCategory,
                          docTopicId: item.id
                        }
                        baby.children.push(documentObj)
                      
                      })
                      
                    }
                  })
                })
              }
            }
              
              
              
            
          })
          modifiedTempDataArray.unshift({title: 'head2', children: []})
          
          modifiedTempDataArray.unshift({title: 'head1', children: []});

          this.nodesData = modifiedTempDataArray;

        }, (eror) => {
          console.log(eror)
        })

        
       
      }, (error) => {})

      
      
    }, (error) => {});

  }

  goToArticleListForTopic(item): any{
    // this._articlesDataService.topicForArticleList = item._id;
    localStorage.setItem('topicForArticleList', item._id);
    this.router.navigate(['/dashboard/article-list']);
  }

  

  getDocumentList(crrDocs): void {
    const url = environment.apiUrls.getDocumentsListUrl;
    

      //////////////
      let demoTree = [
        {
          title: 'CRR',
          detailsShow: false,
          children: [
            {
              title: 'CRR',
              detailsShow: false,
              children: [
                {
                  id: null,
                  documentCategory: 'CRR',
                  title: '2015/62/EU',
                  detailsShow: true,
                  details: {}
                },
                {
                  id: null,
                  documentCategory: 'CRR',
                  title: '2016/1014/EU',
                  detailsShow: true,
                  details: {}
                },
                {
                  id: null,
                  documentCategory: 'CRR',
                  title: '2017/2188/EU',
                  detailsShow: true,
                  details: {}
                },
                {
                  id: null,
                  documentCategory: 'CRR',
                  title: '2017/2395/EU',
                  detailsShow: true
                },
                {
                  id: null,
                  documentCategory: 'CRR',
                  title: '2017/2401/EU',
                  detailsShow: true,
                  details: {}
                },
                {
                  id: null,
                  documentCategory: 'CRR',
                  title: '2018/405/EU',
                  detailsShow: true,
                  details: {}
                },
                {
                  id: null,
                  documentCategory: 'CRR',
                  title: '2019/2033/EU',
                  detailsShow: true,
                  details: {}
                },
                {
                  id: null,
                  documentCategory: 'CRR',
                  title: '2019/630(R02)/EU',
                  detailsShow: true,
                  details: {}
                },
                {
                  id: null,
                  documentCategory: 'CRR',
                  title: '2019/630/EU',
                  detailsShow: true,
                  details: {}
                },
                {
                  id: null,
                  documentCategory: 'CRR',
                  title: '2019/876/EU',
                  detailsShow: true,
                  details: {}
                },
                {
                  id: null,
                  documentCategory: 'CRR',
                  title: '2020/873/EU',
                  detailsShow: true,
                  details: {}
                },
                {
                  id: null,
                  documentCategory: 'CRR',
                  title: '575/2013(R01)/EU',
                  detailsShow: true,
                  details: {}
                },
                {
                  id: null,
                  documentCategory: 'CRR',
                  title: '575/2013(R02)/EU',
                  detailsShow: true,
                  details: {}
                },
                {
                  id: null,
                  documentCategory: 'CRR',
                  title: '575/2013(R04)/EU',
                  detailsShow: true,
                  details: {}
                }
                
              ]
            }
          ] 
        },
        {
          title: 'Credit Risk',
          detailsShow: false,
          children: [
            {
              title: 'GL',
              detailsShow: false,
              children: [
                {
                  id: null,
                  documentCategory: 'GL',
                  title: 'EBA/CP/2015/21',
                  detailsShow: true,
                  details: {}
                },
                {
                  id: null,
                  documentCategory: 'GL',
                  title: 'EBA/GL/2016/07',
                  detailsShow: true,
                  details: {}
                },
                {
                  id: null,
                  documentCategory: 'GL',
                  title: 'EBA/GL/2018/06',
                  detailsShow: true,
                  details: {}
                },
                {
                  id: null,
                  documentCategory: 'GL',
                  title: 'EBA/GL/2019/01',
                  detailsShow: true,
                  details: {}
                },
                {
                  id: null,
                  documentCategory: 'GL',
                  title: 'EBA/GL/2020/02',
                  detailsShow: true,
                  details: {}
                },
                {
                  id: null,
                  documentCategory: 'GL',
                  title: 'EBA/GL/2020/06',
                  detailsShow: true,
                  details: {}
                },
                {
                  id: null,
                  documentCategory: 'GL',
                  title: 'EBA/GL/2020/08',
                  detailsShow: true,
                  details: {}
                },
                {
                  id: null,
                  documentCategory: 'GL',
                  title: 'EBA/GL/2020/15',
                  detailsShow: true,
                  details: {}
                },
                {
                  id: null,
                  documentCategory: 'GL',
                  title: 'EBA/GL10',
                  detailsShow: true,
                  details: {}
                },
                {
                  id: null,
                  documentCategory: 'GL',
                  title: 'JC 2014 004',
                  detailsShow: true,
                  details: {}
                },
                {
                  id: null,
                  documentCategory: 'GL',
                  title: 'CEBS/GL/20091211*RegArt',
                  detailsShow: true,
                  details: {}
                },
                {
                  id: null,
                  documentCategory: 'GL',
                  title: 'CEBS/GL/Art106-2*RegArt',
                  detailsShow: true,
                  details: {}
                }
              ]
            },
            {
              title: 'RTS',
              detailsShow: false,
              children: [
                {
                  id: null,
                  documentCategory: 'RTS',
                  title: '1152/2014/EU',
                  detailsShow: true,
                  details: {}
                },
                {
                  id: null,
                  documentCategory: 'RTS',
                  title: '183/2014/EU',
                  detailsShow: true,
                  details: {}
                },
                {
                  id: null,
                  documentCategory: 'RTS',
                  title: '2014/526/EU',
                  detailsShow: true,
                  details: {}
                },
                {
                  id: null,
                  documentCategory: 'RTS',
                  title: '2014/529/EU',
                  detailsShow: true,
                  details: {}
                },
                {
                  id: null,
                  documentCategory: 'RTS',
                  title: '2015/1555/EU',
                  detailsShow: true,
                  details: {}
                },
                {
                  id: null,
                  documentCategory: 'RTS',
                  title: '2015/1556/EU',
                  detailsShow: true,
                  details: {}
                },
                {
                  id: null,
                  documentCategory: 'RTS',
                  title: '2015/942/EU',
                  detailsShow: true,
                  details: {}
                },
                {
                  id: null,
                  documentCategory: 'RTS',
                  title: '2016/2251/EU',
                  detailsShow: true,
                  details: {}
                },
                {
                  id: null,
                  documentCategory: 'RTS',
                  title: '2016/2251/EU R01',
                  detailsShow: true,
                  details: {}
                },
                {
                  id: null,
                  documentCategory: 'RTS',
                  title: '2017/323/EU',
                  detailsShow: true,
                  details: {}
                },
                {
                  id: null,
                  documentCategory: 'RTS',
                  title: '2018/171/EU',
                  detailsShow: true,
                  details: {}
                },
                {
                  id: null,
                  documentCategory: 'RTS',
                  title: '2020/448/EU',
                  detailsShow: true,
                  details: {}
                },
                {
                  id: null,
                  documentCategory: 'RTS',
                  title: 'EBA/CP/2014/10',
                  detailsShow: true,
                  details: {}
                },
                {
                  id: null,
                  documentCategory: 'RTS',
                  title: 'EBA/CP/2015/12',
                  detailsShow: true,
                  details: {}
                },
                {
                  id: null,
                  documentCategory: 'RTS',
                  title: 'EBA/CP/2020/25',
                  detailsShow: true,
                  details: {}
                },
                {
                  id: null,
                  documentCategory: 'RTS',
                  title: 'EBA/RTS/2016/02',
                  detailsShow: true,
                  details: {}
                },
                {
                  id: null,
                  documentCategory: 'RTS',
                  title: 'EBA/RTS/2016/03',
                  detailsShow: true,
                  details: {}
                }
              ]
            }
          ] 
        },
        {
          title: 'Liquidity Risk',
          detailsShow: false,
          children: [
            {
              title: 'DA',
              detailsShow: false,
              children: [
                {
                  id: null,
                  documentCategory: 'DA',
                  title: '2015/61/EU',
                  detailsShow: true,
                  details: {}
                },
                {
                  id: null,
                  documentCategory: 'DA',
                  title: '2018/1620/EU',
                  detailsShow: true,
                  details: {}
                }
              ] 
            },
            {
              title: 'GL',
              detailsShow: false,
              children: [
                {
                  id: null,
                  documentCategory: 'GL',
                  title: 'EBA/GL/2013/01',
                  detailsShow: true,
                  details: {}
                },
                {
                  id: null,
                  documentCategory: 'GL',
                  title: 'EBA/GL/2014/04',
                  detailsShow: true,
                  details: {}
                },
                {
                  id: null,
                  documentCategory: 'GL',
                  title: 'EBA/GL/2017/01',
                  detailsShow: true,
                  details: {}
                },
                {
                  id: null,
                  documentCategory: 'GL',
                  title: 'GL/10',
                  detailsShow: true,
                  details: {}
                }
              ] 
            },
            {
              title: 'ITS',
              detailsShow: false,
              children: [
                {
                  id: null,
                  documentCategory: 'ITS',
                  title: '2015/1278/EU',
                  detailsShow: true,
                  details: {}
                },
                {
                  id: null,
                  documentCategory: 'ITS',
                  title: '2015/227/EU',
                  detailsShow: true,
                  details: {}
                },
                {
                  id: null,
                  documentCategory: 'ITS',
                  title: '2015/233/EU',
                  detailsShow: true,
                  details: {}
                },
                {
                  id: null,
                  documentCategory: 'ITS',
                  title: '2015/2344/EU',
                  detailsShow: true,
                  details: {}
                },
                {
                  id: null,
                  documentCategory: 'ITS',
                  title: '2015/79/EU',
                  detailsShow: true,
                  details: {}
                },
                {
                  id: null,
                  documentCategory: 'ITS',
                  title: '2016/1702/EU',
                  detailsShow: true,
                  details: {}
                },
                {
                  id: null,
                  documentCategory: 'ITS',
                  title: '2016/313/EU',
                  detailsShow: true,
                  details: {}
                },
                {
                  id: null,
                  documentCategory: 'ITS',
                  title: '2016/322/EU',
                  detailsShow: true,
                  details: {}
                },
                {
                  id: null,
                  documentCategory: 'ITS',
                  title: '2016/428/EU',
                  detailsShow: true,
                  details: {}
                },
                {
                  id: null,
                  documentCategory: 'ITS',
                  title: '2017/1443/EU',
                  detailsShow: true,
                  details: {}
                },
                {
                  id: null,
                  documentCategory: 'ITS',
                  title: '2017/2114/EU',
                  detailsShow: true,
                  details: {}
                },
                {
                  id: null,
                  documentCategory: 'ITS',
                  title: '2018/1627/EU',
                  detailsShow: true,
                  details: {}
                },
                {
                  id: null,
                  documentCategory: 'ITS',
                  title: '2020/429/EU',
                  detailsShow: true,
                  details: {}
                },
                {
                  id: null,
                  documentCategory: 'ITS',
                  title: '680/2014/EU',
                  detailsShow: true,
                  details: {}
                },
                {
                  id: null,
                  documentCategory: 'ITS',
                  title: 'EBA/ITS/2020/04',
                  detailsShow: true,
                  details: {}
                },
                {
                  id: null,
                  documentCategory: 'ITS',
                  title: 'EBA/ITS/2020/05',
                  detailsShow: true,
                  details: {}
                }
              ] 
            },
            {
              title: 'RTS',
              detailsShow: false,
              children: [
                {
                  id: null,
                  documentCategory: 'RTS',
                  title: '2016/709/EU',
                  detailsShow: true,
                  details: {}
                },
                {
                  id: null,
                  documentCategory: 'RTS',
                  title: '2017/1230/EU',
                  detailsShow: true,
                  details: {}
                },
                {
                  id: null,
                  documentCategory: 'RTS',
                  title: '2017/208/EU',
                  detailsShow: true,
                  details: {}
                }
              ] 
            }
          ] 
        },
        {
          title: 'Model Validation',
          detailsShow: false,
          children: [
            {
              title: 'GL',
              detailsShow: false,
              children: [
                {
                  id: null,
                  documentCategory: 'GL',
                  title: 'EBA/GL/2017/16',
                  detailsShow: true,
                  details: {}
                },
                {
                  id: null,
                  documentCategory: 'GL',
                  title: 'EBA/GL/2019/03',
                  detailsShow: true,
                  details: {}
                },
                {
                  id: null,
                  documentCategory: 'GL',
                  title: 'EBA/GL/2020/05',
                  detailsShow: true,
                  details: {}
                },
                {
                  id: null,
                  documentCategory: 'GL',
                  title: 'ECB/GL Internal Models',
                  detailsShow: true,
                  details: {}
                }
              ] 
            },
            {
              title: 'RTS',
              detailsShow: false,
              children: [
                {
                  id: null,
                  documentCategory: 'RTS',
                  title: '2017/72/EU',
                  detailsShow: true,
                  details: {}
                },
                {
                  id: null,
                  documentCategory: 'RTS',
                  title: 'EBA /RTS/2018/04',
                  detailsShow: true,
                  details: {}
                }
              ] 
            }
          ] 
        }
      ]

      

      demoTree.forEach((item:any) => {
          item.children.forEach((child) => {
            child.children.forEach((gc) => {
              gc['details'] = {
                ragStatus: {
                  fullCompliant: 0,
                  fullCompliantPercentage: "0",
                  nonCompliant: 0,
                  nonCompliantPercentage: "0",
                  notAssessed: 0,
                  notAssessedPercentage: 100,
                  partialCompliant: 0,
                  partialCompliantPercentage: "0",
                  total: 5
                }
              }
            })
          })
          item.children[0].children.forEach((child) => {
            crrDocs.forEach((docItem) => {
              if(docItem._document.title == child.title){
                child['details'] = docItem;
              }
            })
          })
        
      })

    

      this.nodesData = demoTree;
      this.nodesData.unshift({title: 'head2', children: []})
          
            this.nodesData.unshift({title: 'head1', children: []})
      //    
  }

  getDocumentStats(): void{
    let url = environment.apiUrls.getRegulatoryDocStats;
    let urlParam = [
      {
        key: 'type',
        val: 'CRR'
      },
      {
        key: 'regulatoryTopicId',
        val: ''
      }
    ];

    this._dataService.getData(url, urlParam, true).toPromise()
    .then((response: any) => {
      let crrDocsStats = response.data;
      this.getDocumentList(crrDocsStats);
    }, (error) => {

    })
  }

  updateTree(tree): any {

    tree['name'] = tree.title

    if(tree.documents){
      tree['children'] = tree.documents;
      tree['children'] = tree.documents.map((item) => {
        return this.updateTree(item);
      })
    }
    return tree;
}
  
  // getDocumentList(): void {
  //   const url = environment.apiUrls.getDocumentsListUrl;

  //   this._dataService.getData(url, [], true).toPromise()
  //     .then((response: any) => {
  //       if (response.status == 1) {
  //         this.documentsList = response.data.documents;
  //         const allDocTypeObj = {
  //           _id: {
  //             documentType: 'ALL',
  //             documentCategory: 'ALL'
  //           },
  //           documents: []
  //         };
  //         this.documentsList.push(allDocTypeObj);
  //         this.documentsList.forEach((item) => {
  //           item.docType = item._id.documentType;
  //           item.docCategory = item._id.documentCategory;
  //           const allDocObj = {
  //             _id: 'ALL',
  //             title: 'ALL'
  //           };
  //           item.documents.push(allDocObj);
  //         });
  //         this.selectedDocumentCategory = 'ALL';
  //         this.onDocumentCategorySelection('ALL');
  //       }
  //     }, (error) => {

  //     });
  // }
}
