<div class="articles-list-view-container">
    <div (click)="showNavMenu = false">
        <div style="top: 65px;z-index: 9999;background: #f2f6f9;padding-top:30px;padding-bottom:15px;">
            <div class="articles-list-view-header">
                <div class="header-title" style="display: flex;">
                    <!-- <div style="width:180px;">{{regulatoryTopic || ''}}</div> -->
                    <div style="display: flex;margin-left:15px;">
                        <div style="margin-top:-10px;">
                            <div style="font-size:10px;font-family:Montserrat Medium;color:grey;">Topic</div>
                            <div style="width:120px;font-family:Montserrat Medium;">
                                <ng-select id="regTopic" style="width:120px;font-size:10px;" [items]="regulatoryTopicsList"
                                    bindLabel="title" bindValue="_id" (change)="getDocumentsForTopics(selectedTopic)"
                                    [(ngModel)]="selectedTopic" [clearable]="false">
                                    <ng-template ng-option-tmp let-item="item">
                                        <div [title]="item.title">{{item.title}}</div>
                                    </ng-template>
                                </ng-select>
                            </div>
                        </div>
                        <div style="margin-left:10px;margin-top:-10px;">
                            <div style="font-size:10px;font-family:Montserrat Medium;color:grey;">Category</div>
                            <div style="width:70px;font-family:Montserrat Medium;">
                                <ng-select id="docCategory" style="width:70px;font-size:10px;" [items]="documentCategoriesList"
                                    bindLabel="docType" bindValue="docCategory" (change)="onDocumentCategorySelection(selectedDocumentCategory)"
                                    [(ngModel)]="selectedDocumentCategory" [clearable]="false">
                                </ng-select>
                            </div>
                        </div>
                        <div style="margin-left:10px;margin-top:-10px;" class="article-list-test">
                            <div style="font-size:10px;font-family:Montserrat Medium;color:grey;">Document</div>
                            <div style="width:250px;font-family:Montserrat Medium;">
                                <ng-select class="documentDropdown" id="docName" style="width:250px;font-size:10px;" [items]="documentNamesList"
                                    (change)="onDocumentSelection(selectedDocument, documentNamesList)"
                                    bindLabel="title" bindValue="_id" [(ngModel)]="selectedDocument" [clearable]="false">
                                    <ng-template ng-option-tmp let-item="item">
                                        <div [title]="item.longTitle">{{item.title}}</div>
                                    </ng-template>
                                </ng-select>
                            </div>
                        </div>
                        <div style="margin-top:-2.5px;">
                            <button type="button" class="save-changes-btn" (click)="getAssessment([], 'docChange')" style="width:40px;margin-left: 5px;">
                                Go
                            </button>
                        </div>
                        <div style="margin-left:10px; margin-top:-2.5px;">
                            <button type="button" class="save-changes-btn" (click)="updateData('none', 'none')">Save Changes</button>
                        </div>
                        <div style="margin-left:10px; margin-top:-2.5px;">
                            <button type="button" class="save-changes-btn" style="width:140px;" (click)="downloadReportForList()">Download Report</button>
                        </div>
                    </div>
                </div>    
                <div class="header-right-section">
                    <div class=rag-status>
                        <app-r-a-g-status></app-r-a-g-status>
                    </div>
                    <div class="column-customization-box">
                        <span class="dropdown">
                            <span data-toggle="dropdown">
                                Customize Column
                                <img  src="../../../assets/images/icon-dropdown.svg" />
                            </span>
                            <ul class="dropdown-menu dropdown-menu-right custom-col-pane" id="custom-col-pane" *ngIf="articleListHotSettings && articleListHotSettings.columns && articleListHotSettings.columns.length">
                                <form>
                                <li *ngFor="let column of articleListHotSettings.columns; let i=index" class="dropdown-item" style="padding:0px 8px;">
                                    <input id="Customize-col-id{{i}}" name="cust-col{{i}}" (click)="onCheckboxChange($event, i)" type="checkbox" [value]="column.title" [checked] = "!column.isHidden" 
                                    *ngIf="column.title != 'AmendmentType' && column.title != 'amendmentFlag' && column.title != 'id'"        
                                    style="vertical-align: middle;cursor:pointer !important;"/>
                                        
                                    <label (click)="labelClickForChecbox(i)" style="font-size:12px;margin-bottom:2px;cursor:pointer !important;margin-left:4px;" 
                                        *ngIf="column.title != 'AmendmentType' && column.title != 'amendmentFlag' && column.title != 'id'">
                                        {{column.title}}
                                    </label>
                                </li>
                                </form>
                            </ul>
                        </span>
                    </div>
                </div>
            </div>
            <div class="document-reference-text">
                <span class="small-text" *ngIf="currentDocumentReference">({{ selectedDocumentNameLong + ' ' + currentDocumentReference}})</span>
            </div>
            <!---->
            <div class="articles-list-container list-formula-div" id="list-container-height-article">
                <div *ngIf="rowData.length" class="hot" style="font-family:'Montserrat Medium';font-size:12px;" >
                    <hot-table class="hot" [data]="rowData" [colHeaders]="true" [rowHeaders]="false" [settings]="articleListHotSettings"
                        [hotId]="articleListTableId">
                        <!-- <hot-column data="regulationNumber" [width]="50" [readOnly]="true" title="Article No."></hot-column>
                        <hot-column data="description" [width]="180" title="Article" [readOnly]="true"></hot-column>
                        <hot-column data="isRelevant" [width]="60" title="Relevance"></hot-column>
                        <hot-column data="compliantStatus" [width]="70" title="Compliance"></hot-column>
                        <hot-column data="assessment" [width]="150" title="Assessment"></hot-column>
                        <hot-column data="assessmentOwner" [width]="80" title="Assessment Owner"></hot-column>
                        <hot-column data="action" [width]="100" title="Action"></hot-column>
                        <hot-column data="actionOwner" [width]="80" title="Action Owner"></hot-column>
                        <hot-column data="actionStatus" [width]="60" title="Action Status"></hot-column>
                        <hot-column data="actionDueDate" [width]="60" title="Action Due Date"></hot-column> -->
                    </hot-table>
                </div>
                <div style="position: absolute;top:14%;left:40%;" *ngIf="rowData.length == 0 && showLoader">
                    <img src="../../../assets/images/loader1.gif"/>
                    <div>Please wait while we fetch the records...</div>
                </div>
                <div *ngIf="rowData.length == 0 && !showLoader">
                    Select a document and press GO to fetch records.
                </div>
            </div>
            
            <!---->    
        </div>
    </div>
    <!------------>
    <button type="button" *ngIf="navNodesData && navNodesData.length" (click)="showNavMenu = !showNavMenu" style="position:fixed;top:160px;left:0px;z-index:1000000;background:#a5473e;color:#ffffff;
        outline:none;border:none;border-top-right-radius:4px;border-bottom-right-radius:4px;padding:5px;font-family: 'Montserrat Medium';font-size:12px;">
        N<br>A<br>V
    </button>
    <div *ngIf="showNavMenu && navNodesData && navNodesData.length" [@slideInOut] style="width:350px;height:600px;background: #ffffff; border:1px solid lightblue;position:fixed;top:244px;
        z-index:1000000;border-top-right-radius:4px;border-bottom-right-radius:4px;padding:5px;
        left:0px;overflow-y:auto;font-family: 'Montserrat Medium';">
        <div>
            <div>
                <div style="border-bottom: 2px solid lightgrey;padding-bottom:5px;">
                    Articles Tree
                </div>
                <div>
                    <tree-root [nodes]="navNodesData" [options]="nodeOptions">
                        <ng-template #treeNodeTemplate let-node let-index="index">
                            <span *ngIf="node.data.isRelevant == false || node.data.isRelevant == null"
                                style="border-left:4px lightgray solid;padding-left:4px" [title]="node.data.text || ''"
                                [innerHTML]="!node.data.text ? node.data.meta.length ? node.data.meta[0] : node.data.name : ''">
                            </span>
                            <span [title]="node.data.text || ''" *ngIf="node.data.isRelevant == true && (node.data.compliantStatus == 'none' || node.data.compliantStatus == null)"
                                style="border-left:4px #b32d00 solid;padding-left:4px" [innerHTML]="!node.data.text ? node.data.meta.length ? node.data.meta[0] : node.data.name : ''">
                            </span>
                            <span [title]="node.data.text || ''" *ngIf="node.data.isRelevant == true && node.data.compliantStatus == 'partial'"
                                style="border-left:4px #ffcc00 solid;padding-left:4px" [innerHTML]="!node.data.text ? node.data.meta.length ? node.data.meta[0] : node.data.name : ''">
                            </span>
                            <span [title]="node.data.text || ''" *ngIf="node.data.isRelevant == true && node.data.compliantStatus == 'full'"
                                style="border-left:4px #008000 solid;padding-left:4px" [innerHTML]="!node.data.text ? node.data.meta.length ? node.data.meta[0] : node.data.name : ''">
                            </span>
                            <span [title]="node.data.text || ''" *ngIf="node.data.isRelevant == true && node.data.compliantStatus == 'not_assessed'"
                                style="border-left:4px grey solid;padding-left:4px" [innerHTML]="!node.data.text ? node.data.meta.length ? node.data.meta[0] : node.data.name : ''">
                            </span>
                            <span [title]="node.data.text || ''" *ngIf="node.data.isRelevant == true && node.data.compliantStatus == 'not_applicable'"
                                style="border-left:4px lightblue solid;padding-left:4px" [innerHTML]="!node.data.text ? node.data.meta.length ? node.data.meta[0] : node.data.name : ''">
                            </span>
                            <span *ngIf="!node.data.text && node.data.meta && node.data.meta.length > 1"> - <span [innerHTML]="node.data.meta[1]"></span></span>
                            <span *ngIf="node.data.text && node.data.meta.length == 0">  <span [innerHTML]="node.data.text"></span></span>
                        </ng-template>
                    </tree-root>
                </div>
            </div>
        </div>
    </div>
    <!--------->
</div>




<!--------->
<!---------->
<!-- <div class="articles-list-view-container">
    
        <div style="position: absolute;top:40%;left:40%;" *ngIf="showLoader">
            <img src="../../../assets/images/loader1.gif"/>
        </div>
    
    
</div> -->
