import { Component, OnInit } from '@angular/core';
import { DataServiceService } from '../../shared/services/data-service/data-service.service';
import { environment } from '../../../environments/environment';
import { ToastrService } from 'ngx-toastr';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { CookieService } from 'ngx-cookie';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {
  showNewPassword: boolean = false;
  showConfirmPassword: boolean = false;
  userUpdatePassword: any = {userId: null, newPassword: null, confirmPassword: null}

  constructor(
    private _dataService: DataServiceService,
    private _toastr: ToastrService,
    private route: ActivatedRoute,
    private _cookie: CookieService,
    private router: Router,
  ) {}

  ngOnInit(): void {
    var getUrlParams: any = this.route.snapshot.paramMap;
    this.userUpdatePassword.userId = getUrlParams.params.userId;
    this._cookie.put("token", getUrlParams.params.token);
              
  }

  resetPassword(): any{
    if(this.userUpdatePassword.newPassword != this.userUpdatePassword.confirmPassword){
      this._toastr.error('Password do not match!');
    }else{
      if(this.userUpdatePassword.newPassword == this.userUpdatePassword.confirmPassword){
        var url = environment.apiUrls.resetPasswordUrl;
        var data = {
          userId: this.userUpdatePassword.userId,
          token: this.userUpdatePassword.token,
          password: this.userUpdatePassword.confirmPassword
        }

        this._dataService.postData(url, data, true).toPromise()
        .then((response: any) => {
            if(response.status == 1){
              this._toastr.success(response.message);
              this.router.navigate(['/login']);
            }
          },(error) => {
            if(error.error){
              this._toastr.error(error.error.message);
            }else{
              this._toastr.error('Something went wrong!');
            }
        });
      }
    }
  }

}
