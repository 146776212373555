import { Component, OnInit, Input } from '@angular/core';
import { DataServiceService } from '../../services/data-service/data-service.service';
import { environment } from '../../../../environments/environment';
import { ArticlesDataService } from '../../services/articles-data-service/articles-data.service';
@Component({
  selector: 'app-r-a-g-status',
  templateUrl: './r-a-g-status.component.html',
  styleUrls: ['./r-a-g-status.component.scss']
})
export class RAGStatusComponent implements OnInit {
  rValue: any;
  aValue: any;
  gValue: any;
  naValue: any;
  @Input() ragData: any = null;
  ragStatusData: boolean = false;
  constructor(
    private _dataService: DataServiceService,
    private _articleDataService: ArticlesDataService
  ) { }

  ngOnInit(): void {
    this.getRagStatus();
  }

  getRagStatus(): void {
    var url = environment.apiUrls.ragStatusUrl;
    var urlParam = [];
    if(this._articleDataService.selectedDocCategoryFilter){
      let catObj = {
        key: 'documentCategory',
        val: this._articleDataService.selectedDocCategoryFilter
      };
      urlParam.push(catObj);
    }

    if(this._articleDataService.selectedDocFilter){
      let docObj = {
        key: 'documentId',
        val: this._articleDataService.selectedDocFilter
      };
      urlParam.push(docObj);
    }

    if(this.ragData == null){
      this._dataService.getData(url, urlParam, true).toPromise()
      .then((response: any) => {
        if(response.status == 1){
          this.rValue = Number(response.data.fullCompliantPercentage);
          this.aValue = Number(response.data.partialCompliantPercentage);
          this.gValue = Number(response.data.nonCompliantPercentage);
          this.naValue = Number(response.data.notAssessedPercentage);
          this.ragStatusData = true;
        }
      },(error) => {
        if(error.error){
          this.ragStatusData = false;
            // this._toastr.error(error.error.message);
        }
      });
    }else{
      this.rValue = Number(this.ragData.fullCompliantPercentage);
      this.aValue = Number(this.ragData.partialCompliantPercentage);
      this.gValue = Number(this.ragData.nonCompliantPercentage);
      this.naValue = Number(this.ragData.notAssessedPercentage);
      this.ragStatusData = true;
    }
    
  }

}
