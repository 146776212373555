import { Component, OnInit, IterableDiffers } from '@angular/core';
import { DataServiceService } from '../../shared/services/data-service/data-service.service';
import { ToastrService } from 'ngx-toastr';
import { environment } from '../../../environments/environment';
import {ArticleDetailService } from '../article-detail/article-detail.service';
import Handsontable from 'handsontable';
import {CookieService} from 'ngx-cookie';
import {Router, ActivatedRoute} from '@angular/router';

@Component({
  selector: 'app-actions-list',
  templateUrl: './actions-list.component.html',
  styleUrls: ['./actions-list.component.scss']
})
export class ActionsListComponent implements OnInit {
  pageNo: any = 0;
  showLoader: Boolean = false;
  actionsListTableData: Array<any> = [];
  usersList: Array<any> = [];
  usersListForDropdown: Array<any> = [];
  updatedActionRowsData: Array<any> = [];
  columns: Array<any> = [
    {
      title:"Action No.",
      data: 'number',
      readOnly: true,
      width: '50px',
      // cellClass: 'article-no-text',
      isHidden: false,
    },
    {
      title: "Action",
      data: 'action',
      width: '180px',
      // readOnly: true,
      isHidden: false,
      renderer: function(instance, td, row, col, prop, value, cellProperties) {
        Handsontable.renderers.TextRenderer.apply(this, arguments);
        if(value){
          var todayDate = new Date();
          if(value.length > 150){
            // td.innerHTML = value.substring(0,150) + '...';
            let wrapper = document.createElement('div');
            let div1 = document.createElement('div');
            wrapper.style.cssText = 'display:flex';
            div1.innerHTML = value.substring(0,150) + '...';
            wrapper.appendChild(div1);
            let currentRowData = instance.getDataAtRow(row);
            let dueDate = currentRowData[4];
            let isPast;
            
            if(dueDate){
              let tempDueDate = dueDate.split('-');
              let dateToCheck = tempDueDate[1]+'-'+tempDueDate[0]+'-'+tempDueDate[2];
              let dDate = new Date(dateToCheck);
              if(dDate.getFullYear() <= todayDate.getFullYear() )//check the year
            { 
              // console.log("date1+date2"+this.dateString1.getFullYear()+this.dateString2.getFullYear())
              if(dDate.getMonth() <= todayDate.getMonth())//check the month
                {
                  // console.log("date1+date2"+this.dateString1.getMonth()+this.dateString2.getMonth())
                  if(dDate.getDate() < todayDate.getDate())//check the date
                  {
                    isPast = true;
                  }
                }
            }else{
              isPast = false;
            }
            if(isPast){
              let div2 = document.createElement('div');
              let img = document.createElement('img');
              img.src = '../../../assets/images/icon-clock.svg';
              div2.appendChild(img);
              div2.style.cssText = 'margin-left:4px;';
              wrapper.appendChild(div2);
            }
            }
            
            
            Handsontable.dom.empty(td);
            td.appendChild(wrapper);
          }else{
            // td.innerHTML = value;
            let wrapper = document.createElement('div');
            let div1 = document.createElement('div');
            wrapper.style.cssText = 'display:flex';
            div1.innerHTML = value;
            wrapper.appendChild(div1);
            let currentRowData = instance.getDataAtRow(row);
            let dueDate = currentRowData[4];
            if(dueDate){
              let tempDueDate = dueDate.split('-');
            let dateToCheck = tempDueDate[1]+'-'+tempDueDate[0]+'-'+tempDueDate[2];
            let dDate = new Date(dateToCheck);
            let isPast;
            if(dDate.getFullYear() <= todayDate.getFullYear() )//check the year
            { 
              // console.log("date1+date2"+this.dateString1.getFullYear()+this.dateString2.getFullYear())
              if(dDate.getMonth() <= todayDate.getMonth())//check the month
                {
                  // console.log("date1+date2"+this.dateString1.getMonth()+this.dateString2.getMonth())
                  if(dDate.getDate() < todayDate.getDate())//check the date
                  {
                    isPast = true;
                  }
                }
            }else{
              isPast = false;
            }
            if(isPast){
              let div2 = document.createElement('div');
              let img = document.createElement('img');
              img.src = '../../../assets/images/icon-clock.svg';
              div2.appendChild(img);
              div2.style.cssText = 'margin-left:4px;';
              wrapper.appendChild(div2);
            }
            }
            
            Handsontable.dom.empty(td);
            td.appendChild(wrapper);
          }
          td.title = value;
        }
        return td;
      }
    },
    {
      title: "Action Owner",
      data: 'owner',
      width: '150px',
      isHidden: false,
      // readOnly: true
      type: 'dropdown',
      allowInvalid: false,
    },
    {
      title: "Action Status",
      data: 'status',
      width: '70px',
      isHidden: false,
      // readOnly: true,
      type: 'dropdown',
      source: ['New', 'In Progress', 'Completed'],
      
    },
    {
      title: "Action Due date",
      data: 'due_date',
      width: '80px',
      isHidden: false,
      // readOnly: true
      type: 'date',
      dateFormat: 'DD-MM-YYYY',
      correctFormat: true,
      // datePicker additional options (see https://github.com/dbushell/Pikaday#configuration)
      datePickerConfig: {
        onOpen(){
          const container: any = document.querySelector('.htDatepickerHolder');
          const left = parseInt(this.el.offsetParent.style.left, 10);
          container.style.left = (left - 114) + 'px';
          },
        // First day of the week (0: Sunday, 1: Monday, etc)
        firstDay: 0,
        showWeekNumber: true,
        numberOfMonths: 1
      }
    },
    {
      title: "Article/Reporting <br/> Element No.",
      data: 'article_no',
      width: '100px',
      isHidden: false,
      readOnly: true,
      cellClass: 'article-no-text',
    },
    {
      title: "Document",
      data: 'document',
      width: '100px',
      readOnly: true,
      isHidden: false,
    },
    {
      title: " ",
      data: 'actionId',
      width: '50px',
      readOnly: true,
      isHidden: false,
      renderer: function(instance, td, row, col, prop, value, cellProperties) {
        Handsontable.renderers.TextRenderer.apply(this, arguments);
        let wrapper = document.createElement('div');
        let deleteIcon  = document.createElement('i');
        deleteIcon.classList.add('fa');
        deleteIcon.classList.add('fa-trash');
        deleteIcon.style.cssText = 'font-size:14px;cursor:pointer;outline:none';
        deleteIcon.setAttribute('id', `id_${value}`) 
        
        wrapper.appendChild(deleteIcon);
        
        Handsontable.dom.empty(td);
        td.appendChild(wrapper);
        
        td.title = 'delete';
        
        return td;
      }
    },
  ];
  hotSettings: Handsontable.GridSettings;
  id: any = 'actions-list-table';
  tableInstance: Handsontable;

  constructor(
    private _dataService: DataServiceService,
    private _toastr: ToastrService,
    private _articleDetailService: ArticleDetailService,
    private _cookie: CookieService,
    private router: Router

  ) { }

  ngOnInit(): void {
    this.getUsersList();
    this.getActions();
  }

  
  prepareTable(): any{
    var self = this;
    this.hotSettings = {
      width: '100%',
      rowHeights: 50,
      columnHeaderHeight: 50,
      stretchH: 'all',
      data: this.actionsListTableData,
      licenseKey: 'non-commercial-and-evaluation',
      columns: this.columns,
      filters: true,
      dropdownMenu: ['filter_action_bar', 'filter_by_value'],
      afterChange: (changes, src) => {
        if (changes && changes.length) {
          for (let i = 0; i < changes.length; i++) {
            if (changes[i][1] == 'action' || changes[i][1] == 'owner' || changes[i][1] == 'status' || changes[i][1] == 'due_date') {
              const oldValue = changes[i][2];
              const newValue = changes[i][3];
              if (newValue != oldValue) {
                const rowIndex = changes[i][0];
                const existIndex = self.updatedActionRowsData.findIndex(item => item.actionId == self.actionsListTableData[rowIndex].actionId);
                if (self.updatedActionRowsData.length == 0) {
                  self.updatedActionRowsData.push(self.actionsListTableData[rowIndex]);
                } else {
                  if (existIndex == -1) {
                    self.updatedActionRowsData.push(self.actionsListTableData[rowIndex]);
                  }
                }
              }
            }
          }
        }
      },
      cells(row, col, prop) {
        const cellProperties = this;
        if (col == 2 && prop == 'owner') {

          cellProperties.type = 'dropdown';
          const val = self.usersListForDropdown;

          if (typeof val != 'undefined') {
            cellProperties.source = val;
          }
        }

        if(col == 1 && row >= 0){
          this.renderer = function(instance, td, row, col, prop, value, cellProperties) {
            Handsontable.renderers.TextRenderer.apply(this, arguments);
            if(value){
              var todayDate = new Date();
              if(value.length > 150){
                // td.innerHTML = value.substring(0,150) + '...';
                let wrapper = document.createElement('div');
                let div1 = document.createElement('div');
                wrapper.style.cssText = 'display:flex';
                div1.innerHTML = value.substring(0,150) + '...';
                wrapper.appendChild(div1);
                let currentRowData = instance.getDataAtRow(row);
                let dueDate = currentRowData[4];
                let isPast;
                
                if(dueDate){
                  let tempDueDate = dueDate.split('-');
                  let dateToCheck = tempDueDate[1]+'-'+tempDueDate[0]+'-'+tempDueDate[2];
                  let dDate = new Date(dateToCheck);
                  if(dDate.getFullYear() <= todayDate.getFullYear() )//check the year
                { 
                  // console.log("date1+date2"+this.dateString1.getFullYear()+this.dateString2.getFullYear())
                  if(dDate.getMonth() <= todayDate.getMonth())//check the month
                    {
                      // console.log("date1+date2"+this.dateString1.getMonth()+this.dateString2.getMonth())
                      if(dDate.getDate() < todayDate.getDate())//check the date
                      {
                        isPast = true;
                      }
                    }
                }else{
                  isPast = false;
                }
                if(isPast){
                  let div2 = document.createElement('div');
                  let img = document.createElement('img');
                  img.src = '../../../assets/images/icon-clock.svg';
                  div2.appendChild(img);
                  div2.style.cssText = 'margin-left:4px;';
                  wrapper.appendChild(div2);
                }
                }
                
                
                Handsontable.dom.empty(td);
                td.appendChild(wrapper);
              }else{
                // td.innerHTML = value;
                let wrapper = document.createElement('div');
                let div1 = document.createElement('div');
                wrapper.style.cssText = 'display:flex';
                div1.innerHTML = value;
                wrapper.appendChild(div1);
                let currentRowData = instance.getDataAtRow(row);
                let dueDate = currentRowData[4];
                if(dueDate){
                  let tempDueDate = dueDate.split('-');
                let dateToCheck = tempDueDate[1]+'-'+tempDueDate[0]+'-'+tempDueDate[2];
                let dDate = new Date(dateToCheck);
                let isPast;
                if(dDate.getFullYear() <= todayDate.getFullYear() )//check the year
                { 
                  // console.log("date1+date2"+this.dateString1.getFullYear()+this.dateString2.getFullYear())
                  if(dDate.getMonth() <= todayDate.getMonth())//check the month
                    {
                      // console.log("date1+date2"+this.dateString1.getMonth()+this.dateString2.getMonth())
                      if(dDate.getDate() < todayDate.getDate())//check the date
                      {
                        isPast = true;
                      }
                    }
                }else{
                  isPast = false;
                }
                if(isPast){
                  let div2 = document.createElement('div');
                  let img = document.createElement('img');
                  img.src = '../../../assets/images/icon-clock.svg';
                  div2.appendChild(img);
                  div2.style.cssText = 'margin-left:4px;';
                  wrapper.appendChild(div2);
                }
                }
                
                Handsontable.dom.empty(td);
                td.appendChild(wrapper);
              }
              td.title = value;
            }
            return td;
          }
        }

        if(col == 7 && row >= 0){
          this.renderer = function(instance, td, row, col, prop, value, cellProperties) {
            Handsontable.renderers.TextRenderer.apply(this, arguments);
            let wrapper = document.createElement('div');
            let deleteIcon  = document.createElement('i');
            deleteIcon.classList.add('fa');
            deleteIcon.classList.add('fa-trash');
            deleteIcon.style.cssText = 'font-size:14px;cursor:pointer;outline:none';
            deleteIcon.setAttribute('id', `id_${value}`) 
            
            wrapper.appendChild(deleteIcon);
            
            Handsontable.dom.empty(td);
            td.appendChild(wrapper);
            
            td.title = 'delete';
            
            return td;
          }
        }

        return cellProperties;
      },
      afterScrollVertically() {
        const firstRowIndex = AutoRowSizePlugin.getFirstVisibleRow();
        const lastRowIndex = AutoRowSizePlugin.getLastVisibleRow();
        if (lastRowIndex == (self.actionsListTableData.length - 1)) {
          self.loadMoreData();
        }
      },
      afterOnCellMouseDown: function(event, cords, td){
        if((cords.col == 5) && cords.row != -1){
          let currentRowData = self.tableInstance.getDataAtRow(cords.row);
          let selectedRowData = self.actionsListTableData[currentRowData[0] - 1]; 
          if(selectedRowData['regulationId']){
            self.navigateToActionDetails('regulation', selectedRowData['regulationId']);  
          }else if(selectedRowData['reportingElementId']){
            self.navigateToActionDetails('reporting', selectedRowData['reportingElementId']);
          }
        }

        if(cords.col == 7 && cords.row != -1){
          let selectedRowData = self.actionsListTableData[cords.row];
          let url = environment.apiUrls.deleteAction;
          url = url.replace(':actionId', selectedRowData['actionId']);
        
          self._dataService.deleteData(url, true).toPromise()
            .then((response: any) => {
              if(response.status == 1){
                self.tableInstance.alter('remove_row', cords.row);
                self._toastr.success('Action deleted');
              }
            }, (error: any) =>{
              self._toastr.error(error.error.message);
            })
        }

      }
    };
    const container = document.getElementById(this.id);
    this.tableInstance = new Handsontable(container, this.hotSettings);
    const AutoRowSizePlugin = this.tableInstance.getPlugin('autoRowSize');
  }

  navigateToActionDetails(item, id): any {
    if(item == 'regulation'){
      const url = this.router.createUrlTree(['dashboard/article-detail', id]).toString();
      window.open(url, '_blank');
    }
    if(item == 'reporting'){
      const url = this.router.createUrlTree(['dashboard/reporting-element-detail', id]).toString();
      window.open(url, '_blank');
    }
  }

  updateActions(): void {
    let patchActionDataList = [];
    const loginUser = this._cookie.get('_id');
    this.updatedActionRowsData.forEach((item) => {
      let obj = {
        action: item.action,
        assignedBy: loginUser,
        dueDate: item.due_date
      }

      if (item.status == 'New') {
        obj['status'] = 'new';
      } else {
        if (item.status == 'In Progress') {
          obj['status'] = 'in_progress';
        } else {
          if (item.status == 'Completed') {
            obj['status'] = 'completed';
          }
        }
      }

      this.usersList.forEach((user) => {
        if (user.name == item.owner) {
          obj['actionOwnerId'] = user.id;
        }
      });

      const url = environment.apiUrls.newActionForAssessmentUrl + item.actionId;
      const patchPromise = this._dataService.patchData(url, obj, true).toPromise();
      patchActionDataList.push(patchPromise);
    })

    Promise.all(patchActionDataList).then((res) => {
      this.updatedActionRowsData = [];
      res.forEach((obj: any) => {
        if (obj.status == 1) {
          this._toastr.success(obj.message);
        } else {
          this._toastr.error(obj.message);
        }
      });
    });
    
  }

  getUsersList(): void {
    const url = environment.apiUrls.getUsersUrl;

    this._dataService.getData(url, [], true).toPromise()
      .then((response: any) => {
        if (response.status == 1) {
          this.usersList = [];
          this.usersListForDropdown = [];
          response.data.users.forEach((obj) => {
            const userObj = {
              name: obj.firstName + ' ' + obj.lastName,
              id: obj._id
            };
            this.usersList.push(userObj);
          });

          this.usersList.forEach((item) => {
            this.usersListForDropdown.push(item.name);
          });

          this.prepareTable();
        }
      }, (error) => {
        if (error.error) {
          // this._toastr.error(error.error.message);
        }
      });
  }

  loadMoreData(): void{
    this.pageNo = this.pageNo + 1;
    var url = environment.apiUrls.newActionForAssessmentUrl;

    const urlParam = [{
      key: 'page',
      val: this.pageNo
    }, {
      key: 'limit',
      val: 50
    }];

    this.showLoader = true;

    this._dataService.getData(url, urlParam, true).toPromise()
        .then((response: any) => {
            if (response.status == 1) {
                if(response.data && response.data.length){
                  response.data.forEach((item, index) => {
                    let newListObj = {
                      number: index + 1,
                      action: item.action,
                      owner: item.actionOwnerName ? item.actionOwnerName : '',
                      status: item.status,
                      due_date: null,
                      article_no: item.articleNumber,
                      document: item.documentName,
                      section: item.section,
                      actionId: item._id,
                      regulationId: item.regulationId,
                      reportingElementId: item.reportingElementId
                    };

                    newListObj.status = item.status == 'in_progress' ? 'In Progress' : item.status == 'completed' ? 'Completed' : item.status == 'new' ? 'New' : 'New';
                    if(item.dueDate){
                      var tempSplitDate = item.dueDate.split('T');
                      var tempDisplayDate = tempSplitDate[0].split('-');
                      newListObj.due_date = tempDisplayDate[2] + '-' + tempDisplayDate[1]  + '-' + tempDisplayDate[0];
  
                    }

                    this.actionsListTableData.push(newListObj);
                  });
                }
                this.showLoader = false;
                // this.tableInstance.render();
                this.tableInstance.loadData(this.actionsListTableData);
            }
        }, (error) => {
            if (error.error) {
              this.showLoader = false;
                // this._toastr.error(error.error.message);
            }
        });
  }

  

  getActions(): void {
    var url = environment.apiUrls.newActionForAssessmentUrl;

    const urlParam = [{
      key: 'page',
      val: this.pageNo
    }, {
      key: 'limit',
      val: 50
    }];

    this._dataService.getData(url, urlParam, true).toPromise()
        .then((response: any) => {
            this.actionsListTableData = [];
            if (response.status == 1) {
                if(response.data && response.data.length){
                  response.data.forEach((item, index) => {
                    let newListObj = {
                      number: index + 1,
                      action: item.action,
                      owner: item.actionOwnerName ? item.actionOwnerName : '',
                      status: item.status,
                      due_date: null,
                      article_no: item.articleNumber,
                      document: item.documentName,
                      section: item.section,
                      actionId: item._id,
                      regulationId: item.regulationId,
                      reportingElementId: item.reportingElementId
                    };

                    newListObj.status = item.status == 'in_progress' ? 'In Progress' : item.status == 'completed' ? 'Completed' : item.status == 'new' ? 'New' : 'New';
                    if(item.dueDate){
                      var tempSplitDate = item.dueDate.split('T');
                      var tempDisplayDate = tempSplitDate[0].split('-');
                      newListObj.due_date = tempDisplayDate[2] + '-' + tempDisplayDate[1]  + '-' + tempDisplayDate[0];
  
                    }

                    this.actionsListTableData.push(newListObj);
                  });
                }
                this.prepareTable();
            }
        }, (error) => {
            if (error.error) {
                // this._toastr.error(error.error.message);
            }
        });
}

}
