<div class="reset-password-page-container">
    <div class="reset-password-form-container">
        <div class="form-header-container">
            <div class="logo-container">
                <img src="../../../assets/images/regart-logo.png"/>
            </div>
            <h4 class="form-title margin-bt0">Reset Password</h4>
        </div>
        <div class="form-content-container">
            <form name="reset-password-form" #ResetPassForm="ngForm" (ngSubmit)="ResetPassForm.valid && resetPassword()">
                <div class="form-group margin-bt0">
                    <label for="newPass">New Password</label>
                    <div class="password-container">
                        <input class="form-control margin-tp8" [(ngModel)]="userUpdatePassword.newPassword" 
                            [type]="showNewPassword ? 'text' : 'password'" 
                            name="newPass" id="newPass" required #newPass="ngModel"
                            [ngClass]="{ 'is-invalid': ResetPassForm.submitted && newPass.invalid, 'form-field-invalid': ResetPassForm.submitted && newPass.invalid}"/>
                        <img (click)="showNewPassword = !showNewPassword" *ngIf="showNewPassword" src="../../../assets/images/icon-password-hide.svg" />
                        <img (click)="showNewPassword = !showNewPassword" *ngIf="!showNewPassword" src="../../../assets/images/icon-password-show.svg" />
                    </div>
                </div>
                <div class="form-group margin-bt0 margin-tp24">
                    <label for="confirmPass">Confirm Password</label>
                    <div class="password-container">
                        <input class="form-control margin-tp8" [(ngModel)]="userUpdatePassword.confirmPassword" 
                            [type]="showConfirmPassword ? 'text' : 'password'" 
                            name="confirmPass" id="confirmPass" required #confirmPass="ngModel"
                            [ngClass]="{ 'is-invalid': ResetPassForm.submitted && confirmPass.invalid, 'form-field-invalid': ResetPassForm.submitted && confirmPass.invalid}"/>
                        <img (click)="showConfirmPassword = !showConfirmPassword" *ngIf="showConfirmPassword" src="../../../assets/images/icon-password-hide.svg" />
                        <img (click)="showConfirmPassword = !showConfirmPassword" *ngIf="!showConfirmPassword" src="../../../assets/images/icon-password-show.svg" />
                    </div>
                </div>
                <div class="submit-button-container">
                    <button type="submit" class="app-form-submit-btn width100">Update Password</button>
                </div>
            </form>
        </div>
    </div>
</div>