import { Injectable }           from '@angular/core';
import { Observable }           from 'rxjs';
import { CanDeactivate,
         ActivatedRouteSnapshot,
         RouterStateSnapshot }  from '@angular/router';

import { ArticlesListComponent} from './articles-list.component';

@Injectable()
export class CanDeactivateGuard implements CanDeactivate<ArticlesListComponent> {

  canDeactivate(
    component: ArticlesListComponent,
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | boolean {
    // you can just return true or false synchronously
    let val = component.goToOtherTabs();
    return val;

  }
}