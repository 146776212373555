<div class="article-detail-view-container formula-div">
    <div style="padding-top:20px;">
        <div class="article-detail-view-header">
            <div class="article-detail-info">
                <span class="head-value" *ngFor="let ref of selectedArticleDetailsObj.fullReference; let i = index;">
                    <span>
                        <a style="cursor:pointer;" href="javascript: void(0);" (click)="navigateToReferenceLink(ref)">
                          <span *ngIf="selectedArticleDetailsObj['refDocumentType'] && i == 0">{{ selectedArticleDetailsObj['refDocumentType'] + ' '}}</span>  
                          {{ref.refText}}
                        </a>
                    </span>
                    <span *ngIf="i != selectedArticleDetailsObj.fullReference.length -1">
                        ->
                    </span>
                </span>
            </div>
            <div class="article-detail-nav">
                <!-- <span class="prev-btn" (click)="goToPreviousArticle()">
                    <i class="fa fa-chevron-left"></i>Previous
                </span>
                <span class="next-btn" (click)="goToNextArticle()">
                    Next<i class="fa fa-chevron-right"></i>
                </span> -->
            </div>
        </div>

        <!--current version article-->
        <div class="article-detail-section">
            <div class="row">
                <div class="col-md-6">
                    <div class="row">
                        <div class="col-md-12">
                            <div class="element-info-label">
                                <span style="margin-right:10px;" *ngIf="selectedArticleDetailsObj.regulations && selectedArticleDetailsObj.regulations.amendmentFlag == 'star'">
                                    <img src="../../../assets/images/icon-star.svg">
                                </span>
                                <span>Article</span>
                                <span (click)="goToParentArticle()" class="goto-parent-icon-css" title="go to parent" *ngIf="selectedArticleDetailsObj.regulations && selectedArticleDetailsObj.regulations.parentId">
                                    <i class="fa fa-level-up"></i>
                                </span>
                                <span *ngIf="selectedArticleDetailsObj.regulations && selectedArticleDetailsObj.regulations.amendmentSourceDocument" style="margin-left:15px;">
                                    {{'(Source: ' + selectedArticleDetailsObj['refDocumentType'] + ' ' + selectedArticleDetailsObj.regulations.amendmentSourceDocument + ')'}}
                                </span>
                                <span style="margin-left:10px;">
                                    <button type="button" class="add-new-action-btn" data-toggle="modal" *ngIf="imagesListArticle.length" data-target="#ImagesModal">
                                        IMAGES
                                    </button>
                                </span>
                                <span style="margin-left:15px;" *ngIf="selectedArticleDetailsObj.regulations && selectedArticleDetailsObj.regulations.enforcementDate">
                                    Enforcement Date - {{selectedArticleDetailsObj.regulations.enforcementDate}}
                                </span>
                                <span style="margin-left:15px;" *ngIf="selectedArticleDetailsObj.regulations && selectedArticleDetailsObj.regulations.nextAmendmentType == 'delete'">
                                    (This article is not in force)
                                </span>
                            </div>
                            <div id="target">
                                <ul class='custom-menu'>
                                    <li data-action = "first" (click)="copyData()">Copy</li>
                                    <ng-container *ngIf="clonedDataonRightClick?.checkOpen">
                                        <li data-action = "second" (click)="openNodeData()">Open...</li>
                                        <li data-action = "third" (click)="openNewTab()">Open in New Tab</li>
                                    </ng-container>
                                </ul>
                                <tree-root [nodes]="nodesData" [options]="nodeOptions" (toggleExpanded)="onNodeExpander($event)">
                                    <ng-template #treeNodeTemplate let-node let-index="index">
                                        
                                        <span style="margin-right:10px;display: inline-flex;">
                                            <img *ngIf="node.data.amendmentFlag == 'star'" src="../../../assets/images/icon-star.svg">
                                            <span *ngIf="node.data.amendmentFlag == 'new'" class="amendmend-flag-new-node">New</span>
                                            <span *ngIf="node.data.amendmentFlag == 'delete'" class="amendmend-flag-delete-node">Deleted</span>
                                            <span *ngIf="node.data.amendmentFlag == 'tbd'" class="amendmend-flag-tbd-node">TBD</span>
                                            <span *ngIf="node.data.amendmentType == 'actual'" style="display:flex;margin-left:4px;">
                                                <!-- <span style="width:0;height:0;border-left:15px solid transparent;border-right:15px solid transparent;border-bottom:15px solid #e6e6e6;"></span> -->
                                                    <i class="fa fa-caret-up" style="color:grey;font-size:25px;"></i>
                                            </span>
                                            <span *ngIf="node.data.amendmentType == 'rolled'" style="margin-top:-6px;margin-left:4px;">
                                                <!-- <span style="position:relative;width:0;border-bottom:solid 15px #e6e6e6;border-right:solid 15px transparent;border-left:solid 15px transparent;">
                                                    <span style="position:absolute;top:2px;left:-12px;width:0;border-bottom:solid 12px white;border-right:solid 12px transparent;border-left:solid 12px transparent;"></span>
                                                </span> -->
                                                <i class="fa fa-caret-up" style="color:grey;position: relative;font-size:25px;">
                                                    <i class="fa fa-caret-up" style="color: white;position: absolute;font-size:18px;top:3px;right:2px;"></i>
                                                </i>
                                            </span>
                                        </span>
                                        <!-- {{node.data.name}} -->
                                        <span class="formula-div" *ngIf="(node.data.isRelevant == false || node.data.isRelevant == null) && !istable" style="border-left:4px lightgray solid;padding-left:4px" [innerHTML]="node.data.name"></span>
                                        <span class="formula-div" *ngIf="node.data.isRelevant == true && !istable &&  (node.data.compliantStatus == 'none' || node.data.compliantStatus == null)" style="border-left:4px #b32d00 solid;padding-left:4px" [innerHTML]="node.data.name"></span>
                                        <span class="formula-div" *ngIf="node.data.isRelevant == true && node.data.compliantStatus == 'partial' && !istable" style="border-left:4px #ffcc00 solid;padding-left:4px" [innerHTML]="node.data.name"></span>
                                        <span class="formula-div" *ngIf="node.data.isRelevant == true && node.data.compliantStatus == 'full' && !istable" style="border-left:4px #008000 solid;padding-left:4px" [innerHTML]="node.data.name"></span>
                                        <span class="formula-div" *ngIf="node.data.isRelevant == true && node.data.compliantStatus == 'not_assessed' && !istable" style="border-left:4px grey solid;padding-left:4px" [innerHTML]="node.data.name"></span>
                                        <span class="formula-div" *ngIf="node.data.isRelevant == true && node.data.compliantStatus == 'not_applicable' && !istable" style="border-left:4px lightblue solid;padding-left:4px" [innerHTML]="node.data.name"></span>
                                        <span class="formula-div article-details-tables" *ngIf="istable"  [innerHTML]="sanitizer.bypassSecurityTrustHtml(node.data.name)"></span>
                                        <!--tables as images-->
                                        <span style="display:block;" id="articletableImages" class="formula-div article-details-tables" *ngIf="!istable && selectedArticleDetailsObj.regulations && selectedArticleDetailsObj.regulations.text && 
                                            selectedArticleDetailsObj.regulations.text.includes('Table') &&
                                            selectedArticleDetailsObj.regulations.images && selectedArticleDetailsObj.regulations.images.length">
                                            <img [src]="selectedArticleDetailsObj.regulations.images[0]" />
                                        </span>
                                        <!--tables as images-->
                                    </ng-template>
                                </tree-root>
                                
                            </div>
                            <!--tables-->
                            <!-- <div *ngIf="selectedArticleDetailsObj.regulations && selectedArticleDetailsObj.regulations['tablesDisplay']">
                            <div *ngFor="let table of selectedArticleDetailsObj.regulations['tablesDisplay']; let i = index;">
                                <div style="margin-top:15px;margin-bottom:15px;font-weight: bold;font-size: 20px;">Table {{i + 1}}:
                                    <span *ngIf="selectedArticleId == '601846ae5ca33e6e9bbfa49e' || selectedArticleId == '6018469a5ca33e6e9bbebe52'">{{demoTablesLabel[i]}}</span>
                                </div>
                                <div style="margin-top:20px;font-size:14px;font-family:'Montserrat Medium'" style="overflow-x: auto;" [innerHTML]="table"></div>
                            </div>
                            </div> -->
                            <!-- <div [innerHTML]="tabletest"></div> -->
                            <!--tables-->
                        </div>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="row">
                        <div class="col-md-4">
                            <div>
                                <div class="element-info-label">Relevance</div>
                                <div class="element-info-value">
                                    <ng-select id="elementRelevance" style="width:125px;" [items]="relevanceOptions" *ngIf="selectedArticleDetailsObj.regulations" bindLabel="label" bindValue="value" 
                                        (change)="onChangeRelevance('current')" [clearable]="false" [(ngModel)]="selectedArticleDetailsObj.regulations.isRelevant">
                                        <ng-template ng-label-tmp let-item="item">
                                            <div style="display:flex;">
                                                <div style="width:4px;border-radius:4px;margin-right:4px;" [style.background]="item.barColor"></div>
                                                <div>{{item.label}}</div>
                                            </div>
                                        </ng-template>
                                    </ng-select>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div >
                                <div class="element-info-label">Compliance</div>
                                <div class="element-info-value">
                                    <ng-select id="elementCompliance" *ngIf="selectedArticleDetailsObj.regulations" [disabled]="selectedArticleDetailsObj.regulations.isRelevant == false" [clearable]="false"
                                        style="width:150px;" [items]="complianceOptions" bindLabel="label" bindValue="value"
                                        (change)="updateCompliantStatus('currentVersion')" [(ngModel)]="selectedArticleDetailsObj.regulations.compliantStatus">
                                        <ng-template ng-label-tmp let-item="item">
                                            <div style="display:flex;" >
                                                <div style="width:4px;border-radius:4px;margin-right:4px;padding:2px;" [style.background]="item.barColor"></div>
                                                <div>{{item.label}}</div>
                                            </div>
                                        </ng-template>
                                    </ng-select>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div>
                                <div class="element-info-label">Assessment Owner</div>
                                <div class="element-info-value">
                                   <ng-select id="elementAssessmentOwner" [items]="usersList" *ngIf="selectedArticleDetailsObj.assessments" [clearable]="false" bindLabel="name" bindValue="id"
                                        (change)="updateAssessment('current')" [(ngModel)]="selectedArticleDetailsObj.assessments.assessmentOwnerId">
                                            <ng-template ng-label-tmp let-item="item">
                                                <div>{{item.name}}</div>
                                            </ng-template>
                                    </ng-select>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12" style="margin-top:20px;">
                            <div class="element-info-label">
                                Assessment
                                <span style="width:16px;cursor:pointer;" (click)="editAssessmentFlag = !editAssessmentFlag">
                                    <img src="../../../assets/images/icon-edit.svg" />
                                </span>
                                <span *ngIf="editAssessmentFlag" (click)="updateAssessment('current')" style="margin-left:8px;cursor:pointer;">
                                    <i style="font-size:18px;" class="fa fa-save"></i>
                                </span>
                            </div>
                            <div *ngIf="!editAssessmentFlag && selectedArticleDetailsObj.assessments" class="element-info-value" style="white-space: pre-line;overflow-wrap:break-word;" [innerHTML]="selectedArticleDetailsObj.assessments.comment"></div>
                            <div *ngIf="editAssessmentFlag && selectedArticleDetailsObj.assessments" class="element-info-value">
                                <textarea rows="15" [(ngModel)]="selectedArticleDetailsObj.assessments.comment" style="resize:none;border:1px solid lightgray;border-radius:5px;width:100%;">
                                </textarea>
                            </div>
                        </div>
                        <!--regart Comment-->
                        <div class="col-md-12" style="margin-top:20px;" *ngIf="selectedArticleDetailsObj.regulations && selectedArticleDetailsObj.regulations.regartComment && selectedArticleDetailsObj.regulations.regartComment.length">
                            <div class="element-info-label">Regart Comment</div>
                            <div class="element-info-value" *ngFor="let comment of selectedArticleDetailsObj.regulations.regartComment">
                                <div [innerHTML]="comment"></div>
                            </div>
                        </div> 
                        <!--regart Comment-->
                    </div>
                    <div class="row" style="margin-top:20px;">
                        <div class="col-md-4">
                            <div class="element-info-label">
                                Tags
                                <span style="width:16px;cursor:pointer;" (click)="editTagsFlag = !editTagsFlag">
                                    <img src="../../../assets/images/icon-edit.svg" />
                                </span>
                                <span *ngIf="editTagsFlag" (click)="updateTags()" style="margin-left:8px;cursor:pointer;">
                                    <i style="font-size:18px;" class="fa fa-save"></i>
                                </span>
                            </div>
                            <div *ngIf="!editTagsFlag && selectedArticleDetailsObj.regulations" class="element-info-value" style="white-space: pre-line;overflow-wrap:break-word;" [innerHTML]="selectedArticleDetailsObj.regulations.tags"></div>
                            <div *ngIf="editTagsFlag && selectedArticleDetailsObj.regulations" class="element-info-value">
                                <textarea rows="5" [(ngModel)]="selectedArticleDetailsObj.regulations.tags" style="resize:none;border:1px solid lightgray;border-radius:5px;width:100%;">
                                </textarea>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="element-info-label">
                                Effective
                                <span style="width:16px;cursor:pointer;" (click)="editEffectiveFlag = !editEffectiveFlag">
                                    <img src="../../../assets/images/icon-edit.svg" />
                                </span>
                                <span *ngIf="editEffectiveFlag" (click)="updateTags()" style="margin-left:8px;cursor:pointer;">
                                    <i style="font-size:18px;" class="fa fa-save"></i>
                                </span>
                            </div>
                            <div *ngIf="!editEffectiveFlag && selectedArticleDetailsObj.regulations" class="element-info-value" style="white-space: pre-line;overflow-wrap:break-word;" [innerHTML]="selectedArticleDetailsObj.regulations.effective"></div>
                            <div *ngIf="editEffectiveFlag && selectedArticleDetailsObj.regulations" class="element-info-value">
                                <textarea rows="5" [(ngModel)]="selectedArticleDetailsObj.regulations.effective" style="resize:none;border:1px solid lightgray;border-radius:5px;width:100%;">
                                </textarea>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="element-info-label">
                                Controls
                                <span style="width:16px;cursor:pointer;" (click)="editControlsFlag = !editControlsFlag">
                                    <img src="../../../assets/images/icon-edit.svg" />
                                </span>
                                <span *ngIf="editControlsFlag" (click)="updateTags()" style="margin-left:8px;cursor:pointer;">
                                    <i style="font-size:18px;" class="fa fa-save"></i>
                                </span>
                            </div>
                            <div *ngIf="!editControlsFlag && selectedArticleDetailsObj.regulations" class="element-info-value" style="white-space: pre-line;overflow-wrap:break-word;" [innerHTML]="selectedArticleDetailsObj.regulations.controls"></div>
                            <div *ngIf="editControlsFlag && selectedArticleDetailsObj.regulations" class="element-info-value">
                                <textarea rows="5" [(ngModel)]="selectedArticleDetailsObj.regulations.controls" style="resize:none;border:1px solid lightgray;border-radius:5px;width:100%;">
                                </textarea>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-7"></div>
                <div class="col-md-5">
                    <div class="row">
                        <div class="col-md-12">
                            <div class="article-actions-list" style="margin-top:15px;">
                                <table class="width100" *ngIf="actionsListData && actionsListData.length">
                                    <thead>
                                        <tr>
                                            <th>Action</th>
                                            <th>Action Owner/Department</th>
                                            <th>Action Status</th>
                                            <th>Action Due Date</th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let item of actionsListData;">
                                            <td style="width:300px;white-space: pre-line;text-align:left;">{{item.action}}</td>
                                            <td>{{item.actionOwnerName}}<br>{{item.actionOwnerDepartment}}</td>
                                            <td>{{item.status == 'in_progress' ? 'In Progress' : item.status == 'completed' ? 'Completed' : item.status == 'new' ? 'New' : 'New'}}</td>
                                            <td>{{item.displayDueDate}}</td>
                                            <td style="display: inline-flex;">
                                                <span (click)="updateSelectedActionModal(item, 'current')" style="cursor:pointer;outline:none;">
                                                    <img src="../../../assets/images/icon-edit.svg" style="height:14px;"/>
                                                </span>
                                                <span  style="cursor:pointer;outline:none;margin-left:10px;">
                                                    <i (click)="deleteAction(item._id, 'currentVersion')" class="fa fa-trash" style="font-size:14px;"></i>
                                                </span>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div>
                                <button type="button" *ngIf="showAction" (click)="clearActionModalData('current')" class="add-new-action-btn" data-toggle="modal" data-target="#addNewActionModal">
                                    <img src="../../../assets/images/icon-new-add.svg" />
                                    <span>New Action</span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!--future version article-->
        <div class="article-detail-section" style="margin-top:25px;" *ngIf="selectedArticleFutureVersionObj && selectedArticleFutureVersionObj.regulations != null">
            <div class="row">
                <div class="col-md-6">
                    <div class="row">
                        <div class="col-md-12">
                            <div class="element-info-label">
                                <span>Article Future Version</span>
                                <span *ngIf="selectedArticleFutureVersionObj && selectedArticleFutureVersionObj.regulations && selectedArticleFutureVersionObj.regulations.amendmentSourceDocument" style="margin-left:15px;">
                                    {{'(Source: ' + selectedArticleFutureVersionObj['refDocumentType'] + ' ' + selectedArticleFutureVersionObj.regulations.amendmentSourceDocument  + ')'}}
                                </span>
                                <span style="margin-left:15px;" *ngIf="selectedArticleFutureVersionObj && selectedArticleFutureVersionObj.regulations && selectedArticleFutureVersionObj.regulations.enforcementDate">
                                    Enforcement Date - {{selectedArticleFutureVersionObj.regulations.enforcementDate}}
                                </span>
                                <span style="margin-left:15px;" *ngIf="selectedArticleFutureVersionObj && selectedArticleFutureVersionObj.regulations && selectedArticleFutureVersionObj.regulations.amendmentType == 'delete'">
                                    (This article is not in force)
                                </span>
                            </div>
                            <div>
                                <tree-root  [nodes]="futureVersionNodesData" [options]="futureVersionNodeOptions">
                                    <ng-template #treeNodeTemplate let-node let-index="index">
                                        <span style="margin-right:10px;display: inline-flex;">
                                            <img *ngIf="node.data.amendmentFlag == 'star'" src="../../../assets/images/icon-star.svg">
                                            <span *ngIf="node.data.amendmentFlag == 'new'" class="amendmend-flag-new-node">New</span>
                                            <span *ngIf="node.data.amendmentFlag == 'delete'" class="amendmend-flag-delete-node">Deleted</span>
                                            <span *ngIf="node.data.amendmentFlag == 'tbd'" class="amendmend-flag-tbd-node">TBD</span>
                                            
                                            <span *ngIf="node.data.amendmentType == 'actual'" style="display:flex;margin-left:4px;">
                                                <!-- <span style="width:0;height:0;border-left:15px solid transparent;border-right:15px solid transparent;border-bottom:15px solid #e6e6e6;"></span> -->
                                                    <i class="fa fa-caret-up" style="color:grey;font-size:25px;"></i>
                                            </span>
                                            <span *ngIf="node.data.amendmentType == 'rolled'" style="margin-top:-6px;margin-left:4px;">
                                                <!-- <span style="position:relative;width:0;border-bottom:solid 15px #e6e6e6;border-right:solid 15px transparent;border-left:solid 15px transparent;">
                                                    <span style="position:absolute;top:2px;left:-12px;width:0;border-bottom:solid 12px white;border-right:solid 12px transparent;border-left:solid 12px transparent;"></span>
                                                </span> -->
                                                <i class="fa fa-caret-up" style="color:grey;position: relative;font-size:25px;">
                                                    <i class="fa fa-caret-up" style="color: white;position: absolute;font-size:18px;top:3px;right:2px;"></i>
                                                </i>
                                            </span>
                                        </span>
                                        <span *ngIf="node.data.isRelevant == false || node.data.isRelevant == null" style="border-left:4px lightgray solid;padding-left:4px" [innerHTML]="node.data.name"></span>
                                        <span *ngIf="node.data.isRelevant == true && (node.data.compliantStatus == 'none' || node.data.compliantStatus == null)" style="border-left:4px #b32d00 solid;padding-left:4px;" [innerHTML]="node.data.name"></span>
                                        <span *ngIf="node.data.isRelevant == true && node.data.compliantStatus == 'partial'" style="border-left:4px #ffcc00 solid;padding-left:4px;" [innerHTML]="node.data.name"></span>
                                        <span *ngIf="node.data.isRelevant == true && node.data.compliantStatus == 'full'" style="border-left:4px #008000 solid;padding-left:4px;" [innerHTML]="node.data.name"></span>
                                        <span *ngIf="node.data.isRelevant == true && node.data.compliantStatus == 'not_assessed'" style="border-left:4px grey solid;padding-left:4px;" [innerHTML]="node.data.name"></span>
                                        <span *ngIf="node.data.isRelevant == true && node.data.compliantStatus == 'not_applicable'" style="border-left:4px lightblue solid;padding-left:4px" [innerHTML]="node.data.name"></span>
                                    </ng-template>
                                </tree-root>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="row">
                        <div class="col-md-4">
                            <div>
                                <div class="element-info-label">Relevance</div>
                                <div class="element-info-value">
                                    <ng-select id="elementRelevancefuture" style="width:125px;" *ngIf="selectedArticleFutureVersionObj.regulations" [clearable]="false" [items]="relevanceOptions" bindLabel="label"
                                        bindValue="value" (change)="onChangeRelevance('futureVersion')" [(ngModel)]="selectedArticleFutureVersionObj.regulations.isRelevant">
                                        <ng-template ng-label-tmp let-item="item">
                                            <div style="display:flex;">
                                                <div style="width:4px;border-radius:4px;margin-right:4px;" [style.background]="item.barColor"></div>
                                                <div>{{item.label}}</div>
                                            </div>
                                        </ng-template>
                                    </ng-select>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div>
                                <div class="element-info-label">Compliance</div>
                                <div class="element-info-value">
                                    <ng-select id="elementCompliancefuture" style="width:150px;" *ngIf="selectedArticleFutureVersionObj.regulations" [disabled]="selectedArticleFutureVersionObj.regulations.isRelevant == false"
                                        [items]="complianceOptions" bindLabel="label" bindValue="value" [clearable]="false"
                                        (change)="updateCompliantStatus('futureVersion')" [(ngModel)]="selectedArticleFutureVersionObj.regulations.compliantStatus">
                                        <ng-template ng-label-tmp let-item="item">
                                            <div style="display:flex;">
                                                <div style="width:4px;border-radius:4px;margin-right:4px;padding:2px;" [style.background]="item.barColor"></div>
                                                <div>{{item.label}}</div>
                                            </div>
                                        </ng-template>
                                    </ng-select>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div>
                                <div class="element-info-label">Assessment Owner</div>
                                <div class="element-info-value">
                                    <ng-select id="elementAssessmentOwnerFuture" *ngIf="selectedArticleFutureVersionObj.assessments" [items]="usersList" bindLabel="name" bindValue="id" [clearable]="false"
                                        (change)="updateAssessment('futureVersion')" [(ngModel)]="selectedArticleFutureVersionObj.assessments.assessmentOwnerId">
                                        <ng-template ng-label-tmp let-item="item">
                                            <div>{{item.name}}</div>
                                        </ng-template>
                                    </ng-select>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12" style="margin-top:20px;">
                            <div class="element-info-label">
                                Assessment
                                <span style="width:16px;cursor:pointer;" (click)="editFutureVersionAssessmentFlag = !editFutureVersionAssessmentFlag">
                                    <img src="../../../assets/images/icon-edit.svg" />
                                </span>
                                <span *ngIf="editFutureVersionAssessmentFlag" (click)="updateAssessment('futureVersion')" style="margin-left:8px;cursor:pointer;">
                                    <i style="font-size:18px;" class="fa fa-save"></i>
                                </span>
                            </div>
                            <div *ngIf="!editFutureVersionAssessmentFlag && selectedArticleFutureVersionObj.assessments" class="element-info-value">{{selectedArticleFutureVersionObj.assessments.comment}}</div>
                            <div *ngIf="editFutureVersionAssessmentFlag && selectedArticleFutureVersionObj.assessments" class="element-info-value">
                                <textarea [(ngModel)]="selectedArticleFutureVersionObj.assessments.comment" rows="15" style="resize:none;border:1px solid lightgray;border-radius:5px;width:100%;">
                                </textarea>
                            </div>
                        </div>
                        <!--regart Comment-->
                        <div class="col-md-12" style="margin-top:20px;" *ngIf="selectedArticleFutureVersionObj.regulations && selectedArticleFutureVersionObj.regulations.regartComment && selectedArticleFutureVersionObj.regulations.regartComment.length">
                            <div class="element-info-label">Regart Comment</div>
                            <div class="element-info-value" *ngFor="let comment of selectedArticleFutureVersionObj.regulations.regartComment">
                                <div [innerHTML]="comment"></div>
                            </div>
                        </div> 
                        <!--regart Comment-->
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-7"></div>
                <div class="col-md-5">
                    <div class="row">
                        <div class="col-md-12">
                            <div class="article-actions-list" style="margin-top:15px;">
                                <table class="width100" *ngIf="futureVersionActionsListData && futureVersionActionsListData.length">
                                    <thead>
                                        <tr>
                                            <th>Action</th>
                                            <th>Action Owner/Department</th>
                                            <th>Action Status</th>
                                            <th>Action Due Date</th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let item of futureVersionActionsListData;">
                                            <td style="width:300px;white-space: pre-line;text-align:left;">{{item.action}}</td>
                                            <td>{{item.actionOwnerName}}<br>{{item.actionOwnerDepartment}}</td>
                                            <td>{{item.status == 'in_progress' ? 'In Progress' : item.status == 'completed' ? 'Completed' : item.status == 'new' ? 'New' : 'New'}}</td>
                                            <td>{{item.displayDueDate}}</td>
                                            <td style="display: inline-flex;">
                                                <span (click)="updateSelectedActionModal(item, 'futureVersion')" style="cursor:pointer;outline:none;">
                                                    <img src="../../../assets/images/icon-edit.svg" style="height:14px;"/>
                                                </span>
                                                <span  style="cursor:pointer;outline:none;margin-left:10px;">
                                                    <i (click)="deleteAction(item._id, 'futureVersion')" class="fa fa-trash" style="font-size:14px;"></i>
                                                </span>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div>
                                <button type="button" *ngIf="showFutureVersionAction" (click)="clearActionModalData('futureVersion')" class="add-new-action-btn" data-toggle="modal" data-target="#addNewActionFutureVersionModal">
                                    <img src="../../../assets/images/icon-new-add.svg" />
                                    <span>New Action</span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        

        <!--previous version article-->
        <div class="article-detail-section" style="margin-top:25px;" *ngIf="selectedArticlePreviousVersionObj && selectedArticlePreviousVersionObj.regulations != null">
            <div class="row">
                <div class="col-md-6">
                    <div class="row">
                        <div class="col-md-12">
                            <div class="element-info-label">
                                <span>Article Previous Version</span>
                                <span *ngIf="selectedArticlePreviousVersionObj && selectedArticlePreviousVersionObj.regulations && selectedArticlePreviousVersionObj.regulations.amendmentSourceDocument" style="margin-left:15px;">
                                    {{'(Source: ' + selectedArticlePreviousVersionObj['refDocumentType'] + ' ' + selectedArticlePreviousVersionObj.regulations.amendmentSourceDocument  + ')'}}
                                </span>
                                <span style="margin-left:15px;" *ngIf="selectedArticlePreviousVersionObj && selectedArticlePreviousVersionObj.regulations && selectedArticlePreviousVersionObj.regulations.enforcementDate">
                                    Enforcement Date - {{selectedArticlePreviousVersionObj.regulations.enforcementDate}}
                                </span>
                                <span style="margin-left:15px;" *ngIf="selectedArticlePreviousVersionObj && selectedArticlePreviousVersionObj.regulations && selectedArticlePreviousVersionObj.regulations.amendmentType == 'delete'">
                                    (This article is not in force)
                                </span>
                            </div>
                            <div>
                                <tree-root  [nodes]="previousVersionNodesData" [options]="previousVersionNodeOptions">
                                    <ng-template #treeNodeTemplate let-node let-index="index">
                                        <span style="margin-right:10px;display: inline-flex;">
                                            <img *ngIf="node.data.amendmentFlag == 'star'" src="../../../assets/images/icon-star.svg">
                                            <span *ngIf="node.data.amendmentFlag == 'new'" class="amendmend-flag-new-node">New</span>
                                            <span *ngIf="node.data.amendmentFlag == 'delete'" class="amendmend-flag-delete-node">Deleted</span>
                                            <span *ngIf="node.data.amendmentFlag == 'tbd'" class="amendmend-flag-tbd-node">TBD</span>
                                            
                                            <span *ngIf="node.data.amendmentType == 'actual'" style="display:flex;margin-left:4px;">
                                                <!-- <span style="width:0;height:0;border-left:15px solid transparent;border-right:15px solid transparent;border-bottom:15px solid #e6e6e6;"></span> -->
                                                    <i class="fa fa-caret-up" style="color:grey;font-size:25px;"></i>
                                            </span>
                                            <span *ngIf="node.data.amendmentType == 'rolled'" style="margin-top:-6px;margin-left:4px;">
                                                <!-- <span style="position:relative;width:0;border-bottom:solid 15px #e6e6e6;border-right:solid 15px transparent;border-left:solid 15px transparent;">
                                                    <span style="position:absolute;top:2px;left:-12px;width:0;border-bottom:solid 12px white;border-right:solid 12px transparent;border-left:solid 12px transparent;"></span>
                                                </span> -->
                                                <i class="fa fa-caret-up" style="color:grey;position: relative;font-size:25px;">
                                                    <i class="fa fa-caret-up" style="color: white;position: absolute;font-size:18px;top:3px;right:2px;"></i>
                                                </i>
                                            </span>
                                        </span>
                                        <span *ngIf="node.data.isRelevant == false || node.data.isRelevant == null" style="border-left:4px lightgray solid;padding-left:4px" [innerHTML]="node.data.name"></span>
                                        <span *ngIf="node.data.isRelevant == true && (node.data.compliantStatus == 'none' || node.data.compliantStatus == null)" style="border-left:4px #b32d00 solid;padding-left:4px;" [innerHTML]="node.data.name"></span>
                                        <span *ngIf="node.data.isRelevant == true && node.data.compliantStatus == 'partial'" style="border-left:4px #ffcc00 solid;padding-left:4px;" [innerHTML]="node.data.name"></span>
                                        <span *ngIf="node.data.isRelevant == true && node.data.compliantStatus == 'full'" style="border-left:4px #008000 solid;padding-left:4px;" [innerHTML]="node.data.name"></span>
                                        <span *ngIf="node.data.isRelevant == true && node.data.compliantStatus == 'not_assessed'" style="border-left:4px grey solid;padding-left:4px;" [innerHTML]="node.data.name"></span>
                                        <span *ngIf="node.data.isRelevant == true && node.data.compliantStatus == 'not_applicable'" style="border-left:4px lightblue solid;padding-left:4px" [innerHTML]="node.data.name"></span>
                                    </ng-template>
                                </tree-root>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="row">
                        <div class="col-md-4">
                            <div>
                                <div class="element-info-label">Relevance</div>
                                <div class="element-info-value">
                                    <ng-select id="elementRelevancePrev" style="width:125px;" [clearable]="false" *ngIf="selectedArticlePreviousVersionObj.regulations" [items]="relevanceOptions" bindLabel="label"
                                        bindValue="value" (change)="onChangeRelevance('previousVersion')" [(ngModel)]="selectedArticlePreviousVersionObj.regulations.isRelevant">
                                        <ng-template ng-label-tmp let-item="item">
                                            <div style="display:flex;">
                                                <div style="width:4px;border-radius:4px;margin-right:4px;" [style.background]="item.barColor"></div>
                                                <div>{{item.label}}</div>
                                            </div>
                                        </ng-template>
                                    </ng-select>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div>
                                <div class="element-info-label">Compliance</div>
                                <div class="element-info-value">
                                    <ng-select id="elementCompliancePrev" style="width:150px;" *ngIf="selectedArticlePreviousVersionObj.regulations" [disabled]="selectedArticlePreviousVersionObj.regulations.isRelevant == false"
                                        [items]="complianceOptions" bindLabel="label" bindValue="value" [clearable]="false"
                                        (change)="updateCompliantStatus('previousVersion')" [(ngModel)]="selectedArticlePreviousVersionObj.regulations.compliantStatus">
                                        <ng-template ng-label-tmp let-item="item">
                                            <div style="display:flex;">
                                                <div style="width:4px;border-radius:4px;margin-right:4px;padding:2px;" [style.background]="item.barColor"></div>
                                                <div>{{item.label}}</div>
                                            </div>
                                        </ng-template>
                                    </ng-select>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div>
                                <div class="element-info-label">Assessment Owner</div>
                                <div class="element-info-value">
                                    <ng-select id="elementAssessmentOwner" [items]="usersList" *ngIf="selectedArticlePreviousVersionObj.assessments" bindLabel="name" bindValue="id" [clearable]="false"
                                        (change)="updateAssessment('previousVersion')" [(ngModel)]="selectedArticlePreviousVersionObj.assessments.assessmentOwnerId">
                                        <ng-template ng-label-tmp let-item="item">
                                            <div>{{item.name}}</div>
                                        </ng-template>
                                    </ng-select>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12" style="margin-top:20px;">
                            <div class="element-info-label">
                                Assessment
                                <span style="width:16px;cursor:pointer;" (click)="editPreviousVersionAssessmentFlag = !editPreviousVersionAssessmentFlag">
                                    <img src="../../../assets/images/icon-edit.svg" />
                                </span>
                                <span *ngIf="editPreviousVersionAssessmentFlag" (click)="updateAssessment('previousVersion')" style="margin-left:8px;cursor:pointer;">
                                    <i style="font-size:18px;" class="fa fa-save"></i>
                                </span>
                            </div>
                            <div *ngIf="!editPreviousVersionAssessmentFlag && selectedArticlePreviousVersionObj.assessments" class="element-info-value">{{selectedArticlePreviousVersionObj.assessments.comment}}</div>
                            <div *ngIf="editPreviousVersionAssessmentFlag && selectedArticlePreviousVersionObj.assessments" class="element-info-value">
                                <textarea [(ngModel)]="selectedArticlePreviousVersionObj.assessments.comment" rows="15" style="resize:none;border:1px solid lightgray;border-radius:5px;width:100%;">
                                </textarea>
                            </div>
                        </div>
                        <!--regart Comment-->
                        <div class="col-md-12" style="margin-top:20px;" *ngIf="selectedArticlePreviousVersionObj.regulations && selectedArticlePreviousVersionObj.regulations.regartComment && selectedArticlePreviousVersionObj.regulations.regartComment.length">
                            <div class="element-info-label">Regart Comment</div>
                            <div class="element-info-value" *ngFor="let comment of selectedArticlePreviousVersionObj.regulations.regartComment">
                                <div [innerHTML]="comment"></div>
                            </div>
                        </div> 
                        <!--regart Comment-->
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-7"></div>
                <div class="col-md-5">
                    <div class="row">
                        <div class="col-md-12">
                            <div class="article-actions-list" style="margin-top:15px;">
                                <table class="width100" *ngIf="previousVersionActionsListData && previousVersionActionsListData.length">
                                    <thead>
                                        <tr>
                                            <th>Action</th>
                                            <th>Action Owner/Department</th>
                                            <th>Action Status</th>
                                            <th>Action Due Date</th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let item of previousVersionActionsListData;">
                                            <td style="width:300px;white-space: pre-line;text-align:left;">{{item.action}}</td>
                                            <td>{{item.actionOwnerName}}<br>{{item.actionOwnerDepartment}}</td>
                                            <td>{{item.status == 'in_progress' ? 'In Progress' : item.status == 'completed' ? 'Completed' : item.status == 'new' ? 'New' : 'New'}}</td>
                                            <td>{{item.displayDueDate}}</td>
                                            <td style="display: inline-flex;">
                                                <span (click)="updateSelectedActionModal(item, 'previousVersion')" style="cursor:pointer;outline:none;">
                                                    <img src="../../../assets/images/icon-edit.svg" style="height:14px;"/>
                                                </span>
                                                <span  style="cursor:pointer;outline:none;margin-left:10px;">
                                                    <i (click)="deleteAction(item._id, 'previousVersion')" class="fa fa-trash" style="font-size:14px;"></i>
                                                </span>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div>
                                <button type="button" *ngIf="showPreviousVersionAction" (click)="clearActionModalData('previousVersion')" class="add-new-action-btn" data-toggle="modal" data-target="#addNewActionPreviousVersionModal">
                                    <img src="../../../assets/images/icon-new-add.svg" />
                                    <span>New Action</span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        
        <!--document references-->
        <div class="reporting-element-details-box" *ngIf="documentReferences && documentReferences.length">
            <div class="row">
                <div class="col-md-12">
                    <div class="element-info-label" style="font-size:15px;">Document References</div>
                    <div>
                        <ul>
                            <li *ngFor="let item of documentReferences" style="font-size:12px;">
                                <a style="cursor:pointer;" href="javascript: void(0);" (click)="navigateToPdf(item)">
                                    {{item.title}}
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>

        

        <!--Article References List-->
        <div class="articles-reference-list formula-div" style="z-index:100;position:relative;">
            <div class="head-value" style="margin-bottom:15px;">Reference Articles
                <span style="margin-left:10px; margin-top:-2.5px;" *ngIf="referencesList.length">
                    <button type="button" class="save-changes-btn" (click)="updateReferencesArticles()">Save Changes</button>
                </span>
            </div>
            <div class="reference-articles-list-container" id="reference-articles-container" class="formula-div">
                <div class="hot" style="font-family: 'Montserrat Medium';font-size: 12px;z-index:100;position:relative;" id="reference-articles-table">
                </div>
            </div>
            <div class="head-value" *ngIf="referencesList.length == 0" style="font-size:14px;margin-top:10px;">No References</div>
        </div>

        <!--QnA-->
        <div class="articles-reference-list" *ngIf="qnaData && qnaData.length" style="z-index:100;position:relative;">
            <div class="head-value" style="margin-bottom:15px;">
                QnA
            </div>
        </div>
        <div class="reporting-element-details-box" *ngIf="qnaData && qnaData.length" style="z-index:100;position:relative;">
            <div class="row">
                <div class="col-md-12">
                    <!---->
                    <div >
                        <table style="width:100%;">
                            <thead>
                                <tr style="margin-bottom:10px;">
                                    <th class="element-info-label" style="padding-bottom:10px;">Document</th>
                                    <th class="element-info-label" style="padding-bottom:10px;">Regart Comment</th>
                                    <th class="element-info-label" style="padding-bottom:10px;">Notes</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let item of qnaData" style="font-size:12px;margin-bottom:10px;">
                                    <td style="padding-bottom:15px;">
                                        <a style="cursor:pointer;"  href="javascript: void(0);" (click)="openQnA(item)">
                                            {{item.qa_id}}
                                        </a>
                                    </td>
                                    <td class="element-info-value" style="padding-bottom:15px;">
                                        {{item.regartComment || ''}}
                                    </td>
                                    <td style="width:40%;padding-bottom: 15px;">
                                        <div style="display: flex;">
                                            <div>
                                                <span style="width:16px;cursor:pointer;" class="pull-right" (click)="item.editQnA = !item.editQna">
                                                    <img src="../../../assets/images/icon-edit.svg" />
                                                </span>
                                                <span *ngIf="item.editQnA" (click)="editQnANotes(item)" class="pull-right" style="margin-left:8px;cursor:pointer;">
                                                    <i style="font-size:18px;" class="fa fa-save"></i>
                                                </span>
                                            </div>
                                            <div style="margin-left:8px;">
                                                <div *ngIf="!item.editQnA" class="element-info-value" >{{item.notes || ''}}</div>
                                                <div *ngIf="item.editQnA" class="element-info-value">
                                                    <textarea [(ngModel)]="item.notes" rows="10" style="resize:none;border:1px solid lightgray;border-radius:5px;width:100%;">
                                                    </textarea>
                                                </div>
                                            </div>
                                            
                                            
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <!---->
                    
                </div>
            </div>
        </div>

        <!--Indirect Article References List-->
        <div class="articles-reference-list" style="z-index:100;position:relative;" *ngIf="selectedArticleDetailsObj.regulations && selectedArticleDetailsObj.regulations.document && selectedArticleDetailsObj.regulations.document.type != 'CL'">
            <div class="head-value" style="margin-bottom:15px;">Indirect References
                <span style="margin-left:10px; margin-top:-2.5px;" *ngIf="indirectReferencesList.length">
                    <button type="button" class="save-changes-btn" (click)="updateIndirectReferencesArticles()">Save Changes</button>
                </span>
            </div>
            <div class="indirect-reference-articles-list-container" id="indirect-reference-articles-container">
                <div class="hot" style="font-family: 'Montserrat Medium';font-size: 12px;" id="indirect-reference-articles-table">
                </div>
            </div>
            <div class="head-value" *ngIf="indirectReferencesList.length == 0" style="font-size:14px;margin-top:10px;">No References</div>
        </div>
        <!---->

        <!--indirect references reporting element -->
    <div class="reporting-elements-reference-list-container" *ngIf="indirect_row_col_ref && indirect_row_col_ref.length">
        <div class="head-value">Indirect Reporting References</div>
        <div class="reporting-element-actions-list">
                <table class="width100" style="table-layout: fixed;">
                    <thead>
                        <tr style="background: #f0f0f0;">
                            <th style="width:105px;">Template Code</th>
                            <th style="width:300px;">Template Name</th>
                            <th style="width:100px;">Row ID</th>
                            <th style="width:100px;">Column ID</th>
                            <th >Row Name</th>
                            <th>Column Name</th>
                            <th>Document</th>
                            
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let item of indirect_row_col_ref">
                            <td style="text-align:left;">{{item.template ? item.template.code : ''}}</td>
                            <td style="text-align:left;">
                                {{item.template ? item.template.title : ''}}
                            </td>
                            <td style="text-align:left;" [ngStyle]="!item.row && {'background-color': 'lightgrey'}">
                                <a href="javascript: void(0);" (click)="navigateToReportingReferences(item)">
                                    {{item.row ? item.row.identifier : ''}}
                                </a>
                            </td>
                            <td style="text-align:left;" [ngStyle]="!item.column && {'background-color': 'lightgrey'}">
                                <a href="javascript: void(0);" (click)="navigateToReportingReferences(item)">
                                    {{item.column ? item.column.identifier : ''}}
                                </a>
                            </td>
                            <td style="text-align:left;" [ngStyle]="!item.row && {'background-color': 'lightgrey'}">
                                <!-- <span [title]="item.row ? item.row.instruction : ''">{{item.row ? item.row.instructionDisplay : ''}}</span> -->
                                <span *ngIf="item.row && !item.row.expandText" [title]="item.row ?item.row.instruction : ''">
                                    {{item.row ? item.row.instructionDisplay : ''}}
                                </span>
                                <span *ngIf="item.row && item.row.expandText" [title]="item.row ?item.row.instruction : ''">
                                    {{item.row ? item.row.instruction : ''}}
                                </span>
                                <span *ngIf="item.row && !item.row.expandText">
                                    <button style="cursor:pointer;border:none;outline:none;color:#a5473e;background:none;"
                                        (click)="item.row.expandText = !item.row.expandText">
                                        Show More
                                    </button>
                                </span>
                                <span *ngIf="item.row && item.row.expandText">
                                    <button style="cursor:pointer;border:none;outline:none;color:#a5473e;background:none;"
                                        (click)="item.row.expandText = !item.row.expandText">
                                        Show Less
                                    </button>
                                </span>
                            </td>
                            <td style="text-align:left;" [ngStyle]="!item.column && {'background-color': 'lightgrey'}">
                                <span *ngIf="item.column && !item.column.expandText" [title]="item.column ?item.column.instruction : ''">
                                    {{item.column ? item.column.instructionDisplay : ''}}
                                </span>
                                <span *ngIf="item.column && item.column.expandText" [title]="item.column ?item.column.instruction : ''">
                                    {{item.column ? item.column.instruction : ''}}
                                </span>
                                <span *ngIf="item.column && !item.column.expandText">
                                    <button style="cursor:pointer;border:none;outline:none;color:#a5473e;background:none;"
                                        (click)="item.column.expandText = !item.column.expandText">
                                        Show More
                                    </button>
                                </span>
                                <span *ngIf="item.column && item.column.expandText">
                                    <button style="cursor:pointer;border:none;outline:none;color:#a5473e;background:none;"
                                        (click)="item.column.expandText = !item.column.expandText">
                                        Show Less
                                    </button>
                                </span>
                            </td>
                            <td style="text-align:left;">
                                {{item.document ? item.document.title : ''}}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
    </div>
    <!--indirect references reporting element-->
    </div>
</div>

<!--images modal-->
<div class="modal fade" id="ImagesModal" tabindex="-1" role="dialog" aria-labelledby="actionsLabel" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header" style="padding:5px 10px;">
              <h5 class="modal-title actionlist-modal-header" id="actionsLabel">Linked Images</h5>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body" style="max-height: 400px;overflow-y:scroll;">
                <div class="row" *ngFor="let image of imagesListArticle" style="text-align:center;margin-top:10px;padding-left:15px;padding-right:15px;">
                    <img src={{image}}  style="width:80%;" />
                </div>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
            </div>
        </div>
    </div>
</div>

<!-- relevance confirmation modal for curreent-->
<div class="modal fade" id="relevanceConfirmationModal" tabindex="-1" role="dialog" aria-labelledby="actionsLabel" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header" style="padding:5px 10px;">
              <h5 class="modal-title actionlist-modal-header" id="actionsLabel">Confirmation</h5>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="selectedArticleDetailsObj.regulations.isRelevant = true">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body" style="max-height: 400px;overflow-y:scroll;">
                <div>
                    Please note by marking this item as 'Not Relevant', all elements
                    under this item will also be marked as 'Not Relevant'.
                </div>
                <div>
                    Do you wish to proceed?
                </div>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-primary" (click)="setRelevancetoNo('current')" data-dismiss="modal">Yes</button>
              <button type="button" class="btn btn-secondary" (click)="setRelevancetoyes('current')" data-dismiss="modal">No</button>
            </div>
        </div>
    </div>
</div>

<!-- Previous Version relevance no confirmation Modal-->
<div class="modal fade" id="relevancePreviousVersionConfirmationModal" tabindex="-1" role="dialog" aria-labelledby="actionsLabel" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header" style="padding:5px 10px;">
              <h5 class="modal-title actionlist-modal-header" id="actionsLabel">Confirmation</h5>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="selectedArticlePreviousVersionObj.regulations.isRelevant = true">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body" style="max-height: 400px;overflow-y:scroll;">
                <div>
                    Please note by marking this item as 'Not Relevant', all elements
                    under this item will also be marked as 'Not Relevant'.
                </div>
                <div>
                    Do you wish to proceed?
                </div>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-primary" (click)="setRelevancetoNo('previousVersion')" data-dismiss="modal">Yes</button>
              <button type="button" class="btn btn-secondary" (click)="setRelevancetoyes('previousVersion')" data-dismiss="modal">No</button>
            </div>
          </div>
        </div>
    </div>

<!-- Future Version relevance no confirmation Modal-->
<div class="modal fade" id="relevanceFutureVersionConfirmationModal" tabindex="-1" role="dialog" aria-labelledby="actionsLabel" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header" style="padding:5px 10px;">
              <h5 class="modal-title actionlist-modal-header" id="actionsLabel">Confirmation</h5>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="selectedArticleFutureVersionObj.regulations.isRelevant = true">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body" style="max-height: 400px;overflow-y:scroll;">
                <div>
                    Please note by marking this item as 'Not Relevant', all elements
                    under this item will also be marked as 'Not Relevant'.
                </div>
                <div>
                    Do you wish to proceed?
                </div>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-primary" (click)="setRelevancetoNo('futureVersion')" data-dismiss="modal">Yes</button>
              <button type="button" class="btn btn-secondary" (click)="setRelevancetoyes('futureVersion')" data-dismiss="modal">No</button>
            </div>
          </div>
        </div>
    </div>


<!--Current Version Add Action Modal-->
<div class="modal fade" id="addNewActionModal" tabindex="-1" role="dialog" aria-labelledby="actionsLabel" aria-hidden="true">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header" style="padding:5px 10px;">
          <h5 class="modal-title actionlist-modal-header" *ngIf="!editAction" id="actionsLabel">Add New Action</h5>
          <h5 class="modal-title actionlist-modal-header" *ngIf="editAction" id="actionsLabel">Edit Action</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
            <div class="row">
                <div class="col-md-6 form-group">
                            <label class="actionlist-modal-labels" style="margin-bottom:0px;">Due Date</label>
                            <div class="input-box-container">
                                    <input class="input-box form-control" placeholder="Click to select a date"
                                      angular-mydatepicker name="mydate" (click)="dp.toggleCalendar()"
                                      [(ngModel)]="actionDueDate" [options]="myDpOptions"
                                      #dp="angular-mydatepicker"/>
                            </div>
                </div>
                <div class="col-md-6 form-group">
                    <label class="actionlist-modal-labels" style="margin-bottom:0px;">Action Owner</label>
                    <ng-select id="elementActionOwner" class="form-control" style="padding:0px;border:none;"
                        [items]="usersList"
                        bindLabel="name"

                        bindValue="id"
                        [clearable]="false"

                        [(ngModel)]="newCurrentVersionActionObj.actionOwnerId">
                            <ng-template ng-label-tmp let-item="item">
                                <div>{{item.name}}</div>
                            </ng-template>
                    </ng-select>
                </div>
            </div>
            <div class="row">
                <div class="col-md-6 form-group">
                    <label class="actionlist-modal-labels" style="margin-bottom:0px;">Action *</label>
                    <textarea class="form-control" type="text" [(ngModel)]="newCurrentVersionActionObj.action"></textarea>
                </div>
                <div class="col-md-6 form-group">
                    <label class="actionlist-modal-labels" style="margin-bottom:0px;">Action Status</label>
                    <ng-select id="elementActionStatus" class="form-control" style="padding:0px;border:none;"
                        [items]="actionStatusList"
                        bindLabel="label"

                        bindValue="val"
                        [clearable]="false"
                        [(ngModel)]="newCurrentVersionActionObj.status">
                            <ng-template ng-label-tmp let-item="item">
                                <div>{{item.label}}</div>
                            </ng-template>
                    </ng-select>
                </div>
            </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal">Cancel</button>
          <button type="button" *ngIf="!editAction" class="btn btn-primary" (click)="addNewAction('current')" data-dismiss="modal">Add Action</button>
          <button type="button" *ngIf="editAction" class="btn btn-primary" (click)="updateAction('current')" data-dismiss="modal">Update Action</button>
        </div>
      </div>
    </div>
</div>

<!--previousVersion Action Modal-->
<div class="modal fade" id="addNewActionPreviousVersionModal" tabindex="-1" role="dialog" aria-labelledby="actionsLabel" aria-hidden="true">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header" style="padding:5px 10px;">
          <h5 class="modal-title actionlist-modal-header" *ngIf="!editPreviousVersionAction" id="actionsLabel">Add New Action</h5>
          <h5 class="modal-title actionlist-modal-header" *ngIf="editPreviousVersionAction" id="actionsLabel">Edit Action</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
            <div class="row">
                <div class="col-md-6 form-group">
                            <label class="actionlist-modal-labels" style="margin-bottom:0px;">Due Date</label>
                            <div class="input-box-container">
                                    <input class="input-box form-control" placeholder="Click to select a date"
                                      angular-mydatepicker name="mydateother" (click)="dp1.toggleCalendar()"
                                      [(ngModel)]="actionPreviousVersionDueDate" [options]="myDpOptions"
                                      #dp1="angular-mydatepicker"/>
                            </div>
                </div>
                <div class="col-md-6 form-group">
                    <label class="actionlist-modal-labels" style="margin-bottom:0px;">Action Owner</label>
                    <ng-select id="elementActionOwner" class="form-control" style="padding:0px;border:none;"
                        [items]="usersList"
                        bindLabel="name"

                        bindValue="id"
                        [clearable]="false"

                        [(ngModel)]="newPreviousVersionActionObj.actionOwnerId">
                            <ng-template ng-label-tmp let-item="item">
                                <div>{{item.name}}</div>
                            </ng-template>
                    </ng-select>
                </div>
            </div>
            <div class="row">
                <div class="col-md-6 form-group">
                    <label class="actionlist-modal-labels" style="margin-bottom:0px;">Action *</label>
                    <textarea class="form-control" type="text" [(ngModel)]="newPreviousVersionActionObj.action"></textarea>
                </div>
                <div class="col-md-6 form-group">
                    <label class="actionlist-modal-labels" style="margin-bottom:0px;">Action Status</label>
                    <ng-select id="elementActionStatus" class="form-control" style="padding:0px;border:none;"
                        [items]="actionStatusList"
                        bindLabel="label"

                        bindValue="val"
                        [clearable]="false"
                        [(ngModel)]="newPreviousVersionActionObj.status">
                            <ng-template ng-label-tmp let-item="item">
                                <div>{{item.label}}</div>
                            </ng-template>
                    </ng-select>
                </div>
            </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal">Cancel</button>
          <button type="button" *ngIf="!editPreviousVersionAction" class="btn btn-primary" (click)="addNewAction('previousVersion')" data-dismiss="modal">Add Action</button>
          <button type="button" *ngIf="editPreviousVersionAction" class="btn btn-primary" (click)="updateAction('previousVersion')" data-dismiss="modal">Update Action</button>
        </div>
      </div>
    </div>
</div>

<!--future Version Action Modal-->
<div class="modal fade" id="addNewActionFutureVersionModal" tabindex="-1" role="dialog" aria-labelledby="actionsLabel" aria-hidden="true">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header" style="padding:5px 10px;">
          <h5 class="modal-title actionlist-modal-header" *ngIf="!editFutureVersionAction" id="actionsLabel">Add New Action</h5>
          <h5 class="modal-title actionlist-modal-header" *ngIf="editFutureVersionAction" id="actionsLabel">Edit Action</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
            <div class="row">
                <div class="col-md-6 form-group">
                            <label class="actionlist-modal-labels" style="margin-bottom:0px;">Due Date</label>
                            <div class="input-box-container">
                                    <input class="input-box form-control" placeholder="Click to select a date"
                                      angular-mydatepicker name="mydateother" (click)="dp2.toggleCalendar()"
                                      [(ngModel)]="actionFutureVersionDueDate" [options]="myDpOptions"
                                      #dp2="angular-mydatepicker"/>
                            </div>
                </div>
                <div class="col-md-6 form-group">
                    <label class="actionlist-modal-labels" style="margin-bottom:0px;">Action Owner</label>
                    <ng-select id="elementActionOwner" class="form-control" style="padding:0px;border:none;"
                        [items]="usersList"
                        bindLabel="name"

                        bindValue="id"
                        [clearable]="false"

                        [(ngModel)]="newFutureVersionActionObj.actionOwnerId">
                            <ng-template ng-label-tmp let-item="item">
                                <div>{{item.name}}</div>
                            </ng-template>
                    </ng-select>
                </div>
            </div>
            <div class="row">
                <div class="col-md-6 form-group">
                    <label class="actionlist-modal-labels" style="margin-bottom:0px;">Action *</label>
                    <textarea class="form-control" type="text" [(ngModel)]="newFutureVersionActionObj.action"></textarea>
                </div>
                <div class="col-md-6 form-group">
                    <label class="actionlist-modal-labels" style="margin-bottom:0px;">Action Status</label>
                    <ng-select id="elementActionStatus" class="form-control" style="padding:0px;border:none;"
                        [items]="actionStatusList"
                        bindLabel="label"

                        bindValue="val"
                        [clearable]="false"
                        [(ngModel)]="newFutureVersionActionObj.status">
                            <ng-template ng-label-tmp let-item="item">
                                <div>{{item.label}}</div>
                            </ng-template>
                    </ng-select>
                </div>
            </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal">Cancel</button>
          <button type="button" *ngIf="!editFutureVersionAction" class="btn btn-primary" (click)="addNewAction('futureVersion')" data-dismiss="modal">Add Action</button>
          <button type="button" *ngIf="editFutureVersionAction" class="btn btn-primary" (click)="updateAction('futureVersion')" data-dismiss="modal">Update Action</button>
        </div>
      </div>
    </div>
</div>