import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DataServiceService } from '../../shared/services/data-service/data-service.service';
import { environment } from '../../../environments/environment';
import { ToastrService } from 'ngx-toastr';
import { CookieService } from 'ngx-cookie';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  userLoginCreds: any = {email: null, password: null};
  showPassword: boolean = false;
  showForgotPasswordForm: boolean = false;
  forgotPassEmailSentSuccess: boolean = false;

  constructor(
    private router: Router,
    private _dataService: DataServiceService,
    private _toastr: ToastrService,
    private _cookie: CookieService,
    
  ) { }

  ngOnInit(): void {
  }

  onLoginSubmit(): void{
    var url = environment.apiUrls.loginUrl;

    this._dataService.postData(url, this.userLoginCreds, false).toPromise()
    .then((response: any) => {
      if(response.status == 1){
        this._cookie.put("token", response.data.token);
        this._cookie.put("_id", response.data._id);
        this._cookie.put("firstName", response.data.firstName);
        this._cookie.put("lastName", response.data.lastName);
        this._cookie.put("email", response.data.email);
        localStorage.getItem('dashboardArticleNavDocId') ? localStorage.removeItem('dashboardArticleNavDocId') : '';
        localStorage.getItem('dashboardArticleNavKey') ? localStorage.removeItem('dashboardArticleNavKey') : '';
        localStorage.getItem('dashboardArticleNavVal') ? localStorage.removeItem('dashboardArticleNavVal') : '';
        this._toastr.success(response.message);
        this.router.navigate(['/dashboard']);
      }else{
        this._toastr.error(response.message);
      }
    },(error) => {
      if(error.error){
          this._toastr.error(error.error.message);
      }
    });
  }

  getResetPasswordLink(): void{
    var url = environment.apiUrls.forgotPasswordUrl;
    var data = {
      email: this.userLoginCreds.email
    }

    this._dataService.postData(url, data, false).toPromise()
    .then((response: any) => {
      if(response.status == 1){
        this._toastr.success(response.message);
        this.forgotPassEmailSentSuccess = true;
        this.showForgotPasswordForm = false;
      }
    },(error) => {
      if(error.error){
          this._toastr.error(error.error.message);
      }
    });
  }

}
