<div class="rag-container">
    <div class="bar-text">
        RAG Status
    </div>
    <div class="status-bar" style="cursor:pointer;" *ngIf="ragStatusData">
        <span class="r-status status-bar-perc" [style.width.%]="rValue" title="{{rValue + '%' + ' fully compliant'}}"
        [ngClass]="{'round-corner-left': rValue == 100, 'round-corner-right': rValue == 100}">
            {{rValue + '%'}}
        </span>
        <span class="a-status status-bar-perc" [style.width.%]="aValue" title="{{aValue + '%' + ' partial compliant'}}"
        [ngClass]="{'round-corner-left': aValue == 100 || rValue == 0, 'round-corner-right': aValue == 100 || (gValue == 0 && naValue == 0)}">
            {{aValue + '%'}}
        </span>
        <span class="na-status status-bar-perc" [style.width.%]="naValue" title="{{naValue + '%' + ' not assessed'}}"
        [ngClass]="{'round-corner-left': naValue == 100 || (aValue == 0 && rValue == 0), 'round-corner-right': naValue == 100 || gValue == 0}">
            {{naValue + " %"}}
        </span>
        <span class="g-status status-bar-perc" [style.width.%]="gValue" title="{{gValue + '%' + ' not compliant'}}"
        [ngClass]="{'round-corner-left': gValue == 100, 'round-corner-right': gValue == 100}">
            {{gValue + '%'}}
        </span>
    </div>
</div>
