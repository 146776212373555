<div class="actions-list-view-container">
    <!--header-->
    <div class="actions-list-header">
        <div class="header-title">
            <!-- Liquidity Risk -->
            <span style="margin-left:10px; margin-top:-2.5px;">
                <button type="button" class="save-changes-btn" (click)="updateActions()">Save Changes</button>
            </span>
        </div>
        <div class="header-right-section">
            <!-- <div class=rag-status>
                <app-r-a-g-status></app-r-a-g-status>
            </div> -->
            <!-- <div class="column-customization-box">
                <span>Customize Column</span>
                <img src="../../../assets/images/icon-dropdown.svg" />
            </div> -->
        </div>
    </div>
    <!--header-->
    <!--actions list content table-->
    <div class="actions-list-container">
        <div class="actions-list-table-container" id="actions-list-table-container">
            <div class="hot" style="font-family: 'Montserrat Medium';font-size: 12px;" >
                <hot-table class="hot" [data]="actionsListTableData" [colHeaders]="true" [rowHeaders]="false" [settings]="hotSettings"
                        [hotId]="'actions-list-table'">
                </hot-table>
            </div>
        </div>
    </div>
    <!--actions list content table-->
    <!--loader-->
    <div style="position: absolute;top:40%;left:40%;" *ngIf="showLoader">
        <img src="../../../assets/images/loader1.gif"/>
    </div>
<!---->
</div>